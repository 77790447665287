import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import Switch from '../../atoms/Switch/Switch';
import ControlType from '../../template/ControlType/ControlType';

function OKNEKOSeulList({ list, submit }) {
  // Hooks
  const { t } = useTranslation();
  const columns = [
    {
      label: '',
      width: 160,
    },
    {
      label: t('inputs.state'),
      width: 120,
    },
  ];
  const initialList = list.map((input) => ({ ...input, isDirty: false }));
  const [inputsList, setInputsList] = useState(initialList);

  const handleChange = (input, event) => {
    const resultsChanged = inputsList.map((inputState) => {
      if (inputState.id === input.id) return ({ ...input, checked: event, isDirty: true });
      return inputState;
    });
    setInputsList(resultsChanged);
  };

  const handleValuesSubmit = () => {
    const valuesToSubmit = inputsList
      .filter((input) => {
        if (input.isDirty && input.checked !== null) return true;
        return false;
      })
      .map((input) => ({
        inputId: input?.id,
        checked: input?.checked,
        comment: input?.comment,
      }));
    submit(valuesToSubmit);
    // setInputsList(initialList);
  };

  return (
    <ControlType columns={columns} onSubmit={handleValuesSubmit}>
      {inputsList.map((input, index) => (
        <div key={input.id} className="inputForm" data-input-type={input?.inputType}>
          <div className="inputForm_control inputForm_label">
            {input.title}
          </div>
          <div className="inputForm_control inputForm_switch">
            <Switch
              id={`ok-ne-seul-${input.id}`}
              value={inputsList[index].checked}
              onChange={(event) => handleChange(input, event)}
            />
          </div>
        </div>
      ))}
    </ControlType>
  );
}

OKNEKOSeulList.propTypes = {
  list: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
};

export default OKNEKOSeulList;
