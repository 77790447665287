/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  useParams, useNavigate, Link,
} from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import Layout from '../../components/template/Layout';
import SelectForm from '../../components/atoms/Select/Select';
import MultiTextInput from '../../components/molecules/MultiTextInput/MultiTextInput';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Radio from '../../components/atoms/Radio/Radio';
import Permissions from '../../components/molecules/Permissions/Permissions';
// hooks
import useAppContext from '../../store/useAppContext';

// services(r)
import {
  editInterventionInformations,
  getIntervention,
  postInterventionInformations,
} from '../../services/interventions';
import { getVehiculelist } from '../../services/vehicules';
import { getBuildingsList } from '../../services/structures';
import { getEquipmentsList } from '../../services/equipments';
import { getControlsList } from '../../services/controls';
import { getSocietiesList } from '../../services/societies';
import { getUsersList } from '../../services/users';
import { INPUT_TYPES, USERS_ROLES } from '../../utils/constant';
import LightningSecurityEquipementList from '../../components/template/LightningSecurityEquipementList/LightningSecurityEquipementList';

// utils
import { formatDateToInput } from '../../utils/dates';

function AddIntervention() {
  const { t } = useTranslation();
  const urlParams = useParams();
  const [context] = useAppContext();
  const isEditing = urlParams.action === 'edit';
  const isViewing = urlParams.action === 'view';
  const isCreating = !isEditing && !isViewing;
  const [buildingId, setBuildingId] = useState(null);
  const [editOn, setEditOn] = useState(null);
  const establishmentId = context.choiceEstablishment.id;
  const [interventionType, setInterventionType] = useState(null);
  const [interventionInputType, setInterventionInputType] = useState(null);
  const [equipmentId, setEquipmentId] = useState(null);
  const isInputInterventionType = interventionType === 'input';
  const isSafetyLightningInterventionInputType = interventionInputType === 'eclairage_securite';

  const navigate = useNavigate();

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  // API Calls
  const getControlsListQuery = useQuery(['controls'], () => getControlsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment.id,
  }));
  const controlsList = getControlsListQuery?.data?.data?.controls || [];

  const getSocietiesListQuery = useQuery(['societiesList'], () => getSocietiesList({
    mine: true,
    structureId: context.choiceEstablishment.id,
    bookletId: context.choiceBooklet,
    establishmentId: context?.choiceEstablishment.id,
  }));
  const societiesList = getSocietiesListQuery?.data?.data?.societies || [];

  const getUsersListQuery = useQuery(['usersList'], () => getUsersList({
    structureId: context.choiceEstablishment.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment.id,
  }));
  const usersList = getUsersListQuery?.data?.data?.users ? getUsersListQuery?.data?.data?.users.concat({
    id: null,
    name: t('addInterventions.any_intervener'),
  })?.map((user) => ({ ...user, name: user.name ? user.name : `${user.firstName} ${user.lastName}` }))
    : [];

  const UserConcat = getUsersListQuery?.data?.data?.total === 0 || getUsersListQuery?.data?.data?.users === undefined ? usersList.concat({ id: null, name: t('addInterventions.any_intervener') }) : usersList;

  const postInterventionMutation = useMutation(postInterventionInformations, {
    onSuccess: () => {
      if (isInputInterventionType && equipmentId === 12 && !isEditing) {
        goBackUrl(t('addInterventions.addedSafetyLightingEquipment'));
      } else {
        goBackUrl(t('addInterventions.added'));
      }
    },
  });
  const editInterventionMutation = useMutation(editInterventionInformations, {
    onSuccess: () => goBackUrl(t('addInterventions.edited')),
  });

  const validationSchema = Yup.object().shape({
    building: Yup.number().nullable().required('global.required_field'),
    equipment: Yup.number().nullable().required('global.required_field'),
    vehicleId: Yup.number().nullable().when('equipment', {
      is: 129,
      then: Yup.number().nullable().required(t('global.required_field')),
    }),
    control: Yup.number().nullable().required('global.required_field'),
    suffixe: Yup.string().nullable(),
    suffixes: Yup.array().of(Yup.object().shape({
      label: Yup.string().min(2),
      value: Yup.string().min(2),
    })).nullable(),
    societyId: !isInputInterventionType
      ? Yup.number().nullable(true)
      : Yup.number().nullable(true),
    userId: Yup.number().nullable(true),
    controlPeriods: Yup.string().nullable(),
    controlDate: Yup.string().nullable().required('global.required_field'),
    frequency: Yup.string().nullable().required('global.required_field'),
    days: Yup.array().of(Yup.string()).nullable().when('frequency', {
      is: 'daily',
      then: Yup.array().of(Yup.string()).min(1, 'global.required_field'),
    }),
    weeklyMultipleOf: Yup.number().nullable().when('frequency', {
      is: 'weekly',
      then: Yup.number().nullable().required('global.required_field'),
    }),
    months: Yup.array().of(Yup.number()).nullable().when(['frequency', 'monthParam', 'type'], {
      is: (frequency, monthParam, type) => frequency === 'monthly' && monthParam === 'choice_multiple' && type !== 'report',
      then: Yup.array().of(Yup.number()).min(1, 'global.required_field'),
    }),
    monthlyMultipleOf: Yup.number().nullable().when(['frequency', 'monthParam', 'type'], {
      is: (frequency, monthParam, type) => (frequency === 'monthly' && monthParam === 'multiple_of') || (frequency === 'monthly' && type === 'report'),
      then: Yup.number().nullable().required('global.required_field'),
    }),
    yearlyMultipleOf: Yup.number().nullable().when('frequency', {
      is: 'yearly',
      then: Yup.number().nullable().required('global.required_field'),
    }),
    token: Yup.string().nullable(),
  });

  const initialValues = {
    building: null,
    equipment: null,
    control: null,
    suffixe: null,
    suffixes: [],
    userId: null,
    vehicleId: null,
    societyId: null,
    controlPeriod: null,
    controlDate: null,
    monthParam: 'multiple_of',
    frequency: null,
    days: [],
    weeklyMultipleOf: null,
    months: [],
    monthlyMultipleOf: null,
    yearlyMultipleOf: null,
    years: null,
    paramLowLimit: null,
    paramHighLimit: null,
    token: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      const monthlyParam = () => {
        if (formValues.frequency === 'monthly') return formValues.monthlyMultipleOf ? 'multiple_of' : 'choice_multiple';
        return null;
      };
      const valuesToSave = {
        ...formValues,
        buildingId: formValues.building ? formValues.building : null,
        equipmentId: formValues.equipment ? formValues.equipment : null,
        vehicleId: formValues.vehicleId ? formValues.vehicleId : null,
        controlId: formValues.control ? formValues.control : null,
        controlDate: formatDateToInput(formValues.controlDate),
        suffixes: formValues.suffixes.length === 0 ? null : formValues.suffixes.map((suffixe) => suffixe.value),
        type: formValues.type,
        monthlyParam: monthlyParam(),
        monthParam: undefined,
        paramLowLimit: formValues.paramLowLimit ? formValues.paramLowLimit : null,
        paramHighLimit: formValues.paramHighLimit ? formValues.paramHighLimit : null,
        token: formValues.token ? formValues.token : null,
      };
      if (isEditing) {
        editInterventionMutation.mutate(valuesToSave);
      } else {
        postInterventionMutation.mutate(valuesToSave);
      }
    },
  });
  const getBuildingsListQuery = useQuery(['buildings'], () => getBuildingsList({ establishmentId, bookletId: context.choiceBooklet }), {
    onSuccess: (data) => {
      setBuildingId(data.data.buildings[0].id);
      formik.setFieldValue('building', data.data.buildings[0].id);
    },
  });
  const buildingsList = getBuildingsListQuery?.data?.data?.buildings || [];

  const getEquipmentsListQuery = useQuery(['equipments'], () => getEquipmentsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment.id,
  }), {
    onSuccess: (data) => {
      if (context.choiceBooklet === 3) {
        formik.setFieldValue('equipment', data?.data?.equipments[0]?.id);
      }
    },
  });

  const equipmentsList = getEquipmentsListQuery?.data?.data?.equipments || [];

  const getInterventionQuery = useQuery('intervention', () => getIntervention(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment.id,
    enabled: !!urlParams.id,
    onSuccess: (data) => {
      setInterventionType(data.data.control.interventionType);
      setInterventionInputType(data.data.control.inputType);
      formik.setValues(
        {
          ...data.data,
          building: data.data.building.id,
          equipment: data.data.equipment.id,
          control: data.data.control.id,
          vehicleId: data.data.vehicle?.id ? data.data.vehicle?.id : null,
          controlPeriod: data.data.control.type.controlPeriod,
          controlDate: data.data.controlDate,
          type: data.data.control.interventionType,
          inputType: data.data.control.inputType,
          suffixe: data.data.suffixe ? data.data.suffixe : null,
          frequency: data.data.frequency,
          suffixes: [],
          monthParam: data.data.monthlyMultipleOf ? 'multiple_of' : 'choice_multiple',
          token: data.data.token ? data.data.token : null,
        },
      );
    },
  });

  const mapForSelect = (arrayToMap) => (arrayToMap.length
    ? sortBy(arrayToMap, ['name']).map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
    : []);

  const equipmentsForBuildingSelected = () => buildingsList && mapForSelect(buildingsList)?.find(
    (building) => building.value === formik.values.building,
  )?.equipmentIds;

  const handleSuffixeChange = (e) => {
    formik.setFieldValue('suffixe', e.target.value);
  };

  const handleSuffixesChange = (e) => {
    const updatedSuffixes = [...e];
    const lastIndex = updatedSuffixes.length - 1;
    if (lastIndex >= 0) {
      updatedSuffixes[lastIndex].value = updatedSuffixes[lastIndex].value.toUpperCase();
      updatedSuffixes[lastIndex].label = updatedSuffixes[lastIndex].label.toUpperCase();
    }
    formik.setFieldValue('suffixes', updatedSuffixes);
  };
  const controlSelected = controlsList?.find((control) => control?.id === formik.values.control);

  const titleIntervention = () => {
    const equipmentName = equipmentsList?.find(
      (equipment) => equipment.id === formik.values.equipment,
    )?.name || '...';

    const controlName = controlSelected?.name || '...';

    const intervenerName = isInputInterventionType
      ? mapForSelect(UserConcat)?.find((user) => user.id === formik.values.userId)?.name
      : mapForSelect(societiesList)?.find((society) => society.id === formik.values.societyId)?.name;

    const suffixeName = formik.values.suffixe ? `- ${formik.values.suffixe}` : '';
    return `${equipmentName} - ${controlName} - ${intervenerName ?? '...'} ${suffixeName}`;
  };
  const frequencies = [
    {
      label: t('addInterventions.daily'),
      value: 'daily',
    },
    {
      label: t('addInterventions.weekly'),
      value: 'weekly',
    },
    {
      label: t('addInterventions.monthly'),
      value: 'monthly',
    },
    {
      label: t('addInterventions.yearly'),
      value: 'yearly',
    },
    {
      label: t('addInterventions.ondeman'),
      value: 'ondemand',
    },
    {
      label: t('addInterventions.permanently'),
      value: 'permanently',
    },
  ].filter((freq) => {
    if (formik.values.controlPeriod === 'permanently' || controlSelected?.type?.controlPeriod === 'permanently') {
      if (freq.value === 'permanently') {
        return true;
      }
      return false;
    }
    if (formik.values.type === 'report') {
      if (freq.value === 'permanently' || freq.value === 'daily') return false;
      return true;
    }
    return true;
  });
  const dailyFrequency = [
    {
      label: t('addInterventions.monday'),
      value: 'mon',
    },
    {
      label: t('addInterventions.tuesday'),
      value: 'tue',
    },
    {
      label: t('addInterventions.wednesday'),
      value: 'wed',
    },
    {
      label: t('addInterventions.thursday'),
      value: 'thu',
    },
    {
      label: t('addInterventions.friday'),
      value: 'fri',
    },
    {
      label: t('addInterventions.saturday'),
      value: 'sat',
    },
    {
      label: t('addInterventions.sunday'),
      value: 'sun',
    },
  ];

  const monthsList = [
    {
      label: t('addInterventions.january'),
      value: 1,
    },
    {
      label: t('addInterventions.february'),
      value: 2,
    },
    {
      label: t('addInterventions.march'),
      value: 3,
    },
    {
      label: t('addInterventions.april'),
      value: 4,
    },
    {
      label: t('addInterventions.may'),
      value: 5,
    },
    {
      label: t('addInterventions.june'),
      value: 6,
    },
    {
      label: t('addInterventions.july'),
      value: 7,
    },
    {
      label: t('addInterventions.august'),
      value: 8,
    },
    {
      label: t('addInterventions.september'),
      value: 9,
    },
    {
      label: t('addInterventions.october'),
      value: 11,
    },
    {
      label: t('addInterventions.december'),
      value: 12,
    },
  ];

  const inputTypeTranslate = React.useMemo(() => [
    {
      value: INPUT_TYPES.OKNEKO_COMM_FACULT,
      label: t('add_control.OKNEKO_COMM_FACULT'),
    },
    {
      value: INPUT_TYPES.OKNEKO_COMM_OBLIG_TTLT,
      label: t('add_control.OKNEKO_COMM_OBLIG_TTLT'),
    },
    {
      value: INPUT_TYPES.OKNEKO_COMM_OBLIG_SI_KO,
      label: t('add_control.OKNEKO_COMM_OBLIG_SI_KO'),
    },
    {
      value: INPUT_TYPES.OKNEKO_COMM_OBLIG_SI_NE,
      label: t('add_control.OKNEKO_COMM_OBLIG_SI_NE'),
    },
    {
      value: INPUT_TYPES.OKNEKO_SEUL,
      label: t('add_control.OKNEKO_Seul'),
    },
    {
      value: INPUT_TYPES.INDEX_DATE_VOL_VOLJR,
      label: t('add_control.INDEX_DATE_VOL_VOLJR'),
    },
    {
      value: INPUT_TYPES.SSI_PERMANENT,
      label: t('add_control.SSI_PERMANENT'),
    },
    {
      value: INPUT_TYPES.T30S_TSTAB_TMPSTAB_HEURE,
      label: t('add_control.T30S_TSTAB_TMPSTAB_HEURE'),
    },
    {
      value: INPUT_TYPES.DEG_HEURE,
      label: t('add_control.DEG_HEURE'),
    },
    {
      value: INPUT_TYPES.PM30S_DEG_HEURE,
      label: t('add_control.PM30S_DEG_HEURE'),
    },
    {
      value: INPUT_TYPES.ECLAIRAGE_SECURITE,
      label: t('add_control.eclairage_securite'),
    },
    {
      value: INPUT_TYPES.GROUPE_ELECTROGENE,
      label: t('add_control.groupe_electrogene'),
    },
    {
      value: INPUT_TYPES.VALEUR_KILO,
      label: t('add_control.ValeurKilo'),
    },
    {
      value: INPUT_TYPES.VALEUR_BAIGNEUR,
      label: t('add_control.ValeurBaigneur'),
    },
    {
      value: INPUT_TYPES.VALEUR_PH,
      label: t('add_control.ValeurPH'),
    },
    {
      value: INPUT_TYPES.VALEUR_CHLORE,
      label: t('add_control.ValeurChlore'),
    },
    {
      value: INPUT_TYPES.VALEUR_TH,
      label: t('add_control.ValeurTH'),
    },
    {
      value: INPUT_TYPES.BALNEOTHERAPIE,
      label: t('add_control.Balneotherapie'),
    },
  ], []);

  const VehiculeQueryList = useQuery('vehicle', () => getVehiculelist({
    buildingId,
    bookletId: context?.choiceBooklet,
  }), {
    enabled: buildingId !== null && context.choiceBooklet === 3,
  });

  const navigateToken = (url) => {
    window.open(url, '_blank');
  };

  const MonthOptions = (nbrIndexOptions) => Array.from(Array(nbrIndexOptions).keys())
    .map((index) => ({ label: `Tous les ${index + 1} mois`, value: index + 1 }));

  const yearsOptions = (nbrYears) => Array.from(Array(nbrYears).keys())
    .map((year) => ({ label: `Tous les ${year + 1} ans`, value: year + 1 }));

  const weeklyOptions = (nbrWeeks) => Array.from(Array(nbrWeeks).keys())
    .map((week) => ({ label: `Toutes les ${week + 1} semaines`, value: week + 1 }));

  const handleCheckboxClick = (fieldName, fieldValue, isChecked) => formik.setFieldValue(
    fieldName,
    isChecked
      ? formik.values[fieldName].concat(fieldValue)
      : formik.values[fieldName].filter((id) => id !== fieldValue),
  );

  const mapForSelectVehicule = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: `${item?.brand} ${item?.model} ${item?.licensePlate}`,
      value: item.id,
    }))
    : []);
  useEffect(() => {
    if (formik.values.control === 253 || formik.values.control === 252) {
      formik.setFieldValue('frequency', 'ondemand');
    } else if (getInterventionQuery?.isLoading) {
      formik.setFieldValue('frequency', getInterventionQuery?.data?.data?.frequency);
    }
  }, [formik.values.control]);
  useEffect(() => {
    if (editOn === null && controlSelected?.interventionType) {
      setEditOn(controlSelected?.interventionType === 'report');
    }
  }, [controlSelected?.interventionType]);
  return (
    <Layout
      title={isCreating ? t('addInterventions.title_create') : t('addInterventions.title_edit')}
      queryError={
        getInterventionQuery?.error
        || getEquipmentsListQuery?.error
        || getControlsListQuery?.error
        || getSocietiesListQuery?.error
        || getUsersListQuery?.error
        || editInterventionMutation?.error
        || postInterventionMutation?.error
      }
    >
      <div>
        <header>
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('addInterventions.back_to_interventions')}</span>
            </button>
          </div>
          <h1 className="title">
            {isCreating ? t('addInterventions.title_create') : t('addInterventions.title_edit')}
          </h1>
        </header>
      </div>
      {
        getBuildingsListQuery.isLoading
        || getEquipmentsListQuery.isLoading
        || getControlsListQuery.isLoading
        || getSocietiesListQuery.isLoading
        || getUsersListQuery.isLoading
          ? <div className="loader" />
          : (
            <form onSubmit={formik.handleSubmit} className="form shadow-sm">
              {context.choiceBooklet !== 3 ? (
                <div>
                  {buildingsList.length > 1 ? (
                    <div className="form_group">
                      <SelectForm
                        id="building"
                        label={t('addInterventions.input_building')}
                        options={mapForSelect(buildingsList)}
                        value={
                          mapForSelect(buildingsList)
                            .find((building) => building.value === formik.values.building)
                        }
                        onChange={(option) => formik.setFieldValue('building', option.value)}
                        disabled={isViewing || isEditing}
                        valueInput={formik.values.building}
                        required
                        loading={getBuildingsListQuery.isLoading}
                      />
                      {formik.errors.building && formik.touched.building ? (
                        <div className="error">
                          {t(formik.errors.building)}
                        </div>
                      ) : null }
                    </div>
                  ) : (
                    <div className="form_group">
                      <SelectForm
                        id="building"
                        label={t('addInterventions.input_building')}
                        options={mapForSelect(buildingsList)}
                        value={
                          mapForSelect(buildingsList)
                            .find((building) => building.value === formik.values.building)
                        }
                        onChange={(option) => formik.setFieldValue('building', option.value)}
                        disabled={isViewing || isEditing}
                        valueInput={formik.values.building}
                        required
                        loading={getBuildingsListQuery?.isLoading}
                      />
                      {formik.errors.building && formik.touched.building ? (
                        <div className="error">
                          {t(formik.errors.building)}
                        </div>
                      ) : null }
                    </div>
                  )}
                </div>
              ) : null }
              {context.choiceBooklet !== 3 ? (
                <div className="form_group">
                  <SelectForm
                    id="equipments"
                    label={t('addInterventions.input_equipments')}
                    options={
                      mapForSelect(equipmentsList)
                        .filter((equipment) => equipmentsForBuildingSelected()?.includes(equipment?.id))
                    }
                    value={
                      mapForSelect(equipmentsList)
                        .find((equipment) => equipment.value === formik.values.equipment)
                    }
                    onChange={(option) => {
                      formik.setFieldValue('equipment', option.value);
                      setEquipmentId(option.value);
                    }}
                    disabled={(isViewing || isEditing || formik.values.building === null) && editOn === false}
                    valueInput={formik.values.equipment}
                    required
                    loading={getEquipmentsListQuery?.isLoading}
                  />
                  {formik.errors.equipment && formik.touched.equipment ? (
                    <div className="error">
                      {t(formik.errors.equipment)}
                    </div>
                  ) : null }
                </div>
              ) : null }
              <div className="form_group">
                <SelectForm
                  id="controls"
                  label={t('addInterventions.input_controls')}
                  options={
                    mapForSelect(controlsList)
                      .filter((control) => control?.equipmentIds?.includes(formik.values.equipment))
                  }
                  value={
                    mapForSelect(controlsList)
                      .find((control) => control.value === formik.values.control)
                  }
                  onChange={(option) => {
                    formik.setFieldValue('control', option.value);
                    formik.setFieldValue('type', option.interventionType);
                    setInterventionType(option.interventionType);
                    if (option.type.controlPeriod === 'permanently') {
                      formik.setFieldValue('frequency', option.type.controlPeriod);
                    }
                  }}
                  disabled={(isViewing || isEditing || (formik.values.equipment === null && context.choiceBooklet !== 3)) && editOn === false}
                  valueInput={formik.values.control}
                  required
                  loading={getControlsListQuery?.isLoading}
                />
                {formik.errors.control && formik.touched.control ? (
                  <div className="error">
                    {t(formik.errors.control)}
                  </div>
                ) : null }
              </div>
              {context.choiceBooklet !== 3 ? (
                <div className="form_group">
                  {isInputInterventionType
                    ? (
                      <>
                        <SelectForm
                          id="intervener"
                          label={t('addInterventions.input_intervener')}
                          options={mapForSelect(UserConcat)}
                          value={mapForSelect(UserConcat).find((user) => user.id === formik.values.userId)}
                          onChange={(option) => formik.setFieldValue('userId', option.id || null)}
                          disabled={isViewing || !interventionType}
                          loading={getUsersListQuery.isLoading}
                        />
                        {formik.errors.intervener && formik.touched.intervener ? (
                          <div className="error">
                            {t(formik.errors.intervener)}
                          </div>
                        ) : null }
                      </>
                    )
                    : (
                      <>
                        <SelectForm
                          id="society"
                          label={t('addInterventions.input_society')}
                          options={mapForSelect(societiesList)}
                          value={mapForSelect(societiesList).find((society) => society.id === formik.values.societyId)}
                          onChange={(option) => formik.setFieldValue('societyId', option.id || null)}
                          disabled={isViewing || !interventionType}
                          loading={getSocietiesListQuery.isLoading}
                        />
                        {formik.errors.societyId && formik.touched.societyId ? (
                          <div className="error">
                            {t(formik.errors.societyId)}
                          </div>
                        ) : null }
                      </>
                    )}
                </div>
              ) : (
                <div className="form_group">
                  <SelectForm
                    label={t('docVehicule.vehicule')}
                    id="vehicles"
                    name="vehicleId"
                    type="text"
                    placeholder={t('docVehicule.vehicule')}
                    onChange={(value) => formik.setFieldValue('vehicleId', value.value)}
                    onBlur={formik.handleBlur}
                    valueInput={formik.values.vehicleId}
                    required
                    options={mapForSelectVehicule(
                      VehiculeQueryList?.data?.data?.vehicles,
                    )}
                    value={mapForSelectVehicule(
                      VehiculeQueryList?.data?.data?.vehicles,
                    ).find((item) => item.value === formik.values.vehicleId)}
                    loading={VehiculeQueryList.isLoading}
                  />
                  {formik.touched.vehicleId && formik.errors.vehicleId ? (
                    <div className="error">{formik.errors.vehicleId}</div>
                  ) : null}
                </div>
              )}
              {context.choiceBooklet !== 3 ? (
                <>
                  {isEditing || isViewing
                    ? (
                      <div className="form_group--fullwidth">
                        <Input
                          id="controls"
                          type="text"
                          label={t('addInterventions.input_multiple_intervention')}
                          value={formik.values.suffixe}
                          onChange={handleSuffixeChange}
                          disabled={isViewing}
                        />
                      </div>
                    )
                    : (
                      <div className="form_group--fullwidth">
                        <MultiTextInput
                          id="controls"
                          label={t('addInterventions.input_multiple_intervention')}
                          value={formik.values.suffixes}
                          onChange={handleSuffixesChange}
                          disabled={isViewing}
                          minimumLengthToSubmit={2}
                        />
                        {formik.errors.suffixes && formik.touched.suffixes ? (
                          <div className="error">
                            {t(formik.errors.suffixes)}
                          </div>
                        ) : null }
                      </div>
                    )}
                  <div className="form_group--fullwidth">
                    <Input
                      id="titleIntervention"
                      name="titleIntervention"
                      type="text"
                      label={t('addInterventions.input_intervention_title')}
                      value={titleIntervention()}
                      disabled
                    />
                  </div>
                </>
              ) : null }
              {context.choiceBooklet !== 3 ? (
                <div className="form_group pt-16">
                  <div>
                    <span className="label">
                      {t('addInterventions.input_controlPeriod')}
                      {' '}
                      :
                    </span>
                    {' '}
                    {controlSelected ? t(`addInterventions.${controlSelected?.interventionType}`) : '-'}
                  </div>
                  { controlSelected?.interventionType === 'input' ? (
                    <div>
                      <span className="label">
                        {t('addInterventions.input_visibility')}
                        {' '}
                        :
                      </span>
                      {' '}
                      {controlSelected ? inputTypeTranslate.find((input) => input.value === controlSelected?.inputType)?.label : '-'}
                    </div>
                  ) : null }
                </div>
              ) : null }
              <div className="form_group">
                <Input
                  id="controlDate"
                  type="date"
                  name="controlDate"
                  label={t('addInterventions.input_date_control')}
                  value={formatDateToInput(formik.values.controlDate)}
                  onChange={(e) => {
                    formik.setFieldValue('controlDate', e.target.value);
                  }}
                  min="2000-01-01"
                  max="2099-12-31"
                />
                {formik.errors.controlDate && formik.touched.controlDate ? (
                  <div className="error">
                    {t(formik.errors.controlDate)}
                  </div>
                ) : null }
              </div>
              { context.choiceBooklet !== 3 ? (
                <div className="form_group">
                  <SelectForm
                    id="frequency"
                    label={t('addInterventions.input_frequency')}
                    options={frequencies}
                    value={
                      controlSelected?.type?.controlPeriod === 'permanently'
                        ? frequencies[0]
                        : frequencies?.find((frequency) => frequency.value === formik.values.frequency)
                    }
                    onChange={(option) => {
                      if (option.value === 'monthly') {
                        formik.setFieldValue('monthParam', 'multiple_of');
                        formik.setFieldValue('monthlyMultipleOf', 1);
                      }
                      formik.setFieldValue('frequency', option.value);
                      formik.setFieldValue('days', []);
                      formik.setFieldValue('weeklyMultipleOf', null);
                      formik.setFieldValue('months', []);
                      formik.setFieldValue('monthlyMultipleOf', null);
                      formik.setFieldValue('yearlyMultipleOf', null);
                    }}
                    disabled={isViewing || controlSelected?.type?.controlPeriod === 'permanently' || formik.values.control === null}
                    valueInput={formik.values.frequency}
                    required
                    loading={getControlsListQuery.isLoading}
                  />
                  {formik.errors.frequency && formik.touched.frequency ? (
                    <div className="error">
                      {t(formik.errors.frequency)}
                    </div>
                  ) : null }
                </div>
              ) : (
                <div className="form_group">
                  <SelectForm
                    id="frequency"
                    label={t('addInterventions.input_frequency')}
                    options={frequencies}
                    value={
                      controlSelected?.id === 253 || controlSelected?.id === 252
                        ? frequencies[4]
                        : frequencies?.find((frequency) => frequency.value === formik.values.frequency)
                    }
                    onChange={(option) => {
                      const newValue = controlSelected?.id === 253 || controlSelected?.id === 252
                        ? 'ondemand'
                        : option.value;
                      formik.setFieldValue('frequency', newValue);
                    }}
                    disabled={formik.values.control === 253 || formik.values.control === 252 || formik.values.control === null}
                    valueInput={formik.values.frequency}
                    required
                  />
                  {formik.errors.frequency && formik.touched.frequency ? (
                    <div className="error">
                      {t(formik.errors.frequency)}
                    </div>
                  ) : null}
                </div>
              )}
              { formik.values.frequency === 'daily'
                ? (
                  <div className="form_group">
                    <div className="label">{t('addInterventions.days')}</div>
                    <div className="column_grid">
                      {dailyFrequency.map((day) => (
                        <div>
                          <Checkbox
                            id={`day-${day.value}`}
                            name={day.value}
                            label={day.label}
                            onChange={(event) => {
                              handleCheckboxClick('days', day.value, event.target.checked);
                            }}
                            onBlur={formik.handleBlur}
                            checked={formik.values.days?.includes(day.value)}
                            value={day.value}
                          />
                        </div>
                      ))}
                    </div>
                    {formik.errors.days && formik.touched.days ? (
                      <div className="error">
                        {t(formik.errors.days)}
                      </div>
                    ) : null }
                  </div>
                )
                : null }
              { formik.values.frequency === 'weekly'
                ? (
                  <div className="form_group">
                    <SelectForm
                      id="year"
                      label={t('addInterventions.weeks')}
                      options={weeklyOptions(10)}
                      value={
                        weeklyOptions(10).find((frequency) => frequency.value === formik.values.weeklyMultipleOf)
                      }
                      onChange={(option) => {
                        formik.setFieldValue('weeklyMultipleOf', option.value);
                      }}
                      disabled={isViewing}
                    />
                    {formik.errors.weeklyMultipleOf && formik.touched.weeklyMultipleOf ? (
                      <div className="error">
                        {t(formik.errors.weeklyMultipleOf)}
                      </div>
                    ) : null }
                  </div>
                ) : null}
              { formik.values.frequency === 'monthly' && isInputInterventionType
                ? (
                  <div className="form_group pt-24">
                    <Radio
                      id="multipleOf"
                      name={t('addInterventions.multipleOf')}
                      label={t('addInterventions.multipleOf')}
                      value="multiple_of"
                      checked={formik.values.monthParam === 'multiple_of'}
                      defaultChecked
                      onChange={() => {
                        formik.setFieldValue('monthlyMultipleOf', null);
                        formik.setFieldValue('months', []);
                        formik.setFieldValue('monthParam', 'multiple_of');
                      }}
                    />
                    <Radio
                      id="choice_multiple"
                      name={t('addInterventions.multipleChoice')}
                      label={t('addInterventions.multipleChoice')}
                      value="choice_multiple"
                      checked={formik.values.monthParam === 'choice_multiple'}
                      onChange={() => {
                        formik.setFieldValue('monthlyMultipleOf', null);
                        formik.setFieldValue('months', []);
                        formik.setFieldValue('monthParam', 'choice_multiple');
                      }}
                    />
                  </div>
                )
                : null}
              {(formik.values.frequency === 'monthly' && formik.values.monthParam === 'multiple_of')
                || (formik.values.frequency === 'monthly' && formik.values.type === 'report')
                ? (
                  <div className="form_group">
                    <SelectForm
                      id="monthly"
                      label={t('addInterventions.months')}
                      options={MonthOptions(12)}
                      value={
                        MonthOptions(12).find(
                          (frequency) => frequency.value === formik.values.monthlyMultipleOf,
                        )
                      }
                      onChange={(option) => {
                        formik.setFieldValue('monthlyMultipleOf', option.value);
                      }}
                      disabled={isViewing}
                    />
                    {formik.errors.monthlyMultipleOf && formik.touched.monthlyMultipleOf ? (
                      <div className="error">
                        {t(formik.errors.monthlyMultipleOf)}
                      </div>
                    ) : null }
                  </div>
                )
                : null}
              { formik.values.frequency === 'monthly'
                  && formik.values.monthParam === 'choice_multiple'
                  && formik.values.type !== 'report' ? (
                    <div className="form_group">
                      <div className="label">{t('addInterventions.months')}</div>
                      <div className="column_grid">
                        {monthsList.map((month) => (
                          <div>
                            <Checkbox
                              id={`day-${month.value}`}
                              name={month.value}
                              label={month.label}
                              onChange={(event) => handleCheckboxClick('months', month.value, event.target.checked)}
                              onBlur={formik.handleBlur}
                              checked={formik.values.months?.includes(month.value)}
                              value={month.value}
                            />
                          </div>
                        ))}
                      </div>
                      {formik.errors.months && formik.touched.months ? (
                        <div className="error">
                          {t(formik.errors.months)}
                        </div>
                      ) : null }
                    </div>
                ) : null }
              { formik.values.frequency === 'yearly'
                ? (
                  <div className="form_group">
                    <SelectForm
                      id="year"
                      label={t('addInterventions.years')}
                      options={yearsOptions(10)}
                      value={
                        yearsOptions(10).find((frequency) => frequency.value === formik.values.yearlyMultipleOf)
                      }
                      onChange={(option) => formik.setFieldValue('yearlyMultipleOf', option.value)}
                      disabled={isViewing}
                    />
                    {formik.errors.yearlyMultipleOf && formik.touched.yearlyMultipleOf ? (
                      <div className="error">
                        {t(formik.errors.yearlyMultipleOf)}
                      </div>
                    ) : null }
                  </div>
                )
                : null}
              {controlSelected && controlSelected?.paramHighLimit !== null && controlSelected?.paramLowLimit !== null ? (
                <>
                  <div className="form_group">
                    <Input
                      id="paramLowLimit"
                      type="number"
                      name="paramLowLimit"
                      label={t('addInterventions.paramLowLimit')}
                      value={formik.values.paramLowLimit}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={isViewing}
                      required={formik.values.paramHighLimit !== null}
                      max={formik.values.paramHighLimit}
                    />
                    {formik.errors.paramLowLimit && formik.touched.paramLowLimit ? (
                      <div className="error">
                        {t(formik.errors.paramLowLimit)}
                      </div>
                    ) : null }
                  </div>
                  <div className="form_group">
                    <Input
                      id="paramHighLimit"
                      type="number"
                      name="paramHighLimit"
                      label={t('addInterventions.paramHighLimit')}
                      value={formik.values.paramHighLimit}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={isViewing}
                      required={formik.values.paramLowLimit !== null}
                      min={formik.values.paramLowLimit}
                    />
                    {formik.errors.paramHighLimit && formik.touched.paramHighLimit ? (
                      <div className="error">
                        {t(formik.errors.paramHighLimit)}
                      </div>
                    ) : null }
                  </div>
                </>
              ) : null}
              {(context?.user?.role === USERS_ROLES.SUPER_ADMIN || context?.user?.role === USERS_ROLES.ADMIN) && (
                <div className="form_group">
                  <Input
                    id="token"
                    type="text"
                    name="token"
                    label={t('addInterventions.token')}
                    value={formik.values.token}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={isViewing}
                  />
                  {formik.values.token && (
                    <div
                      className="info"
                      onClick={() => navigateToken(`/inputs/${context?.choiceEstablishment?.id}/${formik.values.building}/${formik.values.token}`)}
                      style={{ cursor: 'pointer' }}
                    >
                      {`/inputs/${context?.choiceEstablishment?.id}/${formik.values.building}/${formik.values.token}`}
                    </div>
                  )}
                  {formik.errors.token && formik.touched.token ? (
                    <div className="error">
                      {t(formik.errors.token)}
                    </div>
                  ) : null }
                </div>
              )}
              {!isCreating && isInputInterventionType && isSafetyLightningInterventionInputType ? (
                <div className="form_group--fullwidth">
                  <Link
                    className="add"
                    to="/safetyLightingEquipments/add"
                    state={{ interventionId: urlParams?.id }}
                  >
                    {t('addInterventions.add_safetyLightingEquipment')}
                  </Link>
                  <LightningSecurityEquipementList
                    interventionId={urlParams?.id}
                  />
                </div>
              ) : null}
              {/* SUBMISSION PART */}
              {Permissions({ permission: 'INTERVENTION_EDIT' }) !== undefined
                ? (
                  <div className="form_footer">
                    <div className="form_infos">
                      <small>{t('addInterventions.mandatory_fields')}</small>
                    </div>
                    <Button
                      type="submit"
                      className="form_submit"
                      label={t('addInterventions.submit')}
                      isLoading={postInterventionMutation.isLoading || editInterventionMutation.isLoading}
                    />
                  </div>
                ) : null }
            </form>
          )
      }
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('addInterventions.back_to_interventions')}</span>
        </button>
      </footer>
    </Layout>
  );
}

AddIntervention.propTypes = {};

export default AddIntervention;
