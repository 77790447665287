import api from './_api';

/**
 * GET - Get list amendements
 *  @param {Number: page, Number: contractId} pageId
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
 * */
export const getAmendementsList = (queryParams) => api.get('/amendments', { params: queryParams });

/**
 * GET - Get amendement
 * @param {Number} amendementId
 * @returns {Promise<AxiosResponse>}
 * */
export const getAmendement = (amendementId) => api.get(`/amendments/${amendementId}`);

/**
 * POST - Create amendement
 * @param {Object} amendementToCreate
 * @returns {Promise<AxiosResponse>}
 * */
export const createAmendement = (amendementToCreate) => api.post('/amendments', amendementToCreate);

/**
 * Edit- edit amendement
 * @param {Object} amendementToEdit
 *  @returns {Promise<AxiosResponse>}
 */
export const editAmendement = (amendementToEdit) => api.put(`/amendments/${amendementToEdit.id}`, amendementToEdit);

/**
 * GET - Get Document amendement
 * @param {Number} contractId
 * @returns {Promise<AxiosResponse>}
 */
export const getAmendementDocument = (contractId) => api.get(`/amendments/${contractId}/download-file`);

/* DELETE - Delete amendement
    * @param {Number} amendementId
    * @returns {Promise<AxiosResponse>}
*/
export const deleteAmendement = (amendementId) => api.delete(`/amendments/${amendementId}`);
