/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';

// components
import Table from '../../molecules/Table/Table';
import Button from '../../atoms/Button/Button';

// services
import {
  deleteSafetyLightingEquipment,
  getSafetyLightingEquipmentsList,
} from '../../../services/safetyLightingEquipments';
import useAppContext from '../../../store/useAppContext';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function LightningSecurityEquipementList({ interventionId }) {
  const { t } = useTranslation();
  const [context] = useAppContext();

  const [openModal, setOpenModal] = useState(false);
  const [equipmentToDelete, setEquipmentToDelete] = useState({
    id: null,
  });
  const handleModal = (selectedInterventionToDelete) => {
    setOpenModal(true);
    setEquipmentToDelete(selectedInterventionToDelete);
  };

  // API Calls
  const safetyLightingEquipmentQuery = useQuery(
    ['safetyLightingEquipment'],
    () => getSafetyLightingEquipmentsList({
      interventionId,
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    }),
  );
  const deleteSafetyLightingEquipmentMutation = useMutation(deleteSafetyLightingEquipment, {
    onSuccess:
      () => {
        safetyLightingEquipmentQuery.refetch();
      },
  });
  const handleDelete = () => {
    setOpenModal(false);
    deleteSafetyLightingEquipmentMutation.mutate(equipmentToDelete.id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('safetyLightingEquipments.id'),
        accessor: 'id',
      },
      {
        Header: t('safetyLightingEquipments.bloc_number'),
        accessor: 'blockNumber',
      },
      {
        Header: t('safetyLightingEquipments.location'),
        accessor: 'location',
      },
      {
        Header: t('safetyLightingEquipments.function'),
        accessor: 'function',
        Cell: ({ row: { original } }) => (
          <div>
            {t(`add_safety_lighting_equipment.${original.function.toLowerCase()}`)}
          </div>
        ),

      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/safetyLightingEquipments/edit/${id}`}
              title={t('safetyLightingEquipments.edit')}
              state={{ interventionId }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <Button
              type="button"
              className="action suppr"
              label={<FontAwesomeIcon icon={faTrashCan} />}
              title={t('safetyLightingEquipments.suppr')}
              onClick={() => handleModal({ id })}
            />
          </div>
        ),
      },
    ],
  );

  if (safetyLightingEquipmentQuery.isLoading) return <div className="loader" />;
  return (
    <div>
      <Table
        columns={columns}
        isLoading={safetyLightingEquipmentQuery.isLoading}
        data={safetyLightingEquipmentQuery?.data?.data?.safetyLightingEquipments || []}
        noResultsLabel={t('safetyLightingEquipments.no_results')}
      />
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>

    </div>
  );
}

LightningSecurityEquipementList.propTypes = {
  interventionId: PropTypes.number.isRequired,
};

export default LightningSecurityEquipementList;
