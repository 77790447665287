import api from './_api';

/**
 * GET - Get users notifications
 * @param {Number} establishmentId
    * @returns {Promise<AxiosResponse>}
*/
export const getAccountNotifications = (establishmentId) => api.get(`analyses/${establishmentId}`);

/**
 * GET - Get download file
 * @param {Number} fileid
 * @returns {Promise<AxiosResponse>}
*/
export const getDownloadFile = (fileid) => api.get(`analyses/${fileid}/download-report`);

/**
 * GET - Get analyse List
 * @param {Number} establishmentId
    * @returns {Promise<AxiosResponse>}
*/
export const getAnalysesList = (queryParams) => api.get('/analyses', { params: queryParams });

/**
 * GET - Get analyse List
 * @param {Number} establishmentId
    * @returns {Promise<AxiosResponse>}
*/
export const getAnalysesLastList = (queryParams) => api.get('/analyses/last', { params: queryParams });

/**
 * GET - Get analyse
 * @param {Number} id
 * @returns {Promise<AxiosResponse>}
 */
export const analyse = (id) => api.get(`/analyses/${id}`);

/**
 * post analyses
 * @param {object} data
 * @returns {Promise<AxiosResponse>}
 */
export const createAnalyse = (data) => api.post('/analyses', data);

/**
 * DELETE - Delete analyse
 * @param {Number} id
 * @returns {Promise<AxiosResponse>}
 */
export const deleteAnalyse = (id) => api.delete(`/analyses/${id}`);

/**
 * Dowload analyse
 * @param {Number} id
 * @returns {Promise<AxiosResponse>}
 */
export const downloadAnalyse = (id) => api.get(`/analyses/${id}/download-report`);
