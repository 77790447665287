/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './Card.module.css';
import useAppContext from '../../../store/useAppContext';
import cn from '../../../utils/cn';

function Card({
  borderTopColored, children, className, onClick, disabled,
}) {
  const [context] = useAppContext();
  if (onClick) {
    const classList = [disabled === true ? styles.disabled : styles.card, 'shadow-md', className];

    if (borderTopColored) {
      if (context.choiceBooklet === 2) {
        classList.push(styles.security);
      } else if (context.choiceBooklet === 3) {
        classList.push(styles.vehicule);
      } else {
        classList.push(styles.sanitary);
      }
    }

    return (
      <button type="button" onClick={onClick} className={cn(classList)} disabled={disabled}>
        {children}
      </button>
    );
  }
  const classList = [disabled === true ? styles.disabled : styles.card, 'shadow-md', className];

  if (borderTopColored) {
    if (context.choiceBooklet === 2) {
      classList.push(styles.security);
    } else if (context.choiceBooklet === 3) {
      classList.push(styles.vehicule);
    } else {
      classList.push(styles.sanitary);
    }
  }

  return (
    <div
      className={cn(classList)}
    >
      {children}
    </div>
  );
}

Card.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.array.isRequired,
  borderTopColored: PropTypes.bool,
  onClick: PropTypes.func,
};
Card.defaultProps = {
  className: '',
  borderTopColored: false,
  onClick: undefined,
  disabled: false,
};

export default Card;
