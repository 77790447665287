import React from 'react';
// Utils
import styles from './Divider.module.css';

function Divider() {
  return (
    <hr className={styles.divider} />
  );
}

export default Divider;
