import api from './_api';

/**
 * GET - Get Dossier list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getFolderlist = (queryParams) => api.get('/document-base-folders', { params: queryParams });

/**
 * GET - Get Dossier
 * @param {Number} folderId
 * @returns {Promise<AxiosResponse>}
 */
export const getFolder = (folderId) => api.get(`/document-base-folders/${folderId}`);

/**
 * POST - Create Dossier
 * @param {Object} folderToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postFolder = (folderToCreate) => api.post('/document-base-folders', folderToCreate);

/**
 * PUT - Edit Dossier
 * @param {Object} folderToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editFolder = (folderToEdit) => api.put(`/document-base-folders/${folderToEdit.id}`, folderToEdit.data);

/**
 * DELETE - Delete Dossier
 * @param {Number} folderId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteFolder = (folderId) => api.delete(`/document-base-folders/${folderId}`);
