/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Layout from '../../components/template/Layout';
import useAppContext from '../../store/useAppContext';
import { USERS_ROLES } from '../../utils/constant';

import styles from './Error.module.css';
import Button from '../../components/atoms/Button/Button';
import Version from '../Version/Version';

function Error({ error, errorPath }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    } else if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    } else if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    } else if (context?.user?.role?.includes('ADMIN')) {
      navigate('dashboard');
    } else {
      navigate('/choix-carnet');
    }

    window.location.reload();
  };

  const handleRefresh = () => {
    navigate(-2);
    window.location.reload();
  };
  useEffect(() => {
    if (!context?.user) {
      navigate('/');
      window.location.reload();
    }
  }, [context, navigate]);

  return (
    <Layout
      title="Error"
      description="Error"
      keywords="Error"
    >
      <button type="button" className={styles.links} onClick={() => navigateDashboards()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>{t('global.dashboard')}</span>
      </button>
      <div className={styles.content}>
        <p className="mb-20">
          {t('error.errors')}
          :
        </p>
        <div className="mb-20">
          <span className={styles.error}>{error.message}</span>
          {' '}
          <span>{t('error.sur')}</span>
          {' '}
          {' '}
          <span className={styles.error}>{errorPath}</span>
          <Version components="true" />
        </div>
        <p className="mb-20">{t('error.message')}</p>
        <p className="mb-20">{t('error.merci')}</p>
      </div>
      <div className={styles.button}>
        <Button
          type="button"
          label={t('error.refresh')}
          className="supprimer"
          onClick={handleRefresh}
        >
          {' '}
        </Button>
        <Button
          type="button"
          label={t('error.return')}
          className="add"
          onClick={() => navigateDashboards()}
        >
          {' '}
        </Button>
      </div>
    </Layout>
  );
}

Error.propTypes = {
  error: PropTypes.string.isRequired,
  errorPath: PropTypes.string.isRequired,
};
export default Error;
