import api from './_api';

/**
 * GET - Get Registre list
 * @param {Number} registreId
 * @returns {Promise<AxiosResponse>}
 */
export const getRegistreList = (registreId) => api.get(`/myregistry/list/${registreId.buildingId}`);

/**
 * GET - Get Archive Registry list
 * @param {Number} archiveId
 * @returns {Promise<AxiosResponse>}
 */
export const getArchiveList = (archiveId) => api.get(`/myregistry/archive/${archiveId.buildingId}`);

/**
 * GET - Get Booklet list
 * @param {Number} bookletId
 * @returns {Promise<AxiosResponse>}
 */
export const getMyBookletList = (bookletId) => api.get(`/mybooklet/list/${bookletId.buildingId}`);

/**
 * GET - Get Archive booklet list
 * @param {Number} archiveId
 * @returns {Promise<AxiosResponse>}
 */
export const getArchiveMyBookletList = (archiveId) => api.get(`/mybooklet/archive/${archiveId.buildingId}`);

/**
 * Get Registre Details
 * @param {Number} registreId
 * @returns {Promise<AxiosResponse>}
 */
export const getRegistreDetails = (registreId) => api.get(`/myregistry/details/${registreId.id}`);

/**
 * GET - Get Remaining
 * @param {Object} param
 * @returns {Promise<AxiosResponse>}
 */
export const getremaining = (param) => api.get(`/observations/${param.id}/unlifted`, { params: param.data });

/**
 * GET - Get lifted
 * @param {Object} param
 * @returns {Promise<AxiosResponse>}
 */
export const getlifted = (param) => api.get(`/observations/${param.id}/lifted`, { params: param.data });

/**
 * GET - Get one Observation
 * @param {Number} observationId
 * @returns {Promise<AxiosResponse>}
 */
export const getObservation = (observationId) => api.get(`/observations/${observationId.id}`);

/**
 * POST observation
 * @param {Object} observationData
 * @returns {Promise<AxiosResponse>}
 */
export const postObservation = (observationData) => api.post('/observations', observationData);

/**
 * PUT observation
 * @param {Object} observationData
 * @returns {Promise<AxiosResponse>}
 * */
export const putObservation = (observationData) => api.put(`/observations/${observationData.id}`, observationData.data);

/**
 * PUT unlift observation
 * @param {Object} observationData
 * @returns {Promise<AxiosResponse>}
 * */
export const putUnlift = (observationData) => api.put(`/observations/unlift/${observationData}`);

/**
 * DELETE observation
 * @param {{
 * id: Number,
 * }} observationData
 *
 */
// eslint-disable-next-line max-len
export const deleteObservation = (observationData) => api.delete(`/observations/${observationData}`);

/**
* GET Commentaire
* @param {Number} observationid
* @returns {Promise<AxiosResponse>}
*/
export const getCommentaire = (observationid) => api.get(`/observations/${observationid.id}/comments`);

/**
 * POST commentaire
 * @param {Object} observationData
 * @returns {Promise<AxiosResponse>}
 */
export const postCommentaire = (observationData) => api.post('/observations/comments', observationData);

/**
 * POST lift
 * @param {Object} observationData
 * @returns {Promise<AxiosResponse>}
 */
export const postLift = (observationData) => api.post('/observations/lift', observationData);

/**
 * POST Share observation
 * @param {Object} observationData
 * @returns {Promise<AxiosResponse>}
 */
export const postShare = (observationData) => api.post('/observations/share', observationData);

/**
 * DELETE - Delete reports
 * @param {Number} id
 * @returns {Promise<AxiosResponse>}
 */
export const deleteReport = (id) => api.delete(`/reports/${id}`);

/**
 * GET - Get reports
 * @param {Number} registre
 * @returns {Promise<AxiosResponse>}
 */
export const GetdowloadRegistre = (registre) => api.get(`/myregistry/export/${registre.buildingId}`);
