/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable no-bitwise */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-template */
/* eslint-disable no-loop-func */

/**
 * Method to download a file
 * @param {Blob} blob
 * @param {string} fileName
 * @param {string} type - default: application/pdf
 */
export const downloadFile = (blob, fileName, type = 'application/pdf') => {
  const data = URL.createObjectURL(blob, { type });
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(data);
  link.remove();
};

/**
 * Method to download a file csv
 * @param {Array} data
 * @param {string} header
 * @param {string} fileName
 * @param {string} type - default: text/csv
 */
export const downloadFileCsv = (data, headers, fileName) => {
  const separator = ';';
  const lineBreak = '\r\n';
  let csvContent = '';

  headers.forEach((header, index) => {
    csvContent += header.label;
    if (index !== headers.length - 1) {
      csvContent += separator;
    } else {
      csvContent += lineBreak;
    }
  });

  data.forEach((item) => {
    headers.forEach((header, index) => {
      let value = item[header.key];
      if (header.cell) {
        value = header.cell({ original: item });
      }
      csvContent += value;
      if (index !== headers.length - 1) {
        csvContent += separator;
      } else {
        csvContent += lineBreak;
      }
    });
  });

  const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Method to download a file csv
 * @param {Array} data
 * @param {string} header
 * @param {string} fileName
 * @param {string} type - default: text/csv
 */
export const downloadFileAnalyse = (data, headers, fileName) => {
  const separator = ';';
  const lineBreak = '\r\n';
  let csvContent = '';

  headers.forEach((header, index) => {
    csvContent += header.label;
    if (index !== headers.length - 1) {
      csvContent += separator;
    } else {
      csvContent += lineBreak;
    }
  });

  data.forEach((item) => {
    item.forEach((itemparse) => {
      headers.forEach((header, index) => {
        let value = itemparse[header.key];
        if (header.cell) {
          value = header.cell({ original: item });
        }
        csvContent += value;
        if (index !== headers.length - 1) {
          csvContent += separator;
        } else {
          csvContent += lineBreak;
        }
      });
    });
  });
  const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
/**
* Method to download a file csv
* @param {Array} data
* @param {string} header
* @param {string} fileName
* @param {string} type - default: text/csv
*/
export const downloadCsvMonCarnet = (eaufroide, eauchaude, compteur, headers, fileName) => {
  const separator = ';';
  const lineBreak = '\r\n';
  let csvContent = '';
  let csvContent1 = '';
  let csvContent2 = '';
  let csvContent3 = '';

  headers.forEach((header, index) => {
    csvContent += header.label;
    if (index !== headers.length - 1) {
      csvContent += separator;
    } else {
      csvContent += lineBreak;
    }
  });

  eaufroide.forEach((item) => {
    headers.forEach((header, index) => {
      let value = item[header.key];
      if (header.cell) {
        value = header.cell({ original: item });
      }
      csvContent1 += value;
      if (index !== headers.length - 1) {
        csvContent1 += separator;
      } else {
        csvContent1 += lineBreak;
      }
    });
  });

  eauchaude.forEach((item) => {
    headers.forEach((header, index) => {
      let value = item[header.key];
      if (header.cell) {
        value = header.cell({ original: item });
      }
      csvContent2 += value;
      if (index !== headers.length - 1) {
        csvContent2 += separator;
      } else {
        csvContent2 += lineBreak;
      }
    });
  });

  compteur.forEach((item) => {
    headers.forEach((header, index) => {
      let value = item[header.key];
      if (header.cell) {
        value = header.cell({ original: item });
      }
      csvContent3 += value;
      if (index !== headers.length - 1) {
        csvContent3 += separator;
      } else {
        csvContent3 += lineBreak;
      }
    });
  });

  csvContent += csvContent1 + csvContent2 + csvContent3 + lineBreak;

  const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default { downloadFile, downloadFileCsv };
