import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

// Reducers
import appReducer from './appReducer';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const initStore = { isStayLoginCheck: false, choiceBooklet: null, choiceEstablishment: null };

const getSavedContext = () => {
  const savedContext = sessionStorage.getItem('context') || localStorage.getItem('context') || undefined;
  return savedContext ? JSON.parse(savedContext) : initStore;
};

function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, getSavedContext());

  useEffect(() => {
    if (!state.isStayLoginCheck && state.user) {
      sessionStorage.setItem('context', JSON.stringify(state));
    } else if (state.isStayLoginCheck && state.user) {
      localStorage.setItem('context', JSON.stringify(state));
    }
  }, [state]);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => [useContext(AppStateContext), useContext(AppDispatchContext)];

export {
  AppProvider,
  AppStateContext,
  AppDispatchContext,
  useAppContext,
};
