import { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty, omit } from 'lodash';

const urlParamToFilters = (urlParamsObject, initialFilters) => Object.keys(urlParamsObject)
  .reduce((acc, urlParamKey) => {
    let parsedValue = null;
    const typeOfFilterAttribute = typeof initialFilters[urlParamKey];

    switch (typeOfFilterAttribute) {
      case 'object': {
        parsedValue = Array.isArray(initialFilters[urlParamKey])
          ? urlParamsObject[urlParamKey].split(',')
          : urlParamsObject[urlParamKey];
        break;
      }
      case 'string': {
        parsedValue = urlParamsObject[urlParamKey];
        break;
      }
      case 'number': {
        parsedValue = Number(urlParamsObject[urlParamKey]);
        break;
      }
      default: parsedValue = null;
    }

    return { ...acc, [urlParamKey]: parsedValue };
  }, {});

const useTableFiltersPagination = ({ initialFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const URLParamsParsed = useMemo(
    () => urlParamToFilters(omit(Object.fromEntries(searchParams), ['page']), initialFilters),
    [searchParams, initialFilters],
  );

  const [filters, setFilters] = useState(!isEmpty(URLParamsParsed) ? URLParamsParsed : initialFilters);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // To avoid having empty values in URL we clean the object to serialize
    const cleanedFiltersValues = Object.keys(filters).reduce((acc, filterKey) => {
      if (!isEmpty(filters[filterKey])) {
        return { ...acc, [filterKey]: filters[filterKey] };
      }
      return acc;
    }, {});

    setSearchParams(new URLSearchParams({ page, ...cleanedFiltersValues }));
  }, [filters, page]);

  const onFilterChange = (filterValues, filterField) => {
    setFilters((prevState) => ({ ...prevState, [filterField]: filterValues }));
    setPage(1);
  };

  return {
    filters, onFilterChange, page, onPageChange: setPage,
  };
};

export default useTableFiltersPagination;
