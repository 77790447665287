/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import {
  faEdit, faTrashCan, faChevronLeft, faChevronRight, faPlus, faEye, faClose,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';

// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';

// services
import { deleteEstablishment, getEstablishmentsList } from '../../services/structures';

// styles
import styles from './Establishments.module.css';
import UsersList from '../../components/template/UsersList.jsx/UsersList';
import Search from '../../components/molecules/Search/Search';

// utils
import {
  USERS_ROLES,
} from '../../utils/constant';

// TO DO : REFACTO TO MAKE IT GENERIC TO ALL OTHERS MODALS
const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '80vw',
    minWidth: '1024px',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

const customModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    bprder: 'none',
    maxWidth: '100vw',
    minWidth: '1024px',
    maxHeight: '80vh',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Establishments() {
  const navigate = useNavigate();
  const context = useAppContext();
  const [openModal, setOpenModal] = useState(false);
  const [openModalUsersGranted, setOpenModalUsersGranted] = useState(false);
  const [establishmentToDelete, setEstablishmentToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');

  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  // API Calls
  const getEstablishmentsListQuery = useQuery(
    ['establishments', page, search],
    () => getEstablishmentsList({
      page: 0,
      search,
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    }),
  );
  const deleteEstablishmentQuery = useMutation(deleteEstablishment, {
    onSuccess:
      () => {
        getEstablishmentsListQuery.refetch();
      },
  });
  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const handleModalToDelete = (selectedEquipmentToDelete) => {
    setOpenModal(true);
    setEstablishmentToDelete(selectedEquipmentToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteEstablishmentQuery.mutate(establishmentToDelete.id);
    toast.success(t('add_establishment.deleted'));
  };

  const handleOpenModalUsers = (establishmentId) => setOpenModalUsersGranted(establishmentId);

  const columns = useMemo(
    () => [
      {
        Header: `${t('establishments.name')}`,
        accessor: 'name',
      },
      {
        Header: `${t('establishments.group_name')}`,
        accessor: 'groupName',
      },
      {
        Header: `${t('establishments.subsidiary_name')}`,
        accessor: 'subsidiaryName',
      },
      {
        Header: `${t('establishments.division_name')}`,
        accessor: 'divisionName',
      },
      {
        Header: `${t('establishments.city')}`,
        accessor: 'city',
      },
      {
        Header: `${t('establishments.code')}`,
        accessor: 'code',
      },
      {
        Header: `${t('establishments.count_buildings')}`,
        accessor: 'countBuildings',
        Cell: ({ row: { original: { id, countBuildings } } }) => (
          <div>
            <span className={styles.countBuildings}>{countBuildings}</span>
            {context[0]?.user?.role === USERS_ROLES.SUPER_ADMIN || context[0]?.user?.role === USERS_ROLES.ADMIN
              ? (
                <Link
                  className="action action-primary"
                  to={`/etablissements/${id}/batiments/add`}
                  title={t('establishments.view')}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Link>
              ) : null}
          </div>
        ),
      },
      {
        Header: `${t('establishments.count_granted_users')}`,
        accessor: 'countGrantedUsers',
        Cell: ({ row: { original: { id, countGrantedUsers } } }) => (
          <button type="button" className="action" onClick={() => handleOpenModalUsers(id)}>
            {countGrantedUsers}
          </button>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/etablissements/view/${id}`}
              title={t('establishments.view')}
            >
              <FontAwesomeIcon icon={faEye} />
            </Link>
            <Link
              className="action"
              to={`/etablissements/edit/${id}`}
              title={t('establishments.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            { context[0]?.user?.role !== USERS_ROLES.ADMIN_GROUP && (
            <button
              type="button"
              className="action suppr"
              title={t('establishments.suppr')}
              onClick={() => handleModalToDelete({ id })}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
            )}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Layout
      title={t('establishments.title_page')}
      layout="table"
      queryError={
        getEstablishmentsListQuery?.error
          || deleteEstablishmentQuery?.error
        }
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate('/dashboard')}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('establishments.back_to_dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">
              {t('establishments.title_page')}
            </h1>
            { context[0]?.user?.role !== USERS_ROLES.ADMIN_GROUP && (
            <Link className="add" to="/etablissements/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('establishments.add')}</span>
            </Link>
            )}
          </div>
          <div className={styles.search}>
            <Search onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getEstablishmentsListQuery.isLoading}
            data={getEstablishmentsListQuery?.data?.data?.establishments || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getEstablishmentsListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <div className="row mb-20">
          <button
            type="button"
            className="link"
            onClick={() => navigate('/dashboard')}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('establishments.back_to_dashboard')}</span>
          </button>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <button
            type="button"
            className="modal_button"
            onClick={() => setOpenModal(false)}
          >
            {t('global.modal_dismiss')}
          </button>
          <button
            type="button"
            className="modal_button modal_button--success"
            onClick={handleDelete}
          >
            {t('global.modal_validate')}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={!!openModalUsersGranted}
        onRequestClose={() => setOpenModalUsersGranted(false)}
        style={customModalStyle}
      >
        <Button
          type="button"
          className={styles.close}
          label={<FontAwesomeIcon icon={faClose} />}
          onClick={() => setOpenModalUsersGranted(false)}
        />
        <UsersList establishmentId={openModalUsersGranted} />
      </Modal>
    </Layout>
  );
}

Establishments.propTypes = {};

export default Establishments;
