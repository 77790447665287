import React from 'react';
import PropTypes from 'prop-types';
// Utils
import styles from './Checkbox.module.css';

function Checkbox({
  id, name, label, checked, value, onChange, onBlur, disabled,
}) {
  return (
    <>
      <input
        className={styles.input}
        id={id}
        type="checkbox"
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <label className={styles.label} htmlFor={id}>{label}</label>
    </>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  onBlur: null,
  label: '',
};

export default Checkbox;
