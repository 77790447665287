import React from 'react';
import PropTypes from 'prop-types';
// Utils
import styles from './ListItem.module.css';
import cn from '../../../utils/cn';

function ListItem({ className, children }) {
  return (
    <li className={cn([styles.item, className || ''])}>
      {children}
    </li>
  );
}

ListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
};

ListItem.defaultProps = {
  className: '',
};

export default ListItem;
