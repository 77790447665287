/* eslint-disable max-len */
/* eslint-enable  no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faCarSide } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../../components/template/Layout';
import EstablishmentCardChoice from '../../components/molecules/List/EstablishmentCardChoice/EstablishmentCardChoice';
import Search from '../../components/molecules/Search/Search';
// hooks
import useAppContext from '../../store/useAppContext';

// infrastructures
import { getEstablishmentsList } from '../../services/structures';

// style
import styles from './ChoiceEstablishment.module.css';

// util
import cn from '../../utils/cn';
import Picto from '../../components/atoms/Picto/Picto';

function ChoiceEstablishment() {
  const [search, setSearch] = useState('');
  const [establishmentsFiltered, setEstablishmentsFiltered] = useState('');
  const [establishments, setEstablishments] = useState();
  const { t } = useTranslation();
  const [context, dispatch] = useAppContext();
  const navigate = useNavigate();
  const [getEstablishmentsListQuery, setGetEstablishmentsListQuery] = useState();

  const handleChoiceEstablishment = (establishment) => {
    const estab = establishment.name
      .replace(/[^a-zA-Z0-9\s&→]/g, '')
      .replace(/\s+/g, '-')
      .replace(/^-+|-+$/g, '')
      .replace(/-+/g, '-');
    dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: establishment });
    navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
  };

  const fetchData = async () => {
    try {
      const data = await getEstablishmentsList({ bookletId: 3 });
      setGetEstablishmentsListQuery(data);
      setEstablishmentsFiltered(data?.config?.params?.bookletId);
      setEstablishments(data?.data?.establishments);
      if (data?.data?.establishments?.length === 1 && context?.user?.role === 'ROLE_USER') {
        handleChoiceEstablishment(data?.data?.establishments[0]);
      }
    } catch (error) {
      // Handle error
      console.error('Error fetching establishments:', error);
    }
  };

  useEffect(() => {
    if (context?.choiceEstablishment !== null) {
      dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: null });
    }
    if (!context?.Establishment_Vehicule) {
      fetchData();
    } else {
      setEstablishmentsFiltered(2);
      setGetEstablishmentsListQuery(context?.Establishment_Vehicule);
      setEstablishments(context?.Establishment_Vehicule?.data?.establishments);
    }
  }, []);
  const handleSearch = (e) => {
    const establishmentsFilteredBySearch = getEstablishmentsListQuery?.data?.establishments?.filter(
      (establishment) => establishment?.name
        .toString()
        .toLowerCase()
        .indexOf(e.target.value.toLowerCase()) >= 0,
    );
    setEstablishments(establishmentsFilteredBySearch);
    setSearch(e.target.value);
  };

  return (
    <Layout
      title={t('choice_establishment.title_page')}
      queryError={
        getEstablishmentsListQuery?.error
      }
    >
      <header className={cn(['header', styles.headerChoice, 'shadow-md', styles.vehicule])}>
        <h1 className="titleHomePage">
          <Picto name="etablissementsRoundedBlue" />
          {' '}
          {t('choice_establishment.title_page')}
          {' '}
          <Picto name="etablissementsRoundedBlue" />
        </h1>
      </header>
      {
        getEstablishmentsListQuery?.isLoading && establishmentsFiltered !== '' ? (
          <div className="loaderEtablissement" />
        )
          : (
            <section className={styles.sectionChoice}>
              <div className={styles.searchRow}>
                <Search
                  className="mb-20"
                  value={search || ''}
                  onChange={handleSearch}
                />
              </div>
              <div>
                <div className={styles.sectionChoiceList}>
                  {establishments?.map((establishment) => (
                    <EstablishmentCardChoice
                      onClick={handleChoiceEstablishment}
                      establishment={establishment}
                      key={establishment?.id}
                      texte={(
                        <div className={styles.vehicles}>
                          {establishment?.indicators?.vehiclecount ? (
                            <>
                              <FontAwesomeIcon icon={faCarSide} />
                              <p>{establishment?.indicators?.vehiclecount}</p>
                            </>
                          ) : null }
                        </div>
                      )}
                    />
                  )) || ''}
                </div>
              </div>
            </section>
          )
      }
    </Layout>
  );
}

export default ChoiceEstablishment;
