/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faPhone,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';

// Components
import Table from '../../molecules/Table/Table';
import Pagination from '../../molecules/Pagination';

// utils & misc
import styles from './SocietyList.module.css';

/* eslint-disable no-unused-vars */
function SocietyList({ societies }) {
  const { t } = useTranslation();
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [responses, setResponses] = useState(societies?.societyStructures.slice(start, end));
  const [currentPage, setCurrentPage] = useState(1);

  const columns = useMemo(
    () => [
      {
        Header: `${t('societies.establishmentid')}`,
        accessor: 'structureId',
      },
      {
        Header: `${t('societies.email')}`,
        accessor: 'emailContact1',
        Cell: ({ row }) => (
          <div>
            {row?.original?.emailContact1 && (
              <>
                {row?.original?.emailContact1}
                <FontAwesomeIcon data-tip data-for={`email-${row?.original?.structureId}`} icon={faEnvelope} />
                <ReactTooltip id={`email-${row?.original.structureId}`} place="top" type="info" effect="solid">
                  <>
                    {row?.original?.emailContact1 && (
                      <div>
                        <strong>
                          {t('societies.email1')}
                          :
                        </strong>
                        <span>{row?.original?.emailContact1}</span>
                      </div>
                    )}
                    {row?.original?.emailContact2 && (
                      <div>
                        <strong>
                          {t('societies.email2')}
                          :
                        </strong>
                        <span>{row?.original?.emailContact2}</span>
                      </div>
                    )}
                    {row?.original?.emailContact3 && (
                      <div>
                        <strong>
                          {t('societies.email3')}
                          :
                        </strong>
                        <span>{row?.original?.emailContact3}</span>
                      </div>
                    )}
                  </>
                </ReactTooltip>
              </>
            )}
          </div>
        ),
      },
      {
        Header: `${t('societies.firstName')}`,
        accessor: 'firstNameContact1',
        Cell: ({ row }) => (
          <div>
            {row?.original?.firstNameContact1 && (
              <>
                {row?.original?.firstNameContact1}
                <FontAwesomeIcon data-tip data-for={`firstName-${row?.original?.structureId}`} icon={faUser} />
                <ReactTooltip id={`firstName-${row?.original.structureId}`} place="top" type="info" effect="solid">
                  <>
                    {row?.original?.firstNameContact1 && (
                      <div>
                        <strong>
                          {t('societies.firstNameContact1')}
                          :
                        </strong>
                        <span>{row?.original?.firstNameContact1}</span>
                      </div>
                    )}
                    {row?.original?.firstNameContact2 && (
                      <div>
                        <strong>
                          {t('societies.firstNameContact2')}
                          :
                        </strong>
                        <span>{row?.original?.firstNameContact2}</span>
                      </div>
                    )}
                    {row?.original?.firstNameContact3 && (
                      <div>
                        <strong>
                          {t('societies.firstNameContact3')}
                          :
                        </strong>
                        <span>{row?.original?.firstNameContact3}</span>
                      </div>
                    )}
                  </>
                </ReactTooltip>
              </>
            )}
          </div>
        ),
      },
      {
        Header: `${t('societies.lastName')}`,
        accessor: 'lastNameContact1',
        Cell: ({ row }) => (
          <div>
            {row?.original?.lastNameContact1 && (
              <>
                {row?.original?.lastNameContact1}
                <FontAwesomeIcon data-tip data-for={`lastName-${row?.original?.structureId}`} icon={faUser} />
                <ReactTooltip id={`lastName-${row?.original.structureId}`} place="top" type="info" effect="solid">
                  <>
                    {row?.original?.lastNameContact1 && (
                      <div>
                        <strong>
                          {t('societies.lastNameContact1')}
                          :
                        </strong>
                        <span>{row?.original?.lastNameContact1}</span>
                      </div>
                    )}
                    {row?.original?.lastNameContact2 && (
                      <div>
                        <strong>
                          {t('societies.lastNameContact2')}
                          :
                        </strong>
                        <span>{row?.original?.lastNameContact2}</span>
                      </div>
                    )}
                    {row?.original?.lastNameContact3 && (
                      <div>
                        <strong>
                          {t('societies.lastNameContact3')}
                          :
                        </strong>
                        <span>{row?.original?.lastNameContact3}</span>
                      </div>
                    )}
                  </>
                </ReactTooltip>
              </>
            )}
          </div>
        ),
      },
      {
        Header: `${t('societies.mainPhone')}`,
        accessor: 'mainPhoneContact1',
        Cell: ({ row }) => (
          <div>
            {row?.original?.mainPhoneContact1 && (
              <>
                {row?.original?.mainPhoneContact1}
                <FontAwesomeIcon data-tip data-for={`mainPhone-${row?.original?.structureId}`} icon={faPhone} />
                <ReactTooltip id={`mainPhone-${row?.original.structureId}`} place="top" type="info" effect="solid">
                  <>
                    {row?.original?.mainPhoneContact1 && (
                      <div>
                        <strong>
                          {t('societies.mainPhoneContact1')}
                          :
                        </strong>
                        <span>{row?.original?.mainPhoneContact1}</span>
                      </div>
                    )}
                    {row?.original?.mainPhoneContact2 && (
                      <div>
                        <strong>
                          {t('societies.mainPhoneContact2')}
                          :
                        </strong>
                        <span>{row?.original?.mainPhoneContact2}</span>
                      </div>
                    )}
                    {row?.original?.mainPhoneContact3 && (
                      <div>
                        <strong>
                          {t('societies.mainPhoneContact3')}
                          :
                        </strong>
                        <span>{row?.original?.mainPhoneContact3}</span>
                      </div>
                    )}
                  </>
                </ReactTooltip>
              </>
            )}
          </div>
        ),
      },
      {
        Header: `${t('societies.otherPhone')}`,
        accessor: 'otherPhoneContact1',
        Cell: ({ row }) => (
          <div>
            {row?.original?.otherPhoneContact1 && (
              <>
                {row?.original?.otherPhoneContact1}
                <FontAwesomeIcon data-tip data-for={`otherPhone-${row?.original?.structureId}`} icon={faPhone} />
                <ReactTooltip id={`otherPhone-${row?.original.structureId}`} place="top" type="info" effect="solid">
                  <>
                    {row?.original?.otherPhoneContact1 && (
                      <div>
                        <strong>
                          {t('societies.otherPhoneContact1')}
                          :
                        </strong>
                        <span>{row?.original?.otherPhoneContact1}</span>
                      </div>
                    )}
                    {row?.original?.otherPhoneContact2 && (
                      <div>
                        <strong>
                          {t('societies.otherPhoneContact2')}
                          :
                        </strong>
                        <span>{row?.original?.otherPhoneContact2}</span>
                      </div>
                    )}
                    {row?.original?.otherPhoneContact3 && (
                      <div>
                        <strong>
                          {t('societies.otherPhoneContact3')}
                          :
                        </strong>
                        <span>{row?.original?.otherPhoneContact3}</span>
                      </div>
                    )}
                  </>
                </ReactTooltip>
              </>
            )}
          </div>
        ),
      },
      {
        Header: `${t('societies.comment')}`,
        accessor: 'comment',
      },
      {
        Header: `${t('societies.booklet')}`,
        accessor: 'bookletId',
        Cell: ({ row }) => {
          if (row.original.bookletId === 1) {
            return `${t('global.site_name_security')}`;
          }
          if (row.original.bookletId === 3) {
            return `${t('global.site_name_vehicule')}`;
          }
          return `${t('global.site_name_sanitary')}`;
        },
      },
    ],
    [],
  );

  // pagination de la popup
  const next = () => {
    setCurrentPage(currentPage + 1);
    setStart(currentPage * 10);
    setEnd(currentPage * 10 + 10);
    setResponses(societies?.societyStructures?.slice(currentPage * 10, currentPage * 10 + 10));
  };
  const previous = () => {
    setCurrentPage(currentPage - 1);
    setResponses(societies?.societyStructures?.slice(currentPage * 10 - 10 - 10, currentPage * 10 - 10));
  };
  const changePage = (e) => {
    setCurrentPage(e);
    setStart((e - 1) * 10);
    setEnd(e * 10 + 10);
    setResponses(societies?.societyStructures?.slice((e - 1) * 10, e * 10 + 10));
  };

  return (
    <div className={styles.SocietyList}>
      <Table
        columns={columns}
        data={responses || []}
        hasSort
        pagination={(
          <Pagination
            previousLabel={(
              <FontAwesomeIcon
                icon={faChevronLeft}
                onClick={() => { previous(); }}
              />
            )}
            nextLabel={(
              <FontAwesomeIcon
                icon={faChevronRight}
                onClick={() => {
                  next();
                }}
              />
            )}
            onPageChange={(e) => {
              changePage(e);
            }}
            totalCount={societies?.societyStructures.length || 0}
            currentPage={currentPage}
            pageSize={10}
          />
      )}
      />
    </div>
  );
}

SocietyList.propTypes = {
  societies: PropTypes.arrayOf(PropTypes.object),
};
SocietyList.defaultProps = {
  societies: [],
};

export default SocietyList;
