/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
// Utils
import styles from './Switch.module.css';

function Switch({
  id, value, onChange,
}) {
  const inputs = [
    {
      label: 'ko',
      value: false,
    },
    {
      label: 'neutral',
      value: null,
    },
    {
      label: 'ok',
      value: true,
    },
  ];
  const handleOnChange = (e) => {
    onChange(e);
  };

  return (
    <div className={styles.switch}>
      {inputs.map((input) => (
        <div
          key={`radio-${input.label.split(' ').join('')}`}
          className={`${styles[`inputWrapper-${input.label}`]} ${input.value === value ? styles.checked : null}`}
        >
          <input
            type="radio"
            id={`${id}-${input.label.split(' ').join('')}`}
            data-id={input.label}
            className={styles.input}
            name={`switch-${id}`}
            checked={input.value === value}
            onChange={() => handleOnChange(input.value)}
            onClick={() => {
              if (input.value === null || input.value === false) {
                handleOnChange(true);
              } else {
                handleOnChange(false);
              }
            }}
          />
          <label
            htmlFor={`${id}-${input.label.split(' ').join('')}`}
            key={`radio-${input.label.split(' ').join('')}`}
            className={styles.label}
          />
        </div>
      ))}
      <div className={styles.inner} />
    </div>
  );
}

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
  value: null,
};

export default Switch;
