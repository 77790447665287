/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-enable no-unused-vars */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

function Temperature({ data }) {
  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        rtl: true,
        maxSize: {
          height: 100,
          width: 100,
        },
        border: {
          width: 5,
          color: 'black',
        },
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    resizeDelay: 1000,
    onResize: (chart, size) => {
      if (size.width < 1000) {
        chart.options.maintainAspectRatio = size.width < 1000;
        chart.canvas.parentNode.style.height = '80%';
        chart.canvas.parentNode.style.width = '100%';
        chart.options.plugins.legend.position = 'bottom';
        chart.options.plugins.legend.labels.boxWidth = 50;
      }
    },
    options: {
      scales: {
        yAxisKey: {
          display: false,
        },
      },
    },
  };

  const parsing = {
    xAxisKey: 'x',
    yAxisKey: 'y',
  };

  function temperatures() {
    const info = [];
    let info2 = {};

    data?.data?.data?.temps?.forEach((element) => {
      element.data.forEach(() => {
        info2 = {
          data: element.data.map((item) => ({
            x: new Date(item.x).toLocaleDateString(),
            y: item.y,
          })),
          parsing,
          label: element.label,
          backgroundColor: element.borderColor,
          spanGaps: true,
          borderColor: element.borderColor,
          tension: 0.1,
          fill: false,
        };
      });
      info.push(info2);
    });
    return info;
  }

  const data2 = {
    datasets: temperatures(),
  };

  return (
    <div className="chart">
      <Line
        options={options}
        data={data2}
       />
    </div>
  );
}

Temperature.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Temperature;
