import React, { useState } from 'react';
import {
  faChevronLeft,
  faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { getObservation, postLift } from '../../services/registre';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import styles from './DesktopObservation.module.css';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Textarea from '../../components/atoms/Textarea/Textarea';
import CanvasSignature from '../../components/atoms/Canvas/Canvas';
import useAppContext from '../../store/useAppContext';

function DesktopObservation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [oneObservation, setOneObservation] = useState([]);
  const urlParams = useParams();
  const [context] = useAppContext();
  const [openModal, setOpenModal] = React.useState(false);
  const [signature, setSignature] = React.useState('');

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const initialValues = {
    upDate: '',
    prestataireName: '',
    prestataire: false,
    document: null,
    description: '',
    SocietyName: null,
    signature: '',
  };
  const getObservationQuery = useQuery(['getObservation'], () => getObservation({
    id: urlParams.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setOneObservation(data?.data);
    },
  });
  const postLiftMutation = useMutation(postLift, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('raisedObs.successave'));
    },
    onError: () => {
      toast.error(t('raisedObs.echecsave'));
    },
  });

  const validationSchema = Yup.object({
    prestataire: Yup.boolean(),
    upDate: Yup.string().required('global.required_field'),
    prestataireName: Yup.string().required('global.required_field'),
    document: Yup.mixed()
      .nullable()
      .typeError(t('global.wrong_type'))
      .when('prestataire', {
        is: false,
        then: Yup.mixed().nullable()
          .required('global.required_field')
          .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 100000000)
          .test('type', 'global.accepted_formats', (value) => value && (
            value.type === 'application/pdf'
            || value.type === 'application/x-pdf'
            || value.type === 'image/jpeg'
            || value.type === 'image/jpg'
            || value.type === 'image/png'
            || value.type === 'image/tiff'
            || value.type === 'image/bmp'
            || value.type === 'image/heic'
            || value.type === 'image/vnd.dwg'
          )),
      }),
    description: Yup.string(),
    SocietyName: Yup.string().nullable()
      .when('prestataire', {
        is: false,
        then: Yup.string().required('global.required_field'),
      }),
    signature: Yup.string().when('document', {
      is: null,
      then: Yup.string().required('global.doc_sign'),
    }),
  });
  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append('observationIds[0]', urlParams?.id);
    formData.append('reportId', oneObservation?.report?.id);
    formData.append('liftDate', values.upDate);
    formData.append('stakeholder', values.prestataireName);
    if (values.document !== null) {
      formData.append('documentFile', values.document);
    }
    formData.append('actionPerformed', values.description);
    if (values.prestataire !== true) {
      formData.append('society', values.SocietyName);
    }
    if (values.signature !== '') {
      formData.append('signatureFile', values.signature);
    }
    postLiftMutation.mutate(formData);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const canvasToPngDataURL = (canvas) => {
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  };
  const sigCanvas = React.useRef({});
  const trim = () => {
    if (sigCanvas.current.isEmpty()) {
      setOpenModal(false);
      toast.error(t('dairy.nochangeSignature'));
      return;
    }
    const trimmedDataURL = canvasToPngDataURL(sigCanvas.current.getTrimmedCanvas());
    setSignature(trimmedDataURL);
    formik.setFieldValue('signature', trimmedDataURL);
    setOpenModal(false);
  };

  const clear = () => {
    sigCanvas.current.clear();
    setSignature('');
  };
  return (
    <Layout
      title="Observations"
      layout="table"
      queryError={
        getObservationQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">
            {t('raisedObs.add')}
            :
          </h1>
        </div>
        <span className="title-info">
          {oneObservation?.intervention?.title}
        </span>
      </header>
      <div className="title">
        <div>
          {getObservationQuery.isLoading && (
            <div>{t('global.loading')}</div>
          )}
          {/* <div>
            Observation:
            {oneObservation?.title}
            <br />
            Numero de rapport:
            {oneObservation?.number}
          </div> */}
          <form
            onSubmit={formik.handleSubmit}
            className={cn(['form shadow-sm', styles.form])}
          >
            {/* {getObservationQuery?.data?.observationNumber} */}
            {/* <table>
              <thead>
                <tr>
                  <td>Observation</td>
                  <td>Numéro de rapport</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{oneObservation?.title}</td>
                  <td>{oneObservation?.number}</td>
                </tr>
              </tbody>
            </table> */}
            <div className={styles.table}>
              Observation(s)
              <br />
              <span style={{ color: 'grey' }}>
                {oneObservation?.number}
                {' - '}
                {oneObservation?.title}
              </span>
            </div>
            <div>
              {' '}
            </div>
            {/* <div className="form_group">
              <Input
                type="text"
                name="observation"
                label={t('raisedObs.observation')}
                value={oneObservation?.title}
                disabled
              />
            </div>
            <div className="form_group">
              <Input
                type="text"
                name="number"
                label={t('raisedObs.num_rapport')}
                value={oneObservation?.number}
                disabled
              />
            </div> */}
            <div className="form_group">
              <Input
                type="date"
                name="upDate"
                label={t('raisedObs.upDate')}
                placeholder={t('raisedObs.upDate')}
                value={formik.values.upDate}
                onChange={formik.handleChange}
                required
              />
              {formik.errors.upDate && formik.touched.upDate ? (
                <div className="error">
                  {t(formik.errors.upDate)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                type="text"
                name="prestataireName"
                label={t('raisedObs.prestataireName')}
                placeholder={t('raisedObs.prestataireName')}
                value={formik.values.prestataireName}
                onChange={(e) => formik.setFieldValue('prestataireName', e.target.value.toUpperCase())}
                required
              />
              {formik.errors.prestataireName && formik.touched.prestataireName ? (
                <div className="error">
                  {t(formik.errors.prestataireName)}
                </div>
              ) : null }
            </div>
            {formik.values.prestataire === true ? (
              <div className="form_group">
                <Input
                  type="text"
                  name="SocietyName"
                  label={t('raisedObs.SocietyName')}
                  placeholder={t('raisedObs.SocietyName')}
                  value={formik.values.SocietyName}
                  onChange={formik.handleChange}
                  disabled
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className="error">
                    {t(formik.errors.description)}
                  </div>
                ) : null }
              </div>
            ) : (
              <div className="form_group">
                <Input
                  type="text"
                  name="SocietyName"
                  label={t('raisedObs.SocietyName')}
                  placeholder={t('raisedObs.SocietyName')}
                  value={formik.values.SocietyName}
                  onChange={(e) => formik.setFieldValue('SocietyName', e.target.value.toUpperCase())}
                  required={formik.values.prestataire === false}
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className="error">
                    {t(formik.errors.description)}
                  </div>
                ) : null }
              </div>
            )}
            <div className={cn(['form_checkbox', styles.check])}>
              <Checkbox
                id="prestataire"
                name="prestataire"
                className={styles.check}
                label={t('raisedObs.byPrestaataire')}
                placeholder={t('raisedObs.byPrestaataire')}
                value={formik.values.prestataire}
                onChange={formik.handleChange}
                checked={formik.values.prestataire}
              />
            </div>
            {' '}
            <div className="form_group--fullwidth">
              <Textarea
                name="description"
                label={t('raisedObs.descriptionTextarea')}
                placeholder={t('raisedObs.description')}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.description && formik.touched.description ? (
                <div className="error">
                  {t(formik.errors.description)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <UploadFile
                id="documentFile"
                name="document"
                label={t('contract.report')}
                fileName={formik.values.document ? formik.values.document.name : formik.values.document}
                onChange={(event) => formik.setFieldValue('document', event.currentTarget.files[0])}
                onBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
              />
              {formik.errors.document && formik.touched.document ? (
                <div className="error">
                  {t(formik.errors.document)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              {' '}
            </div>
            {formik.values.prestataire && (
            <div className="form_group">
              <div className="form_infos">
                <small>{t('dairy.signature')}</small>
              </div>
              <button type="button" className={cn(['form_submit', styles.button])} onClick={() => setOpenModal(true)}>
                {signature ? t('dairy.change_signature') : t('dairy.add_signature')}
                <FontAwesomeIcon icon={faFileSignature} className={styles.FontAwesomeIcon} transform="grow-15" />
              </button>
              {!formik.values.signature && (
                <input
                  autoComplete="off"
                  style={{ opacity: 0, height: 0, width: 0 }}
                  value={formik.values.signature}
                />
              )}
              {formik.errors.signature && formik.touched.signature ? (
                <div className="error">
                  {t(formik.errors.signature)}
                </div>
              ) : null }
            </div>
            )}
            {signature && formik.values.prestataire && (
              <div className="form_group">
                <img
                  src={signature}
                  alt="signature"
                  className={styles.signature}
                />
              </div>
            )}
            <div className="form_footer">
              <div className="form_infos">
                <small>{t('addInterventions.mandatory_fields')}</small>
              </div>
              <Button
                type="submit"
                className="form_submit"
                label={t('raisedObs.save')}
              />
            </div>
          </form>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
      </footer>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <button onClick={() => setOpenModal(false)} type="button" className={styles.close}>
          <span aria-hidden="true">&times;</span>
        </button>
        <p className="modal_paragraph">
          { signature ? t('dairy.change_signature') : t('dairy.add_signature')}
        </p>
        {signature && (
          <img
            src={signature}
            alt="signature"
            className={styles.signature}
          />
        )}
        <div className={cn(['modal_actions', styles.signatureContainer])}>
          <CanvasSignature
            canvasProps={{
              className: styles.canvas,
            }}
            name="signature"
            trim={() => trim()}
            clear={() => clear()}
            setSignature={setSignature}
            signature={signature}
            sigCanvas={sigCanvas}
          />
        </div>
      </Modal>
    </Layout>
  );
}

export default DesktopObservation;
