import React from 'react';
import PropTypes from 'prop-types';

// components
import Card from '../../atoms/Card/Card';
import Picto from '../../atoms/Picto/Picto';

// styles
import styles from './CardBaseDocument.module.css';

// utils & misc
import cn from '../../../utils/cn';

function CardDashboard({
  title, picto, onClick, classNames, borderTopColored, text, Numbers, disabled,
}) {
  return (
    <Card
      borderTopColored={borderTopColored}
      onClick={onClick}
      className={disabled
        ? cn([styles.card, styles.cardSecurityTheme, styles.disabled].concat(classNames))
        : cn([styles.card, styles.cardSecurityTheme].concat(classNames))}
    >
      <div className={styles.leftColumn}>
        <p className={cn([styles.titleCard])}>
          { title }
        </p>
        <p className={cn([styles.textCard])}>
          { text }
        </p>
      </div>
      <div className={styles.right}>
        <div className={cn([styles.numbersCard])}>
          <p>
            { Numbers }
          </p>
        </div>
        {picto !== 'ssi' && (
          <div className={styles.rightColumn}>
            <Picto className={styles.picto} name={picto} />
          </div>
        )}
      </div>
    </Card>
  );
}

CardDashboard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  Numbers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  picto: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  classNames: PropTypes.array,
  borderTopColored: PropTypes.bool,
  disabled: PropTypes.bool,
};

CardDashboard.defaultProps = {
  borderTopColored: false,
  title: '',
  text: '',
  Numbers: '',
  classNames: [],
  disabled: false,
};

export default CardDashboard;
