import React from 'react';
import PropTypes from 'prop-types';
// Utils
import styles from './ProgressBar.module.css';
import cn from '../../../utils/cn';

function ProgressBar({ value, valueMax, sle }) {
  const progressValue = () => {
    switch (true) {
      case value === 0:
        return 'empty';
      case value >= 1 && value < valueMax:
        return 'inProgress';
      case value === valueMax:
        return 'full';
      default:
        return undefined;
    }
  };

  return (
    <div className={styles.progressBar}>
      <div className={cn([styles.label, value === 0 ? 'tag--error' : ''])}>
        {sle ? `${value} / ${valueMax} OK` : `${value} / ${valueMax}`}
      </div>
      <div className={cn([styles.progress, styles.wrapper])}>
        <div
          className={cn([styles.progress, styles.inner])}
          style={{ width: `calc((100% / ${valueMax}) * ${value} )` }}
          data-progress={progressValue()}
        />
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  value: PropTypes.number,
  valueMax: PropTypes.number,
  sle: PropTypes.bool,
};

ProgressBar.defaultProps = {
  value: 0,
  valueMax: 10,
  sle: false,
};

export default ProgressBar;
