/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { sortBy, uniqBy } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Select from '../../components/atoms/Select/Select';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Radio from '../../components/atoms/Radio/Radio';
import useAppContext from '../../store/useAppContext';
import styles from './AddUser.module.css';
import cn from '../../utils/cn';

// Services
import {
  getUser, postUser, editUser, getUsersList,
} from '../../services/users';
import { getUserGroupsList, getUserGroup } from '../../services/usersGroups';
import { getStructuresList, getGroupsList } from '../../services/structures';
// Utils
import { STRUCTURES_NAME, USERS_ROLES, USERS_JOBS } from '../../utils/constant';

function AddUser() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const inputRef1 = useRef(null);
  const [context] = useAppContext();
  const inputRef2 = useRef(null);
  const [permissionsId, setPermissionsId] = useState(0);
  const [emailError, setEmailError] = useState(0);
  const [groups, setGroups] = useState([]);
  // const [rights, setRights] = useState([]);
  const atLeastOneUppercase = /[A-Z]/g;
  const atLeastOneLowercase = /[a-z]/g;
  const atLeastOneNumeric = /[0-9]/g;
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g;
  const eightCharsOrMore = /.{8,}/g;

  const initialValues = {
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    postalCode: '',
    city: '',
    phone1: '',
    phone2: '',
    company: '',
    job: null,
    permissions: [],
    enabled: true,
    password: '',
    passwordConfirm: '',
    setPassword: '',
    groupId: null,
  };

  const phoneRegExp = /^(?:(?:\+|00)33|0)(?:\s*[1-9](?:[\s.-]*\d{2}){4}|[1-9](?:[\s.-]*\d{2}){4})$/gmi;

  const validationSchema = Yup.object().shape({
    role: Yup.string().required('global.required_field'),
    firstName: Yup.string().required('global.required_field'),
    lastName: Yup.string().required('global.required_field'),
    email: Yup.string().email('global.invalid_email_address').required('global.required_field'),
    address: Yup.string().nullable(true),
    postalCode: Yup.string().nullable(true),
    city: Yup.string().nullable(),
    phone1: Yup.string().nullable().matches(phoneRegExp, 'global.invalid_phone_number')
      .min(10, 'global.too_short')
      .max(10, 'global.too_long'),
    phone2: Yup.string().nullable(true).matches(phoneRegExp, 'global.invalid_phone_number')
      .min(10, 'global.too_short')
      .max(10, 'global.too_long'),
    company: Yup.string().nullable(true),
    job: Yup.string().nullable(true),
    permissions: Yup.array().of(
      Yup.object().shape({
        structureId: Yup.number(),
        groupId: Yup.number(),
        bookletIds: Yup.array(),
      }),
    ),
    enabled: Yup.bool().required('global.required_field'),
    setPassword: Yup.boolean(),
    groupId: Yup.number().nullable(true),
    password: Yup.string()
      .when('setPassword', {
        is: true,
        then: Yup.string()
          .matches(eightCharsOrMore, t('user.password_error_char'))
          .matches(atLeastOneUppercase, t('user.password_error_char'))
          .matches(atLeastOneLowercase, t('user.password_error_char'))
          .matches(atLeastOneNumeric, t('user.password_error_char'))
          .matches(atLeastOneSpecialChar, t('user.password_error_char')),
      }),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], t('user.password_error'))
      .when('setPassword', {
        is: true,
        then: Yup.string().required(t('user.requis')),
      }),
  });

  // API calls
  const postUserMutation = useMutation(postUser, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_user.user_added'));
    },
    onError: (error) => {
      if (error?.response?.status === 400 && error?.response?.data?.code !== 'non_unique_email') {
        toast.error(t('global.error'));
      } else if (error?.response?.data?.code === 'non_unique_email') {
        toast.error(t('add_user.already_used'));
      }
    },
  });
  const editUserMutation = useMutation(editUser, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_user.user_edited'));
    },
    onError: (error) => {
      if (error?.response?.status === 400 && error?.response?.data?.code !== 'constraint_violation') {
        toast.error(t('global.error'));
      } else if (error?.response?.data?.status === 400 || error?.response?.data?.code === 'constraint_violation') {
        toast.error(t('add_user.permission_already_used'));
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      const formValue = {
        ...formValues,
        phone1: formValues.phone1.replace(/\s/gm, '') || '',
        phone2: formValues.phone2.replace(/\s/gm, '') || '',
        password: formValues.password === formValues.passwordConfirm ? formValues.password : null,
      };
      if (context?.user?.role !== USERS_ROLES.SUPER_ADMIN && context?.user?.role !== USERS_ROLES.ADMIN) {
        delete formValue.groupId;
      }
      if (urlParams?.action === 'edit') {
        editUserMutation.mutate(formValue);
      } else {
        postUserMutation.mutate(formValue);
      }
    },
  });

  const getGroupRight = useMutation(getUserGroup, {
    onSuccess: (data) => {
      setPermissionsId(formik.values.permissionsId);
      formik.setFieldValue(`permissions[${formik.values.permissionsId}].rights`, data?.data?.rights);
    },
  });

  const getUserQuery = useQuery('user', () => getUser(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues({
        ...data.data,
        permissions: data.data.permissions.map((permission) => ({
          ...permission,
        })),
        job: data.data?.job === '' ? null : data.data?.job,
        phone1: data.data?.phone1?.replace(/\s/gm, '') || '',
        phone2: data.data?.phone2?.replace(/\s/gm, '') || '',
      });
    },
  });

  const roles = [
    {
      value: USERS_ROLES.SUPER_ADMIN,
      label: t('add_user.super_admin'),
    },
    {
      value: USERS_ROLES.ADMIN,
      label: t('add_user.admin'),
    },
    {
      value: USERS_ROLES.ADMIN_GROUP,
      label: t('add_user.admin_group'),
    },
    {
      value: USERS_ROLES.USER,
      label: t('add_user.user'),
    },
  ];

  const rolesAdmin = [
    {
      value: USERS_ROLES.ADMIN_GROUP,
      label: t('add_user.admin_group'),
    },
    {
      value: USERS_ROLES.USER,
      label: t('add_user.user'),
    },
  ];

  const jobs = [
    {
      value: USERS_JOBS.RECEPTION,
      label: t('add_user.reception'),
    },
    {
      value: USERS_JOBS.TECHNICAL_EXPERT,
      label: t('add_user.technical_expert'),
    },
    {
      value: USERS_JOBS.TECHNICAL_MANAGER,
      label: t('add_user.technical_manager'),
    },
    {
      value: USERS_JOBS.MANAGER,
      label: t('add_user.manager'),
    },
    {
      value: USERS_JOBS.AUTHORITIES,
      label: t('add_user.authorities'),
    },
    {
      value: USERS_JOBS.AUDITOR_MANAGER,
      label: t('add_user.auditor_manager'),
    },
  ];

  const [structures, setStructures] = useState([]);
  const [profiles, setProfiles] = useState([]);

  const getAllStructuresQuery = useQuery(['structures'], () => getStructuresList({
    all: true,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setStructures(uniqBy(sortBy(data.data.structures, (order) => {
        const rank = {
          Group: 1,
          Subsidiary: 2,
          Division: 3,
          Establishment: 4,
          Building: 5,
        };
        return rank[order.type];
      }).map((structure) => (
        {
          value: structure.id,
          label: `
          ${structure.name} - 
          ${structure.type === STRUCTURES_NAME.GROUP ? t('structures.group') : ''} 
          ${structure.type === STRUCTURES_NAME.SUBSIDIARY ? t('structures.subsidiary') : ''} 
          ${structure.type === STRUCTURES_NAME.DIVISION ? t('structures.division') : ''} 
          ${structure.groupName !== null ? `("${t('structures.group')}" - ${structure.groupName})` : ''}`,
        }
      )), 'label'));
    },
  });

  const getAllUserGroupsQuery = useQuery(['user-groups'], () => getUserGroupsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setProfiles(data.data.userGroups.map((userGroup) => (
        {
          value: userGroup.id,
          label: userGroup.name,
        }
      )));
    },
  });

  const getGroupsListQuery = useQuery(['groups'], () => getGroupsList(), {
    enabled: context?.user?.role === USERS_ROLES.SUPER_ADMIN || context?.user?.role === USERS_ROLES.ADMIN,
    onSuccess: (data) => {
      setGroups([{ id: null, name: '- - - - -' }, ...data.data.groups]);
    },
  });
  const getUserMail = useQuery(['usersList'], () => getUsersList({
    email: formik.values.email,
  }), {
    enabled: false,
    onSuccess: (data) => {
      if (data?.data?.total !== 0) {
        setEmailError(1);
      } else {
        setEmailError(0);
      }
    },
  });
  const verifEmail = (email) => {
    if (email.length > 2) {
      getUserMail?.refetch();
    }
  };

  /* eslint-disable prefer-destructuring */
  const addPermission = () => {
    const permissions = formik.values.permissions;
    const newPermission = {
      structureId: '',
      groupId: '',
      bookletIds: [],
      rights: [],
    };

    // ajouter le nouveau au debut de la liste
    formik.setFieldValue('permissions', [newPermission, ...permissions]);
    formik.setFieldValue('permissions[0]', newPermission);
  };

  useEffect(() => {
    if (urlParams?.action !== 'edit') formik.setFieldValue('role', formik.values.role || USERS_ROLES.USER);
    formik.setFieldValue('permission', formik.values.permissions.map((permission, index) => (
      setTimeout(() => formik.setFieldValue('permissionsId', index) && getGroupRight.mutate(permission.groupId), 1000)
    )));
    if (formik.values.permissions.length === 0
      && formik.values.role === USERS_ROLES.ADMIN_GROUP
      && formik.values.role === USERS_ROLES.USER) {
      addPermission();
    }
  }, [formik.values.role]);

  const choicesBooklet = [
    {
      id: 2,
      key: 'securite',
      label: 'Sécurité',
      title: 'add_equipment.booklet_security',
      idClass: 'bookletSecurity',
    },
    {
      id: 1,
      key: 'sanitaire',
      label: 'Sanitaire',
      title: 'add_equipment.booklet_sanitary',
      idClass: 'bookletSanitary',
    },
    {
      id: 3,
      key: 'vehicule',
      label: 'Vehicule',
      title: 'add_equipment.booklet_vehicule',
      idClass: 'bookletVehicule',
    },
  ];

  const mapForSelect = (arrayToMap) => (
    arrayToMap?.length
      ? sortBy(arrayToMap, ['name']).map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
      : []
  );

  return (
    <Layout
      title={t('add_user.title')}
      queryError={
        getUserQuery?.error
        || getAllStructuresQuery?.error
        || getAllUserGroupsQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_user.back_to_users')}</span>
          </button>
        </div>
        <h1 className="title">
          {urlParams?.action === 'edit' ? t('add_user.edit_title') : t('add_user.title')}
        </h1>
      </header>
      {getAllStructuresQuery.isLoading || getAllUserGroupsQuery.isLoading || getUserQuery.isLoading
        ? <div className="loader" />
        : (
          <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
            <div className="form_group">
              <div className="label">{t('add_user.role')}</div>
              {context?.user?.role === USERS_ROLES.ADMIN_GROUP ? (
                <div className="input">
                  {rolesAdmin.map((role) => (
                    <div key={role.value}>
                      <Radio
                        id={role.label}
                        name="role"
                        label={role.label}
                        onChange={(event) => formik.setFieldValue('role', event.target.value)}
                        onBlur={formik.handleBlur}
                        checked={formik.values.role === role.value}
                        value={role.value}
                        disabled={context?.user?.role === USERS_ROLES.ADMIN_GROUP && urlParams?.action === 'edit'}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="input">
                  {roles.map((role) => (
                    <div key={role.value}>
                      <Radio
                        id={role.label}
                        name="role"
                        label={role.label}
                        onChange={(event) => formik.setFieldValue('role', event.target.value)}
                        onBlur={formik.handleBlur}
                        checked={formik.values.role === role.value}
                        value={role.value}
                      />
                    </div>
                  ))}
                </div>
              )}
              {formik.errors.role && formik.touched.role ? (
                <div className="error">
                  {t(formik.errors.role)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Checkbox
                id="enabled"
                name="enabled"
                label={t('add_user.enabled')}
                onChange={(event) => formik.setFieldValue('enabled', event.target.checked)}
                checked={formik.values.enabled}
                value={formik.values.enabled}
              />
              {formik.errors.enabled && formik.touched.enabled ? (
                <div className="error">
                  {t(formik.errors.enabled)}
                </div>
              ) : null }
            </div>
            {formik?.values?.role === USERS_ROLES?.USER || formik?.values?.role === USERS_ROLES?.ADMIN_GROUP ? (
              <div className="form_group form_group--fullwidth form_group--highlighted">
                <Button
                  type="button"
                  className="form_button"
                  label={t('add_user.add_permission')}
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => addPermission()}
                />
                {formik.values.permissions.map((item, index) => (
                  <div>
                    <div className="form_row">
                      <Button
                        type="button"
                        className="form_button suppr"
                        style={{ alignSelf: 'flex-end', position: 'relative', top: '-1.5rem' }}
                        label={<FontAwesomeIcon icon={faXmark} />}
                        title={t('add_user.delete_permission')}
                        onClick={() => formik
                          .setFieldValue('permissions', formik.values.permissions
                            .filter((_, permissionIndex) => index !== permissionIndex))}
                      />
                      <div style={{ position: 'relative' }}>
                        <Select
                          id={`structure-${index}`}
                          label={t('add_user.structure')}
                          options={structures}
                          ref={inputRef1}
                          valueInput={formik.values.permissions[index].structureId}
                          required
                          value={structures
                            .find((option) => option.value === formik.values.permissions[index].structureId)}
                          onChange={(option) => formik
                            .setFieldValue(`permissions[${index}].structureId`, parseInt(option.value, 10))}
                          loading={getAllStructuresQuery.isLoading}
                        />
                      </div>
                      <div style={{ position: 'relative' }}>
                        <Select
                          id={`profile-${index}`}
                          label={t('add_user.profile')}
                          options={profiles}
                          ref={inputRef2}
                          valueInput={formik.values.permissions[index].groupId}
                          required
                          value={profiles.find((option) => option.value === formik.values.permissions[index].groupId)}
                          onChange={(option) => formik
                            .setFieldValue(`permissions[${index}].groupId`, parseInt(option.value, 10))
                            && formik.setFieldValue('permissionsId', index)
                            && setTimeout(() => getGroupRight.mutate(parseInt(option.value, 10)), 1000)}
                          loading={getAllUserGroupsQuery.isLoading}
                        />
                      </div>
                      <div>
                        <div className="label">{t('add_equipment.booklets')}</div>
                        { choicesBooklet.map((choice) => (
                          <div key={choice.id}>
                            <Checkbox
                              id={choice.idClass + index}
                              name="permissions.bookletIds[]"
                              label={t(choice.title)}
                              onChange={(event) => formik
                                .setFieldValue(`permissions[${index}].bookletIds`, event.target.checked
                                  ? formik.values.permissions[index].bookletIds.concat(choice.id)
                                  : formik.values.permissions[index].bookletIds.filter((id) => id !== choice.id))}
                              onBlur={formik.handleBlur}
                              checked={formik.values.permissions[index].bookletIds?.includes(choice.id)}
                              value={choice.id}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={styles.rightsBlock} id={`rights-${index}`} style={{ position: 'relative' }}>
                      {getGroupRight.isLoading ? (
                        <div className="loader" />
                      ) : formik.values.permissions[`${index}`]?.rights?.map((right) => (
                        <div key={right.id}>
                          <div className={cn([styles.rights, 'rights'])}>
                            {t(`right.${right}`)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : null }
            {formik.values.role === USERS_ROLES.USER ? (
              <>
                <div className="form_group">
                  <Input
                    id="company"
                    type="text"
                    name="company"
                    label={t('add_user.company')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company}
                  />
                  {formik.errors.company && formik.touched.company ? (
                    <div className="error">
                      {t(formik.errors.company)}
                    </div>
                  ) : null }
                </div>
                <div className="form_group">
                  <Select
                    id="job"
                    label={t('add_user.job')}
                    options={jobs}
                    value={jobs.find((option) => option.value === formik.values.job)}
                    onChange={(option) => formik.setFieldValue('job', option.value)}
                  />
                  {formik.errors.job && formik.touched.job ? (
                    <div className="error">
                      {t(formik.errors.job)}
                    </div>
                  ) : null }
                </div>
              </>
            ) : null }
            {(context?.user?.role === USERS_ROLES.SUPER_ADMIN
              || context?.user?.role === USERS_ROLES.ADMIN) ? (
                <div className="form_group">
                  <Select
                    id="groupId"
                    label={t('add_user.group')}
                    name="groupId"
                    type="select"
                    onBlur={formik.handleBlur}
                    options={mapForSelect(groups)}
                    value={mapForSelect(groups).find((option) => (
                      option.value === formik.values.groupId
                    ))}
                    onChange={(option) => formik.setFieldValue('groupId', option.value)}
                    isLoading={getGroupsListQuery.isLoading}
                    valueInput={formik.values.groupId}
                  />
                  {formik.errors.groupId && formik.touched.groupId ? (
                    <div className="error">
                      {t(formik.errors.groupId)}
                    </div>
                  ) : null }
                </div>
              ) : null }
            <div className="form_group">
              <Input
                id="lastName"
                type="text"
                name="lastName"
                label={t('add_user.lastname')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <div className="error">
                  {t(formik.errors.lastName)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="firstName"
                type="text"
                name="firstName"
                label={t('add_user.firstname')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <div className="error">
                  {t(formik.errors.firstName)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="email"
                type="email"
                name="email"
                label={t('add_user.email')}
                onChange={formik.handleChange}
                onBlur={(e) => verifEmail(e.target.value)}
                value={formik.values.email}
              />
              {emailError === 1 ? (
                <div className="error">
                  {t('add_user.already_used')}
                </div>
              ) : null }
              {formik.errors.email && formik.touched.email ? (
                <div className="error">
                  {t(formik.errors.email)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="address"
                type="text"
                name="address"
                label={t('add_user.address')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              />
              {formik.errors.address && formik.touched.address ? (
                <div className="error">
                  {t(formik.errors.address)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="postalCode"
                type="text"
                name="postalCode"
                label={t('add_user.postal_code')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postalCode}
              />
              {formik.errors.postalCode && formik.touched.postalCode ? (
                <div className="error">
                  {t(formik.errors.postalCode)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="city"
                type="text"
                name="city"
                label={t('add_user.city')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
              />
              {formik.errors.city && formik.touched.city ? (
                <div className="error">
                  {t(formik.errors.city)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="phone1"
                type="text"
                name="phone1"
                label={t('add_user.phone1')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone1.replace(/ /g, '')}
              />
              {formik.errors.phone1 && formik.touched.phone1 ? (
                <div className="error">
                  {t(formik.errors.phone1)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="phone2"
                type="text"
                name="phone2"
                label={t('add_user.phone2')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone2.replace(/ /g, '')}
              />
              {formik.errors.phone2 && formik.touched.phone2 ? (
                <div className="error">
                  {t(formik.errors.phone2)}
                </div>
              ) : null }
            </div>
            {(context?.user?.role === USERS_ROLES.SUPER_ADMIN
            || context?.user?.role === USERS_ROLES.ADMIN)
            && !context.userImpersonated && urlParams?.action === 'edit'
            && (
              <div>
                <div className="form_group">
                  <Checkbox
                    id="setPassword"
                    name="setPassword"
                    label={t('user.modify_password')}
                    value={formik.values.setPassword ? 'true' : 'false'}
                    checked={formik.values.setPassword}
                    onChange={(event) => {
                      formik.setFieldValue('setPassword', event.target.checked);
                    }}
                  />
                </div>
              </div>
            )}
            {formik.values.setPassword && (
            <div>
              <div className="form_group">
                <Input
                  id="password"
                  type="password"
                  name="password"
                  label={t('user.password')}
                  value={formik.values.password || ''}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : null}
              </div>
              <div className="form_group">
                <Input
                  id="passwordConfirm"
                  type="password"
                  name="passwordConfirm"
                  label={t('user.password_confirm')}
                  value={formik.values.passwordConfirm}
                  onChange={formik.handleChange}
                  required={formik.values.password !== ''}
                />
                {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (
                  <div className="error">{formik.errors.passwordConfirm}</div>
                ) : null}
              </div>
            </div>
            )}
            {urlParams?.action === 'edit' ? (
              <div className="form_subfooter">
                <div>
                  {t('add_user.created_at')}
                  {' '}
                  {getUserQuery?.data?.data?.createdAt ? (
                    format(new Date(`${getUserQuery?.data?.data?.createdAt}`), 'dd/MM/yyyy à HH:mm')
                  ) : null}
                  {getUserQuery?.data?.data?.createdBy ? (
                    ` ${t('global.by')} ${getUserQuery?.data?.data?.createdBy}`
                  ) : null}
                </div>
                {getUserQuery?.data?.data?.createdAt !== getUserQuery?.data?.data?.updatedAt ? (
                  <div>
                    {t('add_user.updated_at')}
                    {' '}
                    {getUserQuery?.data?.data?.updatedAt ? (
                      format(new Date(`${getUserQuery?.data?.data?.updatedAt}`), 'dd/MM/yyyy à HH:mm')
                    ) : null}
                    {getUserQuery?.data?.data?.updatedBy ? (
                      ` ${t('global.by')} ${getUserQuery?.data?.data?.updatedBy}`
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null }
            <div className="form_footer">
              <div className="form_infos">
                <small>{t('add_user.mandatory_fields')}</small>
              </div>
              <Button
                type="submit"
                className="form_submit"
                label={t('add_user.submit')}
                isLoading={urlParams?.action === 'edit' ? editUserMutation.isLoading : postUserMutation.isLoading}
              />
            </div>
          </form>
        )}
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('add_user.back_to_users')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddUser;
