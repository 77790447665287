/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
// Components
import CardDashboard from '../../components/molecules/CardBaseDocument/CardBaseDocument';
import Layout from '../../components/template/Layout';
// Hooks
// Utils
import { getDocumentwithid } from '../../services/docBase';
// styles
import styles from './DocumentBaseSousCategorie.module.css';
import useAppContext from '../../store/useAppContext';

function Documentary() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState();
  const [getId, setId] = useState();
  const [documentBaseItemCategory, setDocumentBaseItemCategory] = useState();
  const [context] = useAppContext();

  const getDocumentwithidQuery = useQuery(['getDocumentwithid'], () => getDocumentwithid({
    id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setDocumentBaseItemCategory(data?.data?.documentBaseItemTypes);
      setTitle(data?.data?.name);
      setId(data?.data?.id);
    },
  });

  const array = [
    'Présentation du SSI',
    'Liste des matériels du SSI installé',
    'Consignes pour l’exploitation du SSI',
    'Plans de zones de détection',
    'Plans de zones de mise en sécurité',
    'Plans de recollement détection',
    'Plans de recollement SMSI',
    'Plans de recollement du SSS',
    'Corrélations entre ZD et ZS telles que réalisées',
    'Corrélations entre ZS et DCT telles que réalisées',
    'Schémas unifilaires du SSI installés',
    'Listing de programmation ECS',
    'Listing de programmation CMSI',
    'Adéquation entre les EAE/EAES/AES et l’autonomie exigée',
    'Ventilation : Schéma de principe',
    'Désenfumage : Schéma de principe',
    'Désenfumage : Débits et APS',
    'Historique des travaux réalisés',
    'Cahier des charges fonctionnel SSI',
    'Rapport de réception technique',
    'Notices exploitation et maintenance',
    'Justificatifs de conformités des équipements',
    'Justificatifs d’associativité des équipements',
    'Rapport d’essais par autocontrôle',
    'Rapport de réception acoustique du SSS',
    'Extinction automatique',
  ];

  return (
    <Layout
      title={t('documentary.title')}
      layout="table"
      queryError={
        getDocumentwithidQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate('/base-documentaire')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('documentary.back_to_dashboard')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">
            {title}
          </h1>
        </div>
      </header>
      <div className={getId === 5 ? styles.ssi : styles.Sanitaire}>
        {documentBaseItemCategory?.map((item, nb) => (
          <CardDashboard
            classNames={item.categoryId === 5 ? styles.cardSSI : styles.card}
            title={item.name}
            picto="ssi"
            text={context?.choiceEstablishment?.id === 4317 ? array[nb] : ''}
            onClick={() => navigate(`/base-documentaire/${id}/${item?.id}`)}
            Numbers={item.countDocument}
            disabled={item.countDocument === 0}
            borderTopColored
          />
        ))}
      </div>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate('/base-documentaire')}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('documentary.back_to_dashboard')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default Documentary;
