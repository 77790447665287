/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  faEye, faChevronLeft, faEnvelopeOpenText, faClose, faChevronRight, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../components/atoms/Button/Button';
import Table from '../../components/molecules/Table/Table';
import { getNotificationList, GetAccountNotification } from '../../services/notification';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './NotificationDashboard.module.css';
import useAppContext from '../../store/useAppContext';
import Layout from '../../components/template/Layout';

function Notification() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [responses, setResponses] = useState([]);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [context] = useAppContext();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  const getNotificationListQuery = useQuery(['notificationList', page], () => getNotificationList({
    page,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const customStylest = {
    content: {
      top: '50%',
      left: '50%',
      width: '50%',
      height: '50%',
      right: 'auto',
      bottom: 'auto',
      borderRadius: '1rem',
      boxShadow: '0.1rem 0.1rem white',
      marginRight: '-50%',
      zIndex: 2,
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const read = useMutation(GetAccountNotification, {
    onSuccess: (data) => {
      setResponses(data?.data);
    },
  });

  const open = (value) => {
    read.mutate(value);
    setOpenNotificationModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('notification.title'),
        accessor: 'title',
      },
      {
        Header: t('notification.dateView'),
        accessor: 'readAt',
        Cell: ({ value }) => <div className={styles.date}>{value && new Date(value).toLocaleDateString()}</div>,
      },
      {
        Header: t('notification.action'),
        accessor: 'id',
        Cell: ({ value }) => (
          <div>
            <FontAwesomeIcon icon={faEye} onClick={() => open(value)} />
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <Layout
        title={t('notification.title')}
        description={t('notification.description')}
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/dashboard')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('notification.allNotifications')}</h1>
            <Link className="add col-3" to="/addNotification">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('notification.add')}</span>
            </Link>
          </div>
        </header>
        <div className="row">
          <div className={styles.table}>
            <Table
              columns={columns}
              isLoading={getNotificationListQuery?.isLoading}
              data={getNotificationListQuery?.data?.data?.accountNotifications || []}
              hasSort
              pagination={(
                <Pagination
                  previousLabel={(
                    <FontAwesomeIcon icon={faChevronLeft} />
                  )}
                  nextLabel={(
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                  onPageChange={onPageChange}
                  totalCount={getNotificationListQuery?.data?.totalPages || 0}
                  currentPage={page}
                  haveToPaginate
                  pageSize={10}
                  numberElementsDisplayed={getNotificationListQuery?.data?.length || 0}
                />
              )}
            />
          </div>
        </div>
        <footer className="footer">
          <button type="button" className="link" onClick={() => navigate('/dashboard')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </footer>
      </Layout>
      <Modal
        isOpen={openNotificationModal}
        onRequestClose={() => setOpenNotificationModal(false)}
        style={customStylest}
        ariaHideApp={false}
        id="notificationModal"
        contentLabel="notification Modal"
      >
        <div>
          <h2 className={styles.header}>
            <FontAwesomeIcon icon={faEnvelopeOpenText} />
            {t('notification.message')}
          </h2>
          <hr />
          <div className={styles.titre}>
            <div>{responses?.title}</div>
          </div>
          <div className={styles.text}>
            <p className={styles.message}>
              <pre>{responses?.message}</pre>
            </p>
          </div>
          <div className={styles.button}>
            <Button
              type="button"
              className={styles.close}
              label={<FontAwesomeIcon icon={faClose} />}
              onClick={() => setOpenNotificationModal(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Notification;
