/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  faChevronLeft, faChevronRight, faEye, faEnvelopeOpenText, faClose, faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Search from '../../components/molecules/Search/Search';
import { getContactsList } from '../../services/contact';
import useAppContext from '../../store/useAppContext';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './contact.module.css';
import cn from '../../utils/cn';

/* eslint-disable react/prop-types */

function Contact() {
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  // const [supplierToDelete, setsupplierToDelete] = useState('');
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [search, setSearch] = useState('');
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [context] = useAppContext();

  const message = useQuery(['message', search], () => getContactsList({
    page: 0,
    search,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      width: '80rem',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const handleModal = (id) => {
    setInfo(id);
    setOpenModal(true);
  };
  const handleModalDelete = () => {
    setOpenModalDelete(true);
    // setsupplierToDelete(id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'societe',
        accessor: 'senderCompany',
      },
      {
        Header: `${t('contact.name')}`,
        accessor: 'senderName',
      },
      {
        Header: `${t('contact.email')}`,
        accessor: 'senderEmail',
      },
      {
        Header: `${t('contact.purpose')}`,
        accessor: 'purpose',
      },
      {
        Header: `${t('contact.phone')}`,
        accessor: 'senderPhone',
      },
      {
        Header: `${t('contact.date')}`,
        accessor: 'createdAt',
        Cell: ({ row }) => (
          <span>
            {new Date(row.original.createdAt).toLocaleDateString()}
          </span>
        ),
      },
      {
        Header: `${t('message')}`,
        Cell: ({ row }) => (
          row.original.content.length > 50 ? (
            <div className="message">
              {row.original.content.substring(0, 50)}
              ...
              <Button
                onClick={() => handleModal(row.original)}
                className="action"
                label={<FontAwesomeIcon icon={faEye} />}
              />
            </div>
          ) : (
            <div className="message">
              {row.original.content}
            </div>
          )
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Button
              type="button"
              className="action suppr"
              label={<FontAwesomeIcon icon={faTrashCan} />}
              title={t('safetyLightingEquipments.suppr')}
              onClick={() => handleModalDelete({ id })}
            />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title={t('contact.title_page')}
        layout="table"
        table={message.data}
        queryError={
          message?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/dashboard')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('contact.liste_message')}</h1>
          </div>
        </header>
        <div className={styles.table}>
          <div className={styles.search}>
            <Search
              onChange={handleSearch}
              className="mb-20"
            />
          </div>
        </div>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={message.isLoading}
            data={message?.data?.data?.contacts || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={message?.data?.data?.total || 0}
                currentPage={page}
                haveToPaginate
                pageSize={10}
                numberElementsDisplayed={message?.data?.data?.count || 0}
              />
            )}
          />
        </div>
        <div>
          <button type="button" className="link" onClick={() => navigate('/dashboard')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('contact.dashboard')}</span>
          </button>
        </div>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <h2 className={styles.div}>
          <FontAwesomeIcon icon={faEnvelopeOpenText} />
          {' '}
          {t('contact.your_message')}
        </h2>
        <div>
          <Button
            onClick={() => setOpenModal(false)}
            className={cn(['action', styles.close])}
            label={<FontAwesomeIcon icon={faClose} />}
          />
        </div>
        <hr />
        <div className="container">
          <div className="row">
            <div className="col-3 col-md-8">
              <small className={styles.titre}>
                {t('contact.sendnamefor')}
              </small>
              :
              {' '}
              {info.senderName}
            </div>
            <div className="col-3 col-md-8">
              <small className={styles.titre}>
                {t('contact.sendemailfor')}
              </small>
              :
              {' '}
              {info.senderEmail}
            </div>
            <div className="col-3 col-md-4">
              <small className={styles.titre}>
                {t('contact.sendphonefor')}
              </small>
              :
              {' '}
              {info.senderPhone}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <small className={styles.titre}>
                {t('contact.sendcompanyfor')}
              </small>
              :
              {' '}
              {info.senderCompany}
            </div>
          </div>
          <div className="row">
            <div className={cn(['col-12 col-md-4', styles.titre2])}>
              <small className={styles.titre}>
                {t('contact.sendpurposefor')}
              </small>
              :
              {' '}
              {info.purpose}
            </div>
          </div>
          <hr />
          <div className="col-12 col-md-6">
            {' '}
            {info.content}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openModalDelete}
        onRequestClose={() => setOpenModalDelete(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModalDelete(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            // onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
    </>
  );
}
export default Contact;
