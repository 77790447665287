/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Components
import Switch from '../../../atoms/Switch/Switch';
import Textarea from '../../../atoms/Textarea/Textarea';
import styles from './SwitchComment.module.css';
import cn from '../../../../utils/cn';

function SwitchComment({
  id, label, value, onChange, className, inputType,
}) {
  // Hooks
  const { t } = useTranslation();

  const isCommRequired = () => {
    if (value.isDirty) {
      if (inputType === 'commObligTTLT' || (inputType === 'commObligSiKo' && value.checked === false) || (inputType === 'commObligSiNe' && value.checked === 'null')) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <div className={cn(['inputForm', className])} data-input-type={value?.inputType}>
      {
        typeof label === 'string' ? (
          <div className={cn(['inputForm_control', 'inputForm_label', styles.label])}>
            {label}
          </div>
        ) : (
          label?.map((lab) => (
            <div
              className={cn(['inputForm_control', 'inputForm_labels', 'control'])}
              style={
                window.innerWidth > 768
                  ? {
                    width: lab.width
                      ? `${lab.width}px`
                      : '100%',
                  }
                  : {}
              }
            >
              {window.innerWidth < 768 && (
                <div className="inputForm_control inputForm_label inputTitle">
                  {lab.columns}
                </div>
              )}
              {lab.title}
            </div>
          ))
        )
      }
      <div className="inputForm_control inputForm_switch control" data-input-type={value?.inputType}>
        {window.innerWidth < 768 && (
          <div className="inputForm_control inputForm_label inputTitle">
            {t('inputs.state')}
          </div>
        )}
        <Switch
          id={`switch-${id}`}
          value={value?.checked}
          onChange={(event) => onChange({
            ...value,
            checked: event,
          })}
        />
      </div>
      <div className="inputForm_control inputForm_textarea control">
        {window.innerWidth < 768 && (
          <div className="inputForm_control inputForm_label inputTitle">
            {t('inputs.comment')}
          </div>
        )}
        <Textarea
          id={`textarea-${id}`}
          name={`textarea-${id}`}
          label={t('inputs.comment')}
          labelHidden
          value={value?.comment || ''}
          onChange={(event) => onChange({
            ...value,
            comment: event.target.value,
          })}
          required={isCommRequired()}
        />
      </div>

    </div>
  );
}

SwitchComment.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  value: PropTypes.shape({
    checked: PropTypes.bool,
    comment: PropTypes.string,
    inputType: PropTypes.string,
    isDirty: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  inputType: PropTypes.string,
};

SwitchComment.defaultProps = {
  className: '',
  inputType: '',
};
export default SwitchComment;
