/* eslint-disable max-len */
import api from './_api';

/**
 * GET - Get Inputs to complete
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getInputsToComplete = (queryParams) => api.get('/inputs', { params: queryParams });

/**
 * GET - Get Inputs history
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getInputsHistory = (queryParams) => api.get('/inputs/history', { params: queryParams });

/**
 * Get one Inputs
 * @param {id: Number} inputId
 * @returns {Promise<AxiosResponse>}
 */
export const getOneInputs = (inputId) => api.get(`/inputs/${inputId.establishmentId}/${inputId.buldingId}/${inputId.token}`);

// si que ke front a modifier rappeler url:
// export const getOneInputs = (inputId) => api.get(`/inputs/${inputId.token}`);
/**
 * Generate input
 * @returns {Promise<AxiosResponse>}
 */
export const generateInput = () => api.get('/inputs/generate');

/**
 * PUT - PUT Input
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const putInput = (input) => api.put(`/inputs/${input.id}`, input);

/**
 * POST - Post Inputs
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const postInputs = (inputs) => api.post('/inputs', inputs);

/**
 * DELETE - DELETE Input
 * @param {id: Number} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const deleteInput = (id) => api.delete(`/inputs/${id}`);

/**
 * DELETE - DELETE Input
 * @param {id: Number} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getInput = (id) => api.get(`/inputs/${id}`);

/**
 * GET - Get lightning equipments
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getLightningEquipments = (queryParams) => api.get('/safety-lighting-equipment-inputs', {
  params: queryParams,
});

/**
 * POST - Post Inputs
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const postLightingEquipment = (inputs) => api.post('/safety-lighting-equipment-inputs', inputs);

/**
 * Edit - Edit Inputs
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const editLightingEquipment = (inputs) => api.put('/safety-lighting-equipment-inputs', inputs);

/**
 * POST - Post Inputs CT/REV
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const postCTREV = (inputs) => api.post(`/inputs/${inputs.id}`, inputs.data);
