/* eslint-disable max-len */
import api from './_api';
import apiCron from './_apiCron';

/**
 * GET - Get users notifications
 * @param {{Number: Establissement}} establishmentId
 * @returns {Promise<AxiosResponse>}
*/
export const getIndicatorByEtablissement = (establishmentId) => api.get(`indicators/${establishmentId.Establissement}`);

/**
 * GET - Get users notifications
 * @param {{Object: data}} data
 * @returns {Promise<AxiosResponse>}
*/
export const getIndicator = (data) => api.get('/indicators', { params: data });

/**
 * GET - Update Indicator by all establishment
 * @returns {Promise<AxiosResponse>}
 */
export const calculeIndicateur = () => apiCron.get('/indicator/all');
