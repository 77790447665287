import apiCron from './_apiCron';

/**
 * GET - Cron securite user
 * @returns {Promise<AxiosResponse>}
 */
export const sendUserSecuriteEmailAll = (optionalParam = null) => {
  const url = optionalParam ? `/SecuriteUser/${optionalParam}` : '/SecuriteUser';
  return apiCron.get(url);
};

/**
 * GET - Cron sanitaire user
* @param {string|null} optionalParam - Paramètre facultatif
 * @returns {Promise<AxiosResponse>}
 */
export const sendUserSanitaireEmailAll = (optionalParam = null) => {
  const url = optionalParam ? `/SanitaireUser/${optionalParam}` : '/SanitaireUser';
  return apiCron.get(url);
};

/**
 * GET - Cron securite admin
* @param {string|null} optionalParam - Paramètre facultatif
 * @returns {Promise<AxiosResponse>}
 */
export const sendAdminSecuriteEmailAll = (optionalParam = null) => {
  const url = optionalParam ? `/SecuriteAdmin/${optionalParam}` : '/SecuriteAdmin';
  return apiCron.get(url);
};

/**
 * GET - Cron sanitaire admin
* @param {string|null} optionalParam - Paramètre facultatif
 * @returns {Promise<AxiosResponse>}
 */
export const sendAdminSanitaireEmailAll = (optionalParam = null) => {
  const url = optionalParam ? `/SanitaireAdmin/${optionalParam}` : '/SanitaireAdmin';
  return apiCron.get(url);
};

/**
 * GET - recap report
 * @param {string|null} optionalParam - Paramètre facultatif
 * @returns {Promise<AxiosResponse>}
 */
export const sendRecapreport = (optionalParam = null) => {
  const url = optionalParam ? `/Recapreport/${optionalParam}` : '/Recapreport';
  return apiCron.get(url);
};
