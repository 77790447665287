import React from 'react';
import PropTypes from 'prop-types';
// Utils
import styles from './List.module.css';
import cn from '../../../utils/cn';

function List({ className, children }) {
  return (
    <ul className={cn([styles.list, className || ''])}>
      {children}
    </ul>
  );
}

List.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
};

List.defaultProps = {
  className: '',
};

export default List;
