import api from './_api';

/**
 * GET - Get Vehicle list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 *  */
export const getVehiculelist = (queryParams) => api.get('/vehicles', { params: queryParams });

/**
 * POST - Create a new vehicle
 * @param {Object} vehiculeinfo
 * @returns {Promise<AxiosResponse>}
 */

export const createVehicle = (vehiculeinfo) => api.post('/vehicles', vehiculeinfo);

/**
 * Get- Get the vehicle type
 *  @param {Object} vehicle
 * @returns {Promise<AxiosResponse>}
 */
export const typeVehicule = (vehicule) => api.get(`/vehicle-types/${vehicule}`);

/**
 * Get- List type vehicle
 * @param {Object} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const typeVehicleList = (queryParams) => api.get('/vehicle-types', { params: queryParams });

/**
 * Get- Get one vehicle
 * @param {Object} vehicleId
 * @returns {Promise<AxiosResponse>}
 */
export const GetVehicle = (vehicleId) => api.get(`/vehicles/${vehicleId}`);

/**
 * PUT- edit a vehicle
 *  @param {object} vehiculeId
 * @param {Object} vehicle
 * @returns {Promise<AxiosResponse>}
 */
export const EditVehicle = (vehicle) => api.put(`/vehicles/${vehicle.vehiculeId}`, vehicle);

/**
 * DELETE - Document vehicle
 * @param {id: Number} param
 * @returns {Promise<AxiosResponse>}
 */
export const deleteVehicle = (param) => api.delete(`/vehicles/${param}`);
