/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './VideoPopUp.module.css';

function VideoPopup({ videoUrl, onClick, onClose }) {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = () => {
    setIsOpen(false);
    onClose();
    localStorage.setItem('videoPopup', 'true');
  };

  useEffect(() => {
    /* TEMPORARY DISABLING AUTOPLAY
    const isVideoPopup = localStorage.getItem('videoPopup');
    if (isVideoPopup) {
      setIsOpen(false);
    }
    */
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (onClick === true) {
      setIsOpen(true);
    }
  }, [onClick]);

  return (
    <div>
      {isOpen && (
        <div className={styles.video_popup_overlay}>
          <div className={styles.video_popup_content}>
            <span className={styles.close_button} onClick={closePopup}>&times;</span>
            <iframe
              width="100%"
              height="100%"
              src={videoUrl}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Video Popup"
            />
          </div>
        </div>
      )}
    </div>
  );
}

VideoPopup.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  onClick: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

VideoPopup.defaultProps = {
  onClick: false,
};

export default VideoPopup;
