import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import styles from './EditUser.module.css';
import { editUser, getUser } from '../../services/users';
import Layout from '../../components/template/Layout';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import useAppContext from '../../store/useAppContext';

function EditUser() {
  const { t } = useTranslation();
  const [meter, setMeter] = useState(false);
  const navigate = useNavigate();
  const [context] = useAppContext();
  const atLeastOneUppercase = /[A-Z]/g;
  const atLeastOneLowercase = /[a-z]/g;
  const atLeastOneNumeric = /[0-9]/g;
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g;
  const eightCharsOrMore = /.{8,}/g;

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    adresse: '',
    PostalCode: '',
    city: '',
    phone1: '',
    phone2: '',
    password: '',
    passwordConfirm: '',
    enabled: 1,
    setPassword: '',
  };
  const phoneRegExp = /^(?:(?:\+|00)33|0)(?:\s*[1-9](?:[\s.-]*\d{2}){4}|[1-9](?:[\s.-]*\d{2}){4})$/gmi;

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t('user.requis')),
    lastName: Yup.string().required(t('user.requis')),
    email: Yup.string().email(t('user.email_invalid')).required(t('user.requis')),
    adresse: Yup.string().nullable(),
    PostalCode: Yup.string()
      .min(5, t('user.postal_code_error'))
      .max(5, t('user.postal_code_error'))
      .nullable(),
    city: Yup.string(),
    phone1: Yup.string()
      .matches(
        phoneRegExp,
        { message: t('prestataire.phone_number_invalid'), excludeEmptyString: true },
      )
      .nullable(),
    phone2: Yup.string()
      .matches(
        phoneRegExp,
        { message: t('prestataire.phone_number_invalid'), excludeEmptyString: true },
      )
      .nullable(),
    setPassword: Yup.boolean(),
    password: Yup.string()
      .when('setPassword', {
        is: true,
        then: Yup.string()
          .matches(eightCharsOrMore, t('user.password_error_char'))
          .matches(atLeastOneUppercase, t('user.password_error_char'))
          .matches(atLeastOneLowercase, t('user.password_error_char'))
          .matches(atLeastOneNumeric, t('user.password_error_char'))
          .matches(atLeastOneSpecialChar, t('user.password_error_char')),
      }),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], t('user.password_error'))
      .when('setPassword', {
        is: true,
        then: Yup.string().required(t('user.requis')),
      }),
  });

  const editUserMutation = useMutation(editUser, {
    onSuccess: () => {
      toast.success(t('user.edit_success'));
      navigate(-1);
    },
    onError: () => {
      toast.error(t('user.edit_error'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const user = {
        id: context.user.id,
        role: context.user.role,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        address: values.adresse,
        postalCode: values.PostalCode,
        city: values.city,
        phone1: values.phone1,
        phone2: values.phone2,
        enabled: values.enabled,
        password: values.password,
      };
      editUserMutation.mutate(user);
    },
  });

  const UserMe = useQuery(['userme'], () => getUser(context.user.id), {
    onSuccess: (data) => {
      formik.setFieldValue('firstName', data?.data?.firstName);
      formik.setFieldValue('lastName', data?.data?.lastName);
      formik.setFieldValue('email', data?.data?.email);
      formik.setFieldValue('adresse', data?.data?.address);
      formik.setFieldValue('PostalCode', data?.data?.postalCode);
      formik.setFieldValue('city', data?.data?.city);
      formik.setFieldValue('phone1', data?.data?.phone1);
      formik.setFieldValue('phone2', data?.data?.phone2);
    },
  });

  const passwordTracker = {
    uppercase: formik.values.password.match(atLeastOneUppercase),
    lowercase: formik.values.password.match(atLeastOneLowercase),
    number: formik.values.password.match(atLeastOneNumeric),
    specialChar: formik.values.password.match(atLeastOneSpecialChar),
    eightCharsOrMore: formik.values.password.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value,
  ).length;

  return (
    <Layout
      title="Edit User"
      layout="table"
      queryError={
        UserMe?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button
            type="button"
            className="link"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('user.return')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">{t('user.edit')}</h1>
        </div>
      </header>
      <div className="title">
        <form
          className="form shadow"
          onSubmit={formik.handleSubmit}
        >
          <div className="form_group">
            <Input
              id="firstName"
              type="text"
              name="firstName"
              label={t('user.firstname')}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              required
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="error">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Input
              id="lastName"
              type="text"
              name="lastName"
              label={t('user.lastname')}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              required
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="error">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Input
              id="email"
              type="text"
              name="email"
              label={t('user.email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              touched={formik.touched.email}
              required
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Input
              id="adresse"
              type="text"
              name="adresse"
              label={t('user.adresse')}
              value={formik.values.adresse}
              onChange={formik.handleChange}
              error={formik.errors.adresse}
              touched={formik.touched.adresse}
            />
            {formik.touched.adresse && formik.errors.adresse ? (
              <div className="error">{formik.errors.adresse}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Input
              id="PostalCode"
              type="text"
              name="PostalCode"
              label={t('user.zip_code')}
              value={formik.values.PostalCode}
              onChange={formik.handleChange}
              error={formik.errors.PostalCode}
              touched={formik.touched.PostalCode}
            />
            {formik.touched.PostalCode && formik.errors.PostalCode ? (
              <div className="error">{formik.errors.PostalCode}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Input
              id="city"
              type="text"
              name="city"
              label={t('user.city')}
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.errors.city}
              touched={formik.touched.city}
            />
            {formik.touched.city && formik.errors.city ? (
              <div className="error">{formik.errors.city}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Input
              id="phone1"
              type="text"
              name="phone1"
              label={t('user.phone1')}
              value={formik.values.phone1.replace(/ /g, '')}
              onChange={formik.handleChange}
            />
            {formik.touched.phone1 && formik.errors.phone1 ? (
              <div className="error">{formik.errors.phone1}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Input
              id="phone2"
              type="text"
              name="phone2"
              label={t('user.phone2')}
              value={formik?.values?.phone2?.replace(/ /g, '')}
              onChange={formik.handleChange}
            />
            {formik.touched.phone2 && formik.errors.phone2 ? (
              <div className="error">{formik.errors.phone2}</div>
            ) : null}
          </div>
          <div>
            <div className="form_group">
              <Checkbox
                id="setPassword"
                name="setPassword"
                label={t('user.modify_password')}
                value={formik.values.setPassword ? 'true' : 'false'}
                checked={formik.values.setPassword}
                onChange={(event) => {
                  formik.setFieldValue('setPassword', event.target.checked);
                }}
              />
            </div>
          </div>
          {formik.values.setPassword && (
          <div>
            <div className="form_group">
              <Input
                id="password"
                type="password"
                name="password"
                label={t('user.password')}
                value={formik.values.password || ''}
                onChange={formik.handleChange}
                onFocus={() => setMeter(true)}
              />
              {meter && (
              <div>
                <div className="password-strength-meter">{' '}</div>
                <div>
                  <p className={styles.mdpregle}>
                    {!passwordTracker.uppercase && t('user.uppercase')}
                  </p>
                  <p className={styles.mdpregle}>
                    {!passwordTracker.lowercase && t('user.lowercase')}
                  </p>
                  <p className={styles.mdpregle}>
                    {!passwordTracker.specialChar && t('user.special_char')}
                  </p>
                  <p className={styles.mdpregle}>
                    {!passwordTracker.number && t('user.number')}
                  </p>
                  <p className={styles.mdpregle}>
                    {!passwordTracker.eightCharsOrMore
                      && t('user.must_contain')}
                  </p>
                </div>
              </div>
              )}
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="form_group">
              <Input
                id="passwordConfirm"
                type="password"
                name="passwordConfirm"
                label={t('user.password_confirm')}
                value={formik.values.passwordConfirm}
                onChange={formik.handleChange}
                disabled={passwordStrength < 5}
                required={formik.values.password !== ''}
              />
              {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (
                <div className="error">{formik.errors.passwordConfirm}</div>
              ) : null}
            </div>
          </div>
          )}
          <div className="form_footer">
            <div className="form_infos">
              <small>{t('addInterventions.mandatory_fields')}</small>
            </div>
            <Button
              type="submit"
              className="form_submit"
              label={t('user.edit')}
              isLoading={editUserMutation.isLoading}
            />
          </div>
        </form>
      </div>
      <footer>
        <div className="row mt-50">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('user.return')}</span>
          </button>
        </div>
      </footer>
      <style jsx>
        {`
            .password-strength-meter {
              height: 0.3rem;
              background-color: lightgrey;
              border-radius: 3px;
              margin: .5rem 0
            }
  
            .password-strength-meter::before {
              content: "";
              background-color: ${
                ['red', 'orange', '#03a2cc', '#03a2cc', '#0ce052'][
                  passwordStrength - 1
                ] || ''
              };
              height: 100%;
              width: ${(passwordStrength / 5) * 100}%;
              display: block;
              border-radius: 3px;
              transition: width 0.2s;
            }
          `}
      </style>
    </Layout>
  );
}

export default EditUser;
