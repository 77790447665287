/**
 * Format date to show in French format
 * @param {Date} queryParams
 * @param {String} queryParams
 * @returns {Date}
 */

export const formatDateToUser = (dateToFormat) => {
  if (!dateToFormat) return '-';
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return new Intl.DateTimeFormat('fr-FR', options).format(new Date(dateToFormat));
};
export const formatDateToInput = (dateToFormat) => {
  if (!dateToFormat) return '-';
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return new Intl.DateTimeFormat('fr-FR', options)
    .format(new Date(dateToFormat))
    .split('/')
    .reverse()
    .join('-');
};
