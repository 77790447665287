import api from './_api';

/**
 * GET - Get vacation list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 *  */
export const getVacationList = (queryParams) => api.get(
  `/structures/${queryParams.id}/vacation`,
  { params: queryParams },
);

/**
 * Put - Update Vacation
 * @param {Object} data
 * @returns {Promise<AxiosResponse>}
 * */
export const updateVacation = (data) => api.put(
  `/structures/${data.id}/vacation:setup`,
  data,
);
