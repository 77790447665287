/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-else-return */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faTrashCan,
  faPlus,
  faDownload,
  faFileCsv,
  faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Search from '../../components/molecules/Search/Search';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './Contracts.module.css';
import useAppContext from '../../store/useAppContext';
import {
  getContractsList,
  deleteContract,
  getContractDocument,
} from '../../services/contracts';
import cn from '../../utils/cn';
import { downloadFileCsv } from '../../utils/downloadFile';
import Permissions from '../../components/molecules/Permissions/Permissions';

/* eslint-disable react/prop-types */

function Contracts() {
  const [response, setResponse] = useState([]);
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');
  const [dowloadInfo, setDowloadInfo] = useState([]);

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const GetContractList = useQuery(['contractlist', page, search], () => getContractsList({
    structureId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
    search,
    page: 0,
  }), {
    onSuccess: (data) => {
      setResponse(data);
      setDowloadInfo(data?.data?.contracts);
    },
  });
  const handleModal = (id) => {
    setOpenModal(true);
    setsupplierToDelete(id);
  };

  const deleteContrac = useMutation(deleteContract, {
    onSuccess: () => {
      toast.success(t('contract.delete_contract'));
      setOpenModal(false);
      GetContractList.refetch();
    },
    onError: () => {
      toast.error(t('contract.delete_contract_error'));
    },
  });
  const handleDelete = (id) => {
    setOpenModal(false);
    deleteContrac.mutate(id);
  };
  const hearder = [
    {
      label: t('contract.prestataire'),
      key: 'societyName',
    },
    {
      label: t('contract.equipment'),
      key: 'equipmentName',
    },
    {
      label: t('contract.dateDebut'),
      key: 'effectiveDate',
      cell: (row) => {
        if (row.original.effectiveDate !== null) {
          return new Date(row.original.effectiveDate).toLocaleDateString();
        }
        return '-';
      },
    },
    {
      label: t('contract.dureeinitials'),
      key: 'endDate',
      cell: (row) => {
        if (row.original.endDate !== null) {
          return new Date(row.original.endDate).toLocaleDateString();
        }
        return '-';
      },
    },
    {
      label: t('contract.valo'),
      key: 'valorisation',
      cell: (row) => row.original.valorisation || '-',
    },
    {
      label: t('contract.framwork'),
      key: 'framework',
    },
  ];
  const handleDowload = () => {
    downloadFileCsv(dowloadInfo, hearder, `${t('contract.title')}`);
  };
  const couleur = (date) => {
    const date1 = new Date(date);
    const date2 = new Date();
    const same = date1.getTime() === date2.getTime();
    const before = date1.getTime() < date2.getTime();
    const after = date1.getTime() > date2.getTime();

    if (same) {
      return styles.red;
    }
    if (before) {
      return styles.red;
    }
    if (after) {
      return styles.blue;
    }
    return styles.blue;
  };

  const dowloaddoc = (id, data) => {
    window.open(`${process.env.REACT_APP_API_URL}contracts/download-document/${id}/${data}`, '_blank');
  };

  const getContractDocuments = useMutation(getContractDocument, {
    onSuccess: (data) => {
      dowloaddoc(data.data);
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('contract.prestataire')}`,
        accessor: 'societyName',
        Cell: ({ row }) => (
          <div className={couleur(row.original.endDate)}>
            {row.original.societyName}
          </div>
        ),
      },
      {
        Header: `${t('contract.equipment')}`,
        accessor: 'equipmentName',
        hasSort: true,
        Cell: ({ row }) => (
          <div className={couleur(row.original.endDate)}>
            {row.original.equipmentName}
          </div>
        ),
      },
      {
        Header: `${t('contract.dateDebut')}`,
        accessor: 'effectiveDate',
        Cell: ({ row }) => {
          if (row.original.effectiveDate === null) {
            return '-';
          }
          return (
            <div className={couleur(row.original.endDate)}>
              {new Date(row.original.effectiveDate).toLocaleDateString('fr-FR')}
            </div>
          );
        },
      },
      {
        Header: `${t('contract.dureeinitials')}`,
        accessor: 'endDate',
        Cell: ({ row }) => {
          if (row.original.endDate === null) {
            return '-';
          }
          return (
            <div className={couleur(row.original.endDate)}>
              {new Date(row.original.endDate).toLocaleDateString('fr-FR')}
            </div>
          );
        },
      },
      {
        Header: `${t('contract.valo')}`,
        accessor: 'valorisation',
      },
      {
        Header: `${t('contract.framwork')}`,
        accessor: 'framework',
        Cell: ({ row }) => {
          if (row.original.framework === true) {
            return 'oui';
          } else {
            return 'non';
          }
        },
      },
      {
        Header: `${t('contract.documentFileName')}`,
        accessor: 'documentFileName',
        Cell: ({ row }) => {
          if (row.original.documentFileName === null) {
            return '-';
          }
          return (
            <div>
              <Button
                label={(
                  <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                )}
                type="button"
                className="action edit"
                title={t('contract.download')}
                onClick={() => dowloaddoc(row.original.id, row.original.documentFileName)}
              />
            </div>
          );
        },
      },
      {
        Header: `${t('contract.countAmendments')}`,
        accessor: 'countAmendments',
        Cell: ({ row }) => (
          <div>
            <Button
              label={(
                <div className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faDownload} />
                  <span
                    className={cn(['fa-layers-text fa-inverses', styles.strong])}
                    data-fa-transform="shrink-5"
                  >
                    {row.original.countAmendments}
                  </span>
                </div>
              )}
              type="button"
              className="action edit"
              title={t('contract.avenant')}
              onClick={() => navigate(`/amendement/${row.original.id}/${row.original.societyName}`)}
              disabled={Permissions({ permission: 'AMENDMENT_LIST' }) === undefined}
              style={Permissions({ permission: 'AMENDMENT_LIST' }) === undefined
                ? { cursor: 'not-allowed' } : null}
            >
              {row.original.countAmendments}
            </Button>
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ row }) => {
          if (row.original.framework === true) {
            return ' ';
          }
          return (
            <div className={styles.button}>
              {Permissions({ permission: 'CONTRACT_READ' }) !== undefined
                ? (
                  <Button
                    label={<FontAwesomeIcon icon={faEdit} />}
                    type="button"
                    className="action edit"
                    title={t('contract.edit')}
                    onClick={() => navigate(`/contracts/edit/${row.original.id}`)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                ) : null }
              {Permissions({ permission: 'CONTRACT_DELETE' }) !== undefined
                ? (
                  <Button
                    type="button"
                    className="action suppr"
                    label={<FontAwesomeIcon icon={faTrashCan} />}
                    title={t('contract.suppr')}
                    onClick={() => handleModal(row.original.id)}
                  />
                ) : null }
            </div>
          );
        },
      },
    ],
    [],
  );

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title={t('contract.title')}
        layout="table"
        table={response}
        queryError={
          GetContractList?.error
          || getContractDocuments?.error
          || deleteContrac?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          {Permissions({ permission: 'CONTRACT_CREATE' }) !== undefined
            ? (
              <div className="row">
                <h1 className="title">{t('contract.title')}</h1>
                <Link className="add" to="/contracts/add">
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="add_label">
                    {t('contract.add')}
                  </span>
                </Link>
              </div>
            ) : null }
          <div className="row">
            <button
              className="add"
              type="button"
              onClick={handleDowload}
            >
              <FontAwesomeIcon icon={faFileCsv} />
              {' '}
              {t('reports.download')}
            </button>
            <Search
              className="mb-20"
              onChange={handleSearch}
            />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={response?.isLoading || GetContractList?.isLoading}
            data={response?.data?.contracts || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={GetContractList?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
          )}
          />
        </div>
        <footer>
          <div className="row mt-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
    </>
  );
}
export default Contracts;
