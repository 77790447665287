/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import usePagination, { DOTS } from './usePagination';

import style from './Pagination.module.css';
import cn from '../../../utils/cn';

function Pagination({
  previousLabel,
  nextLabel,
  breakLine,
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
  numberElementsDisplayed,
  className,
}) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const { t } = useTranslation();

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <>
      <div className={style.paginationInfos}>
        {t('global.lines_displayed')}
        {' '}
        <span className={style.paginationIndex}>
          {`${((currentPage - 1) * pageSize) + 1} - ${numberElementsDisplayed + ((currentPage - 1) * pageSize)}`}
        </span>
        {' '}
        {t('global.on')}
        {' '}
        <span className={style.paginationIndex}>{totalCount}</span>
      </div>
      <ul
        data-testid="pagination"
        className={cn([
          style.paginationContainer,
          style[className],
        ])}
      >
        <li
          data-testid="paginationPrevious"
          className={cn([
            style.paginationItem,
            style.paginationLabel,
            currentPage === 1 && style.disabled,
          ])}
          onClick={currentPage === 1 ? () => undefined : onPrevious}
        >
          {previousLabel}
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li
                className={cn([style.paginationItem, style.dots])}
                data-testid="paginationBreakLine"
                key="paginationBreakLine"
              >
                {breakLine}
              </li>
            );
          }

          return (
            <li
              data-testid={`pagination-${pageNumber}`}
              key={`pagination-${pageNumber}`}
              className={cn([
                style.paginationItem,
                pageNumber === currentPage && style.selected,
              ])}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          data-testid="paginationNext"
          className={cn([
            style.paginationItem,
            style.paginationLabel,
            currentPage === lastPage && style.disabled,
          ])}
          onClick={currentPage === lastPage ? () => undefined : onNext}
        >
          {nextLabel}
        </li>
        <input
          type="number"
          name="number"
          className={style.paginationInput}
          onBlur={(e) => e.target.value && onPageChange(parseInt(e.target.value, 10))}
          min={1}
          max={lastPage}
        />
      </ul>
    </>
  );
}

export default Pagination;

Pagination.propTypes = {
  previousLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  nextLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  breakLine: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  numberElementsDisplayed: PropTypes.number,
  className: PropTypes.string,
};

Pagination.defaultProps = {
  siblingCount: 1,
  className: '',
  breakLine: '...',
  numberElementsDisplayed: 0,
};
