import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

// styles
import styles from '../../atoms/Select/Select.module.css';

function MultiTextInput({
  id,
  label,
  value,
  onChange,
  disabled,
  placeholder,
  minimumLengthToSubmit,
}) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '2.75rem',
      borderColor: 'var(--color-grey-200)',
      borderRadius: '0.3rem',
    }),
  };
  const { t } = useTranslation();

  return (
    <div>
      <label className={styles.label} htmlFor={id}>{label}</label>
      <CreatableSelect
        isMulti
        id={id}
        label={label}
        styles={customStyles}
        className={styles.select}
        placeholder={placeholder}
        defaultValue={value}
        isDisabled={disabled}
        formatCreateLabel={(currentText) => (
          <div>
            {t('global.add')}
            {' '}
            :
            {' '}
            {currentText}
          </div>
        )}
        isValidNewOption={
          (e) => (minimumLengthToSubmit ? e.length >= minimumLengthToSubmit : true)
        }
        onChange={onChange}
        backspaceRemovesValue={false}
      />

    </div>
  );
}

MultiTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  minimumLengthToSubmit: PropTypes.number,
};

MultiTextInput.defaultProps = {
  placeholder: '',
  minimumLengthToSubmit: 0,
};

export default MultiTextInput;
