import React from 'react';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import { getObservation } from '../../services/registre';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import styles from './SocietyLifted.module.css';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
import Textarea from '../../components/atoms/Textarea/Textarea';
import useAppContext from '../../store/useAppContext';

function SocietyLifted() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [context] = useAppContext();

  const initialValues = {
    action: '',
    prestataireName: '',
    SocietyName: '',
  };
  const getObservationQuery = useQuery(['getObservation'], () => getObservation({
    id: urlParams?.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const validationSchema = Yup.object({
    prestataireName: Yup.string().required('global.required_field'),
    societyName: Yup.string().required('global.required_field'),
    action: Yup.string().required('global.required_field'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      navigate(`/signature/${urlParams?.id}`, { state: values });
    },
  });
  return (
    <Layout
      title="SocietyLifted"
      layout="table"
      queryError={
        getObservationQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">{t('raisedObs.add')}</h1>
        </div>
      </header>
      <div className="title">
        <div>
          {getObservationQuery.isLoading && (
            <div>{t('global.loading')}</div>
          )}
          <div>
            <form
              onSubmit={formik.handleSubmit}
              className="form shadow"
            >
              {getObservationQuery?.data?.observationNumber}
              <div className="form_group">
                <Input
                  type="text"
                  name="prestataireName"
                  label={t('raisedObs.prestataireName')}
                  placeholder={t('raisedObs.prestataireName')}
                  value={formik?.values?.prestataireName}
                  onChange={formik.handleChange}
                  required
                />
                {formik?.errors?.prestataireName && formik?.touched?.prestataireName ? (
                  <div className="error">
                    {t(formik.errors.prestataireName)}
                  </div>
                ) : null }
              </div>
              <div className="form_group">
                <Input
                  type="text"
                  name="societyName"
                  label={t('raisedObs.SocietyName')}
                  value={formik?.values?.societyName}
                  onChange={formik?.handleChange}
                  required
                  placeholder={t('raisedObs.SocietyName')}
                />
                {formik?.errors.SocietyName && formik?.touched.SocietyName ? (
                  <div className="error">
                    {t(formik?.errors.SocietyName)}
                  </div>
                ) : null }
              </div>
              <div className="form_group--fullwidth">
                <Textarea
                  name="action"
                  label={t('raisedObs.description')}
                  value={formik?.values?.actions}
                  placeholder={t('raisedObs.description')}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  cols={30}
                  rows={10}
                  required
                />
                {formik?.errors.action && formik?.touched.action ? (
                  <div className="error">
                    {t(formik?.errors.action)}
                  </div>
                ) : null }
              </div>
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                </div>
                <Button
                  type="submit"
                  className="form_submit"
                  label={t('Lifted.signée')}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default SocietyLifted;
