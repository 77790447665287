import { useContext } from 'react';
import { AppStateContext, AppDispatchContext } from './AppContext';

const getCurrentUser = (context) => context?.user;

const useAppContext = () => [
  useContext(AppStateContext),
  useContext(AppDispatchContext),
  getCurrentUser(useContext(AppStateContext)),
];

export default useAppContext;
