/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react';
// navigation
import { useNavigate, useParams } from 'react-router-dom';
// tradcuteur
import { useTranslation } from 'react-i18next';
// formulaire
import { useFormik } from 'formik';
import * as Yup from 'yup';
// post et get de l'api
import { useMutation, useQuery } from 'react-query';
// css des notifications
import { toast } from 'react-toastify';
// import des icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import Layout from '../../components/template/Layout';
import Button from '../../components/atoms/Button/Button';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Select from '../../components/atoms/Select/Select';
// les get et post de lapi
import { getChecklist, EditChecklist } from '../../services/checklist';
import { GetVehicle } from '../../services/vehicules';
import useAppContext from '../../store/useAppContext';
// element du formulaire

function ChecklistVechicle() {
  // traduction
  const { t } = useTranslation();
  const urlParams = useParams();
  // navigation
  const navigate = useNavigate();
  const [context] = useAppContext();

  // create const goBackUrl pour retourner à la page précédente et afficher un message de succès
  const goBackUrl = (msg) => {
    navigate(`/intervention/${urlParams.id}`);
    toast.success(msg);
  };

  // create const putContact pour envoyer les données à l'API
  const putContact = useMutation(EditChecklist, {
    onSuccess: () => {
      goBackUrl(t('contract.succes_save_modif'));
    },
    onError: () => {
      toast.error(t('contract.erreur_save_modif'));
    },
  });

  // get checklist list from API
  const GetChecklistList = useQuery(['vehicule'], () => getChecklist({
    page: 0,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const GetVehicleMutate = useMutation(GetVehicle);

  useEffect(() => {
    GetVehicleMutate.mutate(urlParams.id);
  }, []);
  // create const circleCheckLists pour verifier que les donner sois pas vide
  const circleCheckLists = GetChecklistList?.data?.data?.circleCheckLists || [];

  // Créez initialValues en fonction de la valeur de circleCheckLists
  const initialValues = circleCheckLists.reduce((acc, item) => ({
    ...acc,
    [`${item?.controlId}`]: false,
    [`frequency_${item?.controlId}`]: '',
  }), {
    checkAll: false, // Champ pour le bouton checkAll, initialisé à false
    frequency_checkAll: '', // Champ pour la fréquence du bouton checkAll, initialisé à une chaîne vide
  });

  // Créez validationSchema en fonction de la valeur de circleCheckLists
  const validationSchema = Yup.object().shape(
    circleCheckLists.reduce((acc, item) => ({
      ...acc,
      [`${item?.controlId}`]: Yup.boolean(),
      [`frequency_${item?.controlId}`]: Yup.string().when(`${item?.controlId}`, {
        is: true,
        then: Yup.string().required('Ce champ est obligatoire lorsque la case est cochée.'),
        otherwise: Yup.string(),
      }),
    }), {
      checkAll: Yup.boolean(), // Validation pour le bouton checkAll
      frequency_checkAll: Yup.string().when('checkAll', {
        is: true,
        then: Yup.string().required('Ce champ est obligatoire lorsque la case est cochée.'), // Validation pour la fréquence du bouton checkAll
        otherwise: Yup.string(),
      }),
    }),
  );

  // Utilisez formik avec les initialValues et validationSchema adaptés puis envoyer les données avec putContact.mutate(config)
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // Boucle sur circleCheckLists et vérifiez si les champs de checklist sont faux, puis mettez à jour les fréquences correspondantes à une chaîne vide
      const updatedCircleCheckLists = circleCheckLists.map((item) => ({
        controlId: item?.controlId,
        isActive: values[`${item?.controlId}`] !== true ? false : true,
        frequency: values[`${item?.controlId}`] !== true ? '' : values[`frequency_${item?.controlId}`],
        controlStartDate: values[`${item?.controlId}`] !== true ? '' : new Date().toISOString().split('T')[0],
      }));

      const verif = updatedCircleCheckLists.filter((item) => item?.isActive === true);
      const config = {
        data: { cicleCheckLists: verif },
        vehiculeId: urlParams.id,
      };

      putContact.mutate(config);
    },
  });

  // Créez une constante frequencies pour afficher les fréquences dans le select et les traductions
  const frequencies = React.useMemo(() => [
    {
      label: t('addVehicule.everyday'),
      value: 'daily',
      disabled: true,
    },
    {
      label: t('addVehicule.everyweek'),
      value: 'weekly',
    },
    {
      label: t('addVehicule.every2weeks'),
      value: 'weekly2',
    },
    {
      label: t('addVehicule.everymonth'),
      value: 'monthly',
    },
    {
      label: t('addVehicule.every3months'),
      value: 'monthly3',
    },
    {
      label: t('addVehicule.every6months'),
      value: 'monthly6',
    },
    {
      label: t('addVehicule.everyyear'),
      value: 'yearly',
    },
  ]);

  return (
    <Layout
      title={t('vehicule.checklist')}
      layout="form"
      table={GetChecklistList.data}
      loading={GetChecklistList.isLoading || GetVehicleMutate.isLoading}
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate('/vehicule')}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('vehicule.back')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">
            {t('vehicule.checklist')}
            :
              <br />
              <span className="title-info">
                {GetVehicleMutate?.isSuccess ? (
                  <div>
                    {` ${GetVehicleMutate?.data?.data?.brand} - ${GetVehicleMutate?.data?.data?.model} - ${GetVehicleMutate?.data?.data?.licensePlate}`}
                  </div>
                ) : null}
              </span>
            </h1>
          </div>
        </header>
      </div>
      {/* formulaire utilisant formik */}
      <form onSubmit={formik.handleSubmit} className="form shadow-sm" loading={GetChecklistList?.isLoading}>
        <div className="form_group">
          <Checkbox // checkbox select all
            id="checkAll"
            name="checkAll"
            label={t('checklist.check_all')}
            value="checkAll"
            onChange={(e) => {
              formik.setFieldValue('checkAll', e.target.checked);
              circleCheckLists.forEach((item) => {
                formik.setFieldValue(`${item?.controlId}`, e.target.checked);
              });
            }}
            onBlur={formik.handleBlur}
            checked={formik.values.checkAll}
          />
          {formik.touched.checkAll && formik.errors.checkAll ? (
            <div className="form_error">{formik.errors.checkAll}</div>
          ) : null}
        </div>
        { formik?.values?.checkAll ? (
          <div className="form_group">
            <Select // select frequence pour tous
              id="frequency_checkAll"
              label={t('checklist.input_frequency')}
              options={frequencies}
              value={frequencies?.find((frequency) => frequency?.value === formik?.values?.frequency_checkAll)}
              onChange={(option) => {
                formik.setFieldValue('frequency_checkAll', option?.value);
                circleCheckLists.forEach((item) => {
                  formik.setFieldValue(`frequency_${item?.controlId}`, option?.value);
                });
              }}
              defaultOption={t('checklist.input_frequency')}
            />
            {formik.errors.frequency_checkAll && formik.touched.frequency_checkAll ? (
              <div className="error">
                {t(formik.errors.frequency_checkAll)}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="form_group">
            {' '}
          </div>
        )}
        {GetChecklistList?.data?.data?.circleCheckLists?.map((item) => (
          <React.Fragment key={item?.id}>
            {/* key pour chaque item suite au map/ fragment pour ne pas avoir d'erreur vue que 2 div */}
            <div className="form_group">
              <Checkbox // checkbox pour chaque item suite au map
                id={`${item?.controlId}`}
                name={`${item?.controlId}`} // Utilisation de la notation entre crochets pour inclure l'ID dans le nom du champ
                label={item?.checkItem}
                value={item?.isActive}
                onChange={(e) => formik.setFieldValue([`${item?.controlId}`], e.target.checked)} // verifier si la case est coché
                onBlur={formik.handleBlur}
                checked={formik.values[`${item?.controlId}`]}
              />
              {formik.touched[`${item?.controlId}`] && formik.errors[`${item?.controlId}`] ? (
                <div className="form_error">{formik.errors[`${item?.controlId}`]}</div>
              ) : null}
            </div>
            { !formik?.values?.checkAll ? ( // verifier si la case all est coche sinon afficher le vide
              <div className="form_group">
                {formik.values[`${item?.controlId}`] && ( // verifier si la case est coché pour afficher le select
                  <div>
                    <Select // select frequence pour chaque item suite au map
                      id={`frequency_${item?.controlId}`}
                      label={t('checklist.input_frequency')}
                      options={frequencies}
                      value={frequencies?.find((frequency) => frequency.value === formik.values[`frequency_${item?.controlId}`])}
                      onChange={(option) => {
                        formik.setFieldValue(`frequency_${item?.controlId}`, option.value);
                      }}
                      defaultOption={t('checklist.input_frequency')}
                    />
                    {formik.errors[`frequency_${item?.controlId}`] && formik.touched[`frequency_${item?.controlId}`] ? (
                      <div className="error">
                        {t(formik.errors[`frequency_${item?.controlId}`])}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ) : (
              <div className="form_group">
                {' '}
              </div>
            )}
          </React.Fragment>
        ))}
        <section className="form_footer">
          <div className="form_infos">
            <small>{t('add_structure.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('vehicule.checksave')}
          />
        </section>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate('/vehicule')}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('vehicule.back')}</span>
        </button>
      </footer>
    </Layout>
  );
}
export default ChecklistVechicle;
