import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Layout from '../../components/template/Layout';
import styles from './AddUnderTitleDocumentBase.module.css';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';

function AddUnderTitleDocumentBase() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialValues = {
    name: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('documentary.required')),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  return (
    <Layout
      title="AddUnderTitleDocumentBase"
      layout="table"
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('documentary.return')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">
            <br />
            {t('documentary.addUnderTitleDocumentBase')}
          </h1>
        </div>
      </header>
      <div className={styles.table}>
        <form onSubmit={formik.handleSubmit} className="form shadow-sm">
          <div className="form_group">
            <Input
              id="name"
              name="name"
              type="text"
              label={t('documentary.nameUnderTitle')}
              placeholder={t('documentary.nameUnderTitle')}
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue('name', e.target.value.toUpperCase())}
              required
            />
            {formik?.errors?.name && formik?.touched?.name ? (
              <div className="error">
                {t(formik?.errors?.name)}
              </div>
            ) : null}
          </div>
          <div className="form_footer">
            <div className="form_infos">
              <small>{t('addReports.mandatory_fields')}</small>
            </div>
            <Button
              type="submit"
              className="form_submit"
              label={t('documentary.save')}
            />
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default AddUnderTitleDocumentBase;
