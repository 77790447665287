import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import useAppContext from '../../store/useAppContext';

// components
import Picto from '../../components/atoms/Picto/Picto';
import Layout from '../../components/template/Layout';
import Card from '../../components/atoms/Card/Card';

// styles
import styles from './DocumentDeposit.module.css';

// utils & misc
import cn from '../../utils/cn';

function DocumentDeposit() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [, dispatch] = useAppContext();

  const navigateTo = (path, id) => {
    navigate(path);
    dispatch({ type: 'CHOICE_BOOKLET', payload: id });
  };

  return (
    <Layout title="depot de document">
      <div className="row mb-20">
        <button
          type="button"
          className="link"
          onClick={() => navigate('/dashboard')}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('establishments.back_to_dashboard')}</span>
        </button>
      </div>
      {/* Dépot document */}
      <Card className={styles.topTitle}>
        <Picto className={styles.topPicto} name="documentaire" />
        <h1 className={cn(['title no-margin', styles.title])}>{t('dashboard.security_document_depository')}</h1>
        <Picto className={styles.topPicto} name="documentaire" />
      </Card>
      <section className={styles.gridDocs}>
        <Card
          className={styles.card}
          onClick={() => navigateTo('/contracts/admin', 2)}
        >
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.contract')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
        <Card className={styles.card} onClick={() => navigateTo('/depot-document/admin', 2)}>
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.security_document_depository')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
      </section>
      {/* documents sanitaire */}
      <Card className={styles.topTitle}>
        <Picto className={styles.topPicto} name="documentaire" />
        <h1 className={cn(['title no-margin', styles.title])}>{t('dashboard.sanitary_document_depository')}</h1>
        <Picto className={styles.topPicto} name="documentaire" />
      </Card>
      <section className={styles.gridDocs}>
        <Card
          className={styles.card}
          onClick={() => navigateTo('/contracts/admin', 1)}
        >
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.contract')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
        <Card className={styles.card} onClick={() => navigateTo('/depot-document/admin', 1)}>
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.sanitary_document_depository')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
      </section>
      <br />
      <div className="row mb-20">
        <button
          type="button"
          className="link"
          onClick={() => navigate('/')}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('establishments.back_to_dashboard')}</span>
        </button>
      </div>
    </Layout>
  );
}

export default DocumentDeposit;
