/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
import React, { useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import Layout from '../../components/template/Layout';
import InputsTypeSwitcher from '../../components/molecules/InputType/InputsTypeSwitcher';
// Services
import { getEstablishment, getBuilding } from '../../services/structures';
import { getOneInputs, postInputs } from '../../services/inputs';
// Utils
import useAppContext from '../../store/useAppContext';
import styles from './Oneinput.module.css';
import cn from '../../utils/cn';
import { getPermissions } from '../../services/permissions';

function Oneinputs() {
  // Hooks
  const { t } = useTranslation();
  const urlParams = useParams();
  const navigate = useNavigate();
  const [context, dispatch] = useAppContext();

  const getInputsToCompleteQuery = useQuery(['inputs', urlParams.id], () => getOneInputs({
    token: urlParams.inputsid,
    establishmentId: urlParams.establishmentId,
    buldingId: urlParams.buldingId,
    bookletId: context?.choiceBooklet,
  }));

  const getEtabQuery = useMutation(getEstablishment, {
    onSuccess: (data) => {
      dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: data.data });
    },
  });
  const getBuildingquery = useMutation(getBuilding, {
    onSuccess: (data) => {
      dispatch({ type: 'CHOICE_BOOKLET', payload: data.data.bookletId });
    },
  });
  const permissionsQuery = useQuery('permissions', () => getPermissions({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    enabled: !!context?.choiceBooklet && !!context?.choiceEstablishment?.id,
    onSuccess: ({ data }) => {
      const permissions = { ...context?.permissions };
      permissions[`${context?.choiceBooklet}/${context?.choiceEstablishment?.id}`] = data?.rightsList;
      dispatch({ type: 'SET_PERMISSIONS', payload: permissions });
    },
  });
  useEffect(() => {
    // inputs/799/808/272501
    if (!sessionStorage.getItem('context') && !localStorage.getItem('context')) {
      localStorage.setItem('url', window.location.pathname);
      navigate('/');
    }
    getBuildingquery.mutate(urlParams.buldingId);
    getEtabQuery.mutate(urlParams.establishmentId);
  }, [urlParams.buldingId]);
  // si ce netait que le front a modifier:

  const inputsControlTypeSorted = getInputsToCompleteQuery?.data?.data?.inputs
    ? getInputsToCompleteQuery?.data?.data?.inputs.reduce((acc, input) => {
      const isAlreadyInAcc = acc.some((accInput) => accInput.controlTypeId === input.controlTypeId);
      if (!isAlreadyInAcc) {
        acc.push({
          inputsControlList: [input],
        });
        return acc;
      }
      return acc.map((accInput) => {
        if (accInput.controlTypeId === input.controlTypeId) {
          return {
            ...accInput,
            inputsControlList: accInput.inputsControlList.concat(input),
          };
        }
        return accInput;
      });
    }, []) : null;
  const inputsInputTypeSorted = inputsControlTypeSorted?.map((inputControlType) => ({
    ...inputControlType,
    inputsControlList: inputControlType.inputsControlList.reduce((acc, input) => {
      const isAlreadyInAcc = acc.some((accInput) => accInput.inputType === input.inputType);
      if (!isAlreadyInAcc) {
        acc.push({
          inputType: input.inputType,
          inputsTypeList: [input],
        });
        return acc;
      }
      return acc.map((accInput) => {
        if (accInput.inputType === input.inputType) {
          return {
            ...accInput,
            inputsTypeList: accInput.inputsTypeList.concat(input),
          };
        }
        return accInput;
      });
    }, []),
  }));

  const reset = () => {
    getInputsToCompleteQuery.remove();
  };

  const postInputsMutation = useMutation(postInputs, {
    onSuccess: () => {
      toast.success(t('inputs.saved'));
      reset();
    },
  });

  const handleSubmit = (inputsToSubmit) => {
    if (inputsToSubmit.length) {
      postInputsMutation.mutate({
        inputs: inputsToSubmit,
      });
    } else {
      toast.info(t('inputs.nothing_to_save'));
    }
  };

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <Layout
      title={t('inputs.title')}
      queryError={
        getInputsToCompleteQuery?.error
        || postInputsMutation?.error
        || getEtabQuery?.error
        || getBuildingquery?.error
        || permissionsQuery?.error
      }
      layout="table"
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">{t('inputs.title')}</h1>
        </div>
      </header>
      <div className={cn([styles.inputs, 'inputs-form'])}>
        {getInputsToCompleteQuery.isLoading ? (
          <div className="loader" />
        )
          : (
            <div
              key={`row-${inputsInputTypeSorted?.inputControlType?.controlTypeName}`}
              className={cn([styles.controlTypeBloc, 'inputs-form'])}
            >
              {getInputsToCompleteQuery?.data?.data?.count === 0 && (
                <p className={styles.noInputs}>{t('inputs.no_inputs')}</p>
              )}
              {inputsInputTypeSorted?.map((inputControlType) => (
                <>
                  <h2 className={styles.controlTypeTitle}>{inputControlType?.controlTypeName}</h2>
                  <div>
                    {inputControlType?.inputsControlList?.map((inputControl) => (
                      <InputsTypeSwitcher
                        key={inputControl?.inputType}
                        inputControl={inputControl}
                        submit={handleSubmit}
                      />
                    ))}
                  </div>
                </>
              ))}
            </div>
          )}
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate('/dashboard')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default Oneinputs;
