/* eslint-disable prefer-template */
import React, { useEffect } from 'react';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from 'react-query';
import styles from './ShareAllObservation.module.css';
import { getremaining, postShare } from '../../services/registre';
import useAppContext from '../../store/useAppContext';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
import Select from '../../components/atoms/Select/Select';
import Textarea from '../../components/atoms/Textarea/Textarea';
/* eslint-disable max-len */
/* eslint no-unsafe-optional-chaining: "error" */
function ShareObservation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const UrlParams = useParams();
  const [context] = useAppContext();
  const [info, setInfo] = React.useState([]);
  const getObservationQuery = useQuery(['getObservation'], () => getremaining({
    id: UrlParams?.id,
    data: {
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    },
  }), {
    onSuccess: (data) => {
      setInfo(data?.data?.observations);
    },
  });

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };
  const postObservationMutation = useMutation(postShare, {
    onSuccess: () => {
      goBackUrl(t('Observation envoyée avec succès'));
    },
  });
  const options = [
    { value: 'Demande de devis', label: 'Demande de devis' },
    { value: 'Demande d\'intervention', label: 'Demande d\'intervention' },
    { value: 'Demande d\'attestation de levée d\'observation', label: 'Demande d\'attestation de levée d\'observation' },
  ];

  const findOption = (value) => {
    const option = info.find((item) => item.id === value[0]);
    return `  \n-Observation(s): ${option?.title}  -  Numero: ${option?.number}`;
  };

  const text = () => {
    if (getObservationQuery?.data?.data?.observations === info) {
      return `Un utilisateur e-carnet (${
        context?.user?.lastName ? context?.user?.lastName + ' ' : ''
      }${context?.user?.firstName} - ${context?.user?.email}${
        context?.user?.phone ? ' - ' + context?.user?.phone : ''
      }) a besoin d’une réponse de votre part concernant les observations suivantes :
    - Etablissement : ${context?.choiceEstablishment?.name || 'Non renseigné'}
    - Equipement : ${info[0]?.intervention?.equipment?.name || 'Non renseigné'}
    - Rapport N° : ${info[0]?.report?.id || 'Non renseigné'}\n
    ${location.state.observationNumber.map((item) => findOption(item))}
  Cordialement,
  L’équipe e-carnet`;
    }
    return '';
  };

  const validationSchema = Yup.object({
    destinataire: Yup.string().required('Ce champ est obligatoire'),
    sujet: Yup.string().required('Ce champ est obligatoire'),
    // if message is emtry, replace by text()
    message: Yup.string().default(text()),
  });
  const initialValues = {
    destinataire: [],
    sujet: '',
    message: text(),
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      location.state.observationNumber.forEach((element, index) => {
        formData.append(`observationIds[${index}]`, element);
      });
      formData.append('reportId', UrlParams?.id);
      formData.append('recipients', values.destinataire);
      formData.append('title', values.sujet);
      formData.append('body', values.message);
      postObservationMutation.mutate(formData);
    },
  });
  useEffect(() => {
    formik.setFieldValue('message', text());
  }, [info]);
  return (
    <Layout
      title="ShareObservation"
      layout="table"
      queryError={
        getObservationQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
        <div className={cn(['row', 'row'])}>
          <h1 className="title">{t('share.title')}</h1>
        </div>
      </header>
      <div className="title">
        <div>
          {info.isError && (
            <div>Une erreur est survenue</div>
          )}
          <div>
            <form
              onSubmit={formik.handleSubmit}
              className="form shadow"
            >
              {info?.number}
              <div className={cn(['form_group', styles.ebeszj])}>
                <Input
                  type="email"
                  name="destinataire"
                  multiple
                  id={styles.destinataire}
                  label={t('share.destinataire')}
                  value={formik.values.destinataire}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <div className={styles.text}>
                  <small>{t('share.destinataire_help')}</small>
                </div>
                {formik.errors.destinataire && formik.touched.destinataire ? (
                  <div className="error">
                    {t(formik.errors.destinataire)}
                  </div>
                ) : null }
              </div>
              <div>
                {' '}
              </div>
              <div className="form_group">
                <Select
                  options={options}
                  name="sujet"
                  id={styles.sujet}
                  type="select"
                  label={t('share.sujet')}
                  value={options.find((option) => option.value === formik.values.sujet)}
                  onChange={(value) => formik.setFieldValue('sujet', value.value)}
                  required
                  valueInput={formik.values.sujet}
                />
                {formik.errors.sujet && formik.touched.sujet ? (
                  <div className="error">
                    {t(formik.errors.sujet)}
                  </div>
                ) : null }
              </div>
              <div className="form_group--fullwidth">
                <Textarea
                  name="message"
                  label={t('share.message')}
                  id={styles.message}
                  value={formik.values.message}
                  placeholder={getObservationQuery.isLoading ? t('global.loading') : text() || ''} // eslint-disable-line no-nested-ternary
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows="10"
                  cols="20"
                  required
                />
                {formik.errors.message && formik.touched.message ? (
                  <div className="error">
                    {t(formik.errors.message)}
                  </div>
                ) : null }
              </div>
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                </div>
                <Button
                  type="submit"
                  className="form_submit"
                  label={t('raisedObs.shareobs')}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default ShareObservation;
