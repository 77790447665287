/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ToastContainer } from 'react-toastify';
import {
  AppProvider,
  AppStateContext,
} from './store/AppContext';
import Error404 from './views/Error404/Error404';
// Routes
import { publicRoutes, privateRoutes } from './routes';
import ErrorBoundary from './ErrorBoundary';
// Utils
import 'react-toastify/dist/ReactToastify.min.css';

// React Query Client config
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const navigate = (context) => {
    if (context?.user?.role === 'ROLE_ADMIN') {
      return '/dashboard';
    }
    if (context?.user?.role === 'ROLE_ADMIN_GROUP') {
      return '/dashboard';
    }
    if (context?.user?.role === 'ROLE_SUPER_ADMIN') {
      return '/dashboard';
    }
    return '/choix-carnet';
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <AppStateContext.Consumer>
            {(context) => (
              <BrowserRouter>
                <ErrorBoundary>
                  <Routes>
                    {[...context.user ? privateRoutes : publicRoutes]
                      .map((route) => (!route.avalaibleToRole || route?.avalaibleToRole
                        .includes(context?.user?.role) ? (
                          <Route key={route.path} {...route} />
                        ) : null)) }
                    {context.user
                      ? (
                        <>
                          <Route path="/" element={<Navigate to={navigate(context)} replace />} />
                          {context?.user?.role?.includes('ADMIN') ? (
                            <Route
                              path="*"
                              element={context.user ? <Error404 /> : <Navigate to="/dashboard" replace />}
                            />
                          ) : (
                            <Route
                              path="*"
                              element={context.user ? <Error404 /> : <Navigate to="/choix-carnet" replace />}
                            />
                          )}
                        </>
                      ) : (
                        <Route path="*" element={<Navigate to="/" replace />} />
                      )}
                  </Routes>
                </ErrorBoundary>
              </BrowserRouter>
            )}
          </AppStateContext.Consumer>
        </AppProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
