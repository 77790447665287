import api from './_api';

/**
 * GET - Get Equipments list
 * @param {Number} pageId
 * @param {Number} bookletId
 * @returns {Promise<AxiosResponse>}
 */
export const getEquipmentsList = (queryParams) => api.get('/equipments', { params: queryParams });

/**
 * GET - Get Equipment
 * @param {Number} equipmentId
 * @returns {Promise<AxiosResponse>}
 */
export const getEquipment = (equipmentId) => api.get(`/equipments/${equipmentId}`);

/**
 * POST - Create Equipment
 * @param {{name:String, equipmentFamilyId:Number, controlIds:Array, bookletIds:Array}} equipmentToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postEquipmentInformations = (equipmentToCreate) => api.post('/equipments', equipmentToCreate);

/**
 * PUT - Edit Equipment
 * @param {{id:Number, name:String, equipmentFamilyId:Number, controlIds:Array, bookletIds:Array}} equipmentToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editEquipmentInformations = (equipmentToEdit) => api
  .put(`/equipments/${equipmentToEdit.id}`, equipmentToEdit);

/**
 * DEL - Delete Equipment
 * @param {Number} equipmentId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteEquipment = (equipmentId) => api.delete(`/equipments/${equipmentId}`);
