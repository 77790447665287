/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEdit, faTrashCan, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
// Services
import {
  getEquipmentFamiliesList,
  deleteEquipmentFamily,
} from '../../services/equipmentFamilies';
// Utils
import styles from './EquipmentsFamilies.module.css';
import useAppContext from '../../store/useAppContext';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function EquipmentsFamilies() {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  // States
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');
  const [context] = useAppContext();
  const [equipmentFamilyToDelete, setEquipmentFamilyToDelete] = useState({
    id: null,
  });
  // API Calls
  const getEquipmentsFamiliesListQuery = useQuery(
    ['equipments-families', page, search],
    () => getEquipmentFamiliesList({
      page: 0,
      search,
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
    }),
  );
  const deleteEquipmentFamilyMutation = useMutation(deleteEquipmentFamily, {
    onSuccess:
      () => {
        getEquipmentsFamiliesListQuery.refetch();
      },
  });

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const handleModal = (selectedEquipmentFamilyToDelete) => {
    setOpenModal(true);
    setEquipmentFamilyToDelete(selectedEquipmentFamilyToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteEquipmentFamilyMutation.mutate(equipmentFamilyToDelete.id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('equipments_families.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('equipments_families.label')}`,
        accessor: 'name',
      },
      {
        Header: `${t('equipments_families.equipment')}`,
        accessor: 'countEquipments',
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/equipements-familles/edit/${id}`}
              title={t('equipments_families.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button
              type="button"
              className="action suppr"
              title={t('equipments_families.suppr')}
              onClick={() => handleModal({ id })}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title={t('equipments_families.title')}
        queryError={
          getEquipmentsFamiliesListQuery?.error
          || deleteEquipmentFamilyMutation?.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/dashboard')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('equipments_families.title')}</h1>
            <Link className="add" to="/equipements-familles/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('equipments_families.add')}</span>
            </Link>
          </div>
          <div className={styles.search}>
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getEquipmentsFamiliesListQuery.isLoading}
            data={getEquipmentsFamiliesListQuery?.data?.data?.equipmentFamilies || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getEquipmentsFamiliesListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <footer>
          <button type="button" className="link" onClick={() => navigate('/dashboard')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default EquipmentsFamilies;
