import api from './_api';

/**
 * GET - Get Users list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getUsersList = (queryParams) => api.get('/users', { params: queryParams });

/**
 * GET - Get User informations
 * @returns {Promise<AxiosResponse>}
 */
export const getUserMe = () => api.get('/users/me');

/**
 * GET - Get User
 * @param {Number} userId
 * @returns {Promise<AxiosResponse>}
 */
export const getUser = (userId) => api.get(`/users/${userId}`);

/**
 * GET - Get count User
 * @param {Number} userId
 * @returns {Promise<AxiosResponse>}
 */
export const countUser = () => api.get('/users/count');

/**
 * POST - Create User
 * @param {{
 * role:String,
 * firstName:String,
 * lastName:String,
 * email:String,
 * address:String,
 * postalCode:Number,
 * city:String,
 * phone1:Number,
 * phone2:Number,
 * company:String,
 * job:String,
 * enabled:Boolean
 * }} userToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postUser = (userToCreate) => api.post('/users', userToCreate);

/**
 * PUT - Edit User
 * @param {{
 * id:Number,
 * role:String,
 * firstName:String,
 * lastName:String,
 * email:String,
 * address:String,
 * postalCode:Number,
 * city:String,
 * phone1:Number,
 * phone2:Number,
 * company:String,
 * job:String,
 * enabled:Boolean
 * }} userToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editUser = (userToEdit) => api.put(`/users/${userToEdit.id}`, userToEdit);

/**
 * DEL - Delete User
 * @param {Number} userId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteUser = (userId) => api.delete(`/users/${userId}`);

/**
 * post - Edit User Password token
 * @param {{
 * email:String,
 * }} userToEdit
 * @returns {Promise<AxiosResponse>}
 *
 */
export const editTokenPassword = (userToEdit) => api.post('/reset-password', { email: userToEdit.email });

/**
 * post - Edit User Password
 * @param {{
 * password:String,
 * token : String
 * }} userToEdit
 * @returns {Promise<AxiosResponse>}
 *
 */
export const editUserPassword = (userToEdit) => api.post('/reset-password/reset', {
  password: userToEdit.password,
  token: userToEdit.token,
});
