/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { toast } from 'react-toastify';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

axiosClient.interceptors.request.use((config) => {
  const authStringified = localStorage.getItem('auth');
  const auth = authStringified ? JSON.parse(authStringified) : null;
  const contextStringified = sessionStorage.getItem('context') || localStorage.getItem('context');
  const context = contextStringified ? JSON.parse(contextStringified) : null;
  const newConfig = { ...config };
  newConfig.headers.Authorization = auth ? `Bearer ${auth.token}` : null;
  if (context?.userImpersonated) {
    newConfig.headers['X-Switch-User'] = context.user.email;
  }

  return newConfig;
});

const backToLogin = (message) => {
  toast.error(message);
  setTimeout(() => {
    window.location.href = '/';
  }, 3000);
};

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { message } = error.response.data || {};
    const originalRequest = error.config;

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    if (error.code === 'ECONNABORTED') {
      return Promise.reject(new Error('Timeout'));
    }

    // return early because we wont handle common errors in this interceptor
    if (message !== 'Expired JWT Token' || originalRequest._retry) {
      Promise.reject(error.response);
    }

    if (message === 'Expired JWT Token' || message === 'Invalid JWT Refresh Token') {
      localStorage.removeItem('auth');
      sessionStorage.removeItem('context');
      sessionStorage.removeItem('error');
      sessionStorage.removeItem('errorPath');
      backToLogin('Votre session a expiré, veuillez vous reconnecter.');
    }

    originalRequest._retry = true;
    try {
      const authStringified = localStorage.getItem('auth');
      const auth = authStringified ? JSON.parse(authStringified) : null;
      const refreshTokenRes = await axiosClient.post('/token/refresh', { refresh_token: auth.refresh_token });
      localStorage.setItem('auth', JSON.stringify(refreshTokenRes?.data));
      originalRequest.headers.Authorization = `Bearer ${refreshTokenRes?.data?.token}`;
      // retry the failed call with the new config
      return axios.request(originalRequest);
    } catch {
      return Promise.reject(error.response);
    }
  },
);

export default axiosClient;
