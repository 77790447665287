import api from './_api';

/**
 * GET - Get Permissions list
 * @param {Object} params
 * @returns {Promise<AxiosResponse>}
*/
export const getPermissions = (params) => api.get(`/rights/${params.bookletId}/${params.establishmentId}`);

/**
 * GET - Get dairy list
 * @param {Number: page, Number: bookletId, Number: buildingId} pageid
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
 */
export const getDairyList = (queryParams) => api.get('/diaries', { params: queryParams });
