/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-else-return */
/* eslint-disable prefer-const */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faFileArrowDown,
  faTrashCan,
  faChevronRight,
  faPlus,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import {
  getDocumentBaseItem,
  deleteDocumentBaseItem,
  getDocumentwithid,
  getDocument,
} from '../../services/docBase';
import { formatDateToUser } from '../../utils/dates';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import styles from './DocumentBaseItem.module.css';
import Search from '../../components/molecules/Search/Search';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import useAppContext from '../../store/useAppContext';
import Permissions from '../../components/molecules/Permissions/Permissions';

import cn from '../../utils/cn';
import { getFolder } from '../../services/dossier';
import { deletePermisFeu } from '../../services/permisfeu';

function DocBaseItem() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openModalFirePermit, setOpenModalFirePermit] = useState(false);
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const [search, setSearch] = useState('');
  const [context] = useAppContext();
  const [categoryId] = useState(urlParams.categorieid);
  const [vehiculeId] = useState(urlParams.vehiculeId);
  const [typeId] = useState(urlParams.typeid);
  const [folderId] = useState(urlParams.folderId);

  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  // custome Modal
  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  // call API
  const getDocumentBaseItemQuery = useQuery(['getDocumentBaseItem', page, search, categoryId], () => getDocumentBaseItem({
    categoryId,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    typeId: typeId || null,
    structureId: context?.choiceEstablishment?.id,
    vehicleId: vehiculeId || null,
    folderId: folderId || null,
    page: 0,
    search,
  }));

  const getDocumentwithidQuery = useQuery(['getDocumentwithid', categoryId], () => getDocumentwithid({
    id: categoryId,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const getFolderQuery = useMutation(getFolder);
  useEffect(() => {
    if (urlParams.folderId) {
      getFolderQuery.mutate(urlParams.folderId);
    }
  }, []);

  // Delete doc base item
  const deleteDocumentBaseItemQuery = useMutation(deleteDocumentBaseItem, {
    onSuccess:
      () => {
        toast.success(t('documentary.succesDelete'));
        getDocumentBaseItemQuery.refetch();
      },
  });

  const deletePermisFeuQuery = useMutation(deletePermisFeu, {
    onSuccess:
      () => {
        toast.success(t('documentary.succesDelete'));
        getDocumentBaseItemQuery.refetch();
      },
  });
    // delete doc base item
  const handleDelete = (id) => {
    setOpenModal(false);
    deleteDocumentBaseItemQuery.mutate(id);
  };
  const handleDeleteFirePermit = (id) => {
    setOpenModalFirePermit(false);
    deletePermisFeuQuery.mutate(id);
  };
  // open Modal
  const handleModal = (id) => {
    setOpenModal(true);
    setsupplierToDelete(id);
  };

  const handleModalFirePermit = (id) => {
    setOpenModalFirePermit(true);
    setsupplierToDelete(id);
  };

  const downloaddoc = (id, data) => {
    window.open(`${process.env.REACT_APP_API_URL}document-base-items/download-document/${id}/${data}`, '_blank');
  };
  const getDocuments = useMutation(getDocument, {
    onSuccess: (data) => {
      downloaddoc(data.data);
    },
  });

  const color = (isDraft) => {
    if (isDraft === true) {
      return styles.orange;
    } else {
      return null;
    }
  };

  const hidden = (isDraft) => {
    if (isDraft === false) {
      return styles.Nohidden;
    } else if (isDraft === true) {
      return styles.hidden;
    } else {
      return null;
    }
  };

  const navigateEdit = (isPermit, id) => {
    if (isPermit === true) {
      return navigate(`/permisfeu/edit/${id}`);
    } else {
      return navigate(`/document/edit/${id}`);
    }
  };
  const navigateBack = () => {
    if (vehiculeId) {
      return navigate(`/base-documentaire?vehicule=${vehiculeId}`);
    } else if (context?.choiceBooklet === 1) {
      if (urlParams.categorieid === '1') {
        return navigate('/base-documentaire/1');
      } else if (urlParams.categorieid === '2') {
        return navigate('/base-documentaire/2');
      } else {
        return navigate('/base-documentaire');
      }
    } else if (folderId) {
      return navigate(`/base-documentaire/${categoryId}/dossier`);
    } else if (getDocumentwithidQuery?.data?.data?.documentBaseItemTypes?.length > 1) {
      return navigate(`/base-documentaire/${categoryId}`);
    } else {
      return navigate('/base-documentaire');
    }
  };

  const docBaseItem = useMemo(
    () => [
      {
        Header: `${t('documentary.name')}`,
        accessor: 'name',
        Cell: ({ row }) => row.original.name.toUpperCase(),
      },
      {
        Header: `${t('documentary.date')}`,
        accessor: 'date',
        Cell: ({ value }) => formatDateToUser(value),
      },
      {
        Header: `${t('documentary.file')}`,
        accessor: 'documentFileName',
        Cell: ({ row }) => {
          if (row.original.documentFileName === null) {
            return '-';
          }
          return (
            <div>
              {Permissions({ permission: 'DOCUMENT_READ' }) !== undefined
                ? (
                  <Button
                    label={(
                      <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                    )}
                    type="button"
                    className="action edit"
                    title={t('global.download')}
                    onClick={() => downloaddoc(row.original.id, row.original.documentFileName)}
                  />
                ) : null }
            </div>
          );
        },
      },
      {
        Header: `${t('documentary.action')}`,
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className={styles.button}>
            {(Permissions({ permission: 'DOCUMENT_READ' }) !== undefined)
              ? (
                <Button
                  type="button"
                  className="action edit"
                  label={<FontAwesomeIcon icon={faEdit} />}
                  title={t('documentary.edit')}
                  onClick={() => navigateEdit(false, row.original.id)}
                />
              ) : null }
            {Permissions({ permission: 'DOCUMENT_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('documentary.supp')}
                  onClick={() => handleModal(row.original.id)}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const PermitFire = useMemo(
    () => [
      {
        Header: `${t('documentary.name')}`,
        accessor: 'name',
        Cell: ({ row }) => (
          <div className={cn([color(row.original.isDraft)])}>
            {row.original.name}
          </div>
        ),
      },
      {
        Header: `${t('documentary.ispermit')}`,
        accessor: 'isPermit',
        Cell: ({ row }) => (
          <div>
            {row.original.isPermit === true ? (
              <span>Formulaire</span>
            ) : (
              <span>Document</span>
            )}
          </div>
        ),
      },
      {
        Header: `${t('documentary.date')}`,
        accessor: 'date',
        Cell: ({ value }) => formatDateToUser(value),
      },
      {
        Header: `${t('documentary.file')}`,
        accessor: 'documentFileName',
        Cell: ({ row }) => {
          if (row.original.documentFileName === null) {
            return '-';
          }
          return (
            <div className={cn([hidden(row.original.isPermit)])}>
              {Permissions({ permission: 'DOCUMENT_READ' }) !== undefined
                ? (
                  <Button
                    label={(
                      <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                    )}
                    type="button"
                    className="action edit"
                    title={t('global.download')}
                    onClick={() => downloaddoc(row.original.id, row.original.documentFileName)}
                  />
                ) : null }
            </div>
          );
        },
      },
      {
        Header: `${t('documentary.action')}`,
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className={styles.button}>
            {(Permissions({ permission: 'DOCUMENT_READ' }) !== undefined)
              ? (
                <Button
                  type="button"
                  className="action edit"
                  label={<FontAwesomeIcon icon={faEdit} />}
                  title={t('documentary.edit')}
                  onClick={() => navigateEdit(row.original.isPermit, row.original.id)}
                />
              ) : null }
            {Permissions({ permission: 'DOCUMENT_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('documentary.supp')}
                  onClick={() => handleModalFirePermit(row.original.id)}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);
  return (
    <>
      <Layout
        title="Documentaire Base Item"
        layout="table"
        queryError={
          getDocumentBaseItemQuery?.error
          || getDocumentwithidQuery?.error
          || getDocuments?.error
          || deleteDocumentBaseItemQuery?.error
          || deletePermisFeuQuery?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigateBack()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('documentary.back')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">
              {folderId ? (
                getFolderQuery?.data?.data?.name
              ) : (
                getDocumentwithidQuery?.data?.data?.documentBaseItemTypes?.find((item) => item.id === parseInt(urlParams.typeid, 10))?.name
              )}
              <br />
              {folderId && (
                <span className="title-info">
                  {getDocumentwithidQuery?.data?.data?.documentBaseItemTypes?.find((item) => item.id === parseInt(urlParams.typeid, 10))?.name}
                </span>
              )}
            </h1>
            {Permissions({ permission: 'DOCUMENT_CREATE' }) !== undefined && urlParams.categorieid !== '9' && urlParams.categorieid !== '12'
              ? (
                <div>
                  {urlParams.categorieid === '6' && urlParams.folderId ? (
                    <Link className="add" to={`/document/add/${urlParams.categorieid}/dossier/${urlParams.folderId}`}>
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="add_label">{t('documentary.add')}</span>
                    </Link>
                  ) : (
                    <div className={styles.columns}>
                      {urlParams.categorieid === '5' ? (
                        <Link className="add" to={`/base-documentaire/sous-titre/${urlParams.categorieid}/${urlParams.typeid}`}>
                          <FontAwesomeIcon icon={faPlus} />
                          <span className="add_label">{t('documentary.addUnderTitleDocumentBase')}</span>
                        </Link>
                      ) : null}
                      <Link className="add" to={`/document/add/${urlParams.categorieid}/${urlParams.typeid}`}>
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="add_label">{t('documentary.add')}</span>
                      </Link>
                    </div>
                  ) }
                </div>
              ) : null }
          </div>
          <div className={styles.row}>
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        {categoryId === '8' ? (
          <div className={styles.tablePermit}>
            <Table
              columns={PermitFire}
              isLoading={getDocumentBaseItemQuery?.isLoading}
              data={getDocumentBaseItemQuery?.data?.data?.documentBaseItems || []}
              hasSort
              pagination={(
                <Pagination
                  previousLabel={(
                    <FontAwesomeIcon icon={faChevronLeft} />
                  )}
                  nextLabel={(
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                  onPageChange={onPageChange}
                  totalCount={getDocumentBaseItemQuery?.data?.data?.total || 0}
                  currentPage={page}
                  pageSize={10}
                />
              )}
            />
          </div>
        ) : (
          <div className={styles.table}>
            <Table
              columns={docBaseItem}
              isLoading={getDocumentBaseItemQuery?.isLoading}
              data={getDocumentBaseItemQuery?.data?.data?.documentBaseItems || []}
              hasSort
              pagination={(
                <Pagination
                  previousLabel={(
                    <FontAwesomeIcon icon={faChevronLeft} />
                  )}
                  nextLabel={(
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                  onPageChange={onPageChange}
                  totalCount={getDocumentBaseItemQuery?.data?.data?.total || 0}
                  currentPage={page}
                  pageSize={10}
                />
              )}
            />
          </div>
        )}
        <footer>
          <div className="row mt-20">
            <button type="button" className="link" onClick={() => navigateBack()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('documentary.back')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
      <Modal
        isOpen={openModalFirePermit}
        onRequestClose={() => setOpenModalFirePermit(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModalFirePermit(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => handleDeleteFirePermit(supplierToDelete)}
          />
        </div>
      </Modal>
    </>
  );
}

export default DocBaseItem;
