import api from './_api';

/**
 * GET - Get Contracts list
 * @param {Number: categoryId, String: search} pageId
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
*/
export const getFirePermit = (queryParams) => api.get('/fire-permit', { params: queryParams });

/**
 * POST - Create Permis Feu
 * @param {Object} PermisFeuToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const createPermisFeu = (PermisFeuToCreate) => api.post('/fire-permit', PermisFeuToCreate);

/**
 * GET - Get Permis Feu by id
 * @param {Object} params
 * @returns {Promise<AxiosResponse>}
 */
export const getPermisFeuById = (params) => api.get(`/fire-permit/${params.id}`);

/**
 * POST - Update Permis Feu
 * @param {Object} params
 * @returns {Promise<AxiosResponse>}
 */
export const updatePermisFeu = (params) => api.post(`/fire-permit/${params.id}`, params.data);

/**
 * DELETE - Delete Permis Feu
 * @param {Object} params
 * @returns {Promise<AxiosResponse>}
 */
export const deletePermisFeu = (params) => api.delete(`/fire-permit/${params}`);
