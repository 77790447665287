/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
// Utils
import styles from './Textarea.module.css';
import cn from '../../../utils/cn';

function Textarea({
  id, name, label, labelHidden, rows, cols, value, onChange, required, ...props
}) {
  return (
    <>
      <label className={cn([styles.label, labelHidden ? 'visually-hidden' : ''])} htmlFor={id}>
        {label}
      </label>
      <textarea
        className={styles.textarea}
        id={id}
        name={name}
        rows={rows}
        cols={cols}
        value={value}
        onChange={onChange}
        autoComplete="off"
        required={required || null}
        {...props}
      />
    </>
  );
}

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  labelHidden: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

Textarea.defaultProps = {
  labelHidden: false,
  rows: undefined,
  cols: undefined,
  value: undefined,
  name: '',
  label: '',
  required: false,
};

export default Textarea;
