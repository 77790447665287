/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
// Components
import CardDashboard from '../../components/molecules/CardBaseDocument/CardBaseDocument';
import useAppContext from '../../store/useAppContext';
import Layout from '../../components/template/Layout';
// Utils
import { getDocumentBaseItemCategory } from '../../services/docBase';
import { GetVehicle } from '../../services/vehicules';
// styles
import styles from './DocumentBase.module.css';

function Documentary() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [context] = useAppContext();
  const isSecurityBooklet = context?.choiceBooklet === 2;
  const isVehiculeBooklet = context?.choiceBooklet === 3;
  const params = new URLSearchParams(location.search);
  const [vehiculeId] = useState(params.get('vehicule'));

  const getDocumentBaseItemCategoryQuery = useQuery(['getDocumentBaseItemCategory'], () => getDocumentBaseItemCategory({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  const navigateBack = () => {
    if (vehiculeId) {
      navigate('/vehicule');
    } else {
      navigateDashboards();
    }
  };

  const navigateSousCategorie = (categoryId) => {
    navigate(`/base-documentaire/${categoryId}`);
  };

  const navigateVehicle = (categoryId) => {
    if (vehiculeId) {
      navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === categoryId)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === categoryId)?.documentBaseItemTypes[0]?.id}/${vehiculeId}`);
    } else {
      navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === categoryId)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === categoryId)?.documentBaseItemTypes[0]?.id}`);
    }
  };

  const GetVehicleMutate = useMutation(GetVehicle);

  useEffect(() => {
    if (vehiculeId) {
      GetVehicleMutate.mutate(vehiculeId);
    }
  }, []);

  return (
    <Layout
      title={t('documentary.title')}
      layout="table"
      queryError={
        getDocumentBaseItemCategoryQuery?.error
        || GetVehicleMutate?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigateBack()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('documentary.back_to_dashboard')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">
            {t('documentary.title')}
            <br />
            <span className="title-info">
              {GetVehicleMutate?.isSuccess ? (
                <div>
                  {` ${GetVehicleMutate?.data?.data?.brand} - ${GetVehicleMutate?.data?.data?.model} - ${GetVehicleMutate?.data?.data?.licensePlate}`}
                </div>
              ) : null}
            </span>
          </h1>
        </div>
      </header>
      {!getDocumentBaseItemCategoryQuery?.isSuccess ? (
        <div className="loader" />
      ) : null}
      {isSecurityBooklet && !getDocumentBaseItemCategoryQuery?.isLoading && (
        <section className={styles.Securite}>
          {/* information general */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 4)?.name}
            picto="etablissements"
            text={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 4)?.documentBaseItemTypes?.length < 3
              ? (
                getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 4)?.documentBaseItemTypes?.map((item) => (
                  <>
                    {item?.name}
                    <br />
                  </>
                ))
              ) : null}
            classNames={styles.cardgeneral}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 4)?.countDocuments}
            onClick={() => {
              if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 4)?.withFolders === true) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 4)?.id}/dossier`);
              } else if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 4)?.documentBaseItemTypes?.length < 2) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 4)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 4)?.documentBaseItemTypes[0]?.id}`);
              } else {
                navigateSousCategorie(4);
              }
            }}
            borderTopColored
          />
          {/* Dossier SSI */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 5)?.name}
            picto="book"
            text={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 5)?.documentBaseItemTypes?.length < 3
              ? (
                getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 5)?.documentBaseItemTypes?.map((item) => (
                  <>
                    {item?.name}
                    <br />
                  </>
                ))
              ) : null}
            classNames={styles.book}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 5)?.countDocuments}
            onClick={() => {
              if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 5)?.withFolders === true) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 5)?.id}/dossier`);
              } else if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 5)?.documentBaseItemTypes?.length < 2) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 5)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 5)?.documentBaseItemTypes[0]?.id}`);
              } else {
                navigateSousCategorie(5);
              }
            }}
            borderTopColored
          />
          {/* Suivie des traveaux */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 6)?.name}
            picto="suivieTravaux"
            text={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 6)?.documentBaseItemTypes?.length < 3
              ? (
                getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 6)?.documentBaseItemTypes?.map((item) => (
                  <>
                    {item?.name}
                    <br />
                  </>
                ))
              ) : null}
            classNames={styles.suivieTravaux}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 6)?.countDocuments}
            onClick={() => {
              if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 6)?.withFolders === true) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 6)?.id}/dossier`);
              } else if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 6)?.documentBaseItemTypes?.length < 2) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 6)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 6)?.documentBaseItemTypes[0]?.id}`);
              } else {
                navigateSousCategorie(6);
              }
            }}
            borderTopColored
          />
          {/* Reglementation */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 7)?.name}
            picto="reglementation"
            text={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 7)?.documentBaseItemTypes?.length < 3
              ? (
                getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 7)?.documentBaseItemTypes?.map((item) => (
                  <>
                    {item?.name}
                    <br />
                  </>
                ))
              ) : null}
            classNames={styles.reglementation}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 7)?.countDocuments}
            onClick={() => {
              if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 7)?.withFolders === true) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 7)?.id}/dossier`);
              } else if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 7)?.documentBaseItemTypes?.length < 2) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 7)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 7)?.documentBaseItemTypes[0]?.id}`);
              } else {
                navigateSousCategorie(7);
              }
            }}
            borderTopColored
          />
          {/* Permis feu */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 8)?.name}
            picto="flamme"
            text={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 8)?.documentBaseItemTypes?.length < 3
              ? (
                getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 8)?.documentBaseItemTypes?.map((item) => (
                  <>
                    {item?.name}
                    <br />
                  </>
                ))
              ) : null}
            classNames={styles.flamme}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 8)?.countDocuments}
            onClick={() => {
              if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 8)?.withFolders === true) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 8)?.id}/dossier`);
              } else if (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 8)?.documentBaseItemTypes?.length < 2) {
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 8)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 8)?.documentBaseItemTypes[0]?.id}`);
              } else {
                navigateSousCategorie(8);
              }
            }}
            borderTopColored
          />
        </section>
      )}
      {isVehiculeBooklet && !getDocumentBaseItemCategoryQuery?.isLoading && (
        <section className={styles.Vehicule}>
          {/* Cartes grises */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 9)?.name}
            picto="carteGriseBleu"
            classNames={styles.Vcgrise}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 9)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 9)?.documentBaseItemTypes?.length < 2
              ? (
                navigateVehicle(9)
              ) : (
                navigateSousCategorie(9)
              ))}
            borderTopColored
          />
          {/* controle technique */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 10)?.name}
            picto="controlTechniqueBleu"
            classNames={styles.Vcontrole}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 10)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 10)?.documentBaseItemTypes?.length < 2
              ? (
                navigateVehicle(10)
              ) : (
                navigateSousCategorie(10)
              ))}
            borderTopColored
          />
          {/* Entretiens (revison) */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 11)?.name}
            picto="entretienBleu"
            classNames={styles.Vrevision}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 11)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 11)?.documentBaseItemTypes?.length < 2
              ? (
                navigateVehicle(11)
              ) : (
                navigateSousCategorie(11)
              ))}
            borderTopColored
          />
          {/* Factures */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 12)?.name}
            picto="reglementation"
            classNames={styles.Vfacture}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 12)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 12)?.documentBaseItemTypes?.length < 2
              ? (
                navigateVehicle(12)
              ) : (
                navigateSousCategorie(12)
              ))}
            borderTopColored
          />
          {/* Assurences */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 13)?.name}
            picto="assuranceBleu"
            classNames={styles.Vassurance}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 13)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 13)?.documentBaseItemTypes?.length < 2
              ? (
                navigateVehicle(13)
              ) : (
                navigateSousCategorie(13)
              ))}
            borderTopColored
          />
          {/* Hayons electrique */}
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 14)?.name}
            picto="hayonBleu"
            classNames={styles.Vhayon}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 14)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 14)?.documentBaseItemTypes?.length < 2
              ? (
                navigateVehicle(14)
              ) : (
                navigateSousCategorie(14)
              ))}
            borderTopColored
          />
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 15)?.name}
            picto="documentaire"
            classNames={styles.Vhayon}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 15)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 15)?.documentBaseItemTypes?.length < 2
              ? (
                navigateVehicle(15)
              ) : (
                navigateSousCategorie(15)
              ))}
            borderTopColored
          />
        </section>
      )}
      {!isVehiculeBooklet && !isSecurityBooklet && !getDocumentBaseItemCategoryQuery?.isLoading && (
        <section className={styles.Sanitaire}>
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 1)?.name}
            picto="depotrapport"
            text={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 1)?.documentBaseItemTypes?.length < 3
              ? (
                getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 1)?.documentBaseItemTypes?.map((item) => (
                  <>
                    {item?.name}
                    <br />
                  </>
                ))
              ) : null}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 1)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 1)?.documentBaseItemTypes?.length < 2
              ? (
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 1)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 1)?.documentBaseItemTypes[0]?.id}`)
              ) : (
                navigateSousCategorie(1)
              ))}
            borderTopColored
          />
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 2)?.name}
            picto="archives"
            text={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 2)?.documentBaseItemTypes?.map((item) => (
              <>
                {item?.name}
                <br />
              </>
            ))}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 2)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 2)?.documentBaseItemTypes?.length < 2
              ? (
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 2)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 2)?.documentBaseItemTypes[0]?.id}`)
              ) : (
                navigateSousCategorie(2)
              ))}
            borderTopColored
          />
          <CardDashboard
            title={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 3)?.name}
            picto="reglementation"
            text={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 3)?.documentBaseItemTypes?.length < 3
              ? (
                getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 3)?.documentBaseItemTypes?.map((item) => (
                  <>
                    {item?.name}
                    <br />
                  </>
                ))
              ) : null}
            Numbers={getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 3)?.countDocuments}
            onClick={() => (getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 3)?.documentBaseItemTypes?.length < 2
              ? (
                navigate(`/base-documentaire/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 3)?.id}/${getDocumentBaseItemCategoryQuery?.data?.data?.documentBaseItemCategories?.find((item) => item?.id === 3)?.documentBaseItemTypes[0]?.id}`)
              ) : (
                navigateSousCategorie(3)
              ))}
            borderTopColored
          />
        </section>
      )}
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigateBack()}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('documentary.back_to_dashboard')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default Documentary;
