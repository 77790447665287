import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// Components
import { noop } from 'lodash';
import Button from '../../atoms/Button/Button';
import Permissions from '../../molecules/Permissions/Permissions';
// Utils
import styles from './ControlType.module.css';
import cn from '../../../utils/cn';

function ControlType({
  columns, children, onSubmit, noSubmit,
}) {
  // Hooks
  const { t } = useTranslation();
  const urlParams = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  const columnsWithIds = columns.map((column, index) => ({ ...column, id: index }));

  return (
    <form className={cn([styles.section, 'shadow-sm'])} onSubmit={handleSubmit}>
      <div className={cn([styles.header])}>
        {columnsWithIds.map((column) => (
          <div
            key={`column-${column.id}`}
            className={cn([styles.col, column?.class])}
            style={{ width: column.width }}
          >
            {column.label}
          </div>
        ))}
      </div>
      <div className={styles.main}>
        {children}
      </div>
      {
        noSubmit ? null : (
          <div className={styles.footer}>
            {Permissions({ permission: 'INPUT_CREATE' }) !== undefined
             || urlParams.action === 'edit'
              ? (
                <Button
                  type="submit"
                  className={styles.button}
                  label={t('inputs.submit')}
                />
              ) : null}
          </div>
        )
      }
    </form>
  );
}

ControlType.propTypes = {
  columns: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  onSubmit: PropTypes.func,
  noSubmit: PropTypes.bool,
};

ControlType.defaultProps = {
  noSubmit: false,
  onSubmit: noop,
};

export default ControlType;
