import api from './_api';

/**
 * GET - Get safetyLightingEquipment list
 * @param {{safetyLightingEquipmentId: Number, page:Number}} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getSafetyLightingEquipmentsList = (queryParams) => api.get('/safety-lighting-equipments', {
  params: queryParams,
});

/**
 * GET - Get safetyLightingEquipment
 * @param {Number} safetyLightingEquipmentId
 * @returns {Promise<AxiosResponse>}
 */
// eslint-disable-next-line max-len
export const getSafetyLightingEquipment = (safetyLightingEquipmentId) => api.get(`/safety-lighting-equipments/${safetyLightingEquipmentId}`);

/**
 * POST - Create safetyLightingEquipment
 * @param {{
 * id: Number,
 * blockNumber:Number,
 * location: String,
 * function: String
 * }} safetyLightingEquipmentToCreate
 * @returns {Promise<AxiosResponse>}
 */
// eslint-disable-next-line max-len
export const postSafetyLightingEquipment = (safetyLightingEquipmentToCreate) => api.post('/safety-lighting-equipments', safetyLightingEquipmentToCreate);

/**
  * PUT - Edit safetyLightingEquipment
  * @param {{*
  * id: Number,
  * blockNumber:Number,
  * location: String,
  * function: String
  * }}
  * safetyLightingEquipmentToEdit
  * @returns {Promise<AxiosResponse>}
  */
// eslint-disable-next-line arrow-body-style
export const editsafetyLightingEquipment = (safetyLightingEquipmentToEdit) => {
  return api.put(`/safety-lighting-equipments/${safetyLightingEquipmentToEdit.id}`, safetyLightingEquipmentToEdit);
};

/**
 * DEL - Delete safetyLightingEquipment
 * @param {Number} safetyLightingEquipmentId
 * @returns {Promise<AxiosResponse>}
 */
// eslint-disable-next-line max-len
export const deleteSafetyLightingEquipment = (safetyLightingEquipmentId) => api.delete(`/safety-lighting-equipments/${safetyLightingEquipmentId}`);
