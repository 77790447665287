/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
// Utils
import styles from './Button.module.css';
import cn from '../../../utils/cn';

function Button({
  type, className, label, icon, isLoading, disabled, required, ...props
}) {
  return (
    <button className={cn([styles.button, className || ''])} type={type} disabled={isLoading || disabled} {...props}>
      {isLoading ? <span className={cn([styles.loader, 'loader'])} /> : null }
      {icon ? <span className={styles.icon}>{icon}</span> : null }
      {label}
      {required ? <span className={styles.required}>*</span> : null}
    </button>
  );
}

Button.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,

};

Button.defaultProps = {
  icon: null,
  className: '',
  isLoading: false,
  disabled: false,
  required: false,
};

export default Button;
