/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { sortBy, omit } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Radio from '../../components/atoms/Radio/Radio';
import Button from '../../components/atoms/Button/Button';
import Select from '../../components/atoms/Select/Select';
// Services
import { getControl, postControl, editControl } from '../../services/controls';
import { getControlTypesList } from '../../services/controlTypes';
import { getEquipmentsList } from '../../services/equipments';
// Utils
import { INPUT_TYPES, INTERVENTION_TYPES } from '../../utils/constant';
import useAppContext from '../../store/useAppContext';

const initialValues = {
  name: '',
  typeId: '',
  equipmentIds: [],
  interventionType: '',
  inputType: null,
  paramLow: null,
  paramHigh: null,
  displayOrder: null,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.required_field'),
  typeId: Yup.number().required('global.required_field'),
  equipmentIds: Yup.array(),
  interventionType: Yup.string().required('global.required_field'),
  inputType: Yup.string().nullable(true),
  paramLow: Yup.string().nullable(),
  paramHigh: Yup.string().nullable(),
  displayOrder: Yup.string().nullable(),
});

function AddControl() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [context] = useAppContext();
  // States
  const [controlTypes, setControlTypes] = useState([]);
  const [equipments, setEquipments] = useState([]);
  // API calls
  const getControlTypesListQuery = useQuery('control-types', () => getControlTypesList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setControlTypes(data.data.controlTypes);
    },
  });
  const getEquipmentsListQuery = useQuery('equipments', () => getEquipmentsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setEquipments(data.data.equipments);
    },
  });
  const postControlMutation = useMutation(postControl, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_control.control_added'));
    },
  });
  const editControlMutation = useMutation(editControl, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_control.control_edited'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      if (urlParams?.action === 'edit') {
        editControlMutation.mutate(formValues);
      } else {
        postControlMutation.mutate(formValues);
      }
    },
  });

  const getControlQuery = useQuery('control', () => getControl(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues({ ...omit(data.data, 'type'), typeId: data?.data?.type?.id });
    },
  });

  const inputTypeOptions = React.useMemo(() => [
    {
      value: INPUT_TYPES.OKNEKO_COMM_FACULT,
      label: t('add_control.OKNEKO_COMM_FACULT'),
    },
    {
      value: INPUT_TYPES.OKNEKO_COMM_OBLIG_TTLT,
      label: t('add_control.OKNEKO_COMM_OBLIG_TTLT'),
    },
    {
      value: INPUT_TYPES.OKNEKO_COMM_OBLIG_SI_KO,
      label: t('add_control.OKNEKO_COMM_OBLIG_SI_KO'),
    },
    {
      value: INPUT_TYPES.OKNEKO_COMM_OBLIG_SI_NE,
      label: t('add_control.OKNEKO_COMM_OBLIG_SI_NE'),
    },
    {
      value: INPUT_TYPES.OKNEKO_SEUL,
      label: t('add_control.OKNEKO_Seul'),
    },
    {
      value: INPUT_TYPES.INDEX_DATE_VOL_VOLJR,
      label: t('add_control.INDEX_DATE_VOL_VOLJR'),
    },
    {
      value: INPUT_TYPES.SSI_PERMANENT,
      label: t('add_control.SSI_PERMANENT'),
    },
    {
      value: INPUT_TYPES.T30S_TSTAB_TMPSTAB_HEURE,
      label: t('add_control.T30S_TSTAB_TMPSTAB_HEURE'),
    },
    {
      value: INPUT_TYPES.DEG_HEURE,
      label: t('add_control.DEG_HEURE'),
    },
    {
      value: INPUT_TYPES.PM30S_DEG_HEURE,
      label: t('add_control.PM30S_DEG_HEURE'),
    },
    {
      value: INPUT_TYPES.ECLAIRAGE_SECURITE,
      label: t('add_control.eclairage_securite'),
    },
    {
      value: INPUT_TYPES.GROUPE_ELECTROGENE,
      label: t('add_control.groupe_electrogene'),
    },
    {
      value: INPUT_TYPES.VALEUR_KILO,
      label: t('add_control.ValeurKilo'),
    },
    {
      value: INPUT_TYPES.VALEUR_BAIGNEUR,
      label: t('add_control.ValeurBaigneur'),
    },
    {
      value: INPUT_TYPES.VALEUR_PH,
      label: t('add_control.ValeurPH'),
    },
    {
      value: INPUT_TYPES.VALEUR_CHLORE,
      label: t('add_control.ValeurChlore'),
    },
    {
      value: INPUT_TYPES.VALEUR_TH,
      label: t('add_control.ValeurTH'),
    },
    {
      value: INPUT_TYPES.BALNEOTHERAPIE,
      label: t('add_control.Balneotherapie'),
    },
  ], []);

  const handleCheckboxClick = (fieldName, fieldValue, isChecked) => formik.setFieldValue(
    fieldName,
    isChecked
      ? formik.values[fieldName].concat(fieldValue)
      : formik.values[fieldName].filter((id) => id !== fieldValue),
  );
  return (
    <Layout
      title={t('add_control.title')}
      queryError={
        postControlMutation?.error
        || editControlMutation?.error
        || getControlTypesListQuery?.error
        || getEquipmentsListQuery?.error
        || getControlQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_control.back_to_controls')}</span>
          </button>
        </div>
        <h1 className="title">
          {urlParams?.action === 'edit' ? t('add_control.edit_title') : t('add_control.title')}
        </h1>
      </header>
      <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
        <div className="form_group">
          <div className="label">{t('add_control.intervention_type')}</div>
          <div>
            <Radio
              id={t('add_control.input')}
              name="interventionType"
              label={t('add_control.input')}
              onChange={(event) => formik.setFieldValue('interventionType', event.target.value)}
              onBlur={formik.handleBlur}
              checked={formik.values.interventionType === INTERVENTION_TYPES.INPUT}
              value={INTERVENTION_TYPES.INPUT}
            />
          </div>
          <div>
            <Radio
              id={t('add_control.report')}
              name="interventionType"
              label={t('add_control.report')}
              onChange={(event) => formik.setFieldValue('interventionType', event.target.value)}
              onBlur={formik.handleBlur}
              checked={formik.values.interventionType === INTERVENTION_TYPES.REPORT}
              value={INTERVENTION_TYPES.REPORT}
            />
          </div>
          {formik.errors.interventionType && formik.touched.interventionType ? (
            <div className="error">
              {t(formik.errors.interventionType)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            id="name"
            type="text"
            name="name"
            label={t('add_control.name')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.errors.name && formik.touched.name ? (
            <div className="error">
              {t(formik.errors.name)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('add_control.equipments')}</div>
          {sortBy(equipments, ['name']).map((equipment) => (
            <div key={equipment.id}>
              <Checkbox
                id={`equipment-${equipment.id}`}
                name="equipmentIds[]"
                label={equipment.name}
                onChange={(event) => handleCheckboxClick('equipmentIds', equipment.id, event.target.checked)}
                onBlur={formik.handleBlur}
                checked={formik.values.equipmentIds?.includes(equipment.id)}
                value={equipment.id}
              />
            </div>
          ))}
          {formik.errors.equipmentIds && formik.touched.equipmentIds ? (
            <div className="error">
              {formik.errors.equipmentIds}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('add_control.control_type')}</div>
          {formik.values.interventionType === 'report'
            ? controlTypes.filter((controlType) => controlType.id === 20
            || controlType.id === 35).map((controlType) => (
              <div key={controlType.id}>
                <Radio
                  id={`controlType-${controlType.id}`}
                  name="typeId"
                  label={controlType.name}
                  onChange={(event) => formik.setFieldValue('typeId', parseInt(event.target.value, 10))}
                  onBlur={formik.handleBlur}
                  checked={formik.values.typeId === controlType.id}
                  value={controlType.id}
                />
              </div>
            )) : controlTypes.map((controlType) => (
              <div key={controlType.id}>
                <Radio
                  id={`controlType-${controlType.id}`}
                  name="typeId"
                  label={controlType.name}
                  onChange={(event) => formik.setFieldValue('typeId', parseInt(event.target.value, 10))}
                  onBlur={formik.handleBlur}
                  checked={formik.values.typeId === controlType.id}
                  value={controlType.id}
                />
              </div>
            ))}
          {formik.errors.typeId && formik.touched.typeId ? (
            <div className="error">
              {t(formik.errors.typeId)}
            </div>
          ) : null }
        </div>
        {formik.values.interventionType === INTERVENTION_TYPES.INPUT ? (
          <>
            <div className="form_group">
              <Select
                id="inputTypeOptions"
                label={t('add_control.input_type')}
                options={inputTypeOptions}
                value={inputTypeOptions.find((option) => option.value === formik.values.inputType)}
                onChange={(option) => formik.setFieldValue('inputType', option.value)}
              />
              {formik.errors.inputType && formik.touched.inputType ? (
                <div className="error">
                  {t(formik.errors.inputType)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              {' '}
            </div>
            <div className="form_group">
              <Input
                id="paramLow"
                type="number"
                name="paramLow"
                label={t('add_control.low')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.paramLow}
              />
              {formik.errors.paramLow && formik.touched.paramLow ? (
                <div className="error">
                  {t(formik.errors.paramLow)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="paramHigh"
                type="number"
                name="paramHigh"
                label={t('add_control.high')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.paramHigh}
              />
              {formik.errors.paramHigh && formik.touched.paramHigh ? (
                <div className="error">
                  {t(formik.errors.paramHigh)}
                </div>
              ) : null }
            </div>
          </>
        ) : null }
        {formik.values.interventionType === INTERVENTION_TYPES.REPORT ? (
          <div className="form_group">
            <Input
              id="displayOrder"
              type="number"
              name="displayOrder"
              label={t('add_control.display_order')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.displayOrder}
            />
            {formik.errors.displayOrder && formik.touched.displayOrder ? (
              <div className="error">
                {t(formik.errors.displayOrder)}
              </div>
            ) : null }
          </div>
        ) : null }
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('add_control.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('add_control.submit')}
            isLoading={postControlMutation.isLoading}
          />
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('add_control.back_to_controls')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddControl;
