import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ControlType from '../../template/ControlType/ControlType';
import DateSelectUploadNumber from '../InputTypes/DateSelectUploadNumber/DateSelectUploadNumber';

function Revision({ list, submit }) {
  const columns = [];

  const initialList = list.map((input) => ({ ...input, isDirty: false }));
  const [inputsList, setInputsList] = useState(initialList);

  const handleChange = (input) => {
    const resultsChanged = inputsList.map((inputState) => {
      if (inputState.id === input.id) return ({ ...input, isDirty: true });
      return inputState;
    });
    setInputsList(resultsChanged);
  };

  const handleValuesSubmit = () => {
    const valuesToSubmit = inputsList
      .filter((input) => {
        if (input.isDirty && input.checked !== null) return true;
        return false;
      })
      .map((input) => ({
        inputId: input?.id,
        indexDate: input?.date,
        attachments: input?.file,
        mileageIndex: input?.number,
        result: input?.result,
        comment: input?.comment,
      }));
    submit(valuesToSubmit);
    setInputsList(initialList);
  };

  return (
    <ControlType columns={columns} onSubmit={handleValuesSubmit}>
      {inputsList?.map((input) => (
        <DateSelectUploadNumber
          key={input.id}
          id={input?.id}
          label={input?.title}
          value={input}
          onChange={handleChange}
        />
      ))}
    </ControlType>
  );
}

Revision.propTypes = {
  list: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
};

export default Revision;
