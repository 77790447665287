/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Error from './views/Error/Error';

// eslint-disable-next-line react/prop-types
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, whereError: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, whereError: error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ whereError: error });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    const { whereError } = this.state;
    if (hasError) {
      return <Error errorPath={window.location.pathname} error={whereError} />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
