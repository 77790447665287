import api from './_api';

/**
 * GET - Get Equipment Families list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getEquipmentFamiliesList = (queryParams) => api.get('/equipment-families', { params: queryParams });

/**
 * GET - Get Equipment Family
 * @param {Number} equipmentFamilyId
 * @returns {Promise<AxiosResponse>}
 */
export const getEquipmentFamily = (equipmentFamilyId) => api.get(`/equipment-families/${equipmentFamilyId}`);

/**
 * POST - Create Equipment Family
 * @param {{name:String}} equipmentFamilyToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postEquipmentFamilyInformations = (equipmentFamilyToCreate) => api
  .post('/equipment-families', equipmentFamilyToCreate);

/**
 * PUT - Edit Equipment Family
 * @param {{id:Number, name:String}} equipmentFamilyToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editEquipmentFamilyInformations = (equipmentFamilyToEdit) => api
  .put(`/equipment-families/${equipmentFamilyToEdit.id}`, equipmentFamilyToEdit);

/**
 * DEL - Delete Equipment Family
 * @param {Number} equipmentFamilyId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteEquipmentFamily = (equipmentFamilyId) => api.delete(`/equipment-families/${equipmentFamilyId}`);
