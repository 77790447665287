import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import Layout from '../../components/template/Layout';
import Permissions from '../../components/molecules/Permissions/Permissions';
import Button from '../../components/atoms/Button/Button';
import InputsTypeSwitcher from '../../components/molecules/InputType/InputsTypeSwitcher';
// Hooks
import useAppContext from '../../store/useAppContext';
// Services
import { getInputsToComplete, postInputs, postCTREV } from '../../services/inputs';
import { getBuildingsList } from '../../services/structures';
// Utils
import styles from './Inputs.module.css';
import cn from '../../utils/cn';

function Inputs() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const [buildingId, setBuildingId] = useState();
  const [vehicleId, setVehicleId] = useState();
  // const [inputForSubmit, setInputForSubmit] = useState([]);
  let getInputsToCompleteQuery = useState([]);
  // API Calls

  if (context?.choiceBooklet !== 3) {
    getInputsToCompleteQuery = useQuery(['inputs', buildingId], () => getInputsToComplete({
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
      buildingId,
    }), {
      enabled: !!buildingId,
    });
  } else {
    getInputsToCompleteQuery = useQuery(['inputs', vehicleId], () => getInputsToComplete({
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
      buildingId,
      vehicleId,
    }), {
      enabled: !!buildingId,
    });
  }

  const firstVehicle = () => {
    let first = false;
    getInputsToCompleteQuery?.data?.data?.buildingInputInfos?.forEach((vehicle) => {
      if (!first && vehicle.countInputsToComplete !== 0) {
        setVehicleId(vehicle.buildingId);
        getInputsToCompleteQuery.remove();
        first = true;
      }
    });
  };

  useEffect(() => {
    if (vehicleId === undefined
      && getInputsToCompleteQuery?.data?.data?.buildingInputInfos?.length > 0
      && context?.choiceBooklet === 3
    ) {
      firstVehicle();
    }
  }, [getInputsToCompleteQuery]);

  const inputsControlTypeSorted = getInputsToCompleteQuery?.data?.data?.inputs
    ? getInputsToCompleteQuery?.data?.data?.inputs.reduce((acc, input) => {
      const isAlreadyInAcc = acc.some((accInput) => accInput.controlTypeId === input.controlTypeId);
      if (!isAlreadyInAcc) {
        acc.push({
          controlTypeId: input.controlTypeId,
          controlTypeName: input.controlTypeName,
          inputsControlList: [input],
        });
        return acc;
      }
      return acc.map((accInput) => {
        if (accInput.controlTypeId === input.controlTypeId) {
          return {
            ...accInput,
            inputsControlList: accInput.inputsControlList.concat(input),
          };
        }
        return accInput;
      });
    }, []) : null;

  const inputsInputTypeSorted = inputsControlTypeSorted?.map((inputControlType) => ({
    ...inputControlType,
    inputsControlList: inputControlType.inputsControlList.reduce((acc, input) => {
      const isAlreadyInAcc = acc.some((accInput) => accInput.inputType === input.inputType);
      if (!isAlreadyInAcc) {
        acc.push({
          inputType: input.inputType,
          inputsTypeList: [input],
        });
        return acc;
      }
      return acc.map((accInput) => {
        if (accInput.inputType === input.inputType) {
          return {
            ...accInput,
            inputsTypeList: accInput.inputsTypeList.concat(input),
          };
        }
        return accInput;
      });
    }, []),
  }));

  const reset = () => {
    getInputsToCompleteQuery.remove();
    getInputsToCompleteQuery.refetch();
  };

  const postInputsMutation = useMutation(postInputs, {
    onSuccess: () => {
      toast.success(t('inputs.saved'));
      // pause de 500ms pour laisser le temps à la base de données de se mettre à jour
      setTimeout(() => {
        reset();
      }, 1000);
    },
  });

  const postCTREVMutation = useMutation(postCTREV, {
    onSuccess: () => {
      toast.success(t('contract.success'));
      // pause de 500ms pour laisser le temps à la base de données de se mettre à jour
      setTimeout(() => {
        reset();
      }, 1000);
    },
  });

  const handleSubmit = (inputsToSubmit) => {
    if (inputsToSubmit[0]?.attachments) {
      const formdata = new FormData();
      formdata.append('attachment', inputsToSubmit[0]?.attachments);
      formdata.append('mileageIndex', inputsToSubmit[0]?.mileageIndex);
      formdata.append('indexDate', inputsToSubmit[0]?.indexDate);
      formdata.append('comment', inputsToSubmit[0]?.comment);
      formdata.append('result', inputsToSubmit[0]?.result);
      const values = {
        id: inputsToSubmit[0]?.inputId,
        data: formdata,
      };
      postCTREVMutation.mutate(values);
    }
    if (inputsToSubmit.length > 0 && !inputsToSubmit[0]?.attachments) {
      postInputsMutation.mutate({
        inputs: inputsToSubmit,
      });
    } else if (!inputsToSubmit[0]?.attachments) {
      toast.info(t('inputs.nothing_to_save'));
    }
  };
  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      if (data?.data?.buildings?.length > 0) {
        setBuildingId(data.data.buildings[0].id);
      }
    },
  });

  const handleBuilding = (id) => {
    setBuildingId(id);
  };

  const handleVehicle = (id) => {
    setVehicleId(id);
  };

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  useEffect(() => {
    reset();
  }, [vehicleId]);

  return (
    <Layout
      title={t('inputs.title')}
      queryError={
        getInputsToCompleteQuery?.error
        || getBuildingsQuery?.error
        || postInputsMutation?.error
      }
      layout="table"
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        {Permissions({ permission: 'INPUTHISTORY_LIST' }) !== undefined
          ? (
            <div className="row">
              <h1 className="title">{t('inputs.title')}</h1>
              <Link
                className={cn([styles.add, 'add col-1'])}
                type="button"
                to="/saisies/historique"
              >
                <FontAwesomeIcon icon={faClockRotateLeft} />
                {' '}
                {t('inputs.input_historic')}
              </Link>
            </div>
          ) : null }
        <div className={styles.filters}>
          { context?.choiceBooklet !== 3 ? (
            <div className={styles.filtersToComplete}>
              {getInputsToCompleteQuery?.data?.data?.buildingInputInfos?.map((building) => (
                building.countInputsToComplete !== 0 ? (
                  <Button
                    key={building.id}
                    type="button"
                    label={`(${building.countInputsToComplete}) ${building.buildingName}`}
                    className={cn([styles.filter, buildingId === building.buildingId
                      ? styles.active
                      : '', 'shadow-md'])}
                    onClick={() => handleBuilding(building.buildingId)}
                  />
                ) : null
              ))}
            </div>
          ) : (
            <div className={styles.filtersToComplete}>
              {getInputsToCompleteQuery?.data?.data?.buildingInputInfos?.map((vehicule) => (
                vehicule.countInputsToComplete !== 0 ? (
                  <Button
                    type="button"
                    label={`(${vehicule.countInputsToComplete}) ${vehicule.buildingName}`}
                    className={cn([styles.filter, vehicleId === vehicule.buildingId
                      ? styles.active
                      : '', 'shadow-md'])}
                    onClick={() => handleVehicle(vehicule?.buildingId)}
                  />
                ) : null
              ))}
            </div>
          )}
        </div>
      </header>
      <div className={cn([styles.inputs, 'inputs-form'])}>
        {getInputsToCompleteQuery.isLoading || (context.choiceBooklet === 3 && !vehicleId) ? (
          <div className="loader" />
        )
          : inputsInputTypeSorted?.map((inputControlType) => (
            <div
              key={`row-${inputControlType.controlTypeName}`}
              className={cn([styles.controlTypeBloc, 'inputs-form'])}
            >
              <h2 className={styles.controlTypeTitle}>{inputControlType.controlTypeName}</h2>
              <div>
                {inputControlType?.inputsControlList?.map((inputControl) => (
                  <InputsTypeSwitcher
                    key={inputControl?.inputType}
                    inputControl={inputControl}
                    submit={handleSubmit}
                  />
                ))}
              </div>
            </div>
          )) || <div>{t('inputs.no_inputs')}</div>}
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default Inputs;
