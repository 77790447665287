/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEdit, faEye, faClose, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
// Hooks
// import useAppContext from '../../store/useAppContext';
// Components
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
// Services
import { getSociety } from '../../services/societies';
// Utils
import styles from './ProviderList.module.css';
import SocietyList from '../../components/template/SocietyList/SocietyList';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function ProviderList() {
  // States
  const [openModal, setOpenModal] = useState(false);

  const [search, setSearch] = useState('');
  const [openModalSociety, setOpenModalSociety] = useState([]);
  // Hooks
  const { t } = useTranslation();
  //   const [context] = useAppContext();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const navigate = useNavigate();
  // API Calls
  const getProviderListQuery = useQuery(['socitey', page, search], () => getSociety({ page: 0, search }));

  const handleModal = (selectedSociety) => {
    setOpenModal(true);
    setOpenModalSociety(selectedSociety);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('prestataire.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('prestataire.type')}`,
        accessor: 'type',
        Cell: ({ row }) => (
          <span>
            {t(`prestataire.${row.original.type}`)}
          </span>
        ),
      },
      {
        Header: `${t('prestataire.company')}`,
        accessor: 'name',
      },
      {
        Header: `${t('prestataire.address')}`,
        accessor: 'address',
      },
      {
        Header: `${t('prestataire.zip_code')}`,
        accessor: 'postalCode',
      },
      {
        Header: `${t('prestataire.establishment')}`,
        accessor: 'establishment',
        Cell: ({ row }) => (
          <Button
            type="button"
            className="action"
            label={<FontAwesomeIcon icon={faEye} />}
            title={t('prestataire.establishmentList')}
            onClick={() => handleModal({ societyStructures: row.original.societyStructures })}
          />
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            <Button
              label={<FontAwesomeIcon icon={faEdit} />}
              type="button"
              className={styles.edit}
              title={t('societies.edit')}
              onClick={() => navigate(`/provider/edit/${row.original.id}`)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <>
      <Layout
        title={t('prestataire.title')}
        queryError={
            getProviderListQuery?.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate('/dashboard')}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('prestataire.title')}</h1>
            <Link className="add" to="/addNewProvider">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('prestataire.add_prestataire')}</span>
            </Link>
          </div>
          <div className={styles.row}>
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getProviderListQuery?.isLoading}
            data={getProviderListQuery?.data?.data?.societies || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getProviderListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <div className="row mb-20">
          <button
            type="button"
            className="link"
            onClick={() => navigate('/dashboard')}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <Button
          type="button"
          className={styles.close}
          label={<FontAwesomeIcon icon={faClose} />}
          onClick={() => setOpenModal(false)}
        />
        <SocietyList societies={openModalSociety} />
      </Modal>
    </>
  );
}

export default ProviderList;
