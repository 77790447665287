import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import ControlType from '../../template/ControlType/ControlType';
import NumberDate from '../InputTypes/NumberDate/NumberDate';
import Input from '../../atoms/Input/Input';
// Utils
import { formatDateToInput } from '../../../utils/dates';

function IndexDateVolVolJrList({ list, submit }) {
  // Hooks
  const { t } = useTranslation();
  const urlParams = useParams();

  const isEditing = urlParams.action === 'edit';

  const columns = [
    {
      label: t('inputs.meter_readings'),
    },
    {
      label: t('inputs.index'),
      width: 200,
    },
    {
      label: t('inputs.volume'),
      width: 160,
    },
    {
      label: t('inputs.succession_date'),
      width: 160,
    },
    {
      label: t('inputs.daily_volume'),
      width: 200,
    },
  ];
  const initializeValues = list.map((input) => ({
    ...input,
    isDirty: false,
    indexValue: null || (isEditing && input?.lastIndexValue),
    indexDate: null || (isEditing ? formatDateToInput(input?.lastIndexDate) : new Date()),
  }));

  const [numberDateValues, setNumberDatesValues] = useState(initializeValues);
  const handleNumberValues = (newValue) => {
    const newCommentValues = numberDateValues.map((radioNumberTimesValue) => {
      if (radioNumberTimesValue.id === newValue.id) return { ...newValue, isDirty: true };
      return radioNumberTimesValue;
    });
    setNumberDatesValues(newCommentValues);
  };

  const computeVolume = (input) => {
    if (input?.volume) return input.volume;
    const findInput = numberDateValues?.find((numberDateValue) => numberDateValue.id === input.id);
    if (!findInput) return '-';
    const diff = findInput.indexValue - input.lastIndexValue;
    if (input.lastIndexValue) {
      if (diff < 0) return `Index préc. : ${input.lastIndexValue}`;
      return diff;
    }
    return '';
  };

  const daysBetween = (date1, date2) => {
    if (!date1 || !date2) return null;
    let difference = new Date(date1).getTime() - new Date(date2).getTime();
    if (difference === 0) {
      difference = 1;
    }
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  const computeVolumeJr = (input) => {
    if (input?.diaryVolume) return input.diaryVolume;
    const findInput = numberDateValues.find((numberDateValue) => numberDateValue.id === input.id);
    if (!findInput) return '-';
    const volume = findInput.indexValue - input.lastIndexValue;
    const countDays = daysBetween(input.indexDate, findInput.lastIndexDate);
    if (input.numberOfResidents === 0) return '';
    if (input.lastIndexValue && input.lastIndexDate) {
      if (volume < 0 || findInput.indexDate === null || findInput.indexDate === false) return '-';
      return Math.round((volume / countDays / input.numberOfResidents) * 1000);
    }
    return '';
  };

  const handleValuesSubmit = () => {
    const valuesToSubmit = numberDateValues
      .filter((input) => input.isDirty)
      .map((numberTimeValue) => (
        {
          inputId: numberTimeValue.id,
          indexValue: numberTimeValue.indexValue,
          indexDate: formatDateToInput(numberTimeValue.indexDate),
          volume: typeof computeVolume(numberTimeValue) === 'number' ? computeVolume(numberTimeValue) : null,
          diaryVolume: typeof computeVolumeJr(numberTimeValue) === 'number' ? computeVolumeJr(numberTimeValue) : null,
          alertComment: numberTimeValue.alertComment,
          alert: numberTimeValue.alert,
          alertHelp: numberTimeValue.alertHelp,
        }
      ));
    submit(valuesToSubmit);
    setNumberDatesValues(initializeValues);
  };

  return (
    <ControlType columns={columns} onSubmit={handleValuesSubmit}>
      {numberDateValues?.map((input) => (
        <NumberDate
          id={input.id}
          label={input.title}
          key={input.id}
          value={input}
          onChange={handleNumberValues}
          min={input.lastIndexValue}
          brotherNumber={(
            <div className="inputForm_control inputForm_volume control">
              {window.innerWidth < 768 && (
              <div className="inputForm_control inputForm_label inputTitle">
                {t('inputs.index')}
              </div>
              )}
              <Input
                id={`volume-${input.id}`}
                name="volume"
                value={computeVolume(input)}
                responsive
                onChange={() => ''}
                label="Volume"
                labelHidden
                type="text"
                title={input.lastIndexValue ? `Index précédent : ${input.lastIndexValue}` : null}
                disabled
              />
            </div>
            )}
          brotherDate={(
            <div title={computeVolumeJr(input)} className="inputForm_control inputForm_volume control">
              {window.innerWidth < 768 && (
                <div className="inputForm_control inputForm_label inputTitle">
                  {t('inputs.daily_volume')}
                </div>
              )}
              <Input
                type="text"
                label="Volume Journalier"
                labelHidden
                responsive
                id={`volume-jr-${input.id}`}
                value={computeVolumeJr(input)}
                onChange={() => ''}
                title={input.lastIndexDate ? `Date précédente : ${input.lastIndexDate}` : null}
                disabled
              />
            </div>
            )}
        />
      ))}
    </ControlType>
  );
}

IndexDateVolVolJrList.propTypes = {
  list: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
};

export default IndexDateVolVolJrList;
