import api from './_api';

/**
 * GET - Get notification list
 * @param {{notificationId: Number, page:Number}} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getNotificationList = (queryParams) => api.get('/account/notifications', {
  params: queryParams,
});

/**
 * GET - Get notification detail
 * @param {Number} notificationId
 * @returns {Promise<AxiosResponse>}
 * @constructor
 */
export const GetNotificationDetail = (notificationId) => api.get(`/notifications/${notificationId}`);

/**
 * POST - Mark notification as read
 * @param {Number} notificationId
 * @returns {Promise<AxiosResponse>}
 * @constructor
 */
export const MarkNotificationAsRead = (notificationId) => api.post(`/account/notifications/${notificationId}:read`);

/**
 * GET - Get account 1  notification
 * @param {Number} notificationId
 * @returns {Promise<AxiosResponse>}
 * @constructor
 */
export const GetAccountNotification = (notificationId) => api.get(`/account/notifications/${notificationId}`);

/**
 * POST - Create notification
 * @param {Object} notification
 * @returns {Promise<AxiosResponse>}
 * @constructor
 */
export const CreateNotification = (notification) => api.post('/notifications', notification);

/**
 * get notification all list
 * @param {Object} notification
 * @returns {Promise<AxiosResponse>}
 * @constructor
 *
*/
export const GetNotificationAllList = (notification) => api.get('/notifications', {
  params: notification,
});

/**
 * delete notification
 * @param {Object} notification
 * @returns {Promise<AxiosResponse>}
 * @constructor
 *
 */
export const DeleteNotification = (notification) => api.delete(`/notifications/${notification}`);

/**
 * get 1 notification list
 * @param {Object} notificationid
 * @returns {Promise<AxiosResponse>}
 * @constructor
 */
export const GetNotification = (notificationid) => api.get(`/notifications/${notificationid}`);
