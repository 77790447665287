/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faChevronLeft, faPlus, faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useQuery, useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Button from '../../components/atoms/Button/Button';
import Search from '../../components/molecules/Search/Search';
import Layout from '../../components/template/Layout';
import styles from './analyses.module.css';
import cn from '../../utils/cn';
import useAppContext from '../../store/useAppContext';
import { deleteAnalyse, getAnalysesList, downloadAnalyse } from '../../services/analyses';
import { downloadFileAnalyse } from '../../utils/downloadFile';
import Permissions from '../../components/molecules/Permissions/Permissions';
import AnalyseList from '../../components/template/AnalyseList/AnalyseList';

function Analyse() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');
  const [context] = useAppContext();
  const [dowloadInfo, setDowloadInfo] = useState([]);

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const AnalysisList = useQuery(
    ['analyses', search],
    () => getAnalysesList({
      page: 0,
      search,
      establishmentId: context?.choiceEstablishment?.id,
      bookletId: context?.choiceBooklet,
    }),
    {
      onSuccess: (response) => {
        setDowloadInfo(response?.data?.analyses);
      },
    },
  );

  const deleteAnalysMutate = useMutation(deleteAnalyse, {
    onSuccess: () => {
      toast.success(t('analyses.delete_success'));
      setOpenModal(false);
      AnalysisList.refetch();
    },
  });

  const dowloaddoc = (id, name) => {
    window.open(`${process.env.REACT_APP_API_URL}analyses/download-document/${id}/${name}`, '_blank');
  };

  const downloadAnalysMutate = useMutation(downloadAnalyse, {
    onSuccess: (response) => {
      dowloaddoc(response.data);
    },
    onError: () => {
      toast.error(t('global.error_500'));
    },
  });
  const handleDelete = () => {
    setOpenModal(false);
    deleteAnalysMutate.mutate();
  };

  const displayPneumophila = (isPneumophila, quantity) => {
    if (quantity <= 10) {
      return 'non detectées';
    } else if (isPneumophila === true) {
      return 'Pneumophila';
    } else {
      return 'Non Pneumophila';
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
  };
  const hearder = [
    {
      key: 'id',
      label: t('analyses.id'),
    },
    { key: 'type', label: t('analyses.type') },
    {
      key: 'date',
      label: t('analyses.date'),
    },
    {
      key: 'name',
      label: t('analyses.analysePoint'),
    },
    {
      key: 'quantity',
      label: t('analyses.quantity'),
    },
    {
      key: 'potability',
      label: t('analyses.potability'),
    },
    {
      key: 'pneumophila',
      label: t('analyses.pneumophila'),
    },
  ];

  const dataToExport = dowloadInfo?.map((analyse) => (
    analyse?.analysisPoints?.map((point) => ({
      id: analyse.id,
      type: t(`analyses.${analyse.type}`),
      date: new Date(analyse?.samplingDate).toLocaleDateString(),
      ...(analyse.type === 'legionella'
        ? {
          quantity: point.quantity,
          potability: '',
          pneumophila: displayPneumophila(point.isPneumophila, point.quantity),
        }
        : {
          potability:
            point.valid !== null && point.valid !== true ? 'conforme' : 'non conforme',
          quantity: '',
          pneumophila: '',
        }),
      name: point.name,
      length: analyse?.analysisPoints?.length,
    }))
  ));

  const handleDowload = () => {
    downloadFileAnalyse(dataToExport, hearder, `${t('analyses.title')}`);
  };

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title={t('analyses.title')}
        layout="table"
        queryError={
          AnalysisList?.error
          || downloadAnalysMutate?.error
          || deleteAnalysMutate?.error
        }
      >
        <header className={styles.headers}>
          <div className={cn([styles.mb20, styles.rows])}>
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className={styles.rows}>
            <h1 className={styles.titles}>
              {t('analyses.title')}
            </h1>
            {Permissions({ permission: 'ANALYSIS_CREATE' }) !== undefined
              ? (
                <Link className={styles.adds} to="/addanalyse">
                  <FontAwesomeIcon icon={faPlus} />
                  <span className={styles.add_labels}>
                    {t('analyses.add')}
                  </span>
                </Link>
              ) : null }
          </div>
          <div className={styles.rows}>
            <button
              className={styles.adds}
              type="button"
              onClick={handleDowload}
            >
              <FontAwesomeIcon icon={faFileCsv} />
              {' '}
              {t('reports.download')}
            </button>
            <Search className={styles.mb20} onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.content}>
          <AnalyseList
            AnalysisList={AnalysisList}
            deleteOn={1}
          />
          <footer className="footer">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </footer>
        </div>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">
          {t('global.modal_content')}
        </p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}
export default Analyse;
