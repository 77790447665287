import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
// Components
import Input from '../../../atoms/Input/Input';
import Textarea from '../../../atoms/Textarea/Textarea';
import Button from '../../../atoms/Button/Button';

import styles from '../RadioNumberTime/RadioNumberTime.module.css';
import cn from '../../../../utils/cn';

function NumberDate({
  label, value, onChange, brotherNumber, brotherDate, id, min,
}) {
  const { t } = useTranslation();

  const [pop, setPop] = useState();
  const [remarque, setRemarque] = useState('');
  const [remarqueFinal, setRemarqueFinal] = useState();
  const EffectPop = useRef();
  const ColorRemarque = useRef();
  const [RemarqueNull, setRemarqueNull] = useState();
  const [titlePop, setTitlePop] = useState();
  const [Nb, setNb] = useState();

  const PopUp = (inputValue, oldValue) => {
    if (oldValue !== null) {
      setPop(true);
      setRemarqueNull();
      const title = `Attention, le relevé saisi [${inputValue}] est inférieur à l’ancien [${oldValue}]`;
      setTitlePop(title);
      setNb(inputValue);
    }
  };
  const PopEffect = () => {
    EffectPop.current.style.transform = 'scale(1.01)';
    setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
  };
  const ChangeValue = () => {
    setRemarqueFinal();
    setPop(false);
    setRemarque('');
    document.getElementById(`number-date-${id}`).focus();
  };
  const PopValid = (params) => {
    if (remarque !== '') {
      setRemarqueFinal(remarque);
      setPop(false);
    } else {
      EffectPop.current.style.transform = 'scale(1.01)';
      setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
      setRemarqueNull(true);
    }
    if (params === 'valid') {
      ColorRemarque.current.style.color = 'green';
    } else if (params === 'aide') {
      ColorRemarque.current.style.color = 'orange';
    }
  };

  return (
    <div>
      <div className="inputForm" style={{ borderBottom: remarqueFinal && 'none' }}>
        <div className="inputForm_control inputForm_label">
          {label}
        </div>
        <div className="inputForm_control inputForm_input control">
          {window.innerWidth < 768 && (
            <div className="inputForm_control inputForm_label inputTitle">
              {t('inputs.meter_readings')}
            </div>
          )}
          <Input
            id={`number-date-${id}`}
            type="number"
            labelHidden
            responsive
            value={value.indexValue}
            onChange={(e) => onChange({ ...value, indexValue: e.target.value })}
            onBlur={(e) => {
              if (e.target.value < min && e.target.value !== '') {
                PopUp(e.target.value, min);
              }
            }}
            required={!!value.isDirty}
          />
        </div>
        {brotherNumber || null}
        <div className="inputForm_control inputForm_date control">
          {window.innerWidth < 768 && (
            <div className="inputForm_control inputForm_label inputTitle">
              {t('inputs.stabRecordDate')}
            </div>
          )}
          <Input
            type="date"
            labelHidden
            responsive
            value={value.indexDate
              ? format(new Date(value.indexDate), 'yyyy-MM-dd')
              : new Date(Date.now()).toISOString().split('T')[0]}
            onChange={(e) => onChange({ ...value, indexDate: e.target.value })}
            required={!!value.isDirty}
            min="2000-01-01"
            max="2099-12-31"
          />
        </div>
        {brotherDate || null}
      </div>
      {!remarqueFinal ? (
        <div className={styles.remarque} style={{ display: value.alertComment ? 'flex' : 'none' }}>
          <div className="inputForm_control inputForm_label">
            {'Commentaire de l\'alerte'}
          </div>
          <div className={styles.remarque} style={{ borderBottom: '0' }}>
            <p style={{ color: '#28337e' }}>{value.alertComment}</p>
          </div>
        </div>
      ) : null }
      <div style={{ display: remarqueFinal ? '' : 'none' }}>
        <div className={styles.remarque}>
          <p ref={ColorRemarque}>{remarqueFinal}</p>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => { PopUp(Nb, min); }}
            className={styles.icon}
          />
        </div>
      </div>
      <div className={styles.pop_up} style={{ display: pop ? '' : 'none' }} ref={EffectPop}>
        <h3 className={styles.title_popUp}>{titlePop}</h3>
        <div className={styles.textarea_popUp}>
          <Textarea
            style={RemarqueNull && {
              border: 'red solid 1px',
            }}
            id="remarque"
            name="remarque"
            label={t('alert.remarque')}
            placeholder={RemarqueNull ? 'Veuillez indiquer une remarque..' : ''}
            value={remarque}
            onChange={(e) => { setRemarque(e.target.value); }}
          />
        </div>
        <div className={styles.div_button}>
          <Button
            type="button"
            label={t('alert.valider')}
            onClick={() => {
              PopValid('valid');
              onChange({
                ...value,
                alert: true,
                alertComment: remarque,
                alertHelp: false,
              });
            }}
            className={cn([styles.btn_popUp, styles.btn_blue])}
          />
          <Button
            type="button"
            label={t('alert.aide')}
            onClick={() => {
              PopValid('aide');
              onChange({
                ...value,
                alert: true,
                alertComment: remarque,
                alertHelp: true,
              });
            }}
            className={cn([styles.btn_popUp, styles.btn_orange])}
          />
          <Button
            type="button"
            label={t('alert.change_value')}
            onClick={() => { ChangeValue(); }}
            className={cn([styles.btn_popUp, styles.btn_red])}
          />
        </div>
      </div>
      <div
        className={styles.back_popUp}
        style={{ display: pop ? '' : 'none' }}
        role="button"
        onClick={() => { PopEffect(); }}
        onKeyUp={() => {}}
        tabIndex={0}
      >
        {' '}
      </div>
    </div>
  );
}

NumberDate.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.objectOf({
    indexValue: PropTypes.number,
    indexDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  brotherNumber: PropTypes.element,
  brotherDate: PropTypes.element,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.number,
};

NumberDate.defaultProps = {
  brotherNumber: null,
  brotherDate: null,
  min: null,
};

export default NumberDate;
