import api from './_api';

/**
 * GET - Get Controls list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getControlsList = (queryParams) => api.get('/controls', { params: queryParams });

/**
 * GET - Get Control
 * @param {Number} controlId
 * @returns {Promise<AxiosResponse>}
 */
export const getControl = (controlId) => api.get(`/controls/${controlId}`);

/**
 * POST - Create Control
 * @param {{name:String, typeId:Number, interventionType:String, inputType:String}} controlToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postControl = (controlToCreate) => api.post('/controls', controlToCreate);

/**
 * PUT - Edit Control
 * @param {{id:Number, name:String, typeId:Number, interventionType:String, inputType:String}} controlToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editControl = (controlToEdit) => api.put(`/controls/${controlToEdit.id}`, controlToEdit);

/**
 * DELETE - Delete Control
 * @param {Number} controlId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteControl = (controlId) => api.delete(`/controls/${controlId}`);
