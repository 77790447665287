/* eslint-disable max-len */
import api from './_api';

/**
 * GET - Get Contracts list
 * @param {Number: page, Number: structureId} pageId
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
*/
export const getContractsList = (queryParams) => api.get('/contracts', { params: queryParams });

/**
 * GET - Get Contracts Vehicle list
 * @param {Number: page, Number: structureId} pageId
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
*/
export const getContractsVehicleList = (queryParams) => api.get('/vehiclesContracts', { params: queryParams });

/**
 * POST - Create Contract
 * @param {Object} contractToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const createContract = (contractToCreate) => api.post('/contracts', contractToCreate);

/**
 * POST - Create Contract Vehicle
 * @param {Object} contractToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const createContractVehicle = (contractToCreate) => api.post('/vehiclesContracts', contractToCreate);

/**
 * GET - Get Contract
 * @param {Number} contractId
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
 */
export const getContract = (contractId) => api.get(`/contracts/${contractId}`);

/**
 * GET - Get Contract
 * @param {Number} contractId
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
 */
export const getContractVehicle = (contractId) => api.get(`/vehiclesContracts/${contractId.id}`);

/**
 * GET - Get Document Contract
 * @param {Number} contractId
 * @returns {Promise<AxiosResponse>}
 */
export const getContractDocument = (contractId) => api.get(`/contracts/${contractId}/download-file`);

/**
 * GET - Get Document Contract Vehicle
 * @param {Number} contractId
 * @returns {Promise<AxiosResponse>}
 */
export const getContractDocumentVehicle = (contractId) => api.get(`/vehiclesContracts/${contractId}/download-file`);

/**
 * POST - End Contract
 * @param {Object} endContract
 * @returns {Promise<AxiosResponse>}
 */
export const endContractVehicle = (endContract) => api.post(`/vehiclesContracts/${endContract.id}/${endContract.enabled}`);

/**
 * PUT - Update Contract
 * @param {Object} contractToUpdate
 * @returns {Promise<AxiosResponse>}
 */
export const updateContract = (contractToUpdate) => api.put(`/contracts/${contractToUpdate.id}`, contractToUpdate.data);

/**
 * PUT - Update Contract Vehicle
 * @param {Object} contractToUpdate
 * @returns {Promise<AxiosResponse>}
 */
export const updateContractVehicle = (contractToUpdate) => api.put(`/vehiclesContracts/${contractToUpdate.id}`, contractToUpdate.data);
/**
 * DELETE - Delete Contract
 * @param {Number} contractId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteContract = (contractId) => api.delete(`/contracts/${contractId}`);

/**
 * DELETE - Delete Contract Vehicle
 * @param {Number} contractId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteContractVehicle = (contractId) => api.delete(`/vehiclesContracts/${contractId}`);

/**
 * get- doc contract
 * @param {Number} contractId
 * @returns {Promise<AxiosResponse>}
 */
export const getDocContract = (contractId) => api.get(`/contracts/${contractId}/download-file`);

/**
 * get- doc contract
 * @returns {Promise<AxiosResponse>}
 */
export const getRewardContract = () => api.get('/contracts/renewal');
