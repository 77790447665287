import React from 'react';
import PropTypes from 'prop-types';

// components
import EtatPneuset from './EtatPneuset';

function EtatPneux({ list, submit }) {
  // Hooks

  const initialList = list?.map((input) => ({ ...input, isDirty: false }));

  const handleValuesSubmit = (genValueSubmited) => {
    submit([genValueSubmited]);
  };

  return initialList.map((input) => (
    <EtatPneuset
      key={input.id}
      id={input.id}
      label={input.title}
      value={input}
      handleSubmit={handleValuesSubmit}
    />
  ));
}

EtatPneux.propTypes = {
  list: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
};

export default EtatPneux;
