import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { sortBy } from 'lodash';
// Components
import cn from '../../../../utils/cn';
import Input from '../../../atoms/Input/Input';
import UploadFile from '../../../atoms/UploadFile/UploadFile';
import styles from './DateSelectUploadNumber.module.css';
import Select from '../../../atoms/Select/Select';
import Textarea from '../../../atoms/Textarea/Textarea';
import Button from '../../../atoms/Button/Button';
import { useAppContext } from '../../../../store/AppContext';

function DateSelectUploadNumber({
  id, label, value, onChange,
}) {
  const { t } = useTranslation();
  const urlParams = useParams();
  const [pop, setPop] = useState();
  const [remarqueFinal, setRemarqueFinal] = useState();
  const EffectPop = useRef();
  const ColorRemarque = useRef();
  const [titlePop, setTitlePop] = useState();
  const [Nb, setNb] = useState();
  const [context] = useAppContext();
  const navigate = useNavigate();
  const handleFileChange = (newFile) => {
    onChange({ ...value, file: newFile });
  };
  const PopUp = (nb, min) => {
    // setRemarqueNull();
    let title;
    if (context.choiceBooklet !== 3 && min !== null) {
      setPop(true);
      title = `Attention, le relevé saisi [${nb}] est inférieur à l’ancien [${min}]`;
    } else if (context.choiceBooklet === 3 && min !== null) {
      setPop(true);
      title = `Attention, le relevé saisi [${nb}] est inférieur à l’ancienne valeur [${min}]`;
    }
    setTitlePop(title);
    setNb(nb);
  };
  const PopEffect = () => {
    EffectPop.current.style.transform = 'scale(1.01)';
    setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
  };
  const ChangeValue = () => {
    setRemarqueFinal();
    setPop(false);
    document.getElementById(`number-${id}`).focus();
  };

  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: item.label,
      value: item.value,
    }))
    : []);

  const CT = [
    { label: 'Favorable', value: 'Fav' },
    { label: 'Défavorable contre-visite nécessaire sous 2 mois', value: 'S' },
    { label: 'Défavorable Véhicule immobilisé, contre visite nécessaire sous 2 mois', value: 'R' },
  ];

  return (
    <div>
      <div className={cn(['inputForm', styles.height])}>
        <div className="inputForm_control inputForm_label" style={{ fontSize: '1.25rem' }}>
          {label}
        </div>
      </div>
      <div className={cn(['inputForm', styles.height])}>
        <div className={cn(['inputForm_control inputForm_label', styles.width])}>
          {t('inputs.date')}
        </div>
        <div className={cn(['inputForm_control inputForm_date control'])}>
          <Input
            id={`date-${id}`}
            type="date"
            labelHidden
            responsive
            value={value.date || new Date(value.indexDate).toISOString().split('T')[0]}
            onChange={(e) => onChange({ ...value, date: e.target.value })}
            required
            min="2000-01-01"
            max="2099-12-31"
          />
        </div>
      </div>
      <div className={cn(['inputForm', styles.height])}>
        <div className={cn(['inputForm_control inputForm_label', styles.width])}>
          {t('inputs.resultCT')}
        </div>
        {/* <br /> */}
        <div className={cn(['inputForm_control inputForm_date control'])}>
          <Select
            id={`resultCT-${id}`}
            type="text"
            labelHidden
            value={
              mapForSelect(CT).find(
                (item) => item.value === value.result,
              )
            }
            onChange={(e) => onChange({ ...value, result: e.value })}
            options={mapForSelect(CT)}
            // required
            valueInput={value.result}
          />
        </div>
      </div>
      <div className={cn(['inputForm', styles.height])}>
        <div className={cn(['inputForm_control inputForm_label control', styles.width])}>
          {t('inputs.file')}
        </div>
        <div className={cn(['inputForm_control, inputForm_control--center', styles.UploadFile])}>
          <UploadFile
            id={`file-${id}`}
            labelHidden
            fileName={value?.file ? value.file.name : value.file || value.attachment}
            onChange={(file) => handleFileChange(file.currentTarget.files[0])}
            required={urlParams.action !== 'edit'}
            enableDragDrop={false}
          />
        </div>
      </div>
      <div className={cn(['inputForm', styles.height])}>
        <div className={cn(['inputForm_control inputForm_label', styles.width])}>
          {t('inputs.km')}
        </div>
        {urlParams.action !== 'edit' && (
          <div style={{ color: 'grey' }}>
            {value.mileageIndex && `Ancienne valeur : ${value.mileageIndex}`}
          </div>
        )}
        <div className="inputForm_control inputForm_input control">
          <Input
            id={`number-${id}`}
            type="number"
            labelHidden
            responsive
            value={urlParams.action === 'edit' && !value.number ? value.mileageIndex : value.number}
            onChange={(e) => onChange({ ...value, number: e.target.value })}
            onBlur={(e) => {
              if (e.target.value < value?.mileageIndex && e.target.value !== '') {
                PopUp(e.target.value, value?.mileageIndex);
              }
            }}
            min="0"
            required
          />
        </div>
      </div>
      <div className={cn(['inputForm', styles.height])}>
        <div className={cn(['inputForm_control inputForm_label', styles.width])}>
          {t('inputs.comment')}
        </div>
        <div className="inputForm_control inputForm_control--center control">
          <Textarea
            id={`comment-${id}`}
            className={styles.Textarea}
            type="text"
            labelHidden
            value={value.comment}
            onChange={(e) => onChange({ ...value, comment: e.target.value })}
            required
          />
        </div>
      </div>
      {!remarqueFinal ? (
        <div className={styles.remarque} style={{ display: value.alertComment ? 'flex' : 'none' }}>
          <div className="inputForm_control inputForm_label">
            {'Commentaire de l\'alerte'}
          </div>
          <div className={styles.remarque} style={{ borderBottom: '0' }}>
            <p style={{ color: '#28337e' }}>{value.alertComment}</p>
          </div>
        </div>
      ) : null }
      <div style={{ display: remarqueFinal ? '' : 'none' }}>
        <div className={styles.remarque}>
          <p ref={ColorRemarque}>{remarqueFinal}</p>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => { PopUp(Nb, value?.mileageIndex); }}
            className={styles.icon}
          />
        </div>
      </div>
      <div className={styles.pop_up} style={{ display: pop ? '' : 'none' }} ref={EffectPop}>
        <h3 className={styles.title_popUp}>{titlePop}</h3>
        <div className={styles.div_button}>
          {value.lastInputId !== null ? (
            <Button
              type="button"
              label={t('alert.change_value_historique')}
              onClick={() => { navigate(`/saisies/historique/edit/${value.lastInputId}`); }}
              className={cn([styles.btn_popUp, styles.btn_blue])}
            />
          ) : null}
          <Button
            type="button"
            label={t('alert.change_value')}
            onClick={() => { ChangeValue(); }}
            className={cn([styles.btn_popUp, styles.btn_red])}
          />
        </div>
      </div>
      <div
        className={styles.back_popUp}
        style={{ display: pop ? '' : 'none' }}
        role="button"
        onClick={() => { PopEffect(); }}
        onKeyUp={() => {}}
        tabIndex={0}
      >
        {' '}
      </div>
    </div>
  );
}

DateSelectUploadNumber.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  value: PropTypes.shape({
    date: PropTypes.string,
    number: PropTypes.number,
    file: PropTypes.string,
    result: PropTypes.string,
    comment: PropTypes.string,
    mileageIndex: PropTypes.number,
    lastInputId: PropTypes.number,
    alertComment: PropTypes.string,
    indexDate: PropTypes.string,
    attachment: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

DateSelectUploadNumber.defaultProps = {
};

export default DateSelectUploadNumber;
