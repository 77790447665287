/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

// styles
import styles from './EstablishmentCardChoice.module.css';

// utils & misc
import cn from '../../../../utils/cn';

function EstablishmentCardChoice({
  establishment, onClick, texte, commission,
}) {
  return (
    <button
      type="button"
      onClick={() => onClick && onClick(establishment)}
      className={cn([styles.card, 'shadow-sm'])}
    >
      <h3 className={
        commission
          ? cn([styles.green, 'title'])
          : commission === false
            ? cn([styles.red, 'title'])
            : cn([styles.headerCard, 'title'])
       }
      >
        {establishment.name}
      </h3>
      <div className={styles.bodyCard}>
        <p className={cn([styles.texteCard])}>
          {texte}
        </p>
      </div>
    </button>
  );
}

EstablishmentCardChoice.propTypes = {
  establishment: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  texte: PropTypes.object.isRequired,
  commission: PropTypes.string,
};

function emptyFunc() {}
EstablishmentCardChoice.defaultProps = {
  onClick: emptyFunc,
  commission: '',
};

export default EstablishmentCardChoice;
