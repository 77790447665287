import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Button from '../../components/atoms/Button/Button';
// Services
import {
  getEquipmentFamily,
  postEquipmentFamilyInformations,
  editEquipmentFamilyInformations,
} from '../../services/equipmentFamilies';
import { getEquipmentsList } from '../../services/equipments';
import useAppContext from '../../store/useAppContext';

const initialValues = {
  name: '',
  equipmentIds: [],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.required_field'),
  equipmentIds: Yup.array().min(1, 'global.required_field'),
});

function AddEquipmentFamily() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [context] = useAppContext();
  // States
  const [equipments, setEquipments] = useState([]);
  // API calls
  const getEquipmentsListQuery = useQuery('equipments', () => getEquipmentsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setEquipments(data.data.equipments);
    },
  });
  const postEquipmentFamilyInformationsMutation = useMutation(postEquipmentFamilyInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_equipment_family.equipment_family_added'));
    },
  });
  const editEquipmentFamilyInformationsMutation = useMutation(editEquipmentFamilyInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_equipment_family.equipment_family_edited'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      if (urlParams && urlParams.action === 'edit') {
        editEquipmentFamilyInformationsMutation.mutate(formValues);
      } else {
        postEquipmentFamilyInformationsMutation.mutate(formValues);
      }
    },
  });

  const getEquipmentFamilyQuery = useQuery('equipment-family', () => getEquipmentFamily(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues(data.data);
    },
  });

  const handleCheckboxClick = (fieldName, fieldValue, isChecked) => formik.setFieldValue(
    fieldName,
    isChecked
      ? formik.values[fieldName].concat(fieldValue)
      : formik.values[fieldName].filter((id) => id !== fieldValue),
  );
  return (
    <Layout
      title={t('add_equipment_family.title')}
      queryError={
        postEquipmentFamilyInformationsMutation?.error
        || editEquipmentFamilyInformationsMutation?.error
        || getEquipmentsListQuery?.error
        || getEquipmentFamilyQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_equipment_family.back_to_equipment_family')}</span>
          </button>
        </div>
        <h1 className="title">
          {urlParams && urlParams.action === 'edit'
            ? t('add_equipment_family.edit_title')
            : t('add_equipment_family.title')}
        </h1>
      </header>
      <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
        <div className="form_group">
          <Input
            id="name"
            type="text"
            name="name"
            label={t('add_equipment_family.name')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.errors.name && formik.touched.name ? (
            <div className="error">
              {t(formik.errors.name)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('add_equipment_family.equipments')}</div>
          {sortBy(equipments, ['name']).map((equipment) => (
            <div>
              <Checkbox
                id={`equipment-${equipment.id}`}
                name="equipmentIds[]"
                label={equipment.name}
                onChange={(event) => handleCheckboxClick('equipmentIds', equipment.id, event.target.checked)}
                onBlur={formik.handleBlur}
                checked={formik.values.equipmentIds?.includes(equipment.id)}
                value={equipment.id}
              />
            </div>
          ))}
          {formik.errors.equipmentIds && formik.touched.equipmentIds ? (
            <div className="error">
              {t(formik.errors.equipmentIds)}
            </div>
          ) : null }
        </div>
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('add_equipment_family.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('add_equipment_family.submit')}
            isLoading={postEquipmentFamilyInformationsMutation.isLoading}
          />
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('add_equipment_family.back_to_equipment_family')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddEquipmentFamily;
