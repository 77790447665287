/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from 'react-query';
import {
  GetVersion,
  appVersion,
  appDate,
  getVersionsFront,
} from '../../services/version';
import Layout from '../../components/template/Layout';

function Version({ components }) {
  const getVersion = useQuery(['back'], () => GetVersion());
  const getVersionFront = useQuery(['front'], () => getVersionsFront());
  if (components === 'true') {
    return (
      <div>
        <span>
          Version applicative :
          {appVersion}
          -
          {appDate}
        </span>
        <br />
        <span>
          Version serveur :
          {getVersion.data?.data?.version}
          -
          {getVersion.data?.data?.date}
        </span>
      </div>
    );
  }
  return (
    <Layout
      title="test"
      layout="form"
    >
      <div>
        <span>
          Version applicative :
          {appVersion}
          -
          {appDate}
        </span>
        <br />
        <span>
          Version front serveur :
          {getVersionFront.data?.data?.version}
          -
          {getVersionFront.data?.data?.date}
        </span>
        <br />
        <span>
          Version serveur :
          {getVersion.data?.data?.version}
          -
          {getVersion.data?.data?.date}
        </span>
      </div>
    </Layout>
  );
}

Version.defaultProps = {
  components: false,
};
export default Version;
