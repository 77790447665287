import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Services
import {
  editsafetyLightingEquipment,
  getSafetyLightingEquipment,
  postSafetyLightingEquipment,
} from '../../services/safetyLightingEquipments';

// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import SelectForm from '../../components/atoms/Select/Select';
import Button from '../../components/atoms/Button/Button';
import useAppContext from '../../store/useAppContext';

const initialValues = {
  blockNumber: null,
  location: null,
  equipmentFunction: null,
};

function AddEquipmentLightningSecurity() {
  const { t } = useTranslation();
  const urlParams = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [context] = useAppContext();

  const isEditing = urlParams.action === 'edit';
  const isViewing = urlParams.action === 'view';
  const isCreating = !isEditing && !isViewing;

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const validationSchema = Yup.object().shape({
    blockNumber: Yup.string().typeError(t('global.wrong_type')).required('global.required_field'),
    location: Yup.string().typeError(t('global.wrong_type')).required('global.required_field'),
    equipmentFunction: Yup.string().typeError(t('global.wrong_type')).required('global.required_field'),
  });

  const postSafetyEquipmentMutation = useMutation(postSafetyLightingEquipment, {
    onSuccess: () => goBackUrl(t('add_safety_lighting_equipment.added')),
  });
  const editSafetyEquipmentMutation = useMutation(editsafetyLightingEquipment, {
    onSuccess: () => {
      goBackUrl(t('add_safety_lighting_equipment.edited'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      const valuesToSave = {
        ...formValues,
        interventionId: state.interventionId,
        function: formValues.equipmentFunction,
      };
      if (isEditing) {
        editSafetyEquipmentMutation.mutate(valuesToSave);
      } else {
        postSafetyEquipmentMutation.mutate(valuesToSave);
      }
    },
  });

  const safetyLightingEquipmentQuery = useQuery(
    ['safetyLightingEquipment'],
    () => getSafetyLightingEquipment(urlParams.id),
    {
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
      enabled: !!urlParams?.id,
      onSuccess: (data) => {
        formik.setValues(
          {
            ...data.data,
            equipmentFunction: data?.data?.function?.toLowerCase(),
          },
        );
      },
    },
  );

  const functionsList = [
    {
      label: t('add_safety_lighting_equipment.ambiance'),
      value: 'ambient',
    },
    {
      label: t('add_safety_lighting_equipment.evacuation'),
      value: 'evacuation',
    },
    {
      label: t('add_safety_lighting_equipment.telecommande'),
      value: 'remote_control',
    },
    {
      label: t('add_safety_lighting_equipment.bapi'),
      value: 'bapi',
    },
  ];

  return (
    <Layout
      title={isCreating
        ? t('add_safety_lighting_equipment.title_create')
        : t('add_safety_lighting_equipment.title_edit')}
      queryError={safetyLightingEquipmentQuery?.error
        || postSafetyEquipmentMutation?.error
        || editSafetyEquipmentMutation?.error}
      layout="table"
    >
      <header>
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_safety_lighting_equipment.back_to_intervention')}</span>
          </button>
        </div>
        <h1 className="title">
          {isCreating ? t('add_safety_lighting_equipment.title_create') : t('add_safety_lighting_equipment.title_edit')}
        </h1>
      </header>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          <Input
            id="blockNumber"
            name="blockNumber"
            type="text"
            label={t('add_safety_lighting_equipment.input_block_number')}
            value={formik.values.blockNumber}
            onChange={(e) => formik.setFieldValue('blockNumber', e.target.value)}
          />
          {formik.errors.blockNumber && formik.touched.blockNumber ? (
            <div className="error">
              {t(formik.errors.blockNumber)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            id="location"
            name="location"
            type="text"
            label={t('add_safety_lighting_equipment.input_location')}
            value={formik.values.location}
            onChange={(e) => formik.setFieldValue('location', e.target.value)}
          />
          {formik.errors.location && formik.touched.location ? (
            <div className="error">
              {t(formik.errors.location)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <SelectForm
            id="building"
            label={t('add_safety_lighting_equipment.input_function')}
            options={functionsList}
            value={
              functionsList.find((equipmentFunction) => equipmentFunction.value === formik.values.equipmentFunction)
            }
            onChange={(option) => formik.setFieldValue('equipmentFunction', option.value)}
            disabled={isViewing}
          />
          {formik.errors.equipmentFunction && formik.touched.equipmentFunction ? (
            <div className="error">
              {t(formik.errors.equipmentFunction)}
            </div>
          ) : null }
        </div>
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('add_safety_lighting_equipment.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('add_safety_lighting_equipment.submit')}
            isLoading={editSafetyEquipmentMutation.isLoading || postSafetyEquipmentMutation.isLoading}
          />
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('add_safety_lighting_equipment.back_to_intervention')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddEquipmentLightningSecurity;
