import api from './_api';

/**
 * GET - Get calendar list
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
*/
export const getCalendarList = (queryParams) => api.get('/calendar', { params: queryParams });

/**
 * GET - Get past calendar
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
*/
export const getPastCalendar = (queryParams) => api.get('/pastCalendar', { params: queryParams });
