import api from './_api';

/**
 * GET - Get Control Types list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getControlTypesList = (queryParams) => api.get('/control-types', { params: queryParams });

/**
 * GET - Get Control Type
 * @param {Number} controlTypeId
 * @returns {Promise<AxiosResponse>}
 */
export const getControlType = (controlTypeId) => api.get(`/control-types/${controlTypeId}`);

/**
 * POST - Create Control Type
 * @param {{name:String, controlPeriod:String, visibility:String, displayOrder:Number}} controlTypeToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postControlType = (controlTypeToCreate) => api.post('/control-types', controlTypeToCreate);

/**
 * PUT - Edit Control Type
 * @param {Number} controlTypeId
 * @param {{id:Number, name:String, controlPeriod:String, visibility:String, displayOrder:Number}} controlTypeToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editControlType = (controlTypeToEdit) => api
  .put(`/control-types/${controlTypeToEdit.id}`, controlTypeToEdit);

/**
 * DELETE - Delete Control Type
 * @param {Number} controlTypeId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteControlType = (controlTypeId) => api.delete(`/control-types/${controlTypeId}`);
