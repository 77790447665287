import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import ProgressBar from '../../../atoms/ProgressBar/ProgressBar';

function ProgressLink({ label, value }) {
  // Hooks
  const { t } = useTranslation();

  return (
    <div className="inputForm">
      <div className="inputForm_control inputForm_label">
        {label}
      </div>
      <div className="inputForm_control inputForm_progress">
        {
          value.valueMax
            ? <ProgressBar value={value.value} valueMax={value.valueMax} sle />
            : <div className="add_label">-</div>
        }
      </div>
      <div className="inputForm_control inputForm_link">
        <Link to={value.link} className="link">{t('inputs.see_equipments')}</Link>
      </div>
    </div>
  );
}

ProgressLink.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
};

export default ProgressLink;
