import React from 'react';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { updateVacation } from '../../services/vacation';
import { getBuilding } from '../../services/structures';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import styles from './addvacation.module.css';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
import { formatDateToInput } from '../../utils/dates';

function AddVacation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();

  const initialValues = {
    startDate: '',
    endDate: '',
  };

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const validationSchema = Yup.object({
    startDate: Yup.string(),
    endDate: Yup.string(),
  });
  const updateVacationMutation = useMutation(updateVacation, {
    onSuccess: () => {
      goBackUrl(t('vacantion.updated'));
    },
    onError: () => {
      toast.error(t('vacantion.error'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateVacationMutation.mutate({
        id: values.id,
        buildingVacations: [{
          buildingId: values.buildingId,
          startDate: values.startDate,
          endDate: values.endDate,
        }],
      });
    },
  });
  const formatDateForInput = (dateString) => {
    const [day, month, year] = dateString.split(' ')[0].split('-');
    return `${year}-${month}-${day}`;
  };
  const getBuildingQuery = useQuery(['establishment', urlParams.id], () => getBuilding(urlParams.id), {
    onSuccess: (data) => {
      formik.setFieldValue('id', data?.data?.establishmentId);
      formik.setFieldValue('buildingId', data?.data?.id);
      formik.setFieldValue('startDate', formatDateForInput(data.data.vacationStartDate));
      formik.setFieldValue('endDate', formatDateForInput(data.data.vacationEndDate));
    },
  });

  const calcule = (startdate, enddate) => {
    const startDate = new Date(startdate);
    const endDate = new Date(enddate);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  return (
    <Layout
      title="Vacation"
      layout="table"
      queryError={
        updateVacationMutation?.error
        || getBuildingQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('vacantion.return')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">{t('vacantion.add')}</h1>
        </div>
      </header>
      <div className="title">
        <div>
          {' '}
          <div>
            <div>
              {formik.values.startDate && formik.values.endDate ? (
                <div className="form shadow">
                  {calcule(formik.values.startDate, formik.values.endDate)}
                  {' '}
                  {t('vacantion.days')}
                </div>
              ) : null}
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="form shadow"
            >
              <div className="form_group">
                <Input
                  id="startDate"
                  type="date"
                  name="startDate"
                  label={t('vacantion.start_date')}
                  value={formik.values.startDate}
                  onChange={(e) => {
                    formik.setFieldValue('startDate', e.target.value);
                  }}
                  min={formatDateToInput(new Date())}
                  max="2099-12-31"
                  required
                />
                {formik.errors.startDate && formik.touched.startDate ? (
                  <div className="error">
                    {t(formik.errors.startDate)}
                  </div>
                ) : null }
              </div>
              <div className="form_group">
                <Input
                  id="endDate"
                  type="date"
                  name="endDate"
                  label={t('vacantion.end_date')}
                  value={formik.values.endDate}
                  onChange={(e) => {
                    formik.setFieldValue('endDate', e.target.value);
                  }}
                  min={formik.values.startDate}
                  max="2099-12-31"
                  required
                />
                {formik.errors.endDate && formik.touched.endDate ? (
                  <div className="error">
                    {t(formik.errors.endDate)}
                  </div>
                ) : null }
              </div>
              <br />
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                </div>
                <Button
                  type="submit"
                  className="form_submit"
                  label={t('vacantion.save')}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('vacantion.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default AddVacation;
