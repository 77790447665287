export const USERS_ROLES = {
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  ADMIN_GROUP: 'ROLE_ADMIN_GROUP',
  USER: 'ROLE_USER',
};

export const USERS_JOBS = {
  RECEPTION: 'reception',
  TECHNICAL_EXPERT: 'technical_expert',
  TECHNICAL_MANAGER: 'technical_manager',
  MANAGER: 'manager',
  AUTHORITIES: 'authorities',
  AUDITOR_MANAGER: 'auditor_manager',
};

export const CONFIGURATION = {
  EQUIPMENTS_LINK: '/equipements',
  EQUIPMENTS_PICTO: 'equipements',
  EQUIPMENTS_FAMILIES_LINK: '/equipements-familles',
  EQUIPMENTS_FAMILIES_PICTO: 'equipements',
  CONTROLS_LINK: '/controles',
  CONTROLS_PICTO: 'settings',
  CONTROLS_TYPES_LINK: '/controles-types',
  CONTROLS_TYPES_PICTO: 'settings',
  BUILDINGS_TEMPLATES_LINK: '/batiments-modeles',
  BUILDINGS_TEMPLATES_PICTO: 'etablissements',
  CRON_LINK: '/cron',
  CRON_PICTO: 'settings',
};

export const STRUCTURES = {
  STRUCTURES_LINK: '/structures',
  STRUCTURES_PICTO: 'etablissements',
};

export const ESTABLISHMENTS = {
  ESTABLISHMENTS_LINK: '/etablissements',
  ESTABLISHMENTS_PICTO: 'etablissements',
};

export const USERS = {
  USERS_LINK: '/users',
  USERS_PICTO: 'users',
};

export const INTERVENTIONS = {
  TRAININGS_LINK: '/formations',
  TRAININGS_PICTO: 'trainings',
  INTERVENTIONS_LINK: '/interventions',
  INTERVENTIONS_PICTO: 'interventions',
  RAPPORT_LINK: '/rapports',
  RAPPORT_PICTO: 'depotrapport',
  ARCHIVE_LINK: '/archives',
  ARCHIVE_PICTO: 'archives',
  DAIRY_LINK: '/Dairy',
  DAIRY_PICTO: 'contrats',
  ANALYSE_LINK: '/analyse',
  ANALYSE_PICTO: 'synthese',
  REPORT_LINK: '/rapports',
  REPORT_PICTO: 'depotrapport',
  DOCUMENT_LINK: '/depot_document',
  DOCUMENT_PICTO: 'documentaire',
  CALENDAR_LINK: '/calendar',
  CALENDAR_PICTO: 'calendrier',
  PRESTATAIRE_LINK: '/society',
  PRESTATAIRE_PICTO: 'prestataires',
  CONTRACT_VEHICULE_LINK: '/contratsVehicule',
  CONTRACT_VEHICULE_PICTO: 'contrats',
};

export const INTERVENTIONSVEHICULE = {
  TRAININGS_LINK: '/formations',
  TRAININGS_PICTO: 'trainings',
  INTERVENTIONS_LINK: '/interventions',
  INTERVENTIONS_PICTO: 'interventions',
  RAPPORT_LINK: '/rapport',
  RAPPORT_PICTO: 'depotrapport',
  ARCHIVE_LINK: '/archives',
  ARCHIVE_PICTO: 'archives',
  DOC_ATTENTE: '/vehicule/document_attente',
  DAIRY_LINK: '/journal',
  DAIRY_PICTO: 'journal',
};

export const INPUTS = {
  INPUTS_LINK: '/saisies',
  INPUTS_PICTO: 'inputs',
};

export const TRAINING_TYPES = {
  FIRE_SAFETY_PREVENTION: 'fire_safety_prevention',
  MAINTENANCE_MANAGER: 'maintenance_manager',
};

export const TRAINING_NAMES = {
  HANDLING_EMERGENCY_MEANS: 'handling_emergency_means',
  EVACUATION: 'evacuation',
  DESIGNATED_EMPLOYEES_EVACUATION: 'designated_employees_evacuation',
  UNANNOUNCED_EXERCISE: 'unannounced_exercise',
  SSI: 'ssi',
  INTERNAL: 'internal',
  SSIAP: 'ssiap',
  SST: 'sst',
  ELECTRICAL_CLEARANCE: 'electrical_clearance',
};

export const BOOKLET_NAME = {
  SECURITY: 'Sécurité',
  SANITARY: 'Sanitaire',
  VEHICLE: 'Véhicule',
};

export const STRUCTURES_NAME = {
  GROUP: 'Group',
  SUBSIDIARY: 'Subsidiary',
  DIVISION: 'Division',
};

export const CONTROL_PERIOD = {
  MONDAY_BEFORE: 'monday_before',
  CONTROL_DATE: 'control_date',
  PERMANENTLY: 'permanently',
};

export const VISIBILITY = {
  UNIQUE: 'unique',
  ONE_WEEK: 'one_week',
  WHOLE_CYCLE: 'whole_cycle',
  PERMANENTLY: 'permanently',
};

export const INTERVENTION_TYPES = {
  INPUT: 'input',
  REPORT: 'report',
};

export const INPUT_TYPES = {
  OKNEKO_SEUL: 'OKNEKO_Seul',
  OKNEKO_COMM_FACULT: 'OKNEKO_COMM_FACULT',
  OKNEKO_COMM_OBLIG_TTLT: 'OKNEKO_COMM_OBLIG_TTLT',
  OKNEKO_COMM_OBLIG_SI_KO: 'OKNEKO_COMM_OBLIG_SI_KO',
  OKNEKO_COMM_OBLIG_SI_NE: 'OKNEKO_COMM_OBLIG_SI_NE',
  SSI_PERMANENT: 'SSI_Permanent',
  GROUPE_ELECTROGENE: 'groupe_electrogene',
  ECLAIRAGE_SECURITE: 'eclairage_securite',
  DEG_HEURE: 'DEG_HEURE',
  PM30S_DEG_HEURE: 'PM30S_DEG_HEURE',
  VALEUR_TH: 'ValeurTH',
  VALEUR_KILO: 'ValeurKilo',
  INDEX_DATE_VOL_VOLJR: 'INDEX_DATE_VOL_VOLJR',
  T30S_TSTAB_TMPSTAB_HEURE: 'T30S_TSTAB_TMPSTAB_HEURE',
  VALEUR_PH: 'ValeurPH',
  VALEUR_BAIGNEUR: 'ValeurBaigneur',
  VALEUR_CHLORE: 'ValeurChlore',
  BALNEOTHERAPIE: 'BALNEO',
};

export const SSI_TROUBLE_TYPES = {
  NONE: null,
  DETECTION_ISSUE: 'detection_issue',
  SAFETY_ISSUE: 'safety_issue',
  POWER_SUPPLY_ISSUE: 'power_supply_issue',
};

export const GENERATING_SET_INPUTS = {
  OIL: 'oilChecked',
  WATER: 'waterChecked',
  FUEL_LEVEL: 'fuelLevelChecked',
  ENGINE_WARMING_SYSTEM: 'engineWarmingSystemChecked',
  BATTERY: 'batteryChecked',
  AUTO_START_30MIN: 'autoStart30minChecked',
};

export const Pneu = {
  ARD: 'arriereDroit',
  ARG: 'arriereGauche',
  AVD: 'avantDroit',
  AVG: 'avantGauche',
};

export const SCREEN_SIZES = {
  MOBILE: window.innerWidth < 768,
  TABLET: window.innerWidth < 1280,
  DESKTOP: window.innerWidth >= 1280,
};

export const INTER_TYPES = {
  safety_committee: 'Committee de sécurité',
  maintenance_control: 'Contrôle de maintenance',
  approved_body_control: 'Contrôle organisme agréé',
  laboratory_control: 'Contrôle d\'un laboratoire',
  training: 'Formations',
  troubleshooting_or_worksite: 'Dépannage/ Travaux',
  other: 'Autre',
};
