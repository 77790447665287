import { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  useFormik,
} from 'formik';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { createContact } from '../../services/contact';
import styles from './AddContact.module.css';
import Input from '../../components/atoms/Input/Input';
import Textarea from '../../components/atoms/Textarea/Textarea';
import Layout from '../../components/template/Layout';
import Button from '../../components/atoms/Button/Button';
import useAppContext from '../../store/useAppContext';

function Addcontact() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [context] = useAppContext();
  const [send, setSend] = useState(false);

  const SendMessage = useMutation(createContact, {
    onSuccess: () => {
      toast.success((t('contact.success')));
      setSend(true);
    },
    onError: () => {
      toast.error((t('contact.error')));
    },
  });
  const validationSchema = Yup.object().shape({
    purpose: Yup.string()
      .required(t('global.required_field'))
      .min(3, 'l\'object de votre message doit contenir au moins 3 caractères'),
    content: Yup.string()
      .required(t('global.required_field'))
      .min(5, 'le message doit contenir au moins 5 caractères'),
    name: Yup.string(),
    email: Yup.string(),
    phone: Yup.string(),
    structureId: Yup.string(),
  });

  const initialValues = {
    name: '',
    email: '',
    purpose: '',
    content: '',
    phone: '',
    structureId: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      formik.setFieldValue('name', context.user.lastName);
      formik.setFieldValue('email', context.user.email);
      formik.setFieldValue('phone', context.user.phone);
      formik.setFieldValue('structureId', context.user.permissions[0]?.structureId || '');
      SendMessage.mutate(formik.values);
    },
  });
  return (
    <Layout
      title={t('contact.title')}
      queryError={SendMessage?.isError}
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">
            contactez-nous
          </h1>
        </div>
        <span className={styles.number_contact}>
          {t('contact.phase1')}
          <b> 04 69 84 62 61</b>
        </span>
      </header>
      {send ? (
        <div className="success">
          <h4>
            {t('contact.success')}
          </h4>
          <p className="text">
            {t('contact.reply')}
          </p>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} className="form shadow-sm">
          <div className="form_group pt-16">
            <Input
              id="purpose"
              label={t('contact.purpose')}
              type="text"
              name="purpose"
              value={formik.values.purpose}
              placeholder={t('contact.Object')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          {formik.errors.purpose && formik.touched.purpose ? (
            <div className="error">
              {t(formik.errors.purpose)}
            </div>
          ) : null }
          <div className="form_group pt-16">
            {' '}
          </div>
          <div className="form_group pt-16">
            <Textarea
              id="content"
              type="text"
              label={t('contact.message')}
              name="content"
              value={formik.values.content}
              placeholder="Votre Message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          {formik.errors.content && formik.touched.content ? (
            <div className="error">
              {t(formik.errors.content)}
            </div>
          ) : null }
          <div className="form_footer">
            <div className="form_infos">
              <small>{t('addInterventions.mandatory_fields')}</small>
            </div>
            <Button
              type="submit"
              className="form_submit"
              label={t('contact.send')}
              isLoading={SendMessage.isLoading}
            />
          </div>
        </form>
      )}
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('contact.back')}</span>
        </button>
      </footer>
    </Layout>
  );
}
export default Addcontact;
