import React from 'react';
import PropTypes from 'prop-types';
import OKNEKOSeulList from './OKNEKOSeulList';
import OKNEKOCommObligSiKoList from './OKNEKOCommObligSiKoList';
import OKNEKOCommObligTTLTList from './OKNEKOCommObligTTLTList';
import OKNEKOCommFacultList from './OKNEKOCommFacultList';
import OKNEKOCommObligSiNeList from './OKNEKOCommObligSiNeList';
import SSIPermanent from './SSIPermanent';
import GroupeElectrogeneList from './GroupeElectrogeneList';
import SafetyLightning from './SafetyLightning';
import DegHeureList from './DegHeureList';
import PM30SDegHeureList from './PM30SDegHeureList';
import T30STStabTmpStabHeureList from './T30STStabTmpStabHeureList';
import ValeurTH from './ValeurTH';
import ValeurPH from './ValeurPH';
import ValeurKilo from './ValeurKilo';
import ValeurBaigneur from './ValeurBaigneur';
import ValeurChlore from './ValeurChlore';
import Balneo from './balneo';
import IndexDateVolVolJrList from './IndexDateVolVolJrList';
import EtatPneu from './EtatPneux';
import ControleCT from './controleCT';
import Revision from './Revision';
import Kilometrage from './Kilometrage';
import NFC from './nfc';

function InputsTypeSwitcher({ inputControl, submit }) {
  switch (inputControl.inputType) {
    case 'OKNEKO_Seul': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'OKNEKO_COMM_OBLIG_SI_KO': return (
      <OKNEKOCommObligSiKoList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'OKNEKO_COMM_OBLIG_TTLT': return (
      <OKNEKOCommObligTTLTList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'OKNEKO_COMM_FACULT': return (
      <OKNEKOCommFacultList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'OKNEKO_COMM_OBLIG_SI_NE': return (
      <OKNEKOCommObligSiNeList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'SSI_Permanent': return (
      <SSIPermanent list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'groupe_electrogene': return (
      <GroupeElectrogeneList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'eclairage_securite': return (
      <SafetyLightning list={inputControl.inputsTypeList} />
    );
    case 'DEG_HEURE': return ( // ALERT
      <DegHeureList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'PM30S_DEG_HEURE': return ( // ALERT
      <PM30SDegHeureList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'T30S_TSTAB_TMPSTAB_HEURE': return ( // ALERT
      <T30STStabTmpStabHeureList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'ValeurTH': return (
      <ValeurTH list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'ValeurPH': return (
      <ValeurPH list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'ValeurKilo': return (
      <ValeurKilo list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'ValeurBaigneur': return (
      <ValeurBaigneur list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'ValeurChlore': return (
      <ValeurChlore list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'INDEX_DATE_VOL_VOLJR': return ( // ALERT COMPTEUR
      <IndexDateVolVolJrList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'INDEX_KILOMETRIQUE': return ( // ALERT KM
      <Kilometrage list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'ETAT_PNEU': return (
      <EtatPneu list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'OKNEKO_PERIODEHIVER': return (
      <OKNEKOCommFacultList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'Trousse_de_seccours': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'Gilet': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'Triangle': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'Niveau_huile': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'Niveau_lave_vitre': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'Niveau_liquide_refroidissement': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'Controle_feux_AV_AR': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'proprete_interieur': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'proprete_exterieur': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'Tenue_carnet_bord': return (
      <OKNEKOSeulList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'Etat_carrosserie': return (
      <OKNEKOCommObligSiKoList list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'REVISION': return (
      <Revision list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'TECHNICAL_CONTROL': return (
      <ControleCT list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'BALNEO': return (
      <Balneo list={inputControl.inputsTypeList} submit={submit} />
    );
    case 'NFC': return (
      <NFC list={inputControl.inputsTypeList} submit={submit} />
    );
    default: return 'failed';
  }
}

InputsTypeSwitcher.propTypes = {
  inputControl: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};

export default InputsTypeSwitcher;
