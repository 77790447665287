import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Components
import InfoMessage from '../../atoms/InfoMessage/InfoMessage';

function QueryError({ error }) {
  // Hooks
  const { t } = useTranslation();

  const errorMessage = () => {
    if (error?.data?.errors) {
      if (Array.isArray(error?.data?.errors)) {
        return error?.data?.errors.map((message) => (
          <p className="paragraph">{message}</p>
        ));
      }
      return Object.values(error?.data?.errors).map((message) => (
        <p className="paragraph">{message}</p>
      ));
    }
    return undefined;
  };

  const errorStatus = () => {
    switch (error?.status) {
      case 401:
        return t('global.error_401');
      case 403:
        return t('global.error_403');
      case 404:
        return t('global.error_404');
      case 500:
        return t('global.error_500');
      default:
        return t('global.error_other');
    }
  };

  return error ? (
    <InfoMessage
      type="error"
      label={error?.data?.message || errorMessage() || error?.data?.description || errorStatus()}
    />
  ) : null;
}

QueryError.propTypes = {
  error: PropTypes.shape({
    data: PropTypes.shape({
      message: PropTypes.string,
      description: PropTypes.string,
      errors: PropTypes.arrayOf(PropTypes.string),
    }),
    status: PropTypes.string,
  }),
};

QueryError.defaultProps = {
  error: null,
};

export default QueryError;
