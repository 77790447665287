import api from './_api';

/**
 * GET - Get checklist list
 * @param {Number: page, Number: structureId} pageId
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
*/
export const getChecklist = (queryParams) => api.get('/circle-check-lists', { params: queryParams });

/**
 * GET - Get checklist list
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
*/
export const getVehiculeChecklist = (queryParams) => api.get(`/circle-check-lists/${queryParams.vehicleId}`);

/**
 * GET - Get One checklist list
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
*/
export const getVehiculeOnelist = (queryParams) => api.get(`/vehicles/inputs/${queryParams.vehicleId}`);

/**
 * PUT- edit a vehicle
 *  @param {object} vehiculeId
 * @param {Object} vehicle
 * @returns {Promise<AxiosResponse>}
 */
export const EditChecklist = (vehicle) => api.put(`/circle-check-lists/${vehicle.vehiculeId}`, vehicle.data);

/**
 * POST - Create Contract
 * @param {Object} contactToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const createContact = (contactToCreate) => api.post('/contacts', contactToCreate);
