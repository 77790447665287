import api from './_api';

/**
 * GET - Get Societies list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getSocietiesList = (queryParams) => api.get('/societies', { params: queryParams });

/**
 * GET - Get Society list
 * @param {Number} societyId
 * @returns {Promise<AxiosResponse>}
 */
export const getSociety = () => api.get('/societies');

/**
 * GET - Get Societies one
 * @param {object} params
 * @returns {Promise<AxiosResponse>}
 */
export const getSocietyOne = (params) => api.get(`/societies/${params.societyId}/${params.establishmentId}`);

/**
 * POST - get Society
 * @param {Object} data
 * @returns {Promise<AxiosResponse>}
 */
export const getSocietyById = (data) => api.get(`/societies/${data.id}`);

/**
 * POST - Create Society
 * @param {Object} societyData
 * @returns {Promise<AxiosResponse>}
*/
export const createSociety = (societyData) => api.post('/societies', societyData);

/**
 * Post - add prestataire to society
 * @param {Object} data
 * @returns {Promise<AxiosResponse>}
 *
 */
export const addPrestataireToSociety = (data) => api.post(`/societies/${data.id}:link`, data.data);

/**
 * Put - edit prestataire to society
 * @param {Object} data
 * @returns {Promise<AxiosResponse>}
 *
 */
export const editPrestataireToSociety = (data) => api.put('/societies/edit', data.data);

/**
 * Post - unlink prestataire to society
 * @param {Object} data
 * @returns {Promise<AxiosResponse>}
 *  */
export const unlinkPrestataireToSociety = (data) => api.post(`/societies/${data.id}:unlink`, data);
/**
 * DELETE - Delete Society
 * @param {Number} societyId
 * @returns {Promise<AxiosResponse>}
 * */
export const deleteSociety = (societyId) => api.delete(`/societies/${societyId}`);
