import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Local files
import localFR from './local/fr.json';

const resources = {
  fr: { translation: localFR },
};

const lng = navigator.language.split('-')[0];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng,
    fallbackLng: 'fr',
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
