/* eslint-disable import/prefer-default-export */
const INTERVENTION_TYPES = {
  input: 'input',
  report: 'report',
};

/**
 * Pure function to know if control is type input
 * @param {object} control
 * @returns {Boolean}
 */
// eslint-disable-next-line arrow-body-style
export const isInputIntervention = (control) => {
  return control?.interventionType === INTERVENTION_TYPES.input;
};
