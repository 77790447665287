import React from 'react';
import PropTypes from 'prop-types';
// Utils
import styles from './Radio.module.css';

function Radio({
  id, name, label, value, checked, onChange, onBlur, required, disabled,
}) {
  return (
    <div>
      <input
        className={styles.input}
        id={id}
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        required={required || null}
      />
      <label className={styles.label} htmlFor={id}>{label}</label>
    </div>
  );
}

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
};

Radio.defaultProps = {
  checked: false,
  onBlur: null,
  required: false,
  disabled: false,

};

export default Radio;
