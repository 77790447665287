import React, { useState } from 'react';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getObservation, putObservation } from '../../services/registre';
import Input from '../../components/atoms/Input/Input';
import Layout from '../../components/template/Layout';
import styles from './EditObservation.module.css';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
import useAppContext from '../../store/useAppContext';

function EditObservation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [oneObservation, setOneObservation] = useState([]);
  const [context] = useAppContext();

  const putObservationMutation = useMutation(putObservation, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('raisedObs.successave'));
    },
    onError: () => {
      toast.error(t('raisedObs.echecsave'));
    },
  });

  const initialValues = {
    title: '',
    number: '',
    reportId: '',
    society: '',
    organisme: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(t('global.required_field')),
    number: Yup.string().required(t('global.required_field')),
    reportId: Yup.string().required(t('global.required_field')),
    society: Yup.string().nullable(),
    organisme: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      putObservationMutation.mutate({
        id: urlParams.id,
        data: {
          title: values.title,
          number: values.number,
        },
      });
    },
  });
  const OneObservationQuery = useQuery(['getObservation'], () => getObservation({
    id: urlParams.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setOneObservation(data?.data);
      formik.setFieldValue('title', data?.data?.title);
      formik.setFieldValue('number', data?.data?.number);
      formik.setFieldValue('reportId', data?.data?.report.reference ?? '');
      if (data?.data?.report?.reportType === 'organization') {
        formik.setFieldValue('organisme', 'Organisme agréé');
      } else if (data?.data?.report?.reportType === 'technician') {
        formik.setFieldValue('organisme', 'Technicien compétent');
      }
    },
  });

  return (
    <Layout
      title="Vacation"
      layout="table"
      queryError={
        OneObservationQuery?.error
        || putObservationMutation?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('observation.return')}</span>
          </button>
        </div>
        <div className={cn(['row', styles.row])}>
          <h1 className="title">
            {t('observation.editObservation')}
          </h1>
        </div>
        <span className="title-info">
          {oneObservation?.intervention?.title}
          -
          {oneObservation?.report?.id}
        </span>
      </header>
      <div className="title">
        <div>
          {' '}
          <div>
            <form
              onSubmit={formik.handleSubmit}
              className="form shadow"
            >
              <div className="form_group">
                <Input
                  id="intitule"
                  type="text"
                  name="intitule"
                  label={t('observation.intitule')}
                  value={formik.values.title}
                  onChange={(e) => formik.setFieldValue('title', e.target.value)}
                  placeholder={t('observation.intitule')}
                  required
                />
                {formik.errors.title && formik.touched.title ? (
                  <div className="error">{formik.errors.title}</div>
                ) : null}
              </div>
              <div className="form_group">
                <Input
                  id="ObservationNumber"
                  type="text"
                  name="ObservationNumber"
                  label={t('observation.observationNumber')}
                  value={formik.values.number}
                  placeholder={t('observation.observationNumber')}
                  onChange={(e) => formik.setFieldValue('number', e.target.value)}
                  required
                />
                {formik.errors.number && formik.touched.number ? (
                  <div className="error">{formik.errors.number}</div>
                ) : null}
              </div>
              <div className="form_group">
                <Input
                  id="equiement"
                  type="text"
                  value={oneObservation?.intervention?.equipment?.name}
                  label={t('observation.equipement')}
                  placeholder={t('observation.equipement')}
                  disabled
                />
              </div>
              <div className="form_group">
                <Input
                  id="numeroReport"
                  type="text"
                  label={t('observation.numeroReport')}
                  value={formik.values.reportId}
                  placeholder={t('observation.numeroReport')}
                  disabled
                />
              </div>
              <div className="form_group">
                <Input
                  id="organisme"
                  type="text"
                  label={t('observation.organisme')}
                  placeholder={t('observation.organisme')}
                  value={formik?.values?.organisme}
                  disabled
                />
              </div>
              <div className="form_group">
                <Input
                  id="societyName"
                  type="text"
                  label={t('observation.societyName')}
                  value={oneObservation?.report?.societyInChargeName}
                  placeholder={t('observation.societyName')}
                  disabled
                />
              </div>
              <br />
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                  <br />
                  <small>
                    {t('observation.creation')}
                    {' '}
                    {oneObservation.creatorName ? (
                      <>
                        Par :
                        {' '}
                        {oneObservation.creatorName}
                        {' '}
                      </>
                    ) : null}
                    Le :
                    {' '}
                    {new Date(oneObservation.createdAt).toLocaleDateString()}
                  </small>
                  <br />
                  <small>
                    {t('observation.lastUpdate')}
                    {' '}
                    {oneObservation.updaterName ? (
                      <>
                        Par :
                        {' '}
                        {oneObservation.updaterName}
                        {' '}
                      </>
                    ) : null}
                    Le :
                    {' '}
                    {new Date(oneObservation.updatedAt).toLocaleDateString()}
                  </small>
                </div>
                <div className="form_footer">
                  <Button
                    type="submit"
                    className="form_submit"
                    label={t('raisedObs.save')}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('observation.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default EditObservation;
