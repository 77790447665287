import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// Components
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import InfoMessage from '../../components/atoms/InfoMessage/InfoMessage';
import QueryError from '../../components/molecules/QueryError/QueryError';
// Services
import { postResetPassword } from '../../services/security';
// Utils
import LogoWhite from '../../assets/img/logos/e-carnet-white-logo.png';
import LogoSecurite from '../../assets/img/logos/e-carnet-securite-logo.png';
import LogoSanitaire from '../../assets/img/logos/e-carnet-sanitaire-logo.png';
import styles from './ResetPassword.module.css';
import cn from '../../utils/cn';

const initialValues = {
  password: '',
  confirm: '',
  token: new URL(document.location).searchParams.get('token'),
};

const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{8,})/;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('global.required_field')
    .matches(passwordRegEx, 'reset_password.requirments'),
  confirm: Yup.string().required('global.required_field')
    .oneOf([Yup.ref('password'), null], 'reset_password.must_match'),
});

function ResetPassword() {
  // States
  const [successForm, setSuccesForm] = useState(false);
  // Hooks
  const { t } = useTranslation();
  // API calls
  const postResetPasswordMutation = useMutation(postResetPassword, {
    onSuccess: () => {
      setSuccesForm(true);
    },
  });
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      postResetPasswordMutation.mutate(formValues);
      navigate('/');
    },
  });

  return (
    <div className={styles.page}>
      <Helmet>
        <title>
          {t('reset_password.title')}
          {' '}
          -
          {' '}
          {t('global.site_name')}
        </title>
      </Helmet>
      <div className="container">
        <img
          className={styles.logo}
          src={LogoWhite}
          alt={t('global.site_name')}
          width="320"
          height="102"
        />
        <h2 className={styles.subtitle}>{t('home.subtitle')}</h2>
        <div className={styles.container}>
          <h1 className={styles.title}>{t('reset_password.title')}</h1>
          <p className={styles.legend}>
            {t('reset_password.requirments')}
          </p>
          <QueryError error={postResetPasswordMutation.error} />
          {successForm
            && <InfoMessage type="success" label={t('reset_password.success_send')} />}
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.formGroup}>
              <Input
                id="password"
                type="password"
                name="password"
                label={t('reset_password.new_password')}
                labelHidden
                placeholder={t('reset_password.new_password')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password ? (
                <div className="error">
                  {t(formik.errors.password)}
                </div>
              ) : null }
            </div>
            <div className={styles.formGroup}>
              <Input
                id="confirm"
                type="password"
                name="confirm"
                label={t('reset_password.confirm')}
                labelHidden
                placeholder={t('reset_password.confirm')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirm}
              />
              {formik.errors.confirm && formik.touched.confirm ? (
                <div className="error">
                  {t(formik.errors.confirm)}
                </div>
              ) : null }
            </div>
            <Button
              type="submit"
              label={t('reset_password.submit')}
              className={cn([styles.submit, 'form_submit'])}
              isLoading={postResetPasswordMutation.isLoading}
            />
          </form>
          <hr className={styles.divider} />
          <div className={styles.logosAlt}>
            <img
              className={styles.logo}
              src={LogoSecurite}
              alt={t('global.site_name_security')}
              width="252"
              height="80"
            />
            <img
              className={styles.logo}
              src={LogoSanitaire}
              alt={t('global.site_name_sanitary')}
              width="252"
              height="80"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
