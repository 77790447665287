import api from './_api';

/**
 * GET - Get Contracts list
 * @param {Number: page, Number: structureId} pageId
 * @returns {Promise<AxiosResponse>}
 * @param {Object} queryParams
*/
export const getContactsList = (queryParams) => api.get('/contacts', { params: queryParams });

/**
 * POST - Create Contract
 * @param {Object} contactToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const createContact = (contactToCreate) => api.post('/contacts', contactToCreate);
