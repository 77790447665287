import api from './_api';

// ALL STRUCTURES

/**
 * GET - Get Structures list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getStructuresList = (queryParams) => api.get('/structures', { params: queryParams });

// GROUPS

/**
 * GET - Get Groups list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getGroupsList = (queryParams) => api.get('/groups', { params: queryParams });

/**
 * GET - Get Group
 * @param {Number} groupId
 * @returns {Promise<AxiosResponse>}
 */
export const getGroup = (groupId) => api.get(`/groups/${groupId}`);

/**
 * POST - Create Group
 * @param {{name:String, address:String, postalCode:Number, city:String}} groupToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postGroupInformations = (groupToCreate) => api.post('/groups', groupToCreate);

/**
 * PUT - Edit Group
 * @param {{id:Number, name:String, parentId:Number, address:String, postalCode:Number, city:String}} groupToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editGroupInformations = (groupToEdit) => api.put(`/groups/${groupToEdit.id}`, groupToEdit);

/**
 * DEL - Delete Group
 * @param {Number} groupId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteGroup = (groupId) => api.delete(`/groups/${groupId}`);

// SUBSIDIARIES

/**
 * GET - Get Subsidiaries list
 * @param {Number} parentId
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getSubsidiariesList = (queryParams) => api.get('/subsidiaries', { params: queryParams });

/**
 * GET - Get Subsidiary
 * @param {Number} subsidiaryId
 * @returns {Promise<AxiosResponse>}
 */
export const getSubsidiary = (subsidiaryId) => api.get(`/subsidiaries/${subsidiaryId}`);

/**
 * POST - Create Subsidiary
 * @param {{name:String, parentId:Number, address:String, postalCode:Number, city:String}} subsidiaryToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postSubsidiaryInformations = (subsidiaryToCreate) => api.post('/subsidiaries', subsidiaryToCreate);

/**
 * PUT - Edit Subsidiary
 * @param {{id:Number, name:String, parentId:Number, address:String, postalCode:Number, city:String}} subsidiaryToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editSubsidiaryInformations = (subsidiaryToEdit) => api
  .put(`/subsidiaries/${subsidiaryToEdit.id}`, subsidiaryToEdit);

/**
 * DEL - Delete Subsidiary
 * @param {Number} subsidiaryId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteSubsidiary = (subsidiaryId) => api.delete(`/subsidiaries/${subsidiaryId}`);

// DIVISIONS

/**
 * GET - Get Divisions list
 * @param {Number} parentId
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getDivisionsList = (queryParams) => api.get('/divisions', { params: queryParams });

/**
 * GET - Get Division
 * @param {Number} divisionId
 * @returns {Promise<AxiosResponse>}
 */
export const getDivision = (divisionId) => api.get(`/divisions/${divisionId}`);

/**
 * POST - Create Division
 * @param {{name:String, parentId:Number, address:String, postalCode:Number, city:String}} divisionToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postDivisionInformations = (divisionToCreate) => api.post('/divisions', divisionToCreate);

/**
 * PUT - Edit Division
 * @param {{id:Number, name:String, parentId:Number, address:String, postalCode:Number, city:String}} divisionToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editDivisionInformations = (divisionToEdit) => api.put(`/divisions/${divisionToEdit.id}`, divisionToEdit);

/**
 * DEL - Delete Division
 * @param {Number} divisionId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteDivision = (divisionId) => api.delete(`/divisions/${divisionId}`);

// ESTABLISHMENTS

/**
 * GET - Get Establishments list
 * @param {Number} parentId
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getEstablishmentsList = (queryParams) => api.get('/establishments', { params: queryParams });

/**
 * GET - Get Establishment
 * @param {Number} establishmentId
 * @returns {Promise<AxiosResponse>}
 */
export const getEstablishment = (establishmentId) => api.get(`/establishments/${establishmentId}`);

/**
 * GET - Get Count Establishment
 * @param {Number} parentId
 * @returns {Promise<AxiosResponse>}
 */
export const countEstablishment = () => api.get('/establishments/count');

/**
 * POST - Create Establishment
 * @param {{
 * name:String,
 * parentId:Number,
 * address:String,
 * postalCode:Number,
 * city:String,
 * code:String,
 * phone:String,
 * email:String,
 * workforce:Number,
 * trainingPerYear:Number,
 * numberOfResidents:Number
 * }} establishmentToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postEstablishmentInformations = (establishmentToCreate) => api
  .post('/establishments', establishmentToCreate);

/**
 * PUT - Edit Establishment
 * @param {{
 * id:Number,
 * name:String,
 * parentId:Number,
 * address:String,
 * postalCode:Number,
 * city:String,
 * code:String,
 * phone:String,
 * email:String,
 * workforce:Number,
 * trainingPerYear:Number,
 * numberOfResidents:Number
 * }} establishmentToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editEstablishmentInformations = (establishmentToEdit) => api
  .put(`/establishments/${establishmentToEdit.id}`, establishmentToEdit);

/**
 * DEL - Delete Establishment
 * @param {Number} establishmentId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteEstablishment = (establishmentId) => api.delete(`/establishments/${establishmentId}`);

// BUILDINGS

/**
 * GET - Get Buildings list
 * @param {Number} parentId
 * @param {Number} bookletId
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getBuildingsList = (queryParams) => api.get('/buildings', { params: queryParams });

/**
 * GET - Get Building
 * @param {Number} buildingId
 * @returns {Promise<AxiosResponse>}
 */
export const getBuilding = (buildingId) => api.get(`/buildings/${buildingId}`);

/**
 * POST - Create Building
 * @param {{name:String, parentId:Number, bookletId:Number, equipmentsId:Array}} buildingToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postBuildingInformations = (buildingToCreate) => api.post('/buildings', buildingToCreate);

/**
 * PUT - Edit Building
 * @param {{id:Number, name:String, parentId:Number, bookletId:Number, equipmentsId:Array}} buildingToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editBuildingInformations = (buildingToEdit) => api.put(`/buildings/${buildingToEdit.id}`, buildingToEdit);

/**
 * DEL - Delete Building
 * @param {Number} buildingId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteBuilding = (buildingId) => api.delete(`/buildings/${buildingId}`);

// BUILDINGS TEMPLATES

/**
 * GET - Get Buildings Templates list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getBuildingsTemplatesList = (queryParams) => api.get('/building-templates', { params: queryParams });

/**
 * GET - Get Building Template
 * @param {Number} buildingTemplateId
 * @returns {Promise<AxiosResponse>}
 */
export const getBuildingTemplate = (buildingTemplateId) => api.get(`/building-templates/${buildingTemplateId}`);

/**
 * POST - Create Building Template
 * @param {{name:String, bookletId:Number, equipmentIds:Array}} buildingTemplateToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postBuildingTemplate = (buildingTemplateToCreate) => api
  .post('/building-templates', buildingTemplateToCreate);

/**
 * PUT - Edit Building Template
 * @param {{id:Number, name:String, bookletId:Number, equipmentIds:Array}} buildingTemplateToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editBuildingTemplate = (buildingTemplateToEdit) => api
  .put(`/building-templates/${buildingTemplateToEdit.id}`, buildingTemplateToEdit);

/**
 * DEL - Delete Building Template
 * @param {Number} buildingTemplateId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteBuildingTemplate = (buildingTemplateId) => api.delete(`/building-templates/${buildingTemplateId}`);
