/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// Hooks
import useAppContext from '../../../store/useAppContext';
// Components
import Permissions from '../../../components/molecules/Permissions/Permissions';
import CardDashboard from '../../../components/molecules/CardDashboard/CardDashboard';
// Styles
import styles from './UserDashboard.module.css';
import { getIndicatorByEtablissement } from '../../../services/indicator';
import cn from '../../../utils/cn';

function UserDashboard() {
  const [context] = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSecurityBooklet = context.choiceBooklet === 2;
  const isVehiculeBooklet = context.choiceBooklet === 3;

  const getIndicatorByEtablissementQuery = useQuery(['establishments'], () => getIndicatorByEtablissement({
    Establissement: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
  }));

  if (isSecurityBooklet) {
    if (context?.permissions?.[`${context?.choiceBooklet}/${context?.choiceEstablishment?.id}`] !== undefined) {
      return (
        <>
          <section className={styles.gridTwoColumns}>
            <CardDashboard
              title={t('dashboard.registry')}
              picto="monRegistreBlanc"
              classNames={[styles.cardLedger]}
              onClick={() => navigate('/registre')}
              disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined
                && Permissions({ permission: 'BOOKLET_READ' }) === undefined}
              texte={(
                <ul className={styles.ul}>
                  {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                    && getIndicatorByEtablissementQuery?.data?.data?.securityRecordKeepingRate2 !== null ? (
                      <li>
                        <b>{`${getIndicatorByEtablissementQuery?.data?.data?.securityRecordKeepingRate2}%`}</b>
                        {' '}
                        {t('dashboard.recordKeepingRate')}
                      </li>
                    ) : ''}
                  {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                    && getIndicatorByEtablissementQuery?.data?.data?.totalSecurityObservationsToLiftCount !== null
                    && getIndicatorByEtablissementQuery?.data?.data?.totalSecurityObservationsToLiftCount !== 0 ? (
                      <li>
                        <b>{getIndicatorByEtablissementQuery?.data?.data?.totalSecurityObservationsToLiftCount}</b>
                        {' '}
                        {t('dashboard.totalObservationsToLiftCount')}
                      </li>
                    ) : ''}
                  {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                    && getIndicatorByEtablissementQuery?.data?.data?.prescriptionsSecurityToLiftCount !== null
                    && getIndicatorByEtablissementQuery?.data?.data?.prescriptionsSecurityToLiftCount !== 0 ? (
                      <li>
                        <b>{getIndicatorByEtablissementQuery?.data?.data?.prescriptionsSecurityToLiftCount}</b>
                        {' '}
                        {t('dashboard.prescriptionsToLiftCount')}
                      </li>
                    ) : ''}
                </ul>
              )}
            />
            <CardDashboard
              title={t('dashboard.documentary_base')}
              picto="documentaire"
              classNames={[]}
              onClick={() => navigate('/base-documentaire')}
              borderTopColored
              disabled={Permissions({ permission: 'DOCUMENT_LIST' }) === undefined}
            />
          </section>
          {/* 2nd line */}
          <section className={styles.gridFourColumns}>
            <CardDashboard
              title={t('dashboard.journal')}
              picto="journalBlanc"
              classNames={[styles.cardSecurityTheme]}
              onClick={() => navigate('/Dairy')}
              disabled={Permissions({ permission: 'DIARY_LIST' }) === undefined}
            />
            <CardDashboard
              title={t('dashboard.input')}
              picto="saisiesBlanc"
              classNames={[styles.cardSecurityTheme]}
              onClick={() => navigate('/saisies')}
              disabled={Permissions({ permission: 'INPUT_LIST' }) === undefined}
            />
            <CardDashboard
              title={t('dashboard.filing_reports')}
              picto="depotRapportBlanc"
              classNames={[styles.cardSecurityTheme]}
              onClick={() => navigate('/rapports')}
              disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined}
              texte={(
                <ul className={cn([styles.ul, styles.ullatesecurityreportscount])}>
                  {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                    && getIndicatorByEtablissementQuery?.data?.data?.lateSecurityReportsCount !== null
                    && getIndicatorByEtablissementQuery?.data?.data?.lateSecurityReportsCount !== 0 ? (
                      <li className={styles.lateSecurityReportsCount}>
                        <b>{getIndicatorByEtablissementQuery?.data?.data?.lateSecurityReportsCount}</b>
                        {' '}
                        {t('dashboard.late_report')}
                      </li>
                    ) : '' }
                </ul>
              )}
            />
            <CardDashboard
              title={t('dashboard.filling_other_documents')}
              picto="depotDocBlanc"
              classNames={[styles.cardSecurityTheme]}
              onClick={() => navigate('/depot_document')}
              disabled={Permissions({ permission: 'DOCUMENT_CREATE' }) === undefined}
            />
          </section>
          {/* 3rd line */}
          <section className={styles.gridFourColumns}>
            <CardDashboard
              title={t('dashboard.calendar')}
              picto="calendrier"
              classNames={[styles.cardBlueLightedTheme]}
              onClick={() => navigate('/calendar')}
              disabled={Permissions({ permission: 'CALENDAR_LIST' }) === undefined}
            />
            <CardDashboard
              title={t('dashboard.providers')}
              picto="prestataires"
              classNames={[styles.cardBlueLightedTheme]}
              onClick={() => navigate('/society')}
              disabled={Permissions({ permission: 'SOCIETY_LIST' }) === undefined}
            />
            <CardDashboard
              title={t('dashboard.contracts')}
              picto="contrats"
              classNames={[styles.cardBlueLightedTheme]}
              onClick={() => navigate('/contracts')}
              disabled={Permissions({ permission: 'CONTRACT_LIST' }) === undefined}
            />
            <CardDashboard
              title={t('dashboard.trainings')}
              picto="equipements"
              classNames={[styles.cardBlueLightedTheme]}
              onClick={() => navigate('/formations')}
              disabled={Permissions({ permission: 'TRAINING_LIST' }) === undefined}
            />
          </section>
        </>
      );
    }
    return (
      <>
        <section className={styles.gridTwoColumns}>
          <CardDashboard
            title={t('dashboard.registry')}
            picto="monRegistreBlanc"
            classNames={[styles.cardLedger, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined
              && Permissions({ permission: 'BOOKLET_READ' }) === undefined}
            texte={(
              <ul className={styles.ul}>
                {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                  && getIndicatorByEtablissementQuery?.data?.data?.securityRecordKeepingRate2 !== null ? (
                    <li>
                      {getIndicatorByEtablissementQuery?.data?.data?.securityRecordKeepingRate2}
                      {' '}
                      {t('dashboard.recordKeepingRate')}
                    </li>
                  ) : ''}
                {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                  && getIndicatorByEtablissementQuery?.data?.data?.totalSecurityObservationsToLiftCount !== null
                  && getIndicatorByEtablissementQuery?.data?.data?.totalSecurityObservationsToLiftCount !== 0 ? (
                    <li>
                      {getIndicatorByEtablissementQuery?.data?.data?.totalSecurityObservationsToLiftCount}
                      {' '}
                      {t('dashboard.totalObservationsToLiftCount')}
                    </li>
                  ) : ''}
                {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                  && getIndicatorByEtablissementQuery?.data?.data?.prescriptionsSecurityToLiftCount !== null
                  && getIndicatorByEtablissementQuery?.data?.data?.prescriptionsSecurityToLiftCount !== 0 ? (
                    <li>
                      {getIndicatorByEtablissementQuery?.data?.data?.prescriptionsSecurityToLiftCount}
                      {' '}
                      {t('dashboard.prescriptionsToLiftCount')}
                    </li>
                  ) : ''}
              </ul>
            )}
          />
          <CardDashboard
            title={t('dashboard.documentary_base')}
            picto="documentaire"
            classNames={[styles.cursorNotAllowed]}
            borderTopColored
            disabled={Permissions({ permission: 'DOCUMENT_LIST' }) === undefined}
          />
        </section>
        {/* 2nd line */}
        <section className={styles.gridFourColumns}>
          <CardDashboard
            title={t('dashboard.journal')}
            picto="journalBlanc"
            classNames={[styles.cardSecurityTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'DIARY_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.input')}
            picto="saisiesBlanc"
            classNames={[styles.cardSecurityTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'INPUT_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.filing_reports')}
            picto="depotRapportBlanc"
            classNames={[styles.cardSecurityTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined}
            texte={(
              <ul className={styles.ul}>
                {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                  && getIndicatorByEtablissementQuery?.data?.data?.lateSecurityReportsCount !== null
                  && getIndicatorByEtablissementQuery?.data?.data?.lateSecurityReportsCount !== 0 ? (
                    <li className={styles.lateSecurityReportsCount}>
                      {getIndicatorByEtablissementQuery?.data?.data?.lateSecurityReportsCount}
                      {' '}
                      {t('dashboard.late_report')}
                    </li>
                  ) : '' }
              </ul>
            )}
          />
          <CardDashboard
            title={t('dashboard.filling_other_documents')}
            picto="depotDocBlanc"
            classNames={[styles.cardSecurityTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'DOCUMENT_CREATE' }) === undefined}
          />
        </section>
        {/* 3rd line */}
        <section className={styles.gridFourColumns}>
          <CardDashboard
            title={t('dashboard.calendar')}
            picto="calendrier"
            classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'CALENDAR_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.providers')}
            picto="prestataires"
            classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'SOCIETY_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.contracts')}
            picto="contrats"
            classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'CONTRACT_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.trainings')}
            picto="equipements"
            classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'TRAINING_LIST' }) === undefined}
          />
        </section>
      </>
    );
  }

  if (isVehiculeBooklet) {
    if (context?.permissions?.[`${context?.choiceBooklet}/${context?.choiceEstablishment?.id}`] !== undefined) {
      return (
        <>
          <section className={styles.gridTwoColumns}>
            <CardDashboard
              title={t('dashboard.vehicule_book')}
              picto="vehiculeblanc"
              classNames={[styles.vehiculeTheme]}
              onClick={() => navigate('/vehicule')}
              disabled={Permissions({ permission: 'DOCUMENT_CREATE' }) === undefined}
            />
            <CardDashboard
              title={t('dashboard.documentary_base')}
              picto="documentaire"
              classNames={[]}
              onClick={() => navigate('/base-documentaire')}
              borderTopColored
              disabled={Permissions({ permission: 'DOCUMENT_LIST' }) === undefined}
            />
          </section>
          <section className={styles.gridTwoColumns}>
            <CardDashboard
              title={t('dashboard.input')}
              picto="saisiesBlanc"
              classNames={[styles.cardvehTheme]}
              onClick={() => navigate('/saisies')}
              disabled={Permissions({ permission: 'INPUT_LIST' }) === undefined}
            />
            <CardDashboard
              title={t('dashboard.doc_attente')}
              picto="depotDocBlanc"
              classNames={[styles.cardvehTheme]}
              onClick={() => navigate('/rapports')}
              disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined}
            />
          </section>
          <section className={styles.gridTwoColumns}>
            <CardDashboard
              title={t('dashboard.calendar')}
              picto="calendrier"
              classNames={[styles.cardBlueLightedTheme]}
              onClick={() => navigate('/calendar')}
              disabled={Permissions({ permission: 'CALENDAR_LIST' }) === undefined}
            />
            <CardDashboard
              title={t('dashboard.contracts_vehicule')}
              picto="contrats"
              classNames={[styles.cardBlueLightedTheme]}
              onClick={() => navigate('/contratsVehicule')}
              disabled={Permissions({ permission: 'CONTRACT_LIST' }) === undefined}
            />
          </section>
        </>
      );
    }
    return (
      <>
        <section className={styles.gridTwoColumns}>
          <CardDashboard
            title={t('dashboard.vehicule_book')}
            picto="vehiculeblanc"
            classNames={[styles.vehiculeTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'DOCUMENT_CREATE' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.documentary_base')}
            picto="documentaire"
            classNames={[styles.cursorNotAllowed]}
            borderTopColored
            disabled={Permissions({ permission: 'DOCUMENT_LIST' }) === undefined}
          />
        </section>
        <section className={styles.gridTwoColumns}>
          <CardDashboard
            title={t('dashboard.input')}
            picto="saisiesBlanc"
            classNames={[styles.cardvehTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'INPUT_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.doc_attente')}
            picto="depotDocBlanc"
            classNames={[styles.cardvehTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined}
          />
        </section>
        <section className={styles.gridTwoColumns}>
          <CardDashboard
            title={t('dashboard.calendar')}
            picto="calendrier"
            classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'CALENDAR_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.contracts_vehicule')}
            picto="contrats"
            classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
            disabled={Permissions({ permission: 'CONTRACT_LIST' }) === undefined}
          />
        </section>
      </>
    );
  }
  // Sanitaire
  if (context?.permissions?.[`${context?.choiceBooklet}/${context?.choiceEstablishment?.id}`] !== undefined) {
    return (
      <>
        {/* 1ere line */}
        <section className={styles.gridTwoColumns}>
          <CardDashboard
            title={t('dashboard.my_book')}
            picto="monRegistreBlanc"
            classNames={[styles.cardBook]}
            onClick={() => navigate('/monCarnet')}
            disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined
            && Permissions({ permission: 'BOOKLET_READ' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.documentary_base')}
            picto="documentaire"
            classNames={[]}
            onClick={() => navigate('/base-documentaire')}
            borderTopColored
            disabled={Permissions({ permission: 'DOCUMENT_LIST' }) === undefined}
          />
        </section>
        {/* 2nd line */}
        <section className={styles.gridFourColumns}>
          <CardDashboard
            title={t('dashboard.journal')}
            picto="journalBlanc"
            classNames={[styles.cardSanitaryTheme]}
            onClick={() => navigate('/Dairy')}
            disabled={Permissions({ permission: 'DIARY_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.input')}
            picto="saisiesBlanc"
            classNames={[styles.cardSanitaryTheme]}
            onClick={() => navigate('/saisies')}
            disabled={Permissions({ permission: 'INPUT_LIST' }) === undefined}
            texte={(
              <ul className={styles.ul}>
                {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                  && getIndicatorByEtablissementQuery?.data?.data?.sanitaryInputsDonePercent !== null ? (
                    <li>
                      <b>{`${getIndicatorByEtablissementQuery?.data?.data?.sanitaryInputsDonePercent}%`}</b>
                      {' '}
                      {t('dashboard.lastTwoWeekSanitaryInputsDonePercent')}
                    </li>
                  ) : ''}
                {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                  && getIndicatorByEtablissementQuery?.data?.data?.lastYearSanitaryInputsDonePercent !== null ? (
                    <li>
                      <b>{`${getIndicatorByEtablissementQuery?.data?.data?.lastYearSanitaryInputsDonePercent}%`}</b>
                      {' '}
                      {t('dashboard.lastYearSanitaryInputsDonePercent')}
                    </li>
                  ) : ''}
              </ul>
            )}
          />
          <CardDashboard
            title={t('dashboard.filing_reports')}
            picto="depotRapportBlanc"
            classNames={[styles.cardSanitaryTheme]}
            onClick={() => navigate('/rapports')}
            disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined}
            texte={(
              <ul className={styles.ul}>
                {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                  && getIndicatorByEtablissementQuery?.data?.data?.lateSanitaryReportsCount !== null
                  && getIndicatorByEtablissementQuery?.data?.data?.lateSanitaryReportsCount !== 0 ? (
                    <li>
                      <b>{getIndicatorByEtablissementQuery?.data?.data?.lateSanitaryReportsCount}</b>
                      {' '}
                      {t('dashboard.latesecurityreportscount')}
                    </li>
                  ) : ''}
              </ul>
            )}
          />
          <CardDashboard
            title={t('dashboard.filling_other_documents')}
            picto="depotDocBlanc"
            classNames={[styles.cardSanitaryTheme]}
            onClick={() => navigate('/depot_document')}
            disabled={Permissions({ permission: 'DOCUMENT_CREATE' }) === undefined}
          />
        </section>
        {/* 3nd line */}
        <section className={styles.gridFourColumns}>
          <CardDashboard
            title={t('dashboard.calendar')}
            picto="calendrier"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/calendar')}
            disabled={Permissions({ permission: 'CALENDAR_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.providers')}
            picto="prestataires"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/society')}
            disabled={Permissions({ permission: 'SOCIETY_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.contracts')}
            picto="contrats"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/contracts')}
            disabled={Permissions({ permission: 'CONTRACT_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.analysis')}
            picto="synthese"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/analyse')}
            disabled={Permissions({ permission: 'ANALYSIS_LIST' }) === undefined}
            texte={(
              <ul className={styles.ul}>
                {Permissions({ permission: 'ANALYSIS_LIST' }) !== undefined
                  && getIndicatorByEtablissementQuery?.data?.data?.pneumophilaAnalysisSanitaryPercent !== null ? (
                    <li>
                      <b>{`${getIndicatorByEtablissementQuery?.data?.data?.pneumophilaAnalysisSanitaryPercent}%`}</b>
                      {' '}
                      {t('dashboard.complianceLastSanitaryAnalysisPercent')}
                    </li>
                  ) : ' '}
              </ul>
            )}
          />
        </section>
      </>
    );
  }

  if (isVehiculeBooklet) {
    return (
      <>
        <section className={styles.gridTwoColumns}>
          <CardDashboard
            title={t('dashboard.vehicule_book')}
            picto="vehiculeblanc"
            classNames={[styles.vehiculeTheme]}
            onClick={() => navigate('/vehicule')}
            disabled={Permissions({ permission: 'DOCUMENT_CREATE' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.documentary_base')}
            picto="documentaire"
            classNames={[]}
            onClick={() => navigate('/base-documentaire')}
            borderTopColored
            disabled={Permissions({ permission: 'DOCUMENT_LIST' }) === undefined}
          />
        </section>
        <section className={styles.gridTwoColumns}>
          <CardDashboard
            title={t('dashboard.input')}
            picto="saisiesBlanc"
            classNames={[styles.cardvehTheme]}
            onClick={() => navigate('/saisies')}
            disabled={Permissions({ permission: 'INPUT_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.doc_attente')}
            picto="depotDocBlanc"
            classNames={[styles.cardvehTheme]}
            onClick={() => navigate('/vehicule/document_attente')}
            disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined}
          />
        </section>
        <section className={styles.gridTwoColumns}>
          <CardDashboard
            title={t('dashboard.calendar')}
            picto="calendrier"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/calendar')}
            disabled={Permissions({ permission: 'CALENDAR_LIST' }) === undefined}
          />
          <CardDashboard
            title={t('dashboard.contracts_vehicule')}
            picto="contrats"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/contratsVehicule')}
            disabled={Permissions({ permission: 'CONTRACT_LIST' }) === undefined}
          />
        </section>
      </>
    );
  }
  // Sanitaire
  return (
    <>
      {/* 1ere line */}
      <section className={styles.gridTwoColumns}>
        <CardDashboard
          title={t('dashboard.my_book')}
          picto="monRegistreBlanc"
          classNames={[styles.cardBook, styles.cursorNotAllowed]}
          disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined
          && Permissions({ permission: 'BOOKLET_READ' }) === undefined}
        />
        <CardDashboard
          title={t('dashboard.documentary_base')}
          picto="documentaire"
          classNames={[styles.cursorNotAllowed]}
          borderTopColored
          disabled={Permissions({ permission: 'DOCUMENT_LIST' }) === undefined}
        />
      </section>
      {/* 2nd line */}
      <section className={styles.gridFourColumns}>
        <CardDashboard
          title={t('dashboard.journal')}
          picto="journalBlanc"
          classNames={[styles.cardSanitaryTheme, styles.cursorNotAllowed]}
          disabled={Permissions({ permission: 'DIARY_LIST' }) === undefined}
        />
        <CardDashboard
          title={t('dashboard.input')}
          picto="saisiesBlanc"
          classNames={[styles.cardSanitaryTheme, styles.cursorNotAllowed]}
          disabled={Permissions({ permission: 'INPUT_LIST' }) === undefined}
          texte={(
            <ul className={styles.ulsaisie}>
              {Permissions({ permission: 'INPUT_LIST' }) !== undefined
                && getIndicatorByEtablissementQuery?.data?.data?.lastTwoWeekSanitaryInputsDonePercent !== null ? (
                  <li>
                    <b>{getIndicatorByEtablissementQuery?.data?.data?.lastTwoWeekSanitaryInputsDonePercent}</b>
                    {' '}
                    {t('dashboard.lastTwoWeekSanitaryInputsDonePercent')}
                  </li>
                ) : ''}
              {Permissions({ permission: 'INPUT_LIST' }) !== undefined
                && getIndicatorByEtablissementQuery?.data?.data?.lastYearSanitaryInputsDonePercent !== null ? (
                  <li>
                    <b>{getIndicatorByEtablissementQuery?.data?.data?.lastYearSanitaryInputsDonePercent}</b>
                    {' '}
                    {t('dashboard.lastYearSanitaryInputsDonePercent')}
                  </li>
                ) : ''}
            </ul>
          )}
        />
        <CardDashboard
          title={t('dashboard.filing_reports')}
          picto="depotRapportBlanc"
          classNames={[styles.cardSanitaryTheme, styles.cursorNotAllowed]}
          disabled={Permissions({ permission: 'REPORT_LIST' }) === undefined}
          texte={(
            <ul className={styles.ul}>
              {Permissions({ permission: 'REPORT_LIST' }) !== undefined
                && getIndicatorByEtablissementQuery?.data?.data?.sanitaryInputsDonePercent !== null ? (
                  <li>
                    <b>{getIndicatorByEtablissementQuery?.data?.data?.sanitaryInputsDonePercent}</b>
                    {' '}
                    {t('dashboard.sanitaryInputsDonePercent')}
                  </li>
                ) : ''}
            </ul>
          )}
        />
        <CardDashboard
          title={t('dashboard.filling_other_documents')}
          picto="depotDocBlanc"
          classNames={[styles.cardSanitaryTheme, styles.cursorNotAllowed]}
          disabled={Permissions({ permission: 'DOCUMENT_CREATE' }) === undefined}
        />
      </section>
      {/* 3nd line */}
      <section className={styles.gridFourColumns}>
        <CardDashboard
          title={t('dashboard.calendar')}
          picto="calendrier"
          classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
          disabled={Permissions({ permission: 'CALENDAR_LIST' }) === undefined}
        />
        <CardDashboard
          title={t('dashboard.providers')}
          picto="prestataires"
          classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
          disabled={Permissions({ permission: 'SOCIETY_LIST' }) === undefined}
        />
        <CardDashboard
          title={t('dashboard.contracts')}
          picto="contrats"
          classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
          disabled={Permissions({ permission: 'CONTRACT_LIST' }) === undefined}
        />
        <CardDashboard
          title={t('dashboard.analysis')}
          picto="equipements"
          classNames={[styles.cardBlueLightedTheme, styles.cursorNotAllowed]}
          disabled={Permissions({ permission: 'ANALYSIS_LIST' }) === undefined}
          texte={(
            <ul className={styles.ul}>
              {Permissions({ permission: 'ANALYSIS_LIST' }) !== undefined
                && getIndicatorByEtablissementQuery?.data?.data?.pneumophilaAnalysisSanitaryPercent !== null ? (
                  <li>
                    {getIndicatorByEtablissementQuery?.data?.data?.pneumophilaAnalysisSanitaryPercent}
                    {' '}
                    {t('dashboard.complianceLastSanitaryAnalysisPercent')}
                  </li>
                ) : ' '}
            </ul>
          )}
        />
      </section>
    </>
  );
}

UserDashboard.propTypes = {};

export default UserDashboard;
