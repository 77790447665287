import api from './_api';

/**
 * GET - Get reports list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getReportsList = (queryParams) => api.get('/reports', { params: queryParams });

/**
 * GET - Get report
 * @param {Number} reportId
 * @returns {Promise<AxiosResponse>}
 */
export const getReport = (reportId) => api.get(`/reports/${reportId.id}`);

/**
 * GET - Get report
 * @param {Number} reportId
 * @returns {Promise<AxiosResponse>}
 */
export const getReportIntervention = (reportId) => api.get(`/reports/intervention/${reportId.id}`);

/**
 * POST - Create Report
 * @param {Object} reportToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const createReport = (reportToCreate) => api.post('/reports', reportToCreate);

/**
 * POST - Edit Report
 @param {Object} reportToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editReport = (reportToEdit) => api.post(`/reports/${reportToEdit.id}`, reportToEdit.data);
