/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { uniqWith, isEqual } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAppContext from '../../store/useAppContext';
// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import SelectForm from '../../components/atoms/Select/Select';
// Services
import {
  getGroup,
  getSubsidiary,
  getDivision,
  postGroupInformations,
  postSubsidiaryInformations,
  postDivisionInformations,
  editGroupInformations,
  editSubsidiaryInformations,
  editDivisionInformations,
  getStructuresList,
} from '../../services/structures';
// Utils
import styles from './AddStructure.module.css';
import { STRUCTURES_NAME } from '../../utils/constant';

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: '14.375rem',
    padding: '2px',
    border: '2px solid #dcdee8',
    borderRadius: '1.875rem',
  }),
};

const initialValues = {
  parentId: '',
  name: '',
  address: '',
  postalCode: '',
  city: '',
};

const validationSchema = Yup.object().shape({
  parentId: Yup.number(),
  name: Yup.string().required('global.required_field'),
  address: Yup.string().nullable(true),
  postalCode: Yup.string().nullable(true),
  city: Yup.string().nullable(true),
});

const options = [
  {
    value: STRUCTURES_NAME.GROUP,
    label: 'Groupe',
  },
  {
    value: STRUCTURES_NAME.SUBSIDIARY,
    label: 'Filiale',
  },
  {
    value: STRUCTURES_NAME.DIVISION,
    label: 'Division',
  },
];

function AddStructure() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [context] = useAppContext();
  let defaultOption = [];
  // States
  const [selectValue, setSelectValue] = useState();
  const subsidiaryFilter = ['Group'];
  const divisionFilter = ['Group', 'Subsidiary'];
  // API calls
  const getQueryFromStructure = (idToFetch) => {
    switch (urlParams.structure) {
      case 'Group': {
        return getGroup(idToFetch);
      }
      case 'Subsidiary': {
        return getSubsidiary(idToFetch);
      }
      case 'Division': {
        return getDivision(idToFetch);
      }
      default: {
        throw new Error(`Unhandled action type: ${urlParams.structure}`);
      }
    }
  };
  const postGroupInformationsMutation = useMutation(postGroupInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_structure.structure_added'));
    },
  });
  const postSubsidiaryInformationsMutation = useMutation(postSubsidiaryInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_structure.structure_added'));
    },
  });
  const postDivisionInformationsMutation = useMutation(postDivisionInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_structure.structure_added'));
    },
  });
  const editGroupInformationsMutation = useMutation(editGroupInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_structure.structure_edited'));
    },
  });
  const editSubsidiaryInformationsMutation = useMutation(editSubsidiaryInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_structure.structure_edited'));
    },
  });
  const editDivisionInformationsMutation = useMutation(editDivisionInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_structure.structure_edited'));
    },
  });

  useEffect(() => (urlParams ? setSelectValue(urlParams.structure) : null), [urlParams]);

  if (urlParams && urlParams.structure === 'Group') {
    defaultOption = options[0];
  } else if (urlParams && urlParams.structure === 'Subsidiary') {
    defaultOption = options[1];
  } else if (urlParams && urlParams.structure === 'Division') {
    defaultOption = options[2];
  }

  const formMutation = () => {
    switch (selectValue) {
      case 'Group':
        return {
          postStructureMutate: postGroupInformationsMutation.mutate,
          editStructureMutate: editGroupInformationsMutation.mutate,
        };
      case 'Subsidiary':
        return {
          postStructureMutate: postSubsidiaryInformationsMutation.mutate,
          editStructureMutate: editSubsidiaryInformationsMutation.mutate,
        };
      case 'Division':
        return {
          postStructureMutate: postDivisionInformationsMutation.mutate,
          editStructureMutate: editDivisionInformationsMutation.mutate,
        };
      default:
        return undefined;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      if (urlParams && urlParams.action === 'edit') {
        formMutation().editStructureMutate(formValues);
      } else {
        formMutation().postStructureMutate(formValues);
      }
    },
  });

  const getFormDataQuery = useQuery('structure', () => getQueryFromStructure(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues(data.data);
    },
  });

  const getAllStructuresQuery = useQuery('structures', () => getStructuresList({
    page: undefined,
    search: undefined,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const parentOptions = React.useMemo(() => (
    uniqWith(getAllStructuresQuery?.data?.data?.structures.map((structure) => (
      {
        value: structure.id,
        label: `${structure.name} - ${structure.type === STRUCTURES_NAME.GROUP ? t('structures.group') : ''} ${structure.type === STRUCTURES_NAME.SUBSIDIARY ? t('structures.subsidiary') : ''} ${structure.type === STRUCTURES_NAME.DIVISION ? t('structures.division') : ''} ${structure.groupName !== null ? `("${t('structures.group')}" - ${structure.groupName})` : ''}`,
        type: structure.type,
      }
    )), isEqual)
  ));

  if (getFormDataQuery.isLoading || getAllStructuresQuery.isLoading) {
    return (
      <Layout title={t('add_structure.title')}>
        <div className="loader" />
      </Layout>
    );
  }

  return (
    <Layout
      title={t('add_structure.title')}
      queryError={
        postGroupInformationsMutation?.error
        || postSubsidiaryInformationsMutation?.error
        || postDivisionInformationsMutation?.error
        || editGroupInformationsMutation?.error
        || editSubsidiaryInformationsMutation?.error
        || editDivisionInformationsMutation?.error
        || getFormDataQuery?.error
        || getAllStructuresQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_structure.back_to_structures')}</span>
          </button>
        </div>
        <h1 className="title">
          {urlParams?.action === 'edit' ? t('add_structure.edit_title') : t('add_structure.title')}
        </h1>
        <div className={styles.select}>
          <label className={styles.label} htmlFor="type">
            {t('add_structure.type')}
          </label>
          <Select
            styles={customStyles}
            id="type"
            options={options}
            placeholder={t('global.choose')}
            noOptionsMessage={() => t('global.no_options_available')}
            defaultValue={() => (urlParams ? defaultOption : null)}
            onChange={(e) => setSelectValue(e.value)}
          />
        </div>
      </header>
      {selectValue === 'Group' || selectValue === 'Subsidiary' || selectValue === 'Division' ? (
        <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
          {selectValue !== 'Group' ? (
            <div className="form_group">
              <SelectForm
                id="parentId"
                label={t('add_structure.parent_id')}
                options={parentOptions.filter((option) => {
                  if (selectValue === 'Subsidiary') {
                    return !!subsidiaryFilter.find((filterOption) => filterOption === option.type);
                  }
                  if (selectValue === 'Division') {
                    return !!divisionFilter.find((filterOption) => filterOption === option.type);
                  }
                  return true;
                })}
                value={parentOptions.find((option) => option.value === formik.values.parentId)}
                onChange={(option) => formik.setFieldValue('parentId', option.value)}
              />
            </div>
          ) : null}
          <div className="form_group">
            <Input
              id="name"
              type="text"
              name="name"
              label={t('add_structure.name')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.errors.name && formik.touched.name ? (
              <div className="error">
                {t(formik.errors.name)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <Input
              id="address"
              type="text"
              name="address"
              label={t('add_structure.address')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />
            {formik.errors.address && formik.touched.address ? (
              <div className="error">
                {formik.errors.address}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <Input
              id="postalCode"
              type="text"
              name="postalCode"
              label={t('add_structure.postal_code')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postalCode}
            />
            {formik.errors.postalCode && formik.touched.postalCode ? (
              <div className="error">
                {formik.errors.postalCode}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <Input
              id="city"
              type="text"
              name="city"
              label={t('add_structure.city')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
            />
            {formik.errors.city && formik.touched.city ? (
              <div className="error">
                {formik.errors.city}
              </div>
            ) : null }
          </div>
          <div className="form_footer">
            <div className="form_infos">
              <small>{t('add_structure.mandatory_fields')}</small>
            </div>
            <Button
              type="submit"
              className="form_submit"
              label={t('add_structure.submit')}
              isLoading={postDivisionInformationsMutation.isLoading}
            />
          </div>
        </form>
      ) : null}
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('add_structure.back_to_structures')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddStructure;
