import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Select from '../../components/atoms/Select/Select';
// Services
import { getControlType, postControlType, editControlType } from '../../services/controlTypes';
// Utils
import { CONTROL_PERIOD, VISIBILITY } from '../../utils/constant';
import useAppContext from '../../store/useAppContext';

const initialValues = {
  name: '',
  controlPeriod: '',
  visibility: '',
  displayOrder: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.required_field'),
  controlPeriod: Yup.string().required('global.required_field'),
  visibility: Yup.string().required('global.required_field'),
  displayOrder: Yup.number().required('global.required_field'),
});

function AddControlType() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [context] = useAppContext();
  // API calls
  const postControlTypeMutation = useMutation(postControlType, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_control_type.control_type_added'));
    },
  });
  const editControlTypeMutation = useMutation(editControlType, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_control_type.control_type_edited'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      if (urlParams?.action === 'edit') {
        editControlTypeMutation.mutate(formValues);
      } else {
        postControlTypeMutation.mutate(formValues);
      }
    },
  });

  const getControlTypeQuery = useQuery('control-type', () => getControlType(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues(data.data);
    },
  });

  const controlPeriodOptions = React.useMemo(() => [
    {
      value: CONTROL_PERIOD.MONDAY_BEFORE,
      label: t('add_control_type.monday_before'),
    },
    {
      value: CONTROL_PERIOD.CONTROL_DATE,
      label: t('add_control_type.control_date'),
    },
    {
      value: CONTROL_PERIOD.PERMANENTLY,
      label: t('add_control_type.permanently'),
    },
  ], []);

  const visibilityOptions = React.useMemo(() => [
    {
      value: VISIBILITY.UNIQUE,
      label: t('add_control_type.unique'),
    },
    {
      value: VISIBILITY.ONE_WEEK,
      label: t('add_control_type.one_week'),
    },
    {
      value: VISIBILITY.WHOLE_CYCLE,
      label: t('add_control_type.whole_cycle'),
    },
    {
      value: VISIBILITY.PERMANENTLY,
      label: t('add_control_type.permanently'),
    },
  ], []);

  const getVisibility = (controlValue) => {
    switch (controlValue) {
      case CONTROL_PERIOD.MONDAY_BEFORE:
        return VISIBILITY.ONE_WEEK;
      case CONTROL_PERIOD.CONTROL_DATE:
        return VISIBILITY.WHOLE_CYCLE;
      case CONTROL_PERIOD.PERMANENTLY:
        return VISIBILITY.PERMANENTLY;
      default:
        return undefined;
    }
  };

  return (
    <Layout
      title={t('add_control_type.title')}
      queryError={
        postControlTypeMutation?.error
        || editControlTypeMutation?.error
        || getControlTypeQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('add_control_type.back_to_control_types')}</span>
          </button>
        </div>
        <h1 className="title">
          {urlParams?.action === 'edit' ? t('add_control_type.edit_title') : t('add_control_type.title')}
        </h1>
      </header>
      <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
        <div className="form_group">
          <Input
            id="name"
            type="text"
            name="name"
            label={t('add_control_type.name')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.errors.name && formik.touched.name ? (
            <div className="error">
              {t(formik.errors.name)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            id="displayOrder"
            type="number"
            name="displayOrder"
            label={t('add_control_type.display_order')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.displayOrder}
          />
          {formik.errors.displayOrder && formik.touched.displayOrder ? (
            <div className="error">
              {t(formik.errors.displayOrder)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Select
            id="controlPeriod"
            label={t('add_control_type.control_period')}
            options={controlPeriodOptions}
            value={controlPeriodOptions.find((option) => option.value === formik.values.controlPeriod)}
            onChange={(option) => {
              formik.setFieldValue('controlPeriod', option.value);
              formik.setFieldValue('visibility', getVisibility(option.value));
            }}
            isDisabled={urlParams?.action === 'edit' && !getControlTypeQuery?.data?.data?.canBeFullyUpdated}
          />
          {formik.errors.controlPeriod && formik.touched.controlPeriod ? (
            <div className="error">
              {t(formik.errors.controlPeriod)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Select
            id="visibility"
            label={t('add_control_type.visibility')}
            options={visibilityOptions}
            value={visibilityOptions.find((option) => option.value === formik.values.visibility)}
            onChange={(option) => formik.setFieldValue('visibility', option.value)}
            isDisabled
          />
          {formik.errors.visibility && formik.touched.visibility ? (
            <div className="error">
              {t(formik.errors.visibility)}
            </div>
          ) : null }
        </div>
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('add_control_type.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('add_control_type.submit')}
            isLoading={postControlTypeMutation.isLoading}
          />
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('add_control_type.back_to_control_types')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddControlType;
