/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faFileArrowDown,
  faCircle,
  faEdit,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { sortBy } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Select from '../../components/atoms/Select/Select';
// Hooks
import useAppContext from '../../store/useAppContext';
// Services
import { getBuildingsList } from '../../services/structures';
// Utils
import styles from './ArchiveDocument.module.css';
import cn from '../../utils/cn';
import Permissions from '../../components/molecules/Permissions/Permissions';
import { deleteReport, getArchiveList, getArchiveMyBookletList } from '../../services/registre';
import { TRAINING_NAMES, TRAINING_TYPES } from '../../utils/constant';
import { deleteDocumentBaseItem } from '../../services/docBase';
import { deleteTraining } from '../../services/trainings';

function Archives() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  // States
  const [search, setSearch] = useState('0');
  const [selectedList, setSelectedList] = useState('0');
  const [buildingId, setBuildingId] = useState();
  const [Registres, setRegistres] = useState([]);
  const [approvedBody, setApprovedBody] = useState([]);
  const [internalManagement, setInternalManagement] = useState([]);
  const [qualifiedTechnician, setQualifiedTechnician] = useState([]);
  const [safetyCommittee, setSafetyCommittee] = useState([]);
  const [training, setTraining] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const [typeDelete, setTypeDelete] = useState();

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  // API Calls

  const getRegistreListQuery = useQuery(['RegistreList', buildingId], () => getArchiveList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    enabled: buildingId !== undefined && context?.choiceBooklet === 2,
    onSuccess: (data) => {
      setRegistres(data);
      setApprovedBody(data?.data?.approvedBody);
      setInternalManagement(data?.data?.internalManagement);
      setQualifiedTechnician(data?.data?.qualifiedTechnician);
      setSafetyCommittee(data?.data?.safetyCommittee);
      setTraining(data?.data?.training);
      setOtherDocuments(data?.data?.otherDocuments);
    },
  });

  const getRegistreMyBookletListQuery = useQuery(['RegistreList', buildingId], () => getArchiveMyBookletList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    enabled: buildingId !== undefined && context?.choiceBooklet === 1,
    onSuccess: (data) => {
      setRegistres(data);
      setApprovedBody(data?.data?.approvedBody);
      setInternalManagement(data?.data?.internalManagement);
      setQualifiedTechnician(data?.data?.qualifiedTechnician);
      setSafetyCommittee(data?.data?.safetyCommittee);
      setTraining(data?.data?.training);
      setOtherDocuments(data?.data?.otherDocuments);
    },
  });

  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      if (data?.data?.buildings?.length > 0) setBuildingId(data.data.buildings[0].id);
    },
  });

  const docfile = (id, fileName, type) => {
    if (type === 'report') {
      window.open(`${process.env.REACT_APP_API_URL}reports/download-report/${id}/${fileName}`, '_blank');
    } else if (type === 'document') {
      window.open(`${process.env.REACT_APP_API_URL}document-base-items/download-document/${id}/${fileName}`, '_blank');
    } else if (type === 'training') {
      window.open(`${process.env.REACT_APP_API_URL}trainings/download-document/${id}/${fileName}`, '_blank');
    }
  };

  const handleBuilding = (id) => {
    setBuildingId(id);
  };

  const uniqueEquipmentSet = new Set();

  const equipmentSafetyCommittee = Registres?.data?.safetyCommittee
    ?.filter((option) => option && option.equipment)
    .map((option) => {
      const value = option.equipment.id.toString();
      if (!uniqueEquipmentSet.has(value)) {
        uniqueEquipmentSet.add(value);
        return {
          value,
          label: option.equipment.name,
        };
      }
      return null;
    })
    .filter((option) => option !== null) || [];

  const equipmentQualifiedTechnician = Registres?.data?.qualifiedTechnician
    ?.filter((option) => option && option.equipment)
    .map((option) => {
      const value = option.equipment.id.toString();
      if (!uniqueEquipmentSet.has(value)) {
        uniqueEquipmentSet.add(value);
        return {
          value,
          label: option.equipment.name,
        };
      }
      return null;
    })
    .filter((option) => option !== null) || [];

  const equipmentInternalManagement = Registres?.data?.internalManagement
    ?.filter((option) => option && option.equipment)
    .map((option) => {
      const value = option.equipment.id.toString();
      if (!uniqueEquipmentSet.has(value)) {
        uniqueEquipmentSet.add(value);
        return {
          value,
          label: option.equipment.name,
        };
      }
      return null;
    })
    .filter((option) => option !== null) || [];

  const equipmentApprovedBody = Registres?.data?.approvedBody
    ?.filter((option) => option && option.equipment)
    .map((option) => {
      const value = option.equipment.id.toString();
      if (!uniqueEquipmentSet.has(value)) {
        uniqueEquipmentSet.add(value);
        return {
          value,
          label: option.equipment.name,
        };
      }
      return null;
    })
    .filter((option) => option !== null) || [];

  const equipementOption = [
    ...equipmentSafetyCommittee,
    ...equipmentQualifiedTechnician,
    ...equipmentInternalManagement,
    ...equipmentApprovedBody,
  ];

  const equipementOptionFiltered = [
    { value: '0', label: 'TOUS' },
    ...equipementOption.sort((a, b) => a.label.localeCompare(b.label)),
  ];

  const searchValue = (value) => {
    const newApprovedBody = Registres?.data?.approvedBody?.filter((option) => {
      if (option.equipment.name === value || value === 'TOUS') {
        return option;
      }
      return null;
    });
    setApprovedBody(newApprovedBody);
    const newInternalManagement = Registres?.data?.internalManagement?.filter((option) => {
      if (option.equipment.name === value || value === 'TOUS') {
        return option;
      }
      return null;
    });
    setInternalManagement(newInternalManagement);
    const newQualifiedTechnician = Registres?.data?.qualifiedTechnician?.filter((option) => {
      if (option.equipment.name === value || value === 'TOUS') {
        return option;
      }
      return null;
    });
    setQualifiedTechnician(newQualifiedTechnician);
    const newSafetyCommittee = Registres?.data?.safetyCommittee?.filter((option) => {
      if (option.equipment.name === value || value === 'TOUS') {
        return option;
      }
      return null;
    });
    setSafetyCommittee(newSafetyCommittee);
    const newTraining = Registres?.data?.training?.filter((option) => {
      if (value === 'TOUS') {
        return option;
      }
      return null;
    });
    setTraining(newTraining);
    const newOtherDocuments = Registres?.data?.otherDocuments?.filter((option) => {
      if (option.equipmentName === value || value === 'TOUS') {
        return option;
      }
      return null;
    });
    setOtherDocuments(newOtherDocuments);
  };

  useEffect(() => {
    setSearch('0');
  }, [Registres]);

  const deleteDocumentBaseItemQuery = useMutation(deleteDocumentBaseItem, {
    onSuccess:
      () => {
        toast.success(t('documentary.succesDelete'));
        getRegistreListQuery.refetch();
      },
  });
  const deleteReportQuery = useMutation(deleteReport, {
    onSuccess:
      () => {
        toast.success(t('documentary.succesDelete'));
        getRegistreListQuery.refetch();
      },
  });
  const deleteTrainingMutation = useMutation(deleteTraining, {
    onSuccess:
      () => {
        toast.success(t('documentary.succesDelete'));
        getRegistreListQuery.refetch();
      },
  });
  const handleDelete = (id, type) => {
    setOpenModal(false);
    if (type === 'report') {
      deleteReportQuery.mutate(id);
    } else if (type === 'training') {
      deleteTrainingMutation.mutate(id);
    } else if (type === 'document') {
      deleteDocumentBaseItemQuery.mutate(id);
    }
  };
  const handleModal = (id, type) => {
    setOpenModal(true);
    setsupplierToDelete(id);
    setTypeDelete(type);
  };

  const options = React.useMemo(() => {
    let optionsArray = [
      { value: '0', label: 'TOUS' },
      { value: '1', label: 'COMMISSION DE SÉCURITÉ' },
      { value: '2', label: 'ORGANISMES AGRÉÉS' },
      { value: '3', label: 'TECHNICIENS COMPÉTENTS' },
      { value: '4', label: 'FORMATION' },
      { value: '5', label: 'RÉGIE INTERNE' },
      { value: '6', label: 'AUTRES DOCUMENTS' },
    ];
    if (Registres?.data?.safetyCommittee?.length < 1) {
      optionsArray = optionsArray.filter((option) => option.value !== '1');
    }
    if (Registres?.data?.internalManagement?.length < 1) {
      optionsArray = optionsArray.filter((option) => option.value !== '5');
    }
    return optionsArray;
  }, [Registres]);

  const mapForSelect = (arrayToMap) => (
    arrayToMap?.length
      ? sortBy(arrayToMap, ['name']).map((item) => ({
        ...item,
        label: item.label,
        value: item.value,
      }))
      : []);
  const commission = React.useMemo(
    () => [
      {
        Header: t('registre.commisiondesecu'),
        accessor: 'interventionTitle',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.reportDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.reportDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.reportDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName, 'report')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.resquise'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/archives/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.prescRestantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    navigate(`/archives/Observations/remaining/${row?.original?.report?.id}`);
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'REPORT_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('documentary.supp')}
                  onClick={() => handleModal(row.original.report.id, 'report')}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const organisme = React.useMemo(
    () => [
      {
        Header: t('registre.RapportOrganismes'),
        accessor: 'interventionTitle',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.reportDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.reportDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.reportDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName, 'report')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/archives/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/archives/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/archives/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'REPORT_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('documentary.supp')}
                  onClick={() => handleModal(row.original.report.id, 'report')}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const technicien = React.useMemo(
    () => [
      {
        Header: t('registre.techie'),
        accessor: 'interventionTitle',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.reportDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.reportDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.reportDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName, 'report')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/archives/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/archives/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/archives/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'REPORT_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('documentary.supp')}
                  onClick={() => handleModal(row.original.report.id, 'report')}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  const formation = React.useMemo(
    () => [
      {
        Header: `${t('trainings.date_title')}`,
        accessor: 'date',
        Cell: ({ row: { original: { date } } }) => (
          format(new Date(date), 'dd/MM/yyyy')
        ),
      },
      {
        Header: `${t('trainings.company')}`,
        accessor: 'society',
        Cell: ({
          row: {
            original: {
              speakerName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? speakerName || t('add_training.house_keeping')
            : '-'}`
        ),
      },
      {
        Header: `${t('trainings.fullname')}`,
        accessor: 'fullname',
        Cell: ({
          row: {
            original: {
              firstName, lastName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? '-'
            : `${firstName} ${lastName}`}`
        ),
      },
      {
        Header: `${t('trainings.type')}`,
        accessor: 'type',
        Cell: ({ row: { original: { type } } }) => (
          `${type === TRAINING_TYPES?.FIRE_SAFETY_PREVENTION
            ? t('add_training.fire_safety_prevention')
            : t('add_training.maintenance_manager')}`
        ),
      },
      {
        Header: `${t('trainings.name')}`,
        accessor: 'name',
        Cell: ({ row: { original: { name } } }) => (
          `${name === TRAINING_NAMES.HANDLING_EMERGENCY_MEANS ? t('add_training.handling_emergency_means') : ''}
          ${name === TRAINING_NAMES.EVACUATION ? t('add_training.evacuation') : ''}
          ${name === TRAINING_NAMES.DESIGNATED_EMPLOYEES_EVACUATION
            ? t('add_training.designated_employees_evacuation')
            : ''
          }
          ${name === TRAINING_NAMES.UNANNOUNCED_EXERCISE ? t('add_training.unannounced_exercise') : ''}
          ${name === TRAINING_NAMES.SSI ? t('add_training.ssi') : ''}
          ${name === TRAINING_NAMES.INTERNAL ? t('add_training.internal') : ''}
          ${name === TRAINING_NAMES.SSIAP ? t('add_training.ssiap') : ''}
          ${name === TRAINING_NAMES.SST ? t('add_training.sst') : ''}
          ${name === TRAINING_NAMES.ELECTRICAL_CLEARANCE ? t('add_training.electrical_clearance') : ''}`
        ),
      },
      {
        Header: `${t('trainings.headcount')}`,
        accessor: 'headcount',
      },
      {
        Header: `${t('trainings.person_in_charge')}`,
        accessor: 'personInCharge',
      },
      {
        Header: `${t('trainings.document')}`,
        accessor: 'documentFileName',
        Cell: ({ row: { original: { documentFileName, id } } }) => (
          documentFileName !== null && (
            <Button
              type="button"
              className="action"
              label={<FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />}
              title={t('trainings.download')}
              onClick={() => docfile(id, documentFileName, 'training')}
            />
          )
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/formations/edit/${row?.original?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'REPORT_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('documentary.supp')}
                  onClick={() => handleModal(row.original.id, 'training')}
                />
              ) : null }
          </div>
        ),
      },
    ],
  );
  const regie = React.useMemo(
    () => [
      {
        Header: t('registre.regieName'),
        accessor: 'equipment.name',
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report?.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: t('registre.interventioninterneDate'),
        accessor: 'report?.reportDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.reportDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.reportDate).toLocaleDateString();
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName, 'report')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/archives/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/archives/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/archives/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/rapports/edit/${row?.original?.report?.id}`}
                  title={t('reports.edit')}
                  state={{
                    interventionId: row?.original?.report?.interventionId,
                    societyInChargeId: row?.original?.report?.societyInChargeId,
                    societyInChargeName: row?.original?.report?.societyInChargeName,
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'REPORT_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('documentary.supp')}
                  onClick={() => handleModal(row.original.report.id, 'report')}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  const otherDoc = React.useMemo(
    () => [
      {
        Header: t('otherDoc.otherDoc'),
        accessor: 'documentName',
      },
      {
        Header: t('otherDoc.building'),
        accessor: 'buildingName',
      },
      {
        Header: t('otherDoc.intervenant'),
        accessor: 'society',
      },
      {
        Header: t('otherDoc.date_inter'),
        accessor: 'date',
        Cell: ({ row }) => {
          if (row?.original?.date === null) {
            return '-';
          }
          return new Date(row?.original?.date).toLocaleDateString();
        },
      },
      {
        Header: t('otherDoc.num_report'),
        accessor: 'name',
      },
      {
        Header: `${t('otherDoc.report')}`,
        accessor: 'documentFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.id, row?.original?.documentFileName, 'document')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row }) => (
          <div className="actions">
            {Permissions({ permission: 'REPORT_CREATE' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/depot_document/edit/${row?.original?.id}`}
                  title={t('reports.edit')}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'REPORT_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className={cn(['action', 'suppr', styles.supp])}
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('documentary.supp')}
                  onClick={() => handleModal(row.original.id, 'document')}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title={t('archives.title')}
        queryError={
          getRegistreListQuery?.error
          || getBuildingsList?.error
          || deleteDocumentBaseItemQuery?.error
          || deleteReportQuery?.error
          || deleteTrainingMutation?.error
          || getRegistreMyBookletListQuery?.error
        }
        layout="table"
      >
        <header className="header">
          <div className="mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('archives.back')}</span>
            </button>
          </div>
          <div className="row mb-5">
            <h1 className="title">
              {t('archives.title')}
            </h1>
          </div>
          <div className={cn(['shadow-sm', styles.form])}>
            <div className="from-group">
              <Select
                className={cn(['col-4', styles.select])}
                label={t('archives.equipement')}
                name="equipement"
                id="equipement"
                type="text"
                value={
                  mapForSelect(equipementOption)
                    ?.find((item) => item.value === search)
                }
                onChange={(value) => {
                  setSearch(value.value);
                  searchValue(value.label);
                }}
                options={mapForSelect(equipementOptionFiltered)}
                loading={getRegistreListQuery?.isLoading}
              />
            </div>
            <div className="from-group">
              <Select
                className={cn(['col-4', styles.select])}
                label={t('archives.type')}
                name="type"
                type="text"
                value={
                    options.find((item) => item.value === selectedList)
                  }
                onChange={(value) => {
                  setSelectedList(value.value.toLowerCase());
                }}
                options={options}
              />
            </div>
          </div>
        </header>
        <div className={styles.filters}>
          <div className={styles.filtersToComplete}>
            {getBuildingsQuery?.data?.data?.buildings?.map((building) => (
              <Button
                type="button"
                label={`${building.name}`}
                className={cn([styles.filter, buildingId === building.id
                  ? styles.active
                  : '', 'shadow-md'])}
                onClick={() => handleBuilding(building?.id)}
              />
            ))}
          </div>
        </div>
        <div className={styles.shadow}>
          {(selectedList === '' || selectedList === '0' || selectedList === '1') && safetyCommittee?.length >= 1
            ? (
              <div className={styles.tableCommision}>
                <Table
                  columns={commission}
                  isLoading={getRegistreListQuery.isLoading}
                  data={safetyCommittee || []}
                  hasSort
                />
              </div>
            ) : null }
          {(selectedList === '' || selectedList === '0' || selectedList === '2') && approvedBody?.length >= 1 ? (
            <div className={styles.tableOrganisme}>
              <Table
                columns={organisme}
                isLoading={getRegistreListQuery.isLoading}
                data={approvedBody || []}
                hasSort
              />
            </div>
          ) : null }
          {selectedList === '' || selectedList === '0' || selectedList === '3' ? (
            <div className={styles.tableTechnicien}>
              <Table
                columns={technicien}
                isLoading={getRegistreListQuery.isLoading}
                data={qualifiedTechnician || []}
                hasSort
              />
            </div>
          ) : null }
          {(selectedList === '' || selectedList === '0' || selectedList === '5') && internalManagement?.length >= 1
            ? (
              <div className={styles.tableRegie}>
                <Table
                  columns={regie}
                  isLoading={getRegistreListQuery.isLoading}
                  data={internalManagement || []}
                  hasSort
                />
              </div>
            ) : null }
          {selectedList === '' || selectedList === '0' || selectedList === '4' ? (
            <div className={styles.tableFormations}>
              <Table
                columns={formation}
                isLoading={getRegistreListQuery.isLoading}
                data={training || []}
                hasSort
              />
            </div>
          ) : null }
          {selectedList === '' || selectedList === '0' || selectedList === '6' ? (
            <div className={styles.tableOtherDoc}>
              <Table
                columns={otherDoc}
                isLoading={getRegistreListQuery.isLoading}
                data={otherDocuments || []}
                hasSort
              />
            </div>
          ) : null }
        </div>
        <footer className="footer">
          <div className="mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('archives.back')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => handleDelete(supplierToDelete, typeDelete)}
          />
        </div>
      </Modal>
    </>
  );
}

export default Archives;
