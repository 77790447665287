import api from './_api';

/**
 * POST - Send login informations
 * @param {{email:String, password:String}} credentials
 * @returns {Promise<AxiosResponse>}
 */
export const postLoginCheck = (credentials) => api.post('/login_check', credentials);

/**
 * POST - Send email to request reset password
 * @param {{email:String}} credentials
 * @returns {Promise<AxiosResponse>}
 */
export const postRequestResetPassword = (credentials) => api.post('/reset-password', credentials);

/**
 * POST - Reset password
 * @param {{password:String, token:String}} credentials
 * @returns {Promise<AxiosResponse>}
 */
export const postResetPassword = (credentials) => api.post('/reset-password/reset', credentials);
