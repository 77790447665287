import api from './_api';
/* eslint-disable max-len */
/**
 * GET - Document base item category
 * @param {Number: bookletId} param
 * @returns {Promise<AxiosResponse>}
  * @param {Object} queryParams
 */
export const getDocumentBaseItemCategory = (queryParams) => api.get('/document-base-item-categories', { params: queryParams });

/**
 * GET - Document base item
 * @param {Number: categoryId} param
 * @returns {Promise<AxiosResponse>}
  * @param {Object} queryParams
 */
export const getDocumentBaseItem = (queryParams) => api.get('/document-base-items', { params: queryParams });

/**
 * POST - Document base item
 * @param {Object} param
 * @returns {Promise<AxiosResponse>}
 */
export const postDocumentBaseItem = (param) => api.post('/document-base-items ', param);

/**
 * DELETE - Document base item
 * @param {id: Number} param
 * @returns {Promise<AxiosResponse>}
 */
export const deleteDocumentBaseItem = (param) => api.delete(`/document-base-items/${param}`);

/**
 * GET - Document base item with categoryid
 * @param {id: Number} param
 * @returns {Promise<AxiosResponse>}
 */
export const getDocumentwithid = (param) => api.get(`/document-base-item-categories/${param.id}`, { params: param });

/**
 * GET - DOCUMENT DOWLOAD - Document base item
 * @param {Object} param
 * @returns {Promise<AxiosResponse>}
 */
export const getDocument = (param) => api.get(`/document-base-items/${param}/download-document`);

/**
 * GET - When Document base item with id
 * @param {id: Number} param
 * @returns {Promise<AxiosResponse>}
 */
export const getDocumentbyId = (param) => api.get(`/document-base-items/${param.id}`);

/**
 * POST - Document base item
 * @param {Object} param
 * @returns {Promise<AxiosResponse>}
 */
export const editDocumentBaseItem = (param) => api.post(`/document-base-items/${param.id}`, param.data);
