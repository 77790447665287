/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Styles
import styles from './Search.module.css';

// Utils
import cn from '../../../utils/cn';

function Search({
  className, id, value, onChange, select, Selectoptions,
}) {
  // Hooks
  const { t } = useTranslation();

  return (
    <div>
      <label className="visually-hidden" htmlFor={id}>{t('global.search')}</label>
      <div className={cn([styles.search, className])}>
        <input
          value={value}
          onChange={onChange}
          type="search"
          id={id}
          className={styles.input}
          placeholder={t('global.search')}
          list={select ? `${id}-datalist` : undefined}
        />
      </div>
      {select && (
      <datalist id={`${id}-datalist`}>
        {Selectoptions.map((option) => (
          <option key={option.value} value={option.label} />
        ))}
      </datalist>
      )}
    </div>
  );
}

Search.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  select: PropTypes.bool,
  Selectoptions: PropTypes.array.isRequired,
};

Search.defaultProps = {
  className: '',
  id: 'search-input',
  select: false,
};

export default Search;
