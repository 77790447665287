import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

// Components
import { sortBy } from 'lodash';
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Select from '../../components/atoms/Select/Select';
import { getVehiculelist } from '../../services/vehicules';
import { editDocumentBaseItem, getDocumentbyId } from '../../services/docBase';
import { getFolderlist } from '../../services/dossier';
import useAppContext from '../../store/useAppContext';
// css
import styles from '../AddDocument/AddDocument.module.css';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';

function AddDocument() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const urlParams = useParams();
  const [context] = useAppContext();
  const [id] = useState(urlParams.id);

  const goBack = (message) => {
    toast.success(message);
    navigate(-1);
  };
  // Api call
  const postDocumentBaseItemMutation = useMutation(editDocumentBaseItem, {
    onSuccess: () => {
      goBack(t('documentary.succesAdd'));
    },
  });

  const initialValues = {
    name: '',
    documentFile: '',
    date: '',
    vehicule: '',
    categoryId: '',
    typeId: '',
    structureIds: '',
    folderId: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required(t('global.required_field')).typeError(t('global.wrong_type')),
    documentFile: Yup.mixed().nullable().typeError(t('global.wrong_type')).when('isEditing', {
      is: true,
      then: Yup.mixed().nullable().required('global.required_field')
        .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 100000000)
        .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
          || value.type === 'application/x-pdf'
          || value.type === 'image/jpeg'
          || value.type === 'image/jpg'
          || value.type === 'image/png'
          || value.type === 'image/tiff'
          || value.type === 'image/bmp'
          || value.type === 'image/heic'
          || value.type === 'image/vnd.dwg')),
    }),
    date: Yup.string().nullable().required(t('global.required_field')),
    categoryId: Yup.string(),
    typeId: Yup.string(),
    structureIds: Yup.mixed(),
    folderId: Yup.string(),
  });

  const getVehiclesListQuery = useQuery(['vehiclesList'], () => getVehiculelist({
    page: 0,
    establishmentId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
  }), {
    enabled: !!context.choiceBooklet === 3,
  });

  const GetFolderList = useQuery(['contractlist'], () => getFolderlist({
    structureId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
    categoryId: urlParams.categoryid,
    page: 0,
  }));

  const mapForSelectVehicule = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['type']).map((item) => ({
      ...item,
      label: `${item?.brand} ${item?.model} (${item?.licensePlate})`,
      value: item.id,
    }))
    : []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('name', values.name);
      if (values.documentFile) {
        formData.append('documentFile', values.documentFile);
      }
      formData.append('folderId', values.folderId);
      formData.append('date', values.date);
      formData.append('categoryId', values.categoryId);
      formData.append('typeId', values.typeId);
      formData.append('structureIds', values.structureIds);
      postDocumentBaseItemMutation.mutate({
        id: urlParams.id,
        data: formData,
      });
    },
  });

  const mapForSelectfolder = (arrayToMap) => (
    arrayToMap?.length
      ? sortBy(arrayToMap, ['name']).map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
      : []
  );

  const category = [
    { label: 'Autorités', value: 54, id: 54 },
    { label: 'Organismes Agréés', value: 55, id: 55 },
    { label: 'Entreprises', value: 56, id: 56 },
  ];

  const getDocumentQuery = useQuery(['getDocumentbyId', id], () => getDocumentbyId({
    id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      formik.setFieldValue('name', data?.data?.name);
      formik.setFieldValue('date', format(new Date(data?.data?.date), 'yyyy-MM-dd'));
      formik.setFieldValue('documentFileName', data?.data?.documentFileName);
      formik.setFieldValue('categoryId', data?.data?.categoryId);
      formik.setFieldValue('typeId', data?.data?.typeId);
      formik.setFieldValue('structureIds', data?.data?.structureIds);
      formik.setFieldValue('folderId', data?.data?.folderId);
    },
  });
  useEffect(() => {
    getDocumentQuery.refetch();
  }, [id]);

  return (
    <Layout
      title="Documentaire Base Item"
      layout="table"
      queryError={
        getVehiclesListQuery?.error
        || getDocumentQuery?.error
        || postDocumentBaseItemMutation?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('documentary.return')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">
            {t('documentary.title')}
          </h1>
        </div>
      </header>
      <div className={styles.table}>
        <form onSubmit={formik.handleSubmit} className="form shadow-sm">
          <div className="form_group">
            <Input
              id="name"
              name="name"
              type="text"
              label={t('documentary.name')}
              placeholder={t('documentary.name')}
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue('name', e.target.value.toUpperCase())}
              required
            />
            {formik?.errors?.name && formik?.touched?.name ? (
              <div className="error">
                {t(formik?.errors?.name)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <Input
              id="date"
              name="date"
              type="date"
              label={t('documentary.date')}
              placeholder={t('documentary.date')}
              value={formik.values.date}
              onChange={formik.handleChange}
              required
            />
            {formik?.errors?.date && formik?.touched?.date ? (
              <div className="error">
                {t(formik?.errors?.date)}
              </div>
            ) : null }
          </div>
          <div className="form_group">
            <div className="label">{t('add_training.document_file')}</div>
            <UploadFile
              id="doccontact"
              name="doccontact"
              label={t('contract.doccontact')}
              fileName={formik.values.documentFile ? formik.values.documentFile.name : formik.values.documentFileName}
              onChange={(event) => formik.setFieldValue('documentFile', event.currentTarget.files[0])}
              onBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
            />
            {formik.errors.documentFile && formik.touched.documentFile ? (
              <div className="error">
                {t(formik.errors.documentFile)}
              </div>
            ) : null }
          </div>
          { context?.choiceBooklet === 3 && (
            <>
              <div className="form_group">
                <Select
                  label={t('docVehicule.vehicule')}
                  id="vehicles"
                  name="vehicule"
                  type="text"
                  placeholder={t('docVehicule.vehicule')}
                  onChange={(value) => formik.setFieldValue('vehicule', value.value)}
                  onBlur={formik.handleBlur}
                  valueInput={formik.values.vehicule}
                  required
                  options={mapForSelectVehicule(
                    getVehiclesListQuery?.data?.data?.vehicles,
                  )}
                  value={mapForSelectVehicule(
                    getVehiclesListQuery?.data?.data?.vehicles,
                  ).find((item) => item.value === formik.values.buildingId)}
                  loading={getVehiclesListQuery.isLoading}
                />
                {formik.touched.vehicule && formik.errors.vehicule ? (
                  <div className="error">{formik.errors.vehicule}</div>
                ) : null}
              </div>
              <div className="form_group">
                {' '}
              </div>
            </>
          )}
          {(formik.values.typeId === 54
            || formik.values.typeId === 55
            || formik.values.typeId === 56
            || formik.values.typeId === 36)
          && (
            <>
              <div className="form_group">
                <Select
                  name="typeId"
                  type="select"
                  label={t('dossierBase.category')}
                  value={
                    category.find(
                      (item) => item.value === formik.values.typeId,
                    )
                  }
                  onChange={(value) => formik.setFieldValue('typeId', value.value)}
                  options={category}
                  valueInput={formik.values.typeId}
                />
                {formik.errors.typeId && formik.touched.typeId ? (
                  <div className="error">
                    {t(formik.errors.typeId)}
                  </div>
                ) : null }
              </div>
              <div className="form_group">
                <Select
                  name="folderId"
                  type="select"
                  label={t('dossierBase.dossier')}
                  value={mapForSelectfolder(
                    GetFolderList?.data?.data?.documentBaseFolderDto,
                  ).find((item) => item.value === formik.values.folderId)}
                  onChange={(value) => formik.setFieldValue('folderId', value.value)}
                  options={mapForSelectfolder(GetFolderList?.data?.data?.documentBaseFolderDto)}
                  valueInput={formik.values.folderId}
                />
                {formik.errors.folderId && formik.touched.folderId ? (
                  <div className="error">
                    {t(formik.errors.folderId)}
                  </div>
                ) : null }
              </div>
            </>
          )}
          <div className="form_footer">
            <div className="form_infos">
              <small>{t('addReports.mandatory_fields')}</small>
            </div>
            <Button
              type="submit"
              className="form_submit"
              label={t('documentary.save')}
            />
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default AddDocument;
