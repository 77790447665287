import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import ControlType from '../../template/ControlType/ControlType';
import SwitchComment from '../InputTypes/SwitchComment/SwitchComment';

function OKNEKOCommObligTTLTList({ list, submit }) {
  // Hooks
  const { t } = useTranslation();
  const columns = [
    {
      label: '',
      width: 160,
    },
    {
      label: t('inputs.state'),
      width: 160,
    },
    {
      label: t('inputs.comment'),
      width: 265,
    },
  ];
  const initialList = list.map((input) => ({ ...input, isDirty: false }));
  const [inputsList, setInputsList] = useState(initialList);

  const handleChange = (input) => {
    const resultsChanged = inputsList.map((inputState) => {
      if (inputState.id === input.id) return ({ ...input, isDirty: true });
      return inputState;
    });
    setInputsList(resultsChanged);
  };

  const handleValuesSubmit = () => {
    const valuesToSubmit = inputsList
      .filter((input) => {
        if (input.isDirty
          && input.comment?.trim() !== ''
          && input.comment !== null) return true;
        return false;
      })
      .map((input) => ({
        inputId: input?.id,
        checked: input?.checked,
        comment: input?.comment,
      }));
    submit(valuesToSubmit);
    // setInputsList(initialList);
  };

  return (
    <ControlType columns={columns} onSubmit={handleValuesSubmit}>
      {inputsList.map((input) => (
        <SwitchComment
          key={input.id}
          id={input.id}
          label={input.title}
          value={input}
          onChange={handleChange}
          inputType="commObligTTLT"
        />
      ))}
    </ControlType>
  );
}

OKNEKOCommObligTTLTList.propTypes = {
  list: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
};

export default OKNEKOCommObligTTLTList;
