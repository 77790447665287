import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
// Components
import Button from '../Button/Button';
// Utils
import styles from './InfoMessage.module.css';
import cn from '../../../utils/cn';

function InfoMessage({ type, label }) {
  // States
  const [dismiss, setDismiss] = useState(false);
  // Hooks
  const { t } = useTranslation();

  return !dismiss ? (
    <div className={cn([
      styles.info,
      type === 'success' ? styles.infoSuccess : '',
      type === 'warning' ? styles.infoWarning : '',
      type === 'error' ? styles.infoError : '',
    ])}
    >
      <Button
        type="button"
        className={styles.dismiss}
        title={t('global.close')}
        label={<FontAwesomeIcon icon={faXmark} />}
        onClick={() => setDismiss(true)}
      />
      {label}
    </div>
  ) : null;
}

InfoMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
  label: PropTypes.string.isRequired,
};

export default InfoMessage;
