/* eslint-disable max-len */
import api from './_api';

/**
 * Get one Inputs
 * @param {id: Number} inputId
 * @returns {Promise<AxiosResponse>}
 */
export const getTemperatureCold = (inputId) => api.get(`/mybooklet/temperatures/cold/${inputId.buildingId}`);

/**
 * Get one Inputs
 * @param {id: Number} inputId
 * @returns {Promise<AxiosResponse>}
 */
export const getTemperatureHot = (inputId) => api.get(`/mybooklet/temperatures/hot/${inputId.buildingId}`);

/**
 * Get one Inputs
 * @param {id: Number} inputId
 * @returns {Promise<AxiosResponse>}
 */
export const getMeter = (inputId) => api.get(`/mybooklet/meters/${inputId.buildingId}`);

/**
 * PUT - PUT Input
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const putInput = (input) => api.put(`/inputs/${input.id}`, input);
