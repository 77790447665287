import api from './_api';

/**
 * GET - Get User Groups list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getUserGroupsList = (queryParams) => api.get('/user-groups', { params: queryParams });

/**
 * GET - Get User Groups Roles
 * @returns {Promise<AxiosResponse>}
 */
export const getUserGroupsRoles = () => api.get('/user-groups/roles');

/**
 * GET - Get User Group
 * @param {Number} userGroupId
 * @returns {Promise<AxiosResponse>}
 */
export const getUserGroup = (userGroupId) => api.get(`/user-groups/${userGroupId}`);

/**
 * POST - Create User Group
 * @param {{name:String, roles:Array}} userGroupToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postUserGroup = (userGroupToCreate) => api.post('/user-groups', userGroupToCreate);

/**
 * PUT - Edit User Group
 * @param {{id:Number, name:String, roles:Array}} userGroupToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editUserGroup = (userGroupToEdit) => api.put(`/user-groups/${userGroupToEdit.id}`, userGroupToEdit);

/**
 * DEL - Delete User Group
 * @param {Number} userGroupId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteUserGroup = (userGroupId) => api.delete(`/user-groups/${userGroupId}`);
