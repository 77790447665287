/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { sortBy, uniqBy } from 'lodash';
import { toast } from 'react-toastify';
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Textarea from '../../components/atoms/Textarea/Textarea';
import Select from '../../components/atoms/Select/Select';
import styles from './AddNotification.module.css';
import { CreateNotification } from '../../services/notification';
import useAppContext from '../../store/useAppContext';
import { getStructuresList } from '../../services/structures';
import { STRUCTURES_NAME, USERS_JOBS } from '../../utils/constant';

function addNotification() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [context] = useAppContext();
  const [structures, setStructures] = useState([]);

  const initialValues = {
    name: '',
    readingRequired: false,
    beginAt: '',
    endAt: '',
    structureId: '' || null,
    jobs: [],
    title: '',
    message: '',
  };

  const sendNotification = useMutation(CreateNotification, {
    onSuccess: () => {
      toast.success(t('notification.create'));
      navigate('/notificationlist/admin');
    },
    onError: () => {
      toast.error(t('notification.error'));
    },
  });

  const getAllStructuresQuery = useQuery(['structures'], () => getStructuresList({
    all: true,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setStructures(uniqBy(sortBy(data.data.structures, (order) => {
        const rank = {
          Group: 1,
          Subsidiary: 2,
          Division: 3,
          Establishment: 4,
          Building: 5,
        };
        return rank[order.type];
      }).map((structure) => (
        {
          value: structure.id,
          label: `
          ${structure.name} - 
          ${structure.type === STRUCTURES_NAME.GROUP ? t('structures.group') : ''} 
          ${structure.type === STRUCTURES_NAME.SUBSIDIARY ? t('structures.subsidiary') : ''} 
          ${structure.type === STRUCTURES_NAME.DIVISION ? t('structures.division') : ''} 
          ${structure.groupName !== null ? `("${t('structures.group')}" - ${structure.groupName})` : ''}`,
        }
      )), 'label'));
    },
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(t('notification.Required')),
    readingRequired: Yup.string().required(t('notification.Required')),
    dateStart: Yup.string(),
    dateEnd: Yup.string(),
    strtuctureId: Yup.string(),
    jobs: Yup.array(),
    title: Yup.string().required(t('notification.Required')),
    message: Yup.string().required(t('notification.Required')),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      sendNotification.mutate(values);
    },
  });

  const trad = [
    {
      value: USERS_JOBS.RECEPTION,
      label: t('add_user.reception'),
    },
    {
      value: USERS_JOBS.TECHNICAL_EXPERT,
      label: t('add_user.technical_expert'),
    },
    {
      value: USERS_JOBS.TECHNICAL_MANAGER,
      label: t('add_user.technical_manager'),
    },
    {
      value: USERS_JOBS.MANAGER,
      label: t('add_user.manager'),
    },
    {
      value: USERS_JOBS.AUTHORITIES,
      label: t('add_user.authorities'),
    },
    {
      value: USERS_JOBS.AUDITOR_MANAGER,
      label: t('add_user.auditor_manager'),
    },
  ];

  const mapForSelectjobs = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['name']).map((item) => ({
      ...item,
      label: `${item.name}`,
      value: trad[item.name],
    }))
    : []);
  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['name']).map((item) => ({
      ...item,
      label: `${item.name}`,
      value: item.id,
    }))
    : []);
  const option = [
    { value: 1, label: 'Validation requise' },
    { value: 0, label: 'Sans validation' },
  ];
  return (
    <Layout
      title={t('notification.titre')}
      icon="fas fa-plus"
      queryError={sendNotification?.error || getAllStructuresQuery?.error}
    >
      <header className="header">
        <div className="row mb-20">
          <button
            type="button"
            className="link"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('notification.backList')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">{t('notification.new_notification')}</h1>
        </div>
      </header>
      <div>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <div className="form_group">
            <Input
              id="name"
              label="Name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              required
            />
          </div>
          {formik.touched.name && formik.errors.name ? (
            <div>{formik.errors.name}</div>
          ) : null}
          <div className="form_group">
            <Select
              id="readingRequired"
              label="Validation"
              name="readingRequired"
              value={mapForSelect(option || [])
                .find((item) => item.value === formik.values.readingRequired)}
              onChange={(value) => formik.setFieldValue('readingRequired', value.value)}
              options={option}
              valueInput={formik.values.readingRequired}
              required
            />
            {formik.touched.readingRequired && formik.errors.readingRequired ? (
              <div>{formik.errors.readingRequired}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Input
              id="beginAt"
              label="Date de début"
              name="beginAt"
              type="date"
              value={formik.values.beginAt}
              onChange={formik.handleChange}
            />
            {formik.touched.beginAt && formik.errors.beginAt ? (
              <div>{formik.errors.beginAt}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Input
              id="endAt"
              label="Date de fin"
              name="endAt"
              type="date"
              min={formik.values.beginAt}
              value={formik.values.endAt}
              onChange={formik.handleChange}
            />
            {formik.touched.endAt && formik.errors.endAt ? (
              <div>{formik.errors.endAt}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Select
              id="structureId"
              label="Structure"
              name="structureId"
              value={structures
                .find((options) => options.value === formik.values.structureId)}
              onChange={(value) => formik.setFieldValue('structureId', value.value)}
              options={structures}
              loading={structures.isLoading}
            />
            {formik.touched.structureId && formik.errors.structureId ? (
              <div>{formik.errors.structureId}</div>
            ) : null}
          </div>
          <div className="form_group">
            <Select
              id="jobs"
              label="fonction **"
              name="jobs"
              isMulti
              isClearable
              noOptionsMessage={() => t('global.no_options_available')}
              defaultValue={mapForSelectjobs(trad || [])
                .filter((item) => formik.values.jobs.includes(item.value))}
              onChange={(value) => formik.setFieldValue('jobs', value.map((item) => item.value))}
              options={trad}
            />
            {formik.touched.jobs && formik.errors.jobs ? (
              <div>{formik.errors.jobs}</div>
            ) : null}
          </div>
          <div className={styles.titre}>
            <Input
              id="title"
              label="titre"
              name="title"
              type="text"
              value={formik.values.title}
              onChange={formik.handleChange}
              required
            />
            {formik.touched.title && formik.errors.title ? (
              <div>{formik.errors.title}</div>
            ) : null}
          </div>
          <div className={styles.textarea}>
            <Textarea
              id="message"
              label="Message *"
              name="message"
              className={styles.textarea2}
              type="text"
              value={formik.values.message}
              onChange={formik.handleChange}
              required
            />
            {formik.touched.message && formik.errors.message ? (
              <div>{formik.errors.message}</div>
            ) : null}
          </div>
          <div className="form_footer">
            <div className="form_infos">
              <small>{t('addInterventions.mandatory_fields')}</small>
              {' '}
              **
              {' '}
              <small>{t('addInterventions.multipleChoice')}</small>
            </div>
            <div>
              <Button
                type="submit"
                className="form_submit"
                label={t('notification.cree')}
              />
            </div>
          </div>
        </form>
      </div>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate('/societyList')}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('notification.backList')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default addNotification;
