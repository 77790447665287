/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faTrashCan,
  faList,
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Search from '../../components/molecules/Search/Search';
import { getVehiculelist, typeVehicleList } from '../../services/vehicules';
import 'reactjs-popup/dist/index.css';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './CarList.module.css';
import useAppContext from '../../store/useAppContext';

function Carlist() {
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [context] = useAppContext();
  // API Calls

  const VehiculeQueryList = useQuery(['vehicule', page, search], () => getVehiculelist({
    page: 0,
    search,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    isActive: 0,
  }));

  const TypeVehicule = useQuery(['typeVehicule', page], () => typeVehicleList({
    page,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }));

  const SearchTypeVehicule = React.useMemo(() => {
    if (VehiculeQueryList?.data && TypeVehicule.data) {
      return VehiculeQueryList?.data?.data?.vehicles?.map((vehicule) => ({
        ...vehicule,
        brand: vehicule?.brand.toUpperCase(),
        buildingName: vehicule?.buildingId,
        vehicleTypeId: TypeVehicule?.data?.data?.vehicleTypes?.find((type) => type.id === vehicule?.vehicleTypeId),
      }));
    }
    return [];
  }, [VehiculeQueryList.data, TypeVehicule.data]);

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('vehicule.type')}`,
        accessor: 'vehicleTypeId.type',
        Cell: ({ row }) => (
          <div title={row?.original?.vehicleTypeId?.label}>
            {row?.original?.vehicleTypeId?.type}
          </div>
        ),
      },
      {
        Header: `${t('vehicule.bulding')}`,
        accessor: 'establishementName',
        Cell: ({ row }) => {
          if (row?.original?.establishementName === null) {
            return '-';
          }
          return (
            <div>
              <span data-tip data-for={`userColResult${row?.original?.id}`}>
                {row?.original?.establishementName}
              </span>
              {' '}
              {row?.original?.divisionName || row?.original?.groupName || row?.original?.subsidiaryName || row?.original?.establishementName ? (
                <ReactTooltip id={`userColResult${row?.original?.id}`} place="top" type="info" effect="solid">
                  {row?.original?.establishementName ? (
                    <div className={styles.nomaj}>
                      Establishement:
                      {' '}
                      {row?.original?.establishementName}
                    </div>
                  ) : null}
                  {row?.original?.divisionName ? (
                    <div className={styles.nomaj}>
                      Division:
                      {' '}
                      {row?.original?.divisionName}
                    </div>
                  ) : null}
                  {row?.original?.groupName ? (
                    <div className={styles.nomaj}>
                      Groupe:
                      {' '}
                      {row?.original?.groupName}
                    </div>
                  ) : null}
                  {row?.original?.subsidiaryName ? (
                    <div className={styles.nomaj}>
                      Filiale:
                      {' '}
                      {row.original.subsidiaryName}
                    </div>
                  ) : null}
                  {row?.original?.buildingsName ? (
                    <div className={styles.nomaj}>
                      Bâtiment:
                      {' '}
                      {row.original.buildingsName}
                    </div>
                  ) : null}
                </ReactTooltip>
              ) : null }
            </div>
          );
        },
      },
      {
        Header: `${t('vehicule.brand')}`,
        accessor: 'brand',
      },
      {
        Header: `${t('vehicule.model')}`,
        accessor: 'model',
      },
      {
        Header: `${t('vehicule.immatform')}`,
        accessor: 'licensePlate',
      },
      {
        Header: `${t('vehicule.currentMileage')}`,
        accessor: 'currentMileage',
      },
      {
        Header: `${t('vehicule.nextMaintenance')}`,
        accessor: 'lastMaintenanceDate.date',
        Cell: ({ row }) => {
          if (row?.original?.lastMaintenanceDate === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {new Date(row?.original?.lastMaintenanceDate?.date).toLocaleDateString()}
            </div>
          );
        },
      },
      {
        Header: `${t('vehicule.serviceIntervalMileage')}`,
        accessor: 'lastMaintenanceMileage',
        Cell: ({ row }) => {
          if (row?.original?.lastMaintenanceMileage === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {row?.original?.lastMaintenanceMileage}
              {' '}
            </div>
          );
        },
      },
      {
        Header: `${t('vehicule.datect')}`,
        accessor: 'lastControlDate.date',
        Cell: ({ row }) => {
          if (row?.original?.lastControlDate === null) {
            return '-';
          }
          return (
            <div className={styles.actions}>
              {new Date(row?.original?.lastControlDate?.date).toLocaleDateString()}
            </div>
          );
        },
      },
      {
        Header: `${t('vehicule.actif')}`,
        accessor: 'active',
        Cell: ({ row: { original: { isActive } } }) => (
          isActive
            ? (
              <span className="tag tag--transparent tag--success">
                <FontAwesomeIcon icon={faCircleCheck} />
                {' '}
                {t('global.yes')}
              </span>
            )
            : (
              <span className="tag tag--transparent tag--error">
                <FontAwesomeIcon icon={faCircleXmark} />
                {' '}
                {t('global.no')}
              </span>
            )
        ),
      },
      {
        Header: ' ',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div>
            <button
              type="button"
              className="action edit"
              alt="modifier le vehicule"
              title="modifier le vehicule"
              onClick={() => navigate(`/EditVehicule/${row?.original?.id}`)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              type="button"
              className="action list"
              name="check list"
              alt="accedez a la check list"
              title="accedez a la check list"
              onClick={() => navigate(`/checklistVehicule/${row?.original?.id}`)}
            >
              <FontAwesomeIcon icon={faList} />
            </button>
            {/* <Button
              type="button"
              className="action"
              label={<FontAwesomeIcon icon={faExchange} />}
              title={t('contract.exchange')}
              onClick={() => navigate('/tranfert/Vehicule/8')}
            /> */}
            <button
              type="button"
              className="action suppr"
              alt="supprimer le vehicule"
              title="supprimer le vehicule"
              onClick={() => navigate(`/mon-vehicule/${row?.original?.id}`)}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Layout
      title={t('vehicule.title')}
      layout="table"
      table={SearchTypeVehicule || []}
      queryError={
        VehiculeQueryList?.error
        || TypeVehicule?.error
        || SearchTypeVehicule?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate('/dashboard')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('global.dashboard')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">{t('vehicule.title')}</h1>
        </div>
      </header>
      <div className={styles.table}>
        <div className="row">
          <Search
            onChange={handleSearch}
            className="mb-20"
          />
        </div>
      </div>
      <div className={styles.table}>
        <Table
          columns={columns}
          isLoading={SearchTypeVehicule?.isLoading}
          data={SearchTypeVehicule || []}
          hasSort
          pagination={(
            <Pagination
              previousLabel={(
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
              nextLabel={(
                <FontAwesomeIcon icon={faChevronRight} />
              )}
              onPageChange={onPageChange}
              totalCount={SearchTypeVehicule?.length || 0}
              currentPage={page}
              haveToPaginate
              pageSize={10}
            />
        )}
        />
      </div>
      <div>
        <button type="button" className="link" onClick={() => navigate('/dashboard')}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('contact.dashboard')}</span>
        </button>
      </div>
    </Layout>
  );
}
export default Carlist;
