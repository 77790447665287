/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
// Utils
import styles from './UploadFile.module.css';
import cn from '../../../utils/cn';

function UploadFile({
  id, name, label, fileName: propFileName, onChange, required, setFieldValue, enableDragDrop, ...props
}) {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState(propFileName);

  const handleDragEnter = (e) => {
    if (!enableDragDrop) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    if (!enableDragDrop) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    if (!enableDragDrop) return;
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    if (!enableDragDrop) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFileName(droppedFile.name);
      setFieldValue(name, droppedFile);
    }
  };

  const handleInputChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      onChange(e);
    }
  };

  return (
    <div>
      <p className={cn([styles.label, 'visually-hidden'])}>
        {label}
      </p>
      <div
        className={cn([enableDragDrop ? styles.field : styles.otherField, isDragging && styles.dragging])}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <label className={styles.fakeInput} htmlFor={id} title={t('global.select_file')}>
          <FontAwesomeIcon icon={faUpload} />
        </label>
        <input
          className={styles.input}
          id={id}
          type="file"
          name={name}
          onChange={handleInputChange}
          required={required}
          {...props}
        />
        <div className={styles.value}>
          {fileName || <span className={styles.disabled}>{t('global.no_file_selected')}</span>}
        </div>
        {fileName ? (
          <span className={styles.picto}>
            <FontAwesomeIcon icon={faCircleCheck} />
          </span>
        ) : null}
      </div>
    </div>
  );
}

UploadFile.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fileName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  enableDragDrop: PropTypes.bool,
};

UploadFile.defaultProps = {
  fileName: '',
  label: '',
  required: false,
  enableDragDrop: true,
};

export default UploadFile;
