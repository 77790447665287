import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
// Components
import Input from '../../../atoms/Input/Input';
import Textarea from '../../../atoms/Textarea/Textarea';
import Button from '../../../atoms/Button/Button';

import styles from '../RadioNumberTime/RadioNumberTime.module.css';
import cn from '../../../../utils/cn';

function NumberTime({
  label, value, onChange, id,
}) {
  const { t } = useTranslation();

  const currentDate = new Date();
  const currentHours = (`0${currentDate.getHours()}`).slice(-2);
  const currentMinutes = (`0${currentDate.getMinutes()}`).slice(-2);
  const currentTime = `${currentHours}:${currentMinutes}`;
  const [pop, setPop] = useState();
  const [remarque, setRemarque] = useState('');
  const [remarqueFinal, setRemarqueFinal] = useState();
  const EffectPop = useRef();
  const ColorRemarque = useRef();
  const [RemarqueNull, setRemarqueNull] = useState();
  const [titlePop, setTitlePop] = useState();
  const [Nb, setNb] = useState();

  const PopUp = (nb, min, max) => {
    setRemarqueNull();
    if (min !== null && max !== null) {
      const title = `La température saisie [${nb}] n'est pas dans
      la plage de valeurs correctes [entre ${min} et ${max}]`;
      setPop(true);
      setTitlePop(title);
      setNb(nb);
    }
  };
  const PopEffect = () => {
    EffectPop.current.style.transform = 'scale(1.01)';
    setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
  };
  const ChangeValue = () => {
    setRemarqueFinal();
    setPop(false);
    setRemarque('');
    document.getElementById(`number-${id}`).focus();
  };
  const PopValid = (params) => {
    if (remarque !== '') {
      setRemarqueFinal(remarque);
      setPop(false);
    } else {
      EffectPop.current.style.transform = 'scale(1.01)';
      setTimeout(() => { EffectPop.current.style.transform = 'scale(1)'; }, 75);
      setRemarqueNull(true);
    }
    if (params === 'valid') {
      ColorRemarque.current.style.color = 'green';
    } else if (params === 'aide') {
      ColorRemarque.current.style.color = 'orange';
    }
  };

  return (
    <div>
      <div className="inputForm" style={{ borderBottom: remarqueFinal && 'none' }}>
        <div className="inputForm_control inputForm_label">
          {label}
        </div>
        <div className="inputForm_control inputForm_input control">
          {window.innerWidth < 768 && (
            <div className="inputForm_control inputForm_label inputTitle">
              {t('inputs.degrees_value')}
            </div>
          )}
          <Input
            id={`number-${id}`}
            name={`number-${id}`}
            label={label}
            labelHidden
            type="number"
            responsive
            value={value.degreeValue}
            onChange={(e) => onChange({ ...value, degreeValue: e.target.value })}
            onBlur={(e) => {
              if ((e.target.value < value.paramLowLimit || e.target.value > value.paramHighLimit)
                && e.target.value !== '') {
                PopUp(e.target.value, value.paramLowLimit, value.paramHighLimit);
              }
            }}
            step="0.1"
            required={!!value.isDirty}
          />
        </div>
        <div className="inputForm_control inputForm_input control">
          {window.innerWidth < 768 && (
            <div className="inputForm_control inputForm_label inputTitle">
              {t('inputs.recorded_time')}
            </div>
          )}
          <Input
            id={`time-${id}`}
            name={`time-${id}`}
            type="time"
            responsive
            value={value.time || (value?.recordDate !== null && value?.recordDate.substring(11, 16)) || currentTime}
            onChange={(e) => onChange({ ...value, time: e.target.value })}
            label={label}
            labelHidden
            required={!!value.isDirty}
          />
        </div>
      </div>
      {!remarqueFinal ? (
        <div className={styles.remarque} style={{ display: value.alertComment ? 'flex' : 'none' }}>
          <div className="inputForm_control inputForm_label">
            {'Commentaire de l\'alerte'}
          </div>
          <div className={styles.remarque} style={{ borderBottom: '0' }}>
            <p style={{ color: '#28337e' }}>{value.alertComment}</p>
          </div>
        </div>
      ) : null }
      <div style={{ display: remarqueFinal ? '' : 'none' }}>
        <div className={styles.remarque}>
          <p ref={ColorRemarque}>{remarqueFinal}</p>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => { PopUp(Nb, value.paramLowLimit, value.paramHighLimit); }}
            className={styles.icon}
          />
        </div>
      </div>
      <div className={styles.pop_up} style={{ display: pop ? '' : 'none' }} ref={EffectPop}>
        <h3 className={styles.title_popUp}>{titlePop}</h3>
        <div className={styles.textarea_popUp}>
          <Textarea
            style={RemarqueNull && {
              border: 'red solid 1px',
            }}
            id="remarque"
            name="remarque"
            label={t('alert.remarque')}
            placeholder={RemarqueNull ? 'Veuillez indiquer une remarque..' : ''}
            value={remarque}
            onChange={(e) => { setRemarque(e.target.value); }}
          />
        </div>
        <div className={styles.div_button}>
          <Button
            type="button"
            label={t('alert.valider')}
            onClick={() => {
              PopValid('valid');
              onChange({
                ...value,
                alert: true,
                alertComment: remarque,
                alertHelp: false,
              });
            }}
            className={cn([styles.btn_popUp, styles.btn_blue])}
          />
          <Button
            type="button"
            label={t('alert.aide')}
            onClick={() => {
              PopValid('aide');
              onChange({
                ...value,
                alert: true,
                alertComment: remarque,
                alertHelp: true,
              });
            }}
            className={cn([styles.btn_popUp, styles.btn_orange])}
          />
          <Button
            type="button"
            label={t('alert.change_value')}
            onClick={() => { ChangeValue(); }}
            className={cn([styles.btn_popUp, styles.btn_red])}
          />
        </div>
      </div>
      <div
        className={styles.back_popUp}
        style={{ display: pop ? '' : 'none' }}
        role="button"
        onClick={() => { PopEffect(); }}
        onKeyUp={() => {}}
        tabIndex={0}
      >
        {' '}
      </div>
    </div>
  );
}

NumberTime.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.objectOf({
    degreeValue: PropTypes.number,
    time: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default NumberTime;
